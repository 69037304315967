<div fxLayout="row">
  <div fxFlex="none">
    <h3 [ngClass]="'mat-headline no-margin'" style="display: block">
      Generate Report
    </h3>
    <!-- <button (click)="sendEmail('rengifoalexis@yahoo.com')">Send Email</button> -->
  </div>

  <div fxFlex="none">
    <button mat-flat-button mat-dialog-close color="warn">X</button>
  </div>
</div>

<div mat-dialog-content>
  <div fxLayoutAlign="center center">
    <div
      class="
        mdc-text-field
        mdc-text-field--outlined
        mdc-text-field--no-label
        mdc-text-field--with-leading-icon
      "
      style="width: 80%; margin-right: 10px; display: none"
    >
      <input
        type="text"
        class="mdc-text-field__input"
        aria-label="Label"
        #ownerId
        placeholder="Enter Owner ID for report"
        [value]="ownerID"
        #ownerIDInput
        (input)="ownerID = ownerIDInput.value"
      />
      <div class="mdc-notched-outline">
        <div class="mdc-notched-outline__leading"></div>
        <div class="mdc-notched-outline__trailing"></div>
      </div>
    </div>
    <mat-form-field appearance="fill">
      <mat-label>Select Owner</mat-label>
      <mat-select>
        <mat-option
          *ngFor="let owner of allOwners"
          [value]="owner.uid"
          (click)="ownerId.value = owner.uid"
        >
          <span class="mdc-list-item__text">
            {{ owner.name }} - {{ owner.email }} </span
          >/ {{ owner.uid }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      color="warn"
      class="mdc-button submission-button"
      (click)="ownerID = ownerId.value; generateReportInfo()"
    >
      BUILD
    </button>
  </div>
  <div id="toPDF" width="1000" height="1000">
    <p id="profileFont" style="margin: 10px">
      Total number of Owners - {{ numberOfClients }}
    </p>

    <mat-divider></mat-divider>
    <h4 id="profileFont" style="margin: 10px" *ngIf="ownerID.length > 0">
      {{ ownerID }} Report
    </h4>
    <ul class="mdc-list" *ngIf="ownerID.length > 0">
      <li class="mdc-list-item" tabindex="0">
        <span
          class="mdc-list-item__text"
          *ngIf="allFilesAndCount['countOfFiles']"
          >{{ allFilesAndCount["countOfFiles"] }} Files</span
        >
        <mat-spinner
          style="margin-right: 10px"
          diameter="20"
          color="warn"
          strokeWidth="5"
          mode="indeterminate"
          *ngIf="!allFilesAndCount['countOfFiles']"
        ></mat-spinner>
      </li>
      <li class="mdc-list-item" tabindex="0">
        <span class="mdc-list-item__text"
          >{{ numberOfUploadsClient }} Uploads by Owner</span
        >
      </li>
      <li class="mdc-list-item" tabindex="0">
        <span class="mdc-list-item__text" *ngIf="totalSize"
          >{{ formatBytes(totalSize) }} of Storage Used</span
        >
        <mat-spinner
          style="margin-right: 10px"
          diameter="20"
          color="warn"
          strokeWidth="5"
          mode="indeterminate"
          *ngIf="!totalSize"
        ></mat-spinner>
      </li>
    </ul>
  </div>
</div>
<mat-dialog-actions align="center">
  <button mat-flat-button mat-dialog-close color="accent">Cancel</button>
  <button mat-flat-button (click)="downloadPDF()" color="primary">
    DOWNLOAD REPORT
  </button>
</mat-dialog-actions>
