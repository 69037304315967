import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blue-asterisk',
  templateUrl: './blue-asterisk.component.html',
  styleUrls: ['./blue-asterisk.component.scss']
})
export class BlueAsteriskComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
