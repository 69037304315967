<mat-chip-list
  selectable="false"
  aria-label="User Information">
  <mat-chip
    *ngIf="patient.caseName"
    color="primary"
    [ngClass]="['mat-body', 'case-name']">
    <b>Client/Matter id:</b>&nbsp;{{ patient.caseName }}
  </mat-chip>
  <mat-chip
    *ngIf="patient.LegalCaseId"
    disableRipple="true"
    [ngClass]="'mat-body'">
    Client/Matter ID - {{ patient.LegalCaseId }}
  </mat-chip>
  <mat-chip
    *ngIf="patient.DateOfBirth"
    disableRipple="true"
    [ngClass]="'mat-body'"
    color="warn">
    DOB - {{ patient.DateOfBirth }}
  </mat-chip>
  <mat-chip
    color="warn"
    *ngIf="patient.clientUser && patient.clientUser.length > 0 && !['silver', 'gold'].includes(plancode)"
    ><b>Client user:</b>&nbsp;{{ patient.clientUser }}</mat-chip
  >
</mat-chip-list>
