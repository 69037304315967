import { Injectable } from '@angular/core';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  createTicket(subject, body) {
    // Call to the Zendesk Callable Cloud Function
    return firebase.functions().httpsCallable('zendesk-createTicket')({ subject, body });
  }

  constructor() {}
}
