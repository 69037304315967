import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-plans-buttons',
  templateUrl: './plans-buttons.component.html',
  styleUrls: ['./plans-buttons.component.scss'],
})
export class PlansButtonsComponent implements OnInit {
  @Input() public plans: any;
  @Output() public choosePlan: EventEmitter<any> = new EventEmitter<any>();
  pricingplans: any;
  constructor() {}

  ngOnInit(): void {
    console.log('this.plans: ', this.plans);
    this.pricingplans = this.plans;
  }

  handleChoosePlan(plan: any) {
    this.choosePlan.emit(plan);
  }
}
