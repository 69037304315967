<app-window-title
  title="Create an Owner"
  [closable]="true"></app-window-title>
<div mat-dialog-content>
  <div
    fxLayoutAlign="center center"
    fxLayout="column">
    <form
      [formGroup]="createClientForm"
      (ngSubmit)="onSubmit()"
      fxLayout="column"
      fxLayoutAlign="center center">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="nameField"
          type="text"
          placeholder="Name"
          #nameInput
          [value]="name"
          (input)="name = nameInput.value"
          required />
        <mat-error *ngIf="createClientForm.controls['nameField'].invalid">
          {{ getErrorMessage(createClientForm.controls['nameField']) }}</mat-error
        >
      </mat-form-field>
      <div class="mdc-text-field-helper-line">
        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">You must have a name</div>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>Company name</mat-label>
        <input
          type="text"
          formControlName="companyNameField"
          matInput
          placeholder="Company Name"
          [value]="companyName"
          #companyNameInput
          (input)="companyName = companyNameInput.value"
          required />
        <mat-error *ngIf="createClientForm.controls['companyNameField'].invalid">
          {{ getErrorMessage(createClientForm.controls['companyNameField']) }}
        </mat-error>
      </mat-form-field>

      <div class="mdc-text-field-helper-line">
        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">Enter in a company name</div>
      </div>

      <!--Email Text Field -->
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input
          type="email"
          matInput
          formControlName="emailField"
          placeholder="Email"
          #emailInput
          [value]="email"
          (input)="email = emailInput.value"
          required />
        <mat-error *ngIf="createClientForm.controls['emailField'].invalid">
          {{ getErrorMessage(createClientForm.controls['emailField']) }}</mat-error
        >
      </mat-form-field>
      <div class="mdc-text-field-helper-line">
        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
          Enter in a correct email
        </div>
      </div>

      <!-- Password Text Field -->
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input
          type="password"
          matInput
          formControlName="passwordField"
          [value]="password"
          #passwordInput
          (input)="password = passwordInput.value"
          required
          minlength="8"
          placeholder="Password" />
        <mat-error *ngIf="createClientForm.controls['passwordField'].invalid">
          {{ getErrorMessage(createClientForm.controls['passwordField']) }}
        </mat-error>
      </mat-form-field>
      <div class="mdc-text-field-helper-line">
        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
          You must have a min length of 8
        </div>
      </div>
    </form>
    <div
      fxLayout="column"
      fxLayoutAlign="center center">
      <h5 style="color: red">{{ errorMessage }}</h5>

      <!-- Forgot your password Text -->
      <p class="fgpass-text mat-body">A password reset link will be sent to the user</p>
    </div>
  </div>
</div>
<mat-dialog-actions align="center">
  <button
    [disabled]="!createClientForm.valid"
    mat-flat-button
    (click)="createUser('Owner')"
    color="warn">
    CREATE OWNER
  </button>
</mat-dialog-actions>
