import { BlockFile } from '../services/upload-helper.service';
import { DICOMMetaDataUnit } from './DICOMMetaDataUnit';

export class UploadBlock {
  blockFiles: any[];
  casename: string;
  clientName: string;
  creator: string;
  desc: string;
  DICOMMetaData: DICOMMetaDataUnit[];
  fireStoreFiles: any;
  fdate: string;
  ftime: string;
  id: any;
  status: string;
  type: string;
  ugid: string;

  constructor(blockDataObj) {
    this.blockFiles = blockDataObj.blockFiles;
    this.casename = blockDataObj.casename;
    this.fireStoreFiles = blockDataObj.fireStoreFiles;
    this.clientName = blockDataObj.clientName;
    this.creator = blockDataObj.creator;
    this.desc = blockDataObj.desc;
    this.DICOMMetaData = blockDataObj.DICOMMetaData;
    this.fdate = blockDataObj.fdate;
    this.ftime = blockDataObj.ftime;
    this.id = blockDataObj.id;
    this.status = blockDataObj.status;
    this.type = blockDataObj.type;
    this.ugid = blockDataObj.ugid;
  }

  /**
   * Delete a file from blockFiles.
   *
   * @param {string} name
   * @return {*}
   * @memberof UploadBlock
   */
  deleteBlockFile(name: string): BlockFile[] {
    this.blockFiles = this.blockFiles.filter(blockFile => blockFile.entry.name !== name);
    return this.blockFiles;
  }

  getStatus() {
    return this.status;
  }

  setStatus(status: string) {
    this.status = status;
  }

  getBlockFiles() {
    return this.blockFiles;
  }
}
