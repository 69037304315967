import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-attorneys-law-firms',
  templateUrl: './attorneys-law-firms.component.html',
  styleUrls: ['./attorneys-law-firms.component.scss'],
})
export class AttorneysLawFirmsComponent implements OnInit {
  selectedOwner: any;
  owners: any;
  ownerID: any;
  searchResult: any[];
  userData: any;
  originalData: any;

  constructor(private auth_$: AuthService, private matDialogRef: MatDialogRef<AttorneysLawFirmsComponent>) {}

  async ngOnInit(): Promise<void> {
    this.owners = await this.getOwnersData();
    console.log('this.owners: ', this.owners);
  }

  handleOwnerChange($event: MatSelectChange) {
    this.matDialogRef.close({ type: 'owner', value: $event.value });
  }

  async getOwnersData(): Promise<any> {
    const { owners } = <{ owners: string[] }>this.auth_$.userData.getValue();
    const result = await Promise.all(
      owners.map(async owner => ({
        value: owner,
        label: await this.auth_$.getOwnerName(owner),
      })),
    );
    return result;
  }
}
