import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioGroup } from '@angular/material/radio';

@Component({
  selector: 'app-simple-message-window',
  templateUrl: './simple-message-window.component.html',
  styles: [`
  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    margin-right: 0px;
  }

  .import-radio-group {
    margin-top: 10px;
    width: 100%;
  }

  .buttons-container {
    margin-top: 20px;
  }
`]
})
export class SimpleMessageWindowComponent {
  @ViewChild('options') options;
  constructor(
    public dialogRef: MatDialogRef<SimpleMessageWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  handleClose(value) {
    const returnValue = this.data.complexAnswer ? {
      answer: value, importOption: (<MatRadioGroup>this.options.radioGroup).value
    } : value;
    this.dialogRef.close(returnValue);
  }

}
