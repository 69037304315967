import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UIMessagingService } from './uimessaging.service';
import { FirebaseUtilitiesService } from './firebase-utilities.service';

@Injectable({
  providedIn: 'root',
})
export class FoldersService {
  constructor(
    private auth_$: AuthService,
    private uiMessaging_$: UIMessagingService,
    private firebase_utilities_$: FirebaseUtilitiesService,
  ) {}

  checkDuplicatedFolder(folderName: string, caseFolders): boolean {
    return Boolean(caseFolders.find(folder => folder.name.toLowerCase().trim() === folderName.toLowerCase().trim()));
  }

  async createFolder(
    folderName: string,
    caseName: string,
    options = {
      color: '#c5f0f6',
      type: 'folder',
      predefined: false,
      forDelete: false,
      modal: false,
    },
    caseFolders,
  ) {
    if (this.checkDuplicatedFolder(folderName, caseFolders)) {
      if (options.modal) this.uiMessaging_$.toastMessage(`Folder name ${folderName} is already taken`, ``);
      return;
    }

    this.auth_$.showLoader(`Creating folder ${folderName}...`);
    const folderId = this.getRandomString(20);
    const result = await this.firebase_utilities_$.createFolder({
      children: ``,
      parentFolder: ``,
      folderId: folderId,
      belongsTo: caseName,
      name: folderName.trim(),
      color: options.color,
      type: options.type,
      predefined: options.predefined,
      forDelete: options.forDelete,
    });
    this.auth_$.hideLoader();
    return result;
  }

  getRandomString(stringLength: number = 10) {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }
}
