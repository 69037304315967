import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  template: `
    <app-prompt-2fa-code
    [method]="getMethod()"
      [title]="getPrompt2faTitle()"
      [message]="getPrompt2faMessage()"
      [email]="email"
      (cancel)="handleCancel($event)"
      (confirm)="handleConfirm($event)"
      (sendnewcode)="handleSendNewCode($event)"
      *ngIf="twofacode_needed"
    ></app-prompt-2fa-code>
  `,
  styles: [
  ]
})
export class TwoFactorSignInModalComponent implements OnInit {
  twofacode_needed = true;
  email: string;
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TwoFactorSignInModalComponent>,
  ) { }

  ngOnInit(): void {
    this.email = this.data.email;
  }

  getPrompt2faTitle() {
    return '';
  }

  getPrompt2faMessage() {
    return '';
  }

  getMethod() {
    return this.data.method;
  }

  handleCancel(ev) {
    this.dialogRef.close('cancel');
  }

  handleConfirm(ev) {

  }

  handleSendNewCode(ev) {

  }

}
