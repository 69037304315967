<div fxLayout="row">
  <div fxFlex="90">
    <h3 [ngClass]="'mat-headline no-margin'">Import Documents from CLIO to {{ data.clientName }}</h3>
  </div>

  <div fxFlex="10">
    <button
      mat-flat-button
      mat-dialog-close
      color="warn">
      X
    </button>
  </div>
</div>
<div *ngIf="matterSelected === false">
  <p>Please <b>select the matter</b> you want to import documents from</p>
  <mat-selection-list
    [multiple]="false"
    class="matters-list">
    <mat-list-option
      (click)="getClioDocuments(item.id, item.display_number, item.description, item.client)"
      *ngFor="let item of mattersPaged"
      ><h3
        class="matter-name"
        matline>
        {{ item.display_number }} - {{ item.description }}
      </h3>
      <span *ngIf="!item.client">...</span>
      <p
        class="mat-small"
        *ngIf="item.client">
        Client: {{ item.client.name }}
      </p>
    </mat-list-option>
  </mat-selection-list>
  <mat-paginator
    [length]="p_length"
    [pageSize]="p_pageSize"
    [pageSizeOptions]="p_pageSizeOptions"
    (page)="handlePageEvent($event)">
  </mat-paginator>
</div>
<button
  mat-button
  *ngIf="matterSelected"
  (click)="matterSelected = false"
  color="warn">
  Back to matters
</button>
<app-clio-list-documents
  *ngIf="matterSelected"
  [selectedMatter]="selectedMatter"
  (imporEvent)="handleImportDocuments($event)"
  [documentsList]="documents"></app-clio-list-documents>
