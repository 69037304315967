import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styles: [
    `
      .red-text {
        color: red;
        border: none;
        background: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 12px;
        padding: 5px;
      }

      .red-text:hover {
        text-decoration: underline;
      }

      .mat-body {
        padding: 0 10px;
      }
    `,
  ],
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;
  confirm_action: Function;
  selected_files: [];
  deleteAllFiles: boolean;
  btnOkText: any;
  btnCancelText: any;
  options: any;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    { title, message, btnOkText, btnCancelText, confirm_action, selected_files, deleteAllFiles, options },
  ) {
    this.title = title;
    this.btnOkText = btnOkText || 'Yes';
    this.btnCancelText = btnCancelText || 'Yes';
    this.message = message;
    this.confirm_action = confirm_action;
    this.selected_files = selected_files;
    this.deleteAllFiles = deleteAllFiles;
    this.options = options;
  }

  exit() {
    this.dialogRef.close();
  }

  markAsRead() {
    this.dialogRef.close({ read: true });
  }

  async confirm() {
    this.dialogRef.close({
      selectedFiles: this.selected_files,
      deleteAllFiles: this.deleteAllFiles,
      ok: true,
    });
  }

  close() {
    this.dialogRef.close();
  }
}
