<mat-dialog-content>
  <p class="mat-body">
    Name documents and files with <b>file name</b> and <b>date</b> before
    upload.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close mat-flat-button color="warn">
    Continue
  </button>
</mat-dialog-actions>
