import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-compare-plans-columns",
  templateUrl: "./compare-plans-columns.component.html",
  styleUrls: ["./compare-plans-columns.component.scss"],
})
export class ComparePlansColumnsComponent {
  constructor() {}
}
