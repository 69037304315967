<div
  class="lpmplatinum-plan-payment-page"
  fxLayout="row"
  fxFlex="90"
  fxFlexOffset="5"
  fxLayoutGap="50px">
  <div
    class="lpmplatinum-plan-details plan-details"
    fxFlex="60">
    <div *ngIf="planContent">
      <h2>{{ planContent.title }}</h2>
      <a
        color="warn"
        class="choose-plan-button"
        mat-flat-button
        (click)="backToPlans()"
        >View plans</a
      >

      <ul>
        <li
          *ngFor="let item of planContent.features; let i = index"
          [innerHTML]="item"></li>
      </ul>

      <span *ngIf="planContent.soonFeatures && planContent.soonFeatures.length > 0">
        <h4>Soon:</h4>
        <ul>
          <li
            *ngFor="let item of planContent.soonFeatures; let i = index"
            [innerHTML]="item"></li>
        </ul>
      </span>
    </div>
  </div>
  <form
    autocomplete="off"
    fxFlex="40"
    (ngSubmit)="onSubmit()"
    [formGroup]="completeClioRegistrationForm">
    <!-- title -->
    <h2>Complete the NuageDx Sign Up</h2>
    <input
      type="hidden"
      name="recurly-token"
      data-recurly="token"
      formControlName="recurlyToken" />
    <div
      fxLayout="row"
      fxLayoutGap="15px grid">
      <mat-form-field fxFlex="50">
        <mat-label>First Name</mat-label>
        <input
          matInput
          required
          data-recurly="first_name"
          type="text"
          formControlName="first_name" />
        <mat-error
          *ngIf="
            completeClioRegistrationForm.get('first_name').touched &&
            completeClioRegistrationForm.get('first_name').invalid
          "
          >{{ getErrorMessage('first_name') }}</mat-error
        >
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          type="text"
          data-recurly="last_name"
          formControlName="last_name" />
        <mat-error
          *ngIf="
            completeClioRegistrationForm.get('last_name').touched &&
            completeClioRegistrationForm.get('last_name').invalid
          "
          >{{ getErrorMessage('last_name') }}</mat-error
        >
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayoutGap="15px grid">
      <mat-form-field fxFlex="50">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          required
          name="email"
          autocomplete="new-email"
          formControlName="email" />
        <mat-error
          *ngIf="completeClioRegistrationForm.get('email').touched && completeClioRegistrationForm.get('email').invalid"
          >{{ getErrorMessage('email') }}</mat-error
        >
      </mat-form-field>

      <mat-form-field fxFlex="50">
        <mat-label>Password</mat-label>
        <input
          matInput
          type="password"
          maxlength="6"
          required
          (focus)="showPasswordMessage()"
          formControlName="password" />
        <mat-error
          *ngIf="
            completeClioRegistrationForm.get('password').touched && completeClioRegistrationForm.get('password').invalid
          "
          >{{ getErrorMessage('password') }}</mat-error
        >
      </mat-form-field>

      <input
        type="hidden"
        formControlName="recurlyplan" />

      <div
        fxLayout="row"
        fxFlex="50">
        <mat-form-field fxFlex="65">
          <mat-label>Cell Phone Number</mat-label>
          <input
            matInput
            required
            type="text"
            mask="(000) 000-0000"
            formControlName="phone" />
          <mat-error
            *ngIf="
              completeClioRegistrationForm.get('phone').touched && completeClioRegistrationForm.get('phone').invalid
            "
            >{{ getErrorMessage('phone') }}</mat-error
          >
        </mat-form-field>

        <mat-form-field
          fxFlex="30"
          fxFlexOffset="5">
          <mat-label>Extension</mat-label>
          <input
            matInput
            type="number"
            formControlName="extension" />
          <mat-error
            *ngIf="
              completeClioRegistrationForm.get('extension').touched &&
              completeClioRegistrationForm.get('extension').invalid
            "
            >{{ getErrorMessage('extension') }}</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutGap="15px grid">
      <mat-form-field fxFlex="50">
        <mat-label>Company Name</mat-label>
        <input
          matInput
          type="text"
          formControlName="companyName" />
        <mat-error
          *ngIf="
            completeClioRegistrationForm.get('companyName').touched &&
            completeClioRegistrationForm.get('companyName').invalid
          "
          >{{ getErrorMessage('companyName') }}</mat-error
        >
      </mat-form-field>
    </div>

    <div
      formGroupName="creditCard"
      [ngClass]="{ hidden: currentPlan === 'free' }">
      <div id="recurly-elements">
        <!-- Recurly Elements will be attached here -->
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="15px grid">
        <div
          fxLayout="column"
          fxFlex="50">
          <mat-form-field>
            <mat-label>Address</mat-label>
            <input
              matInput
              required
              type="text"
              data-recurly="address1"
              formControlName="address" />
            <mat-error
              *ngIf="
                completeClioRegistrationForm.get('creditCard').get('address').touched &&
                completeClioRegistrationForm.get('creditCard').get('address').invalid
              "
              >{{ getErrorMessageN(['creditCard', 'address']) }}</mat-error
            >
          </mat-form-field>

          <mat-form-field>
            <mat-label>Address 2</mat-label>
            <input
              matInput
              type="text"
              formControlName="address2" />
            <mat-error
              *ngIf="
                completeClioRegistrationForm.get('creditCard').get('address2').touched &&
                completeClioRegistrationForm.get('creditCard').get('address2').invalid
              "
              >{{ getErrorMessageN(['creditCard', 'address2']) }}</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxFlex="50"
          fxLayoutGap="15px grid">
          <mat-form-field fxFlex="33">
            <mat-label>City</mat-label>
            <input
              matInput
              type="text"
              max="2"
              required
              data-recurly="city"
              formControlName="city" />
            <mat-error
              *ngIf="
                completeClioRegistrationForm.get('creditCard').get('city').touched &&
                completeClioRegistrationForm.get('creditCard').get('city').invalid
              "
              >{{ getErrorMessageN(['creditCard', 'state']) }}</mat-error
            >
          </mat-form-field>

          <mat-form-field fxFlex="33">
            <mat-label>State</mat-label>
            <input
              matInput
              type="text"
              required
              max="2"
              data-recurly="state"
              mask="AA"
              formControlName="state" />
            <mat-error
              *ngIf="
                completeClioRegistrationForm.get('creditCard').get('state').touched &&
                completeClioRegistrationForm.get('creditCard').get('state').invalid
              "
              >{{ getErrorMessageN(['creditCard', 'state']) }}</mat-error
            >
          </mat-form-field>

          <mat-form-field
            fxFlex="33"
            fxFlexOffset="6">
            <mat-label>Zip</mat-label>
            <input
              matInput
              type="text"
              max="5"
              maxLength="5"
              minlength="3"
              min="3"
              required
              data-recurly="postal_code"
              formControlName="zip" />
            <mat-error
              *ngIf="
                completeClioRegistrationForm.get('creditCard').get('zip').touched &&
                completeClioRegistrationForm.get('creditCard').get('zip').invalid
              "
              >{{ getErrorMessageN(['creditCard', 'zip']) }}</mat-error
            >
          </mat-form-field>
        </div>
      </div>
    </div>
    <div style="margin-top: 10px">
      <div
        fxLayout="row"
        style="font-size: 13px">
        <!-- Material Form Checkbox -->
        <mat-checkbox
          formControlName="terms"
          color="warn"
          fxFlex="48"
          required>
          I agree to the
          <a
            href="https://nuagedx.com/terms-and-conditions/"
            target="_blank"
            >Terms of Service</a
          >
        </mat-checkbox>
        <!-- Material Form Checkbox -->
        <mat-checkbox
          fxFlex="48"
          fxFlexOffset="4"
          formControlName="baa"
          color="warn"
          required>
          I agree to the
          <a
            href="https://nuagedx.com/hipaa-business-agreement-addendum/"
            target="_blank"
            >BAA</a
          >
        </mat-checkbox>
      </div>
    </div>
    <div class="form-actions">
      <button
        mat-flat-button
        type="submit"
        color="warn"
        [disabled]="completeClioRegistrationForm.invalid">
        {{ 'Submit' | uppercase }}
      </button>
    </div>
  </form>
</div>
