<div>
  <app-window-title
    [closable]="true"
    subtitle="If your client/matter isn't listed, go to Matters in Clio and click 'Share to NuageDx' to share it with you."
    [title]="'Client/Matter List'"></app-window-title>
  <div
    mat-dialog-content
    fxLayout="column">
    <!-- SEARCH FOR CLIENT/MATTER  -->
    <div
      fxLayoutAlign="center center"
      fxLayout="row">
      <mat-form-field
        [ngClass]="'width-90p'"
        color="warn"
        appearance="fill">
        <mat-label> Search Client/Matter </mat-label>
        <input
          #searchClientsInput
          [value]="caseName"
          (keyup)="search($event)"
          (input)="caseName = searchClientsInput.value"
          name="searchClientsInput"
          aria-label="Label"
          matInput
          cdkFocusInitial
          placeholder="by name, Case name, Case ID"
          color="warn" />
      </mat-form-field>
      <div class="mdc-notched-outline">
        <div class="mdc-notched-outline__leading"></div>
        <div class="mdc-notched-outline__trailing"></div>
      </div>
      <button
        (click)="search($event)"
        matTooltip="Click to search."
        mat-icon-button
        color="warn">
        <mat-icon>search</mat-icon>
      </button>
      <button
        (click)="getFilesSharedWith()"
        matTooltip="Click to refresh."
        mat-icon-button
        color="warn">
        <mat-icon>update</mat-icon>
      </button>
    </div>
    <i
      *ngIf="!ownerNeeded"
      class="criteria-message mat-small">
      You can search by: 'FirstName', 'LastName', 'caseName', 'LegalCaseId' and 'DateOfBirth'
    </i>
    <div
      *ngIf="!ownerNeeded"
      fxLayoutAlign="center center">
      <p *ngIf="!searchResult?.length">No clients/matters found</p>
    </div>
    <div
      *ngIf="searchResult?.length > 0"
      fxLayoutAlign="center center"
      fxLayout="column">
      <h4
        *ngIf="searchResult.length > 1"
        [ngStyle]="{ margin: '0' }"
        id="profileFont">
        {{ searchResult.length }} Clients/Matters
      </h4>
      <h4
        *ngIf="searchResult.length === 1"
        id="profileFont">
        1 Client/Matter
      </h4>
      <mat-list
        [ngClass]="'client-list-container'"
        id="listStyle">
        <mat-list-item
          *ngFor="let item of searchResult"
          ngClass="client-list-item"
          (click)="dialogdata ? handleSelectClient(item) : routeToProfile(item)">
          <app-client-list-item
            [client]="item"
            [hasNotifications]="item.hasNotifications"
            (editClient)="editClient($event)"
            [permissions]="{dialogdata,ableToEditClient}"></app-client-list-item>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
