import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-choose-plan-button',
  templateUrl: './choose-plan-button.component.html',
  styleUrls: ['./choose-plan-button.component.scss'],
})
export class ChoosePlanButtonComponent {
  @Input() plan: string;
  @Input() planLabel: string;
  @Output() selectPlan: EventEmitter<string> = new EventEmitter<string>();
  constructor() {}

  choosePlan(plan: string, event) {
    // if (Boolean(event.currentTarget.attributes.disabled?.value)) return;
    this.selectPlan.emit(plan);
  }
}
