import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { FirebaseUtilitiesService } from '../../services/firebase-utilities.service';
import { FilesService } from '../../services/files.service';

// TODO: Implement a Logging Service.

@Component({
  selector: 'app-consultant-by-client',
  template: `
    <mat-dialog-actions align="end">
      <button
        mat-flat-button
        mat-dialog-close
        color="accent">
        X
      </button>
    </mat-dialog-actions>

    <h1 mat-dialog-title>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="20px">
        <div>
          <span class="mat-line mat-small">Shared files with consultant:</span>
          <div class="mat-line">{{ data.currentUser.name }}</div>
          <div class="mat-line mat-small">
            {{ data.currentUser.email }}
          </div>
        </div>
        <div>
          <span class="mat-h4 mat-line">Client name:</span>
          <div class="mat-line red-text">{{ data.casename }}</div>
        </div>
      </div>
    </h1>

    <div
      mat-dialog-content
      style="margin: auto">
      <div
        style="margin: 10px"
        *ngIf="data.currentUser">
        <mat-divider></mat-divider>
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="gappx">
          <div
            class="mat-elevation-z0 stickyheader-table-container"
            style="width: 100%">
            <table
              mat-table
              matSort
              matSortActive="fdate"
              matSortDirection="desc"
              matSortDisableClear
              [dataSource]="filesTableDataSource"
              class="mat-elevation-z1"
              style="width: 100%">
              <!-- File Name Column -->
              <ng-container matColumnDef="fileName">
                <th
                  mat-header-cell
                  *matHeaderCellDef>
                  File name
                </th>
                <td
                  mat-cell
                  *matCellDef="let element">
                  <a
                    href="{{ element.URL }}>"
                    *ngIf="!element.viewerurl"
                    attr.data-storename="{{ element.storename }}"
                    target="_blank_"
                    download
                    >{{ element.fileName }}</a
                  >
                  <button
                    [ngClass]="{ 'view-link': true }"
                    *ngIf="element.viewerurl">
                    <mat-icon
                      aria-hidden="false"
                      aria-label="preview icon"
                      >preview</mat-icon
                    >
                    {{ element.fileName }}
                  </button>
                </td>
              </ng-container>

              <!-- File Description Column -->
              <ng-container matColumnDef="fileDesc">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header>
                  File content
                </th>
                <td
                  mat-cell
                  *matCellDef="let element">
                  {{ element.fileDesc }}
                </td>
              </ng-container>

              <!-- Date of File Column -->
              <ng-container matColumnDef="fdate">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header>
                  Date of File
                </th>
                <td
                  mat-cell
                  *matCellDef="let element">
                  {{ element.fdate }}
                </td>
              </ng-container>

              <!-- FilePath Column -->
              <ng-container matColumnDef="isShared">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header>
                  Share action
                </th>
                <td
                  mat-cell
                  *matCellDef="let element">
                  <button
                    class="red-background white-text"
                    (click)="unShareWithMe($event, element)"
                    *ngIf="element.isShared">
                    UnShare
                  </button>

                  <button
                    *ngIf="!element.isShared"
                    (click)="shareWithMe($event, element)">
                    Share
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                (click)="selection.toggle(row)"></tr>
            </table>
          </div>
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="column"></div>
      </div>
    </div>

    <div class="mdc-dialog__scrim"></div>
  `,
  styles: [
    `
      .mat-cell button {
        cursor: pointer;
        padding: 10px 15px;
        border: 0;
      }

      .mat-cell button:hover {
        box-shadow: 1px 2px 10px 2px #ccc;
      }

      .red-text {
        color: #f44336;
      }

      .white-text {
        color: white;
      }

      .red-background {
        background-color: #f44336;
      }
    `,
  ],
})
export class ConsultantByClientComponent implements AfterViewInit {
  filesTableDataSource: any;
  selection = new SelectionModel<any>(true, []);
  files: any;
  displayedColumns: string[] = ['fileName', 'fileDesc', 'fdate', 'isShared'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fu$: FirebaseUtilitiesService) {
    const newClientFiles = [];
    data.clientfiles.forEach(file => {
      if (file.sharedUsers && file.sharedUsers.length > 0) {
        const fileSharedWithArr = file.sharedUsers.map(item => item.email.toLowerCase());
        file.isShared = fileSharedWithArr.indexOf(data.currentUser.email.toLowerCase()) > -1 ? 1 : 0;
        newClientFiles.push(file);
      }
    });
    this.filesTableDataSource = new MatTableDataSource<any>(newClientFiles);
    this.filesTableDataSource.paginator = this.paginator;
    this.filesTableDataSource.sort = this.sort;
    if (this.filesTableDataSource.paginator) {
      this.filesTableDataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    const newClientFiles = [];
    this.data.clientfiles.forEach(file => {
      if (file.sharedUsers && file.sharedUsers.length > 0) {
        newClientFiles.push(file);
      }
    });
    this.filesTableDataSource = new MatTableDataSource<any>(newClientFiles);
    this.filesTableDataSource.paginator = this.paginator;
    this.filesTableDataSource.sort = this.sort;
    if (this.filesTableDataSource.paginator) {
      this.filesTableDataSource.paginator.firstPage();
    }
  }

  async shareWithMe(ev: Event, fileToShare) {
    await this.fu$.shareFile(fileToShare, this.data.currentUser.email, this.data.casename);
    this.updateShares(fileToShare);
  }

  async unShareWithMe(ev: Event, fileToShare) {
    await this.fu$.unShareFile(fileToShare, this.data.currentUser.email, this.data.casename);
    this.updateShares(fileToShare);
  }

  updateShares(file) {
    this.data.clientfiles.forEach(item => {
      if (item.fileId === file.fileId) {
        item.isShared = item.isShared === 1 ? 0 : 1;
      }
    });
    this.ngAfterViewInit();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filesTableDataSource.filter = filterValue.trim().toLowerCase();

    if (this.filesTableDataSource.paginator) {
      this.filesTableDataSource.paginator.firstPage();
    }
  }

  handleCheckboxClick(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    const event = document.createEvent('HTMLEvents');
    event.initEvent('click', true, false);
    ev.currentTarget.parentNode.parentNode.dispatchEvent(event);
  }
}
