import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { pricing } from './pricing';
import { FirebaseUtilitiesService } from './../services/firebase-utilities.service';

@Component({
  selector: 'app-pricing-clio',
  templateUrl: './pricing-clio.component.html',
  styleUrls: ['./pricing-clio.component.scss'],
})
export class PricingClioComponent {
  public _features: any;
  public _plans: any;
  prices: any;
  annualPlans: { id: string }[];
  lpmpPlanCode = 'lpmp';
  constructor(
    public dialog: MatDialogRef<PricingClioComponent>,
    private firebaseUtilities_$: FirebaseUtilitiesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.getFeatures().then(features => {
      this._features = features.sort((a, b) => a['index'] - b['index']);
    });

    this.getPlans()
      .then(plans => {
        // Sort plans by index
        this.annualPlans = plans.filter(plan => plan['yearly'] === true);
        this._plans = plans.sort((a, b) => a['index'] - b['index']).filter(plan => plan['monthly'] === true);
        this.addAnnualPrices();

        this.prices = this._plans.map(plan => ({
          id: plan.id,
          number: this.formatWithTwoDecimals(plan.price),
        }));

        const f3 = this._features.map((feature, index) => {
          const featurePlans = feature['plans'];
          let _featurePlans = [];

          if (!featurePlans || featurePlans.length === 0) {
            const result = this._plans.map(plan => ({ id: plan.id, value: 0 }));
            _featurePlans = result;
          } else {
            this._plans.forEach(plan => {
              const value = featurePlans.find(fp => fp.id === plan.id)?.value || 0;
              _featurePlans.push({ id: plan.id, value });
            });
          }

          feature.plans = _featurePlans;
        });
      })
      .catch(err => {
        console.log('getPlans err: ', err);
      });
  }

  formatWithTwoDecimals(value: number) {
    return value.toFixed(2);
  }

  pricingplans = {
    free: 'free',
    lpmp: 'lpmp',
    gold: 'gold',
    silver: 'silver',
    platinum: 'platinum',
    enterpriseplatinum: 'enterpriseplatinum',
    enterpriseplatinumplus: 'enterpriseplatinumplus',
  };

  pricing = pricing;

  getFeatures() {
    return this.firebaseUtilities_$.getFeatures();
  }

  getPlans() {
    console.log('getPlans');
    return this.firebaseUtilities_$.getPlans();
  }

  choosePlan(plan: string) {
    this.dialog.close(plan);
  }

  choosePlanV2(plan: string) {
    const foundPlan = this.annualPlans.filter(p => p['recurlycode'] === plan)[0].id;
    console.log('foundPlan: ', foundPlan);
    this.dialog.close(foundPlan);
  }

  addAnnualPrices() {
    this._plans.forEach(plan => {
      plan.annual = plan === 'free' ? '' : this.getAnnual(plan);
    });
  }

  getAnnual(plan) {
    const filtered = this.annualPlans.filter(p => p.id === plan.switch);
    if (filtered.length === 0) {
      return '';
    }

    return filtered[0]['recurlycode'] || '';
  }
}
