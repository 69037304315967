import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'app-client-profile-toolbar',
  templateUrl: './client-profile-toolbar.component.html',
  styleUrls: ['./client-profile-toolbar.component.scss'],
})
export class ClientProfileToolbarComponent implements OnInit {
  discsFolderName = 'Discs';
  // Declare @Input Variables
  @Input() selection: any;
  @Input() allowDeleteNote: any;
  @Input() fileSelected: any;
  @Input() refreshButton: any;
  @Input() currentFolder: any;
  @Input() currentFolderName: any;
  @Input() files: any;
  @Input() realRole: any;
  @Input() permissions_createfolder: any;
  @Input() UserRoles: any;
  @Input() activeConsultant: any;
  @Input() filesFilter: any;
  @Input() unshareAllFilesMsg: any;
  @Input() sortTypes: any;
  @Input() fileActionsMenuItems: any;

  // Declare @Output Variables
  @Output() openAddNoteDialogEvent: EventEmitter<any> = new EventEmitter();
  @Output() openDeleteConfirmDialogEvent: EventEmitter<any> = new EventEmitter();
  @Output() handleRefreshEvent: EventEmitter<any> = new EventEmitter();
  @Output() createFolderEvent: EventEmitter<any> = new EventEmitter();
  @Output() openFileSearchEvent: EventEmitter<any> = new EventEmitter();
  @Output() sortFilesBySelectedEvent: EventEmitter<any> = new EventEmitter();
  @Output() removeFilterEvent: EventEmitter<any> = new EventEmitter();
  @Output() handleSelectEvent: EventEmitter<any> = new EventEmitter();
  @Output() unShareAllEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('menu', { static: false }) menu: MatMenu;
  @ViewChild('fileActionsMenu', { static: false }) fileActionsMenu: ElementRef;
  @ViewChild('fileActionsMenu', { read: MatRipple }) ripple: MatRipple;

  deleteButton: boolean;

  constructor() {}

  ngOnInit(): void {
    this.deleteButton = this.selection.selected.length > 1;
  }

  // Declare All Methods called fromthe template.
  openAddNoteDialog() {
    this.openAddNoteDialogEvent.emit();
  }

  openDeleteConfirmDialog() {
    this.openDeleteConfirmDialogEvent.emit();
  }

  handleRefresh() {
    this.handleRefreshEvent.emit();
  }

  createFolder(currentFolder, options) {
    this.createFolderEvent.emit({ currentFolder, options });
  }

  openFileSearch() {
    this.openFileSearchEvent.emit();
  }

  sortFilesBySelected() {
    this.sortFilesBySelectedEvent.emit();
  }

  removeFilter() {
    this.removeFilterEvent.emit();
  }

  fileActionsEnabled() {
    const isSingleSelection = this.selection.selected.length === 1;
    if (isSingleSelection) {
      const selectedItem = this.selection.selected[0];
    }
    return this.fileSelected && isSingleSelection;
  }

  handleSelect(i) {
    this.handleSelectEvent.emit(i);
  }

  unShareAll(activeConsurltant) {
    this.unShareAllEvent.emit(activeConsurltant);
  }
}
