import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  setLPMWelcome(value) {
    localStorage.setItem('lpmWelcome', value);
  }

  getLPMWelcome() {
    return localStorage.getItem('lpmWelcome') === 'true';
  }

  cleanAll() {
    localStorage.clear();
  }

  setClioGetAuthorizationCodeV3Executed(value) {
    localStorage.setItem('clioGetAuthorizationCodeV3Executed', value);
  }

  getClioGetAuthorizationCodeV3Executed() {
    return localStorage.getItem('clioGetAuthorizationCodeV3Executed') === 'true';
  }
}
