<div fxLayout="row">
  <div fxFlex>
    <h2 class="mat-headline">Create {{ userType }}</h2>
  </div>

  <div
    fxFlex="none"
    fxFlexOffset="1">
    <button
      mat-flat-button
      mat-dialog-close
      color="warn">
      X
    </button>
  </div>
</div>
<div fxLayoutAlign="center center">
  <form
    *ngIf="clientForm"
    [formGroup]="clientForm"
    (ngSubmit)="onSubmit()">
    <div fxLayout="row">
      <div fxFlex="49">
        <!-- Content for the first column goes here -->
        <mat-form-field
          appearance="fill"
          class="full-width">
          <mat-label>Name</mat-label>
          <input
            formControlName="name"
            name="name-field"
            required
            matInput />
          <mat-error *ngIf="clientForm.controls['name'].invalid">{{
            getErrorMessage(clientForm.controls['name'], 'name')
          }}</mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          class="full-width">
          <mat-label>Email</mat-label>
          <input
            formControlName="email"
            required
            name="email-field"
            type="email"
            matInput />
          <mat-error *ngIf="clientForm.controls['email'].invalid">{{
            getErrorMessage(clientForm.controls['email'], 'email')
          }}</mat-error>
        </mat-form-field>

        <!-- ACCOUNT TYPE -->
        <p class="fgpass-text">Choose an account type</p>
        <mat-radio-group
          required
          [align]="'start'"
          formControlName="usertype"
          class="radio-buttons-group full-width">
          <mat-radio-button
            *ngFor="let option of radioOptions"
            value="{{ option.value }}"
            id="{{ option.value }}"
            name="{{ option.value }}"
            (click)="onCheckedAccount(option.prefix + ' ' + option.label, $event)"
            class="radio-button">
            {{ option.label }}</mat-radio-button
          >
          <mat-error
            class="mat-small"
            id="options-group"
            *ngIf="this.submitted && !clientForm.controls.usertype.valid">
            {{ getErrorMessage(clientForm.controls.usertype, 'usertype', 1) }}</mat-error
          >
        </mat-radio-group>
      </div>
      <div
        fxFlex="49"
        fxFlexOffset="2">
        <!-- Content for the second column goes here -->

        <p
          *ngIf="consultantPasswordMessage"
          [innerHTML]="consultantPasswordMessageHTML"></p>

        <!-- PASSWORD FIELD -->
        <mat-form-field
          appearance="fill"
          class="full-width">
          <mat-label>Password</mat-label>
          <mat-pass-toggle-visibility
            #toggle
            matSuffix></mat-pass-toggle-visibility>
          <input
            [type]="toggle.type"
            minlength="6"
            name="password-field"
            formControlName="password"
            required
            class="password-input"
            matInput />
          <mat-error *ngIf="!clientForm.controls.password.valid">{{
            getErrorMessage(clientForm.controls.password, 'password')
          }}</mat-error>
          <mat-error *ngIf="clientForm.invalid && clientForm.errors"> The password is not strong enough. </mat-error>
        </mat-form-field>
        <br /><br />
        <mat-password-strength
          (onStrengthChanged)="onStrengthChanged($event)"
          #passwordComponent
          [password]="clientForm.controls.password.value">
        </mat-password-strength>
        <mat-password-strength-info [passwordComponent]="passwordComponent"></mat-password-strength-info>

        <!-- CONFIRTM PASSWORD -->
        <mat-form-field
          appearance="fill"
          class="full-width">
          <mat-label>Confirm password</mat-label>
          <mat-pass-toggle-visibility
            #toggle_cp
            matSuffix></mat-pass-toggle-visibility>
          <input
            class="password-input"
            [type]="toggle_cp.type"
            required
            name="confirmpassword"
            formControlName="passwordconfirm"
            matInput />
          <mat-error *ngIf="!clientForm.controls.passwordconfirm.valid">
            {{ getErrorMessage(clientForm.controls.passwordconfirm, 'passwordconfirm', 1) }}</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <mat-card class="info-box mat-small"> Note: A password reset link will be sent to the new user. </mat-card>
    <br />
    <br />

    <p
      class="mat-body"
      style="color: red">
      {{ generalError }}
    </p>

    <button
      mat-raised-button
      type="submit"
      name="create-user-button"
      [disabled]="blockedForm"
      color="warn">
      CREATE USER
    </button>
  </form>
</div>
