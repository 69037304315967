import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

export interface DialogData {
  title: string;
  message: string;
  options: any[];
  mode: string;
}

@Component({
  template: `
    <app-window-title
      [title]="data.title"
      [closable]="false"></app-window-title>

    <mat-dialog-content [innerHTML]="data.message"> </mat-dialog-content>

    <div *ngIf="data.mode === 'radio'">
      <mat-radio-button
        *ngFor="let option of data.options"
        (click)="handleChoose(option.value)"
        [value]="option.value">
        {{ option.name }}
      </mat-radio-button>
    </div>

    <div
      *ngIf="data.mode === 'button'"
      fxLayoutAlign="space-around center">
      <button
        mat-flat-button
        *ngFor="let option of data.options"
        (click)="handleChoose(option.value)"
        color="warn">
        {{ option.name }}
      </button>
    </div>
  `,
})
export class OptionChooserComponent {
  constructor(
    public dialogRef: MatDialogRef<OptionChooserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  handleChoose(optionValue) {
    this.dialogRef.close(optionValue);
  }
}
