<!-- Create a form with a dropdown to select the owner comming from MAT_DIALOG_DATA list of options -->
<!-- Handle the selection to close the window and send back the choosen ownerID -->
<app-window-title [title]="title"></app-window-title>
<div mat-dialog-content>
  <p>{{ message }}</p>
  <form
    [formGroup]="selectOwnerForm"
    (ngSubmit)="onSubmit()">
    <mat-form-field>
      <mat-label>Owner</mat-label>
      <mat-select
        formControlName="owner"
        #owner>
        <mat-option
          *ngFor="let owner of owners"
          [value]="owner.value">
          {{ owner.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="onNoClick(owner)">
    Cancel
  </button>
  <button
    mat-button
    [mat-dialog-close]="selectOwnerForm.value.owner"
    cdkFocusInitial>
    Ok
  </button>
</div>
