import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-vob-info-window',
  templateUrl: './vob-info-window.component.html',
  styleUrls: ['./vob-info-window.component.scss']
})
export class VOBInfoWindowComponent {

  constructor(private dialogRef: MatDialogRef<VOBInfoWindowComponent>) { }

  downloadVLC(event) {
    window.open('https://www.videolan.org/vlc/', '_blank');
  }

  closeDialog(event) {
    this.dialogRef.close('download');
  }

  getConvertAndExport(event) {
    this.dialogRef.close('getConvertAndExport');
  }
}
