import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-table-header',
  templateUrl: './pricing-table-header.component.html',
  styleUrls: ['./pricing-table-header.component.scss'],
})
export class PricingTableHeaderComponent {
  plans = [
    { name: 'Free', id: 'free', disabled: false },
    { name: 'Silver', id: 'silver', disabled: false },
    { name: 'Gold', id: 'gold', disabled: false },
    { name: 'Platinum', id: 'platinum', disabled: true },
    { name: 'Clio Platinum', id: 'lpmp', disabled: false },
    { name: 'Enterprise Platinum', id: 'enterpriseplatinum', disabled: false },
    { name: 'Enterprise Platinum (+)', id: 'enterpriseplatinumplus', disabled: false },
  ];
  constructor() {}
  choosePlan(plan: string, event) {}
}
