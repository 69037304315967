import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-add-comments',
  template: `
    <div fxLayout="column">
      <div
        fxLayout="row"
        fxFlex="100">
        <textarea
          fxFlex="80"
          #commentInput
          rows="4"></textarea>
        <button
          fxFlex="20"
          mat-raised-button
          color="warn"
          (click)="addCommentAndAttachments(commentInput.value)">
          ADD
        </button>
      </div>

      <app-add-attachments
        fxFlex="100"
        [fileEntries]="fileEntries"
        (addedAttachment)="addAttachment($event)"
        fxFlex="40"></app-add-attachments>
    </div>
  `,
})
export class AddCommentsComponent {
  @Output() addCommentAndAttachmentsEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('commentInput') commentInput: any;
  attachments: any;
  fileEntries: any[] = [];

  constructor() {}

  addCommentAndAttachments(commentText: string) {
    this.addCommentAndAttachmentsEvent.emit({ commentText, attachments: this.attachments });
    this.clearDataAfterEmit();
  }

  clearDataAfterEmit() {
    this.commentInput.nativeElement.value = '';
    this.fileEntries = [];
    this.attachments = [];
  }

  addAttachment(attachments: any) {
    this.attachments = attachments;
  }
}
