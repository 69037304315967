import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div class="mat-small loader-container">
      <mat-spinner
        *ngIf="!icon"
        color="warn"
        class="mat-small"
        style="display: inline-block; vertical-align: middle"
        diameter="20"
        strokeWidth="4"></mat-spinner>
      <mat-icon
        color="warn"
        style="display: inline-block; vertical-align: middle"
        *ngIf="icon"
        >{{ icon }}</mat-icon
      >
      {{ message || 'Please wait...' }}
    </div>
  `,
  styles: [
    `
      .loader-container {
        margin: auto;
        margin-top: 10px;
        display: inline-block;
        text-align: center;
        display: inline-block;
        width: 100%;
        color: #424242;
      }
    `,
  ],
})
export class LoaderComponent implements OnInit {
  @Input() message: string;
  icon: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (this.data?.message) this.message = this.data.message;
    if (this.data?.icon) this.icon = this.data.icon;
  }
}
