<thead>
  <tr>
    <th style="text-align: left">The ultimate in file sharing for consultations and collaborations</th>
    <th *ngFor="let plan of plans">
      <h5 class="plan-name">{{ plan.name }}</h5>
      <a
        color="warn"
        class="choose-plan-button"
        [disabled]="plan.disabled"
        mat-flat-button
        (click)="choosePlan(plan.id, $event)"
        >Select plan</a
      >
    </th>
  </tr>
</thead>
