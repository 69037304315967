import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { UserRoles } from 'src/app/dictionaries/UserRoles';

import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-createclient',
  templateUrl: './createclient.component.html',
  styleUrls: ['./createclient.component.css'],
})
export class CreateclientComponent implements OnInit {
  createClientForm = new FormGroup({
    nameField: new FormControl('', [Validators.required]),
    emailField: new FormControl('', [Validators.required, Validators.email]),
    passwordField: new FormControl('', [Validators.required]),
    companyNameField: new FormControl('', [Validators.required]),
  });

  name: string;
  email: string;
  password: string;
  errorMessage: string;
  companyName: string;
  constructor(
    public auth_$: AuthService,
    public router: Router,
    public ng: NgZone,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateclientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.name = '';
    this.email = '';
    this.password = '';
    this.errorMessage = '';
    this.companyName = '';
  }

  onSubmit() {
    this.createUser('Owner');
  }

  ngOnInit() {
    this.initAuth();

    this.auth_$.errorAuth.subscribe(error => {
      this.errorMessage = error;
    });
  }

  initAuth() {
    this.auth_$.user.subscribe(user => {
      if (user !== null && user !== undefined) {
        this.auth_$._getUserByUID(user.uid).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (doc.data()['role'] !== UserRoles.superuser) {
              this.determineRoute(doc.data()['role'], user);
            }
          });
        });
      } else {
        this.router.navigateByUrl('/');
      }
    });
  }

  createUser(rolename?: string) {
    this.handleOwnerCreation(this.createClientForm.value, rolename);
  }

  handleOwnerCreation(formValue, rolename?: string) {
    const role = rolename || UserRoles.client;

    const email = this.createClientForm.controls['emailField'].value.toLowerCase();
    const name = this.createClientForm.controls['nameField'].value;
    const nrole = role === UserRoles.owner ? 'platinum' : '';
    const uid = '';
    const password = this.createClientForm.controls['passwordField'].value;
    const userData = this.auth_$.userData.getValue();
    const user = new User(
      role,
      email,
      name,
      uid,
      userData['user']['uid'],
      [userData['user']['uid']],
      nrole,
      '',
      '',
      '',
      '',
      rolename === UserRoles.owner ? true : false,
    );
    const userEmail = user.email;
    const companyName = this.createClientForm.controls['companyNameField'].value;

    this.auth_$.createClientAndSendPasswordResetEmail(
      userEmail,
      password,
      user,
      userData,
      companyName,
      UserRoles.owner,
    );
  }

  getErrorMessage(el) {
    return el.invalid ? (el.errors.required ? 'Required' : el.errors.email ? 'Invalid email' : '') : '';
  }

  determineRoute(role: any, user: any) {
    if (role === UserRoles.consultant) {
      this.ng.run(() => this.router.navigateByUrl(`search/${user.uid}/${user.email}/{role}`));
    } else {
      this.ng.run(() => this.router.navigateByUrl('/'));
    }
  }

  goBack() {
    window.history.back();
  }
}
