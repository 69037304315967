import 'firebase/firestore';

import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { AuthService } from '../../services/auth.service';
import { UIMessagingService } from '../../services/uimessaging.service';
import { FirebaseUtilitiesService } from './../../services/firebase-utilities.service';

declare function require(name: string);
const firestore = firebase.firestore();

@Component({
  templateUrl: './allusers.component.html',
  styleUrls: ['./allusers.component.scss'],
})
export class AllusersComponent implements OnInit {
  dbUsers = firestore.collection('users');
  allUsers: any[] = [];
  currentUser: any;
  userInfoDiag: any;
  deleteConfirm: any;
  roles = [UserRoles.client, UserRoles.admin, UserRoles.consultant, UserRoles.client];
  roleSelected: string;
  clientId: string;
  currentSignedInUser: firebase.User;
  currentSignedInUserRole: string;
  searchCriteria: string;
  cachedUsers: any[] = [];
  userPermissions: any;
  selection = new SelectionModel<any>(true, []);
  close: boolean;
  search: boolean;
  subtitle: string;
  title: string;
  filesTableDataSource: any;
  files: any;
  loader: any;
  clientfiles: any;
  consultants: any;
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: string; client: string };
  deleteadmin = false;

  dataFromParent: any = {
    role: '',
    clientid: '',
    close: true,
    search: true,
    title: '',
    subtitle: '',
    files: [],
  };

  curentUserRole: any;
  ownerID: string;

  constructor(
    public snackBar: MatSnackBar,
    private firebase_utilities_$: FirebaseUtilitiesService,
    public auth$: AuthService,
    public router: Router,
    public ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private uiMessaging_$: UIMessagingService,
    public dialogRef: MatDialogRef<AllusersComponent>,
    private auth_$: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      client: UserRoles.client,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
    this.roleSelected = data.role;
    this.searchCriteria = '';
  }

  ngOnInit() {
    this.close = true;
    this.search = true;
    this.title = '';
    this.subtitle = '';
    this.clientfiles = [];
    this.consultants = [];

    // Subscribe to user data.
    this.subscribeToUserData();

    // HANDLE OWNER ID.
    // FIXME: The next two lines are innecesary slow, these pieces of data are already available in the auth service and both could be gotten in just one call.
    // this.curentUserRole = await this.auth_$.getUserRole(this.auth_$.uid);
    this.curentUserRole = this.auth_$.userData.getValue()['role'];

    if (this.curentUserRole === UserRoles.owner) {
      this.ownerID = this.auth_$.uid;
    } else {
      const owners = this.auth_$.userData.getValue()['owners'];

      if (owners.length === 1) {
        this.ownerID = owners[0];
      } else {
        // this.selectOwnerBeforeCreateUser();
        this.ownerID = owners[0];
      }
    }
  }

  private subscribeToUserData() {
    this.auth_$.userData.subscribe({
      next: userData => {
        if (!userData['permissions']) return;

        this.userPermissions = userData['permissions'];
        this.deleteadmin = this.validatePermission('deleteadmin');

        if (Object.keys(userData).length && userData !== null && userData !== undefined) {
          this.currentSignedInUser = this.auth_$.currentUserSignedIn;

          this.auth_$
            ._getUserByUID(userData['uid'])
            .then(async qS => {
              const { uid, role } = qS.docs[0].data();
              if (uid === userData['uid']) {
                this.currentSignedInUserRole = role;
                if (this.clientId) await this.getAllUsersByClientId(UserRoles.consultant);
                else await this.getAllUsers(this.roleSelected);
              }
            })
            .catch(error => {
              console.log('Error getting documents: ', error);
            });
        }
      },
    });
  }

  validatePermission(permission: string): boolean {
    const userRole = this.auth_$.userData.getValue()['role'];
    if (!this.userPermissions || this.userPermissions.length === 0) {
      console.log('No permissions found yet.');
      return false;
    }

    const filteredPermissions = this.userPermissions
      .filter(p => p.role === userRole)
      .filter(p => p.object === permission);
    return filteredPermissions[0]?.access;
  }

  async getCreatedBy(uid: string) {
    return this.dbUsers
      .where('uid', '==', uid)
      .get()
      .then(querySnapshot => {
        return querySnapshot.docs[0].data().createdBy;
      });
  }

  async getOwnerID(uid: string) {
    return this.dbUsers
      .where('uid', '==', uid)
      .get()
      .then(querySnapshot => {
        return querySnapshot.docs[0].data().ownerID;
      });
  }

  // NOTE: Get all user from the same OwnerID.
  async getUserRole(uid: string) {
    return this.dbUsers
      .where('uid', '==', uid)
      .get()
      .then(querySnapshot => {
        return querySnapshot.docs[0].data().role;
      });
  }

  async getAllUsers(role?: string) {
    let ownerID = null;

    if (role) {
      ownerID =
        this.auth_$.userData.getValue()['role'] !== UserRoles.owner
          ? this.auth$.userData.getValue()['ownerID']
          : this.auth_$.userData.getValue()['uid'];
    }

    let allowedUsers = [];

    if (role) {
      allowedUsers.push(role);
    } else {
      allowedUsers = [UserRoles.admin, UserRoles.associate, UserRoles.owner, UserRoles.client, UserRoles.superuser];
    }

    for (let index = 0; index < allowedUsers.length; index++) {
      const userType = allowedUsers[index];
      const users =
        role === UserRoles.owner
          ? await this.dbUsers
              .where('role', '==', UserRoles.owner)
              .where('createdBy', '==', this.auth_$.userData.getValue()['uid'])
              .where('disabled', '==', false)
              .get()
          : await this.dbUsers.where('role', '==', userType).where('owners', 'array-contains', ownerID).get();

      this.allUsers = this.cachedUsers = users.docs
        .map(doc => doc.data())
        .filter(user => [undefined, false].includes(user.disabled));

      this.ref.detectChanges();
    }
  }

  async getAllUsersByClientId(role?: string) {
    const allowedUsers = role ? [role] : [UserRoles.consultant];
    const entries = new Set();

    const promises = allowedUsers.map(async userType => {
      const querySnapshot = await this.dbUsers.where('role', '==', userType).get();
      querySnapshot.forEach(doc => {
        const docData = doc.data();

        if (docData.filesSharedWith) {
          const item = docData.filesSharedWith.find((item_1: any) => item_1.patientCaseName === this.clientId);

          if (item && !entries.has(docData.email.toLowerCase())) {
            entries.add(docData.email.toLowerCase());
            this.allUsers.push(docData);
          }
        }
      });
    });

    try {
      await Promise.all(promises);
      this.cachedUsers = this.allUsers;
      this.ref.detectChanges();
    } catch (error) {
      console.error(error);
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.files.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.files.forEach(row => this.selection.select(row));
  }

  async getFileName(fileId: string) {
    return await this.firebase_utilities_$.getFileNameByFileId(fileId);
  }

  async getPatientName(caseId: string) {
    const { FirstName, LastName } = await this.firebase_utilities_$.getPatientNameByCaseName(caseId);
    return `${FirstName} ${LastName}`;
  }

  distinctFilesByCaseName(files: [{ fileId: string; patientCaseName: string }]) {
    return files.filter(
      (value, index, self) => self.findIndex(m => m.patientCaseName === value.patientCaseName) === index,
    );
  }

  async openUser(user: any) {
    if (user.filesSharedWith && user.filesSharedWith.length) {
      user.filesSharedWith = this.distinctFilesByCaseName(user.filesSharedWith);

      for (let index = 0; index < user.filesSharedWith.length; index++) {
        const element = user.filesSharedWith[index];
        element.fileName = await this.getFileName(element.fileId);
        element.patientName = await this.getPatientName(element.patientCaseName);

        if (!element.fileName) {
          element.fileName = `(X)`;
          console.error('There is an error with this file', element.fileId);
        }
      }
    } else {
      user.filesSharedWith = [];
    }

    this.currentUser = user;
    const mdcDialog = require('@material/dialog');
    const MDCDialog = mdcDialog.MDCDialog;
    this.roleSelected = user.role;

    this.userInfoDiag = new MDCDialog(document.querySelector('.userdiag'));
    this.userInfoDiag.open();
    this.ref.detectChanges();
  }

  closeModal() {
    this.userInfoDiag.close();
  }

  openDeleteConfirmDialog() {
    const mdcDialog = require('@material/dialog');
    const MDCDialog = mdcDialog.MDCDialog;
    this.deleteConfirm = new MDCDialog(document.querySelector('.deleteconfirm'));
    this.deleteConfirm.open();
  }

  async markDeleted(uid: string) {
    this.auth$.showLoader('Mark as deleted...');
    
    const userDoc = await (await this.dbUsers.where('uid', '==', uid).get()).docs[0];
    const userData = userDoc.data();

    const data = (await firestore.collection('files').get()).docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));

    const promises = [];

    data.forEach(file => {
      const sharedUsers = file['sharedUsers'];

      if (sharedUsers && sharedUsers.length > 0) {
        const found = sharedUsers.map(user => user.email).indexOf(userData.email);

        if (found > -1) {
          const docId = file.id;
          const arrayOfUsers = sharedUsers;
          arrayOfUsers.splice(found, 1);
          promises.push(firebase.firestore().collection('files').doc(docId).update({ sharedUsers: arrayOfUsers }));
        }
      }
    });

    const result = await Promise.all(promises);

    await this.updateDocDeleted(userDoc.id, userDoc.data().email.toString());

    this.auth$.hideLoader();
  }

  afterDeleteUser() {
    this.uiMessaging_$.toastMessage('User deleted', 'DONE');
    this.deleteConfirm.close();
    this.userInfoDiag.close();

    if (this.clientId) {
      this.getAllUsersByClientId(UserRoles.consultant);
    } else {
      this.getAllUsers(this.roleSelected);
    }
  }

  async callCloudFunction(functionName: string, param: string) {
    switch (functionName) {
      case 'deleteUserByEmail':
        const userEmail = param;
        await firebase
          .functions()
          .httpsCallable('admin-deleteUserByEmail')({ userEmail: userEmail })
          .then(result => {
            console.log('Success', result);

            if (result.data.status === 200) {
              this.afterDeleteUser();
            }
          })
          .catch(reason => {
            console.log(reason);
          });
        break;
      default:
        break;
    }
  }

  async updateDocDeleted(docID: string, userEmail: string) {
    await this.dbUsers
      .doc(docID)
      .update({ disabled: true })
      .catch(err => console.log(err));
    return this.callCloudFunction('deleteUserByEmail', userEmail).then(result => {
      console.log('User > deleted', result);
    });
  }

  goBack() {
    window.history.back();
  }

  filterUsers(event) {
    document.getElementById('cancelSearch').style.display = 'block';

    if (this.searchCriteria === '') {
      this.cancelSearch();
    } else {
      this.allUsers = this.allUsers.filter(user => user.name.toLowerCase().includes(this.searchCriteria.toLowerCase()));
    }
  }

  cancelSearch() {
    document.getElementById('cancelSearch').style.display = 'none';
    this.allUsers = this.cachedUsers;
  }
}
