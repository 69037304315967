import 'firebase/firestore';

import { Injectable } from '@angular/core';
import firebase from 'firebase/app';

import { LogLevelsDictionary } from './dictionaries/LogLevels';

const db = firebase.firestore();
const dbLogs = db.collection('logs');

@Injectable({
  providedIn: 'root',
})
export class LogService {
  entryData: Date = new Date();
  message = '';
  entraInfo: any[] = [];
  logWithDate = true;

  constructor() {}

  log(type, content) {
    switch (type) {
      case LogLevelsDictionary.warning:
        console.warn(LogLevelsDictionary.warning, JSON.stringify(content));
        break;
      case LogLevelsDictionary.error:
        console.error(LogLevelsDictionary.warning, JSON.stringify(content));
        break;
      default:
        console.log(JSON.stringify(content));
        break;
    }
  }

  storeLog(userData: any, level: string, data: any, action = '', client = null) {
    const content = Object.keys(data).length ? data : '';
    const user = userData['user'];
    const realRole = userData['realRole'];
    const ownerID = userData['ownerID'] || '';
    const name = userData['userName'];
    const useremail = userData['userEmail'];
    const ip = userData['userip'];
    const role = realRole;
    const { uid } = user;
    const created = firebase.firestore.Timestamp.now();
    const dataObj = { created, content, uid, action, level, role, name, useremail, ip, ownerID, client };

    return dbLogs
      .add(dataObj)
      .then(r => console.log('Log registry added', { r, dataObj }))
      .catch(err => console.error(err));
  }
}
