<app-window-title
  [title]="'Important'"
  [subtitle]="'Please download and install VLC Media Player to view this file'"
  [icon]="'info'"
  [iconColor]="'warn'"
  [closable]="true"></app-window-title>
<p class="mat-body">In order to view your videos, you will have to download a media application.</p>
<p class="mat-body">
  Visit <a href="https://ninite.com/">www.ninite.com</a> and choose VLC from Media selection. Then Download and Run the
  custom installer.
</p>
<mat-dialog-actions align="end">
  <button
    mat-button
    (click)="closeDialog($event)"
    mat-flat-button
    color="warn">
    If VLC is already installed, continue opening this file
  </button>
</mat-dialog-actions>
