import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface FolderNode {
  name: string;
  id: string;
  children?: FolderNode[];
  color: string;
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  id: string;
  level: number;
}

@Component({
  selector: 'app-folder-selector',
  templateUrl: './folder-selector.component.html',
  styleUrls: ['./folder-selector.component.scss']
})

export class FolderSelectorComponent {
  @Input() treeSource: any;
  private _transformer = (node: FolderNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      color: node.color,
      id: node.id,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: FolderNode[],
    private dialogRef: MatDialogRef<FolderSelectorComponent>
  ) {
    const foldersData = Object.values(data).filter(item => item.id !== 'all');
    this.dataSource.data = Object.values(foldersData);
  }

  selectFolder(folderId) {
    this.dialogRef.close(folderId);
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

}
