import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import firebase from 'firebase/app';

import { AuthService } from '../../services/auth.service';
import { UtilsService } from '../../services/utils.service';
import { SharingFilesService } from './../../sharing-files.service';
import { FirebaseUtilitiesService } from 'src/app/services/firebase-utilities.service';

const unshareIconUrl = './../../assets/svg/unshare.svg';
@Component({
  selector: 'app-active-consultants',
  templateUrl: 'active-consultants.component.html',
  styleUrls: ['./active-consultants.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActiveConsultantsComponent implements OnInit {
  consultantsList: any[] = [];
  clientId: string;
  currentSignedInUser: firebase.User;
  cachedUsers: any[] = [];
  selection = new SelectionModel<any>(true, []);
  @Input() dataFromParent: any = [];
  @Input() patientDocId: string;
  @Input() caseName: string;
  @Input() selectedFiles: any = [];
  @Input() restrictedProfiles: any = [];
  @Input() allowedProfiles: any = [];
  @Input() sharedFilesCount: number = 0;
  @Output() shareEvent: EventEmitter<any> = new EventEmitter();
  @Output() unshareEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() needConsultantsReloadEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToFolderEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('consultants') consultants: MatSelectionList;
  files: any;
  clientfiles: any;
  role: any;
  activeCachedUsers: any[];
  optionSelectedClick: number;
  currentValue: string;
  pickedFile: {};
  activeClientUser: any;

  constructor(
    public snackBar: MatSnackBar,
    public auth$: AuthService,
    public router: Router,
    private Utils$: UtilsService,
    public ref: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private sharing_files_$: SharingFilesService,
    private firebase_utilities_$: FirebaseUtilitiesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.optionSelectedClick = 0;
    this.currentValue = '';
    this.matIconRegistry.addSvgIcon('unshareIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(unshareIconUrl));
  }

  ngOnInit() {
    this.auth$.user.subscribe(async user => (this.currentSignedInUser = user));

    this.sharing_files_$.pickedFile.subscribe({
      next: pickedFile => this.highLightPicked(pickedFile),
    });
  }

  handleShareEvent(event) {
    this.shareEvent.emit(event);
  }

  handleUnshareEvent(event) {
    this.unshareEvent.emit(event);
  }

  handleGoToFolderEvent(event) {
    this.goToFolderEvent.emit(event);
  }

  handleNeedConsultantsReload() {
    this.needConsultantsReloadEvent.emit();
  }

  clearPicked() {
    this.dataFromParent.forEach(consultant => delete consultant.highlighted);
  }

  highLightPicked(file) {
    this.clearPicked();
    if (Object.keys(file).length)
      if (file.sharedUsers && file.sharedUsers.length) {
        const sharedEmails = file.sharedUsers.map(user => user.email.toLowerCase());
        this.dataFromParent.forEach(consultant => {
          if (sharedEmails.includes(consultant.email.toLowerCase())) consultant.highlighted = true;
        });
      } else return;
  }

  selectedOptionsClear() {
    this.consultants.selectedOptions.clear();
  }

  rowClick(consultant) {
    this.clearPicked();
    this.rowClickEvent.emit(consultant);
    this.currentValue = consultant === '' ? '' : consultant.email;
    this.optionSelectedClick = consultant === '' ? 0 : 1;
  }

  handleOptionClick(consultant, event) {
    event.stopPropagation();
    event.preventDefault();
    if (consultant.email === this.currentValue) {
      this.consultants.selectedOptions.clear();
      this.rowClick('');
    } else this.rowClick(consultant);
  }

  checkSelected(consultant) {
    if (this.selectedFiles.length > 1) {
      if (this.checkSelectedShared(consultant, this.selectedFiles)) {
        return true;
      }
      return false;
    }
  }

  checkSelectedShared(consultant, selectedFiles) {
    let counder = 0;
    if (selectedFiles.length < 2) return false;
    selectedFiles.forEach(file => {
      if (this.fileIsShared(consultant, file)) counder++;
    });
    if (counder === selectedFiles.length) return true;
    return false;
  }

  checkSelectedNotShared(consultant, selectedFiles) {
    if (selectedFiles.length < 2) return false;
    let counder = 0;
    selectedFiles.forEach(file => {
      if (!this.fileIsShared(consultant, file)) {
        counder++;
      }
    });
    if (counder === selectedFiles.length) {
      return true;
    }
    return false;
  }

  /**
   * Check if file is shared with the given consultant.
   */
  fileIsShared(consultant, file) {
    return this.Utils$.fileIsShared(consultant, file);
  }

  share(consultant) {
    this.shareEvent.emit({ email: consultant.email, uid: consultant.uid });
  }

  unShare(consultant) {
    this.unshareEvent.emit({ consultant: consultant.email, type: 'single' });
  }

  unShareAll(consultant) {
    this.unshareEvent.emit({ consultant: consultant.email, type: 'all' });
  }

  toggleVisibilityProfile(consultant) {
    this.router.navigate([`/profile/${consultant.uid}`]);
  }

  isRestrictedProfile(consultant): boolean {
    if (!this.restrictedProfiles) return false;
    if (this.restrictedProfiles.length === 0) return false;
    return this.restrictedProfiles.includes(consultant.uid);
  }

  makeProfilePublic(event, consultant) {
    event.stopPropagation();
    event.preventDefault();
    this.firebase_utilities_$
      .makeProfilePublic(this.patientDocId, consultant.uid)
      .then(r => this.afterProfileUpdated())
      .catch(e => console.log(e));
  }

  makeProfileRestricted(event, consultant) {
    event.stopPropagation();
    event.preventDefault();
    this.firebase_utilities_$
      .makeProfileRestricted(this.patientDocId, consultant.uid)
      .then(r => this.afterProfileUpdated())
      .catch(e => console.log(e));
  }

  private afterProfileUpdated() {
    this.snackBar.open('Profile visibility updated', 'OK', { duration: 2000 });
    this.needConsultantsReloadEvent.emit();
  }

  goToIndex(event) {
    event.stopPropagation();
    event.preventDefault();
    this.goToFolderEvent.emit({ folderId: 'all', consultant: this.consultants._value[0] });
  }
}
