import { Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent {
  @Input() fileViewData: any;
  @Input() viewerUrl: any;
  @ViewChild('viewerIframe') viewerIframe: HTMLIFrameElement;
  loadingIframe: boolean = false;
  constructor() {}

  showIframe() {
    this.loadingIframe = true;
    console.log('The iframe has loaded');
  }
}
