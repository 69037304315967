import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog) {}

  open(component: any, data: any) {
    if (this.dialog.openDialogs.map(dialog => dialog.id).includes(data.id))
      data.id = data.id + Math.floor(Math.random() * 1000);
    return this.dialog.open(component, data);
  }

  close(loaderId: string) {
    this.dialog.openDialogs.forEach(dialog => {
      if (dialog.id === loaderId) {
        dialog.close();
        console.log('Dialog closed');
      }
    });
  }
}
