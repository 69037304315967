import { Injectable } from '@angular/core';
import firebase from 'firebase';
import { UserRoles } from './dictionaries/UserRoles';
import { NotificationData } from './models/NotificationData';
import { NotificationTypes } from './models/NotificationTypes';
import { FileRegistrationResult } from './services/upload-helper.service';

const notificationCollection = 'notifications';
export interface UserData {
  email: string;
  id: string;
  role: string;
  uid: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  fileAdded(params: any, fileData?: FileRegistrationResult) {
    console.log('💾', { params, fileData });
  }

  disableNewDownloadNotification(fileId: string) {
    firebase
      .firestore()
      .collection('notifications')
      .where('fileId', '==', fileId)
      .where('type', '==', 'newfile')
      .get()
      .then(querySnapshot =>
        querySnapshot.forEach(doc => {
          console.log('📬', 'disableNewDownloadNotification', doc.id);
          doc.ref.update({ modalOff: true });
        }),
      );
  }

  DICOMStudyAdded(params: any, fileData?: FileRegistrationResult) {
    console.log('🦴', { params, fileData });
  }

  public async getCLientsWithNotifications() {
    firebase.firestore().collection(notificationCollection);
  }

  private async createNewNoteNotifications(notificationData: NotificationData, currentUser) {
    const ownerUID = await this.getOwnerId(notificationData.userId, notificationData.userRole);
    let targetAccounts = await this.firebase_getAssociatesAndAdmins(ownerUID, currentUser);
    if (![UserRoles.owner].includes(currentUser.role)) {
      const ownerData = await this.getOwner(ownerUID);
      targetAccounts = [...targetAccounts, ownerData].filter(item => item !== undefined);
    }

    return this.fireNewNoteNotifications(targetAccounts, notificationData);
  }

  private async createModifiedNoteNotifications(notificationData: NotificationData, currentUser) {
    const ownerUID = await this.getOwnerId(notificationData.userId, notificationData.userRole);
    let targetAccounts = await this.firebase_getAssociatesAndAdmins(ownerUID, currentUser);
    if (![UserRoles.owner].includes(currentUser.role)) {
      const ownerData = await this.getOwner(ownerUID);
      targetAccounts = [...targetAccounts, ownerData].filter(item => item !== undefined);
    }

    return this.fireModifiedNoteNotifications(targetAccounts, notificationData);
  }

  private async createNewFileNotifications(notificationData: NotificationData, currentUser) {
    const ownerUID = await this.getOwnerId(notificationData.userId, notificationData.userRole);
    let targetAccounts = await this.firebase_getAssociatesAndAdmins(ownerUID, currentUser);

    if (![UserRoles.owner].includes(currentUser.role)) {
      const ownerData = await this.getOwner(ownerUID);
      targetAccounts = [...targetAccounts, ownerData].filter(item => item !== undefined);
    }

    return this.fireNewFileNotifications(targetAccounts, notificationData);
  }

  private async getOwner(ownerUID: string) {
    const owner = await this.firebase_getUserByUID(ownerUID);
    return { uid: ownerUID, id: owner.id, email: owner.email, role: UserRoles.owner };
  }

  async fireNewFileNotifications(targetAccounts: any[], notificationsData: NotificationData) {
    return await this.firebase_createNotificationsV2(
      this.firebase_getNotificationsL(notificationsData, targetAccounts),
    );
  }

  async fireModifiedNoteNotifications(targetAccounts: any[], notificationsData: NotificationData) {
    return await this.firebase_createNotificationsV2(
      this.firebase_getNotificationsL(notificationsData, targetAccounts),
    );
  }

  async fireNewNoteNotifications(targetAccounts: any[], notificationsData: NotificationData) {
    return await this.firebase_createNotificationsV2(
      this.firebase_getNotificationsL(notificationsData, targetAccounts),
    );
  }

  private async getOwnerId(uid: string, userRole: string) {
    if (userRole === UserRoles.owner) return uid;
    return await this.firebase_getOwnerIdByUserId(uid);
  }

  private getNotificationsL(notificationsData: NotificationData, targetAccounts: any[]) {
    return targetAccounts.map(({ uid, role }) => {
      return {
        userId: uid,
        type: notificationsData.type,
        fileId: notificationsData.fileId,
        fileType: notificationsData.fileType,
        folderId: notificationsData.folderId,
        caseId: notificationsData.caseId,
        userRole: role,
      };
    });
  }

  createNotification(notificationData: NotificationData, currentUser: UserData) {
    switch (notificationData.type) {
      case NotificationTypes.newfile:
        return this.createNewFileNotifications(notificationData, currentUser);
      case NotificationTypes.newnote:
        return this.createNewNoteNotifications(notificationData, currentUser);
      case NotificationTypes.modifiednote:
        return this.createModifiedNoteNotifications(notificationData, currentUser);
      default:
        return;
    }
  }

  deleteNotification(fileId: string, noteId: string) {
    console.log('📬', 'deleteNotification');
    return this.firebase_deleteNotification(noteId);
  }

  deleteNotificationsByFileIdAndUserId(fileId: string, userId: string) {
    console.log('📬', 'deleteNotificationsByFileIdAndUserId');
    return this.firebase_deleteNotificationsByFileIdAndUserId(fileId, userId);
  }

  /* ===================================== */
  /*!SECTION: Gettting Notifications */
  /* ===================================== */

  getNotifications() {}

  getNotificationsByNoteId(noteId: string) {
    console.log('📬', 'getNotificationsByNoteId');
    return this.firebase_getNotificationsByUserId(noteId);
  }

  getNotificationsByFileId(fileId: string) {
    console.log('📬', 'getNotificationsByFileId');
    return this.firebase_getNotificationsByFileId(fileId);
  }

  async getNotificationsByUserId(uid: string) {
    return this.firebase_getNotificationsByUserId(uid);
  }

  getNotificationsByCasename(casename: string) {
    console.log('📬', 'getNotificationsByCasename');
    return this.firebase_getNotificationsByCasename(casename);
  }

  getNotificationsByFolderId(folderId: string) {
    console.log('📬', 'getNotificationsByFolderId');
    return this.firebase_getNotificationsByFolderId(folderId);
  }

  /* ==================  END  =================== */

  /* ===================================== */
  /*!SECTION: Firebase Private Functions */
  /* ===================================== */

  firebase_getNotificationsByFolderId(folderId: string) {
    return firebase.firestore().collection(notificationCollection).where('folderId', '==', folderId).get();
  }

  private async firebase_deleteNotificationsByFileIdAndUserId(fileId: string, userId: string) {
    const foundDocs = (
      await firebase
        .firestore()
        .collection(notificationCollection)
        .where('fileId', '==', fileId)
        .where('userId', '==', userId)
        .get()
    ).docs;
    for (const doc of foundDocs) await doc.ref.delete();
  }

  firebase_getNotificationsByCasename(casename: string) {
    return firebase.firestore().collection(notificationCollection).where('casename', '==', casename).get();
  }

  async firebase_getNotificationsByUserId(userId: string) {
    return (await firebase.firestore().collection(notificationCollection).where('userId', '==', userId).get()).docs.map(
      doc => doc.data(),
    );
  }

  firebase_getNotificationsByFileId(fileId: string) {
    return firebase.firestore().collection(notificationCollection).where('fileId', '==', fileId).get();
  }

  async firebase_getOwnerIdByUserId(uid: string) {
    const querySnapshot = await firebase.firestore().collection('users').where('uid', '==', uid).get();
    return querySnapshot.docs[0].data().ownerID;
  }

  private firebase_generateNotificationId(userId: string, type: string, caseId: string): string {
    return `n-${type}-${caseId}-${userId}-${new Date().getTime()}`;
  }

  firebase_createNotification(notificationData: { userId: string; type: string; caseId: string }) {
    const docId = this.firebase_generateNotificationId(
      notificationData.userId,
      notificationData.type,
      notificationData.caseId,
    );
    return firebase.firestore().collection(notificationCollection).doc(docId).set(notificationData);
  }

  firebase_deleteNotification(noteId: string) {
    firebase.firestore().collection(notificationCollection).doc(noteId).collection('notes').doc(noteId).delete();
  }

  async firebase_getUserByUID(uid: string): Promise<any> {
    const doc = (await firebase.firestore().collection('users').where('uid', '==', uid).limit(1).get()).docs[0];
    return { ...doc.data(), id: doc.id };
  }

  async firebase_getAssociatesAndAdmins(ownerId: any, exceptionUser: UserData) {
    return (
      await firebase
        .firestore()
        .collection('users')
        .where('owners', 'array-contains', ownerId)
        .where('disabled', '==', false)
        .where('role', 'in', [UserRoles.associate, UserRoles.admin])
        .get()
    ).docs.map(doc => {
      const docData: any = { ...doc.data(), id: doc.id };
      if (exceptionUser?.email !== docData.email)
        return { id: docData.id, email: docData.email, role: docData.role, uid: docData.uid };
    });
  }

  public firebase_getNotificationsL(notificationsData, targetAccounts) {
    return targetAccounts.map(({ uid, role }) => {
      return {
        userId: uid,
        type: notificationsData.type,
        fileId: notificationsData.fileId,
        fileType: notificationsData.fileType,
        folderId: notificationsData.folderId,
        caseId: notificationsData.caseId,
        userRole: role,
      };
    });
  }

  firebase_createNotificationsV2(notifications: { userId: string; type: string; caseId: string }[]) {
    const promises = [];
    notifications.forEach((notification: { userId: string; type: string; caseId: string }) =>
      promises.push(this.firebase_createNotification(notification)),
    );

    return Promise.all(promises);
  }

  deleteNewFileNotificationsByFileIdAndUserId(fileId: string, userId: string) {
    return this.firebase_deleteNewFileNotificationsByFileIdAndUserId(fileId, userId);
  }

  private async firebase_deleteNewFileNotificationsByFileIdAndUserId(fileId: string, userId: string) {
    const foundDocs = (
      await firebase
        .firestore()
        .collection(notificationCollection)
        .where('fileId', '==', fileId)
        .where('userId', '==', userId)
        .where('type', '==', NotificationTypes.newfile)
        .get()
    ).docs;
    for (const doc of foundDocs) await doc.ref.delete();
  }

  deleteNoteNotificationsByFileIdAndUserId(fileId: string, userId: string) {
    return this.firebase_deleteNoteNotificationsByFileIdAndUserId(fileId, userId);
  }

  private async firebase_deleteNoteNotificationsByFileIdAndUserId(fileId: string, userId: string) {
    const foundDocs = (
      await firebase
        .firestore()
        .collection(notificationCollection)
        .where('fileId', '==', fileId)
        .where('userId', '==', userId)
        .where('type', 'in', [NotificationTypes.newnote, NotificationTypes.modifiednote])
        .get()
    ).docs;
    for (const doc of foundDocs) await doc.ref.delete();
  }

  private async firebase_deleteAllFileNotifications(fileId: string) {
    const foundDocs = (
      await firebase.firestore().collection(notificationCollection).where('fileId', '==', fileId).get()
    ).docs;
    for (const doc of foundDocs) await doc.ref.delete();
  }

  deleteAllFileNotifications(fileId: string) {
    // Delete all notifications for a file by fileId (used when deleting a file)
    return this.firebase_deleteAllFileNotifications(fileId);
  }

  private async firebase_deleteNewFileAndSharedFileNotificationsByFileIdAndUserId(fileId: string, userId: string) {
    const foundDocs = (
      await firebase
        .firestore()
        .collection(notificationCollection)
        .where('fileId', '==', fileId)
        .where('userId', '==', userId)
        .where('type', 'in', [NotificationTypes.newfile, NotificationTypes.sharedfile])
        .get()
    ).docs;
    for (const doc of foundDocs) await doc.ref.delete();
  }

  private async firebase_deleteSharedFileAndSharedFileNotificationsByFileIdAndUserId(fileId: string, userId: string) {
    const foundDocs = (
      await firebase
        .firestore()
        .collection(notificationCollection)
        .where('fileId', '==', fileId)
        .where('userId', '==', userId)
        .where('type', '==', NotificationTypes.sharedfile)
        .get()
    ).docs;
    for (const doc of foundDocs) await doc.ref.delete();
  }

  deleteNewFileAndSharedFileNotificationsByFileIdAndUserId(fileId: string, userId: string) {
    return this.firebase_deleteNewFileAndSharedFileNotificationsByFileIdAndUserId(fileId, userId);
  }

  deleteSharedFileNotificationsByFileIdAndUserId(fileId: string, userId: string) {
    return this.firebase_deleteSharedFileAndSharedFileNotificationsByFileIdAndUserId(fileId, userId);
  }
}
