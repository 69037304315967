<app-window-title
  [title]="'Client/Matter Profile' + ' ' + patientName"
  [bottomBorder]="false"
  [closable]="true"></app-window-title>
<div
  fxLayout="row"
  fxLayout.sm="column"
  fxLayout.xs="column"
  style="margin-bottom: 15px">
  <div
    class="client-matter-data"
    [ngClass]="'mat-body'"
    fxFlex
    fxLayout="row wrap">
    <app-client-matter-data-info
      *ngIf="patient"
      [patient]="patient"></app-client-matter-data-info>
    <button
      *ngIf="
        realRole === UserRoles.owner &&
        (!patient?.clientUser || patient.clientUser.length === 0) &&
        plancode !== 'silver'
      "
      mat-flat-button
      color="warn"
      (click)="handleAddClientUser($event)">
      <mat-icon>person_add</mat-icon>
      Add Client Upload Account
    </button>
  </div>
  <!-- <div fxFlex="none">
    <button
      mat-flat-button
      mat-dialog-close
      color="warn">
      X
    </button>
  </div> -->
</div>
<div
  style="height: 75vh; width: 100%"
  *ngIf="patientName !== '...'">
  <div
    style="height: 100%"
    fxLayout="column">
    <div
      fxLayoutAlign="space-between start"
      fxLayoutGap="15px grid"
      fxLayout="row">
      <div fxLayout="column">
        <div
          *ngIf="loadingPermissions"
          style="margin-bottom: 10px"
          fxLayout="row"
          fxLayoutAlign="start center">
          <app-loader></app-loader>
        </div>

        <!-- TOP BUTTONS -->
        <app-client-profile-top-buttons
          [importFromAppsBtn]="importFromAppsBtn"
          [clioMatterId]="clioMatterId"
          [realRole]="realRole"
          [permissions_uploadfiles]="permissions_uploadfiles"
          (addConsultantEvent)="addConsultant()"
          (openDialogEvent)="openDialog($event)"
          (importFromAppsEvent)="showImportFromApps()"
          (backToClioEvent)="backToClio($event)"
          [currentFolderName]="currentFolderName"></app-client-profile-top-buttons>
        <br />

        <!-- BREADCRUMB -->
        <app-breadcrumb
          *ngIf="UserRoles.client !== realRole"
          [folderColor]="folderColor"
          [currentFolder]="currentFolder"
          [currentFolderName]="currentFolderName"
          (buttonClick)="handleBreadcrumbClick($event)"></app-breadcrumb>

        <!-- HELP TEXT -->
        <app-client-profile-info-box
          [currentFolderName]="currentFolderName"
          [currentFolder]="currentFolder"
          [filesTableDataSource]="filesTableDataSource"
          [permissions_uploadfiles]="permissions_uploadfiles"
          [UserRoles]="UserRoles"
          [realRole]="realRole"></app-client-profile-info-box>

        <!-- TOOLBAR -->
        <app-client-profile-toolbar
          [selection]="selection"
          [sortTypes]="sortTypes"
          [fileActionsMenuItems]="fileActionsMenuItems"
          [allowDeleteNote]="allowDeleteNote"
          [fileSelected]="fileSelected()"
          [refreshButton]="refreshButton"
          [currentFolder]="currentFolder"
          [currentFolderName]="currentFolderName"
          [files]="files"
          [realRole]="realRole"
          [UserRoles]="UserRoles"
          [permissions_createfolder]="permissions_createfolder"
          [activeConsultant]="activeConsultant"
          [filesFilter]="filesFilter"
          [activeConsultant]="activeConsultant"
          [unshareAllFilesMsg]="unshareAllFilesMsg"
          (openAddNoteDialogEvent)="openAddNoteDialog()"
          (openDeleteConfirmDialogEvent)="openDeleteConfirmDialog()"
          (handleRefreshEvent)="handleRefresh()"
          (createFolderEvent)="createFolder(currentFolder, { folderName: currentFolderName })"
          (openFileSearchEvent)="openFileSearch()"
          (sortFilesBySelectedEvent)="sortFilesBySelected()"
          (removeFilterEvent)="removeFilter()"
          (handleSelectEvent)="handleSelect($event)"
          (unShareAllEvent)="unShareAll($event)"></app-client-profile-toolbar>
      </div>
      <app-notifications
        fxLayoutGap="15px"
        [notifications]="clientProfileNotifications"
        (clickAction)="onNotificationClick($event)"></app-notifications>
    </div>

    <div fxLayout="row">
      <div
        fxFlex
        fxLayout="column">
        <div
          *ngIf="filesTableDataSource.data"
          [ngClass]="'mat-elevation-z0 stickyheader-table-container table-big-container'"
          class="files-table">
          <div
            *ngIf="filesTableDataSource.data.length === 0 && loading === false"
            class="no-files-message">
            <h2 [ngClass]="'mat-body'">{{ nofilesMessage }}</h2>
          </div>
          <app-loader
            *ngIf="loading"
            [message]="loadingMessage || ''"></app-loader>
          <div
            *ngIf="filesTableDataSource.data.length > 0; else nofiles"
            [ngClass]="'table-container'">
            <table
              [ngClass]="'mat-elevation-z1'"
              [dataSource]="filesTableDataSource"
              (matSortChange)="handleSortData($event)"
              matSort
              mat-table
              matSortActive="fileName"
              matSortDirection="desc"
              matSortDisableClear>
              <!-- Checkbox Column -->
              <ng-container matColumnDef="select">
                <th
                  *matHeaderCellDef
                  [ngClass]="{ hidden: currentFolder === '' }"
                  mat-header-cell>
                  <mat-checkbox
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    (change)="$event ? masterToggle() : null">
                  </mat-checkbox>
                </th>
                <td
                  *matCellDef="let element"
                  [ngClass]="{ hidden: currentFolder === '' }"
                  mat-cell>
                  <mat-checkbox
                    [checked]="selection.isSelected(element)"
                    (click)="handleCheckboxClick($event)"
                    (change)="$event ? selection.toggle(element) : null">
                  </mat-checkbox>
                </td>
              </ng-container>
              <!-- File Name Column -->
              <ng-container matColumnDef="fileName">
                <th
                  *matHeaderCellDef
                  [ngClass]="'bold-black-table-header'"
                  mat-header-cell
                  mat-sort-header="fileName">
                  <span *ngIf="currentFolder !== '' && currentFolderName !== 'Discs' && realRole !== 'Consultant'">
                    Files you have uploaded
                  </span>
                  <span *ngIf="currentFolderName === 'Discs'"> Discs files you have generated </span>
                  <span *ngIf="currentFolder !== '' && realRole === 'Consultant'">
                    Files you have available for review:
                  </span>
                  <span *ngIf="currentFolder === ''">Your folders</span>
                </th>
                <td
                  *matCellDef="let element"
                  mat-cell>
                  <span
                    *ngIf="checkInactiveStore(element)"
                    class="loading-message">
                    <mat-icon
                      aria-hidden="false"
                      aria-label="preview icon">
                      preview
                    </mat-icon>
                    &nbsp; Study loading...
                  </span>
                  <a
                    *ngIf="!element.viewerurl && element.type !== 'folder'"
                    [ngClass]="'folder-button'"
                    (click)="handleFileClick($event, element)"
                    mat-flat-button
                    attr.data-storename="{{ element.storename }}"
                    matTooltip="Click here to browse the file in a new tab."
                    target="_blank"
                    download>
                    <mat-icon [ngClass]="element.hasNotifications ? 'blinking-bell' : 'hidden'"
                      >fiber_manual_record</mat-icon
                    >{{ element.fileName }}</a
                  >
                  <button
                    *ngIf="element.clioDocId"
                    (click)="openDocumentInClio(element.clioDocId)"
                    mat-flat-button
                    matTooltip="Open this Document in Clio">
                    <img
                      src="./../../../assets/png/clio-blue-square.png"
                      width="15px"
                      alt="Clio logo" />
                  </button>
                  <a
                    *ngIf="element.type === 'folder'"
                    [ngStyle]="getButtonStyle(element.name)"
                    [ngClass]="'folder-button'"
                    (click)="handleFolderClick($event, element)"
                    mat-flat-button
                    matTooltip="Click here to browse the folder contents.">
                    <mat-icon
                      aria-hidden="false"
                      aria-label="Example home icon"
                      class="material-icons-outlined">
                      folder
                    </mat-icon>
                    {{ element.label || element.name }}
                  </a>
                  <mat-icon
                    *ngIf="element.type === 'folder'"
                    [ngClass]="element.hasNotifications ? 'blinking-bell' : 'hidden'"
                    >notifications</mat-icon
                  >

                  <mat-icon
                    *ngIf="element.viewerurl && element.type !== 'folder'"
                    [ngClass]="element.hasNotifications ? 'blinking-bell' : 'hidden'"
                    >fiber_manual_record</mat-icon
                  >

                  <button
                    *ngIf="element.viewerurl && !checkInactiveStore(element)"
                    [ngClass]="{ 'view-link': true }"
                    [ngStyle]="{ display: 'inline-flex', verticalAlign: 'middle' }"
                    (click)="handleFileClick($event, element)"
                    matTooltip="Click here to browse the study in the DICOM viewer.">
                    <mat-icon
                      aria-hidden="false"
                      aria-label="preview icon">
                      preview
                    </mat-icon>
                    {{
                      element.filePath?.indexOf(dicomdirFileName) >= 0 || element.fileName === dicomdirFileName
                        ? 'Imaging Study'
                        : element.fileName
                    }}
                  </button>
                  <mat-icon
                    *ngIf="element.sharedUsers && element.sharedUsers.length > 0 && permissions_sharefileicon"
                    [ngClass]="'file-icon'"
                    (click)="toggleHighLight($event, element)"
                    aria-hidden="false"
                    aria-label="This file is shared."
                    matTooltip="This file is shared.">
                    people
                  </mat-icon>

                  <!-- Place a waiting animation here -->
                  <mat-spinner
                    style="margin-left: 10px; display: inline-block; vertical-align: middle"
                    *ngIf="element.waiting"
                    [diameter]="20"
                    [strokeWidth]="4"
                    [color]="'warn'"></mat-spinner>

                  <!-- Place a DONE icon when waiting is done -->
                  <mat-icon
                    *ngIf="element.waiting === false && element.downloadLink"
                    [ngClass]="'file-icon'"
                    [color]="'warn'"
                    aria-hidden="false"
                    aria-label="This zipped contents are ready for download."
                    matTooltip="This zipped contents are ready for download.">
                    download</mat-icon
                  >

                  <mat-icon
                    *ngIf="element.notes && element.notes.length && validateProfile(element.notes)"
                    [ngClass]="'file-icon'"
                    [ngClass]="
                      element.newNoteNotifications?.length || element.modifiedNoteNotifications?.length
                        ? 'blinking-note'
                        : ''
                    "
                    [ngClass]="{ disabled: element.disabled }"
                    aria-hidden="false"
                    [color]="
                      element.newNoteNotifications?.length || element.modifiedNoteNotifications?.length ? 'warn' : ''
                    "
                    [disabled]="!element.notes.length || !element.disabled"
                    (click)="handleNoteClick($event, element)"
                    aria-label="sticky_note_2 icon"
                    matTooltip="This file has note(s).">
                    sticky_note_2
                  </mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="fileDesc">
                <th
                  *matHeaderCellDef
                  [ngClass]="{ hidden: currentFolder === '' }"
                  mat-header-cell
                  mat-sort-header="fileDesc">
                  File content
                </th>
                <td
                  *matCellDef="let element"
                  [ngClass]="{ hidden: currentFolder === '' }"
                  mat-cell>
                  {{ element.type !== 'folder' ? element.fileDesc : '' }}
                </td>
              </ng-container>
              <!-- Last Modified Column -->
              <ng-container matColumnDef="lastModified">
                <th
                  *matHeaderCellDef
                  [ngClass]="{ hidden: currentFolder === '' }"
                  mat-header-cell
                  mat-sort-header="lastModified">
                  Last Modified
                </th>
                <td
                  *matCellDef="let element"
                  [ngClass]="{ hidden: currentFolder === '' }"
                  mat-cell>
                  {{ element.type !== 'folder' ? (element.lastModified ? element.lastModified : '') : '' }}
                </td>
              </ng-container>
              <!-- Date Uploaded Column -->
              <ng-container matColumnDef="uploadedDate">
                <th
                  *matHeaderCellDef
                  [ngClass]="{ hidden: currentFolder === '' }"
                  mat-header-cell
                  mat-sort-header="uploadedDate">
                  {{ currentFolderName === discsFolderName ? 'Date Created' : 'Date Uploaded' }}
                </th>
                <td
                  *matCellDef="let element"
                  [ngClass]="{ hidden: currentFolder === '' }"
                  mat-cell>
                  {{ element.type !== 'folder' ? element.uploadedDate : '' }}
                </td>
              </ng-container>
              <!-- Date of File Column -->
              <ng-container matColumnDef="fdate">
                <th
                  *matHeaderCellDef
                  [ngClass]="{ hidden: currentFolder === '' }"
                  mat-header-cell
                  mat-sort-header="fdate">
                  Date of File
                </th>
                <td
                  *matCellDef="let element"
                  [ngClass]="{ hidden: currentFolder === '' }"
                  mat-cell>
                  {{
                    element.type !== 'folder'
                      ? element.fdate
                        ? element.ftime
                          ? element.fdate + ' - ' + element.ftime
                          : element.fdate
                        : ''
                      : ''
                  }}
                </td>
              </ng-container>
              <!-- File parentFolderName Column -->
              <ng-container matColumnDef="parentFolderName">
                <th
                  *matHeaderCellDef
                  [ngClass]="{ hidden: currentFolder !== 'all' }"
                  mat-header-cell>
                  File Folder Name
                </th>
                <td
                  *matCellDef="let element"
                  [ngClass]="{ hidden: currentFolder !== 'all' }"
                  mat-cell>
                  <a
                    [ngClass]="['folder-button', 'short']"
                    [ngStyle]="getButtonStyle(element.parentFolderName || element.parentFolder)"
                    (click)="handleFolderNameClick($event, element)"
                    matTooltip=" {{ element.parentFolderName || element.parentFolder || '-' }}"
                    mat-flat-button>
                    <mat-icon
                      aria-hidden="false"
                      aria-label="Example home icon"
                      class="material-icons-outlined">
                      folder
                    </mat-icon>
                    {{ element.parentFolderName || element.parentFolder || '-' }}
                  </a>
                  <div *ngIf="element?.parentFolders">
                    <a
                      *ngFor="let folder of element.parentFolders; let i = index"
                      [ngClass]="['folder-button', 'short']"
                      [ngStyle]="getButtonStyle(folder.folderName)"
                      (click)="handleParentFolderNameClick($event, folder)"
                      matTooltip=" {{ folder }}"
                      mat-flat-button>
                      <mat-icon
                        aria-hidden="false"
                        aria-label="Folder icon"
                        class="material-icons-outlined">
                        folder
                      </mat-icon>
                      {{ folder.folderName || folder }}
                    </a>
                  </div>
                </td>
              </ng-container>
              <!-- File ID Column -->
              <ng-container matColumnDef="fileId">
                <th
                  *matHeaderCellDef
                  mat-header-cell
                  class="table-actions-column">
                  File actions
                </th>
                <td
                  *matCellDef="let element"
                  mat-cell>
                  <button
                    *ngIf="
                      permissions_editfiles &&
                      element.folderId !== 'all' &&
                      element.predefined !== true &&
                      currentFolderName !== discsFolderName
                    "
                    [ngClass]="'small-mat-icon-button'"
                    (click)="renameElement(element, $event)"
                    mat-icon-button
                    matTooltip="Click to edit this file."
                    aria-label="Click here to edit this upload.">
                    <mat-icon>create</mat-icon>
                  </button>
                  <button
                    *ngIf="
                      sysadmin &&
                      permissions_editfiles &&
                      element.folderId !== 'all' &&
                      element.predefined !== true &&
                      element.type === 'folder'
                    "
                    [ngClass]="'small-mat-icon-button'"
                    (click)="deleteFolder(element, $event)"
                    mat-icon-button
                    matTooltip="Click to delete this folder."
                    aria-label="Click to delete this folder.">
                    <mat-icon>delete</mat-icon>
                  </button>

                  <button
                    *ngIf="permissions_deletefiles && element.type !== 'folder'"
                    [ngClass]="'small-mat-icon-button'"
                    (click)="deleteSingleRow(element, $event)"
                    mat-icon-button
                    aria-label="Click here to delete this from the files you have uploaded."
                    matTooltip="Click here to delete this from the files you have uploaded.">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>
              <!-- File predefined Column -->
              <ng-container matColumnDef="predefined">
                <th
                  *matHeaderCellDef
                  class="hidden"
                  mat-header-cell>
                  predefined
                </th>
                <td
                  *matCellDef="let element"
                  class="hidden"
                  mat-cell>
                  {{ element.predefined }}
                </td>
              </ng-container>
              <!-- File Shared Column -->
              <ng-container matColumnDef="isShared">
                <th
                  *matHeaderCellDef
                  [ngClass]="{
                    hidden: !activeConsultant || realRole === 'Consultant'
                  }"
                  mat-header-cell
                  mat-sort-header="isShared">
                  Shared status
                </th>
                <td
                  *matCellDef="let element"
                  [ngClass]="{
                    hidden: !activeConsultant || realRole === 'Consultant'
                  }"
                  mat-cell>
                  <span
                    *ngIf="activeConsultant !== null && !fileSharedValidation(element) && !checkIfSelected(element)"
                    class="not-shared-message">
                    Not shared
                  </span>
                  <button
                    *ngIf="activeConsultant !== null && !fileSharedValidation(element) && checkIfSelected(element)"
                    [ngClass]="{ grayed: !checkIfSelected(element) }"
                    (click)="shareSingleFile(element, $event)"
                    mat-flat-button
                    color="warn"
                    matTooltip="Click here to share this file with active consultant.">
                    Share
                  </button>
                  <span *ngIf="activeConsultant && fileSharedValidation(element) && !checkIfSelected(element)"
                    >Already shared</span
                  >
                  <button
                    *ngIf="activeConsultant && fileSharedValidation(element) && checkIfSelected(element)"
                    [ngClass]="{ blushed: checkIfSelected(element) }"
                    (click)="$event.stopPropagation(); unShareSingleFile(element)"
                    mat-flat-button
                    matTooltip="Click here to stop sharing this file with active consultant.">
                    Unshare
                  </button>
                </td>
              </ng-container>
              <tbody>
                <tr
                  *matHeaderRowDef="displayedColumns; sticky: true"
                  mat-header-row></tr>
                <tr
                  *matRowDef="let element; columns: displayedColumns; let i = index"
                  [ngClass]="{
                    highlighted: fileSharedValidation(element) && realRole !== 'Consultant',
                    'inactive-store': checkInactiveStore(element)
                  }"
                  (click)="
                    isDisplayContextMenu = false;
                    handleRowClick(element, $event, {
                      currentFolder: currentFolder
                    })
                  "
                  mat-row></tr>
              </tbody>
            </table>
          </div>
          <ng-template #nofiles>
            <div class="no-files"><p style="color: red; font-size: 12px">No files found</p></div>
          </ng-template>
          <mat-paginator
            *ngIf="pageSize"
            [length]="pagesLength"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizes"
            showFirstLastButtons
            color="warn"></mat-paginator>
        </div>
      </div>

      <mat-accordion
        displayMode="flat"
        fxFlex="30"
        *ngIf="
          permissions_consultantslist &&
          filesTableDataSource.data.length > 0 &&
          currentFolder !== '' &&
          currentFolderName !== discsFolderName
        ">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>Consultants & Users</mat-panel-title>
          </mat-expansion-panel-header>
          <!-- <h2 [ngClass]="'mat-h3 red-title'">Consultants & Users</h2> -->
          <mat-tab-group
            [ngClass]="['custom-tab-label', 'consultants-list-tab-group']"
            [(selectedIndex)]="selectedTabIndex"
            (selectedTabChange)="handleTabsClick($event)"
            #consultants_list_tab_group
            color="warn">
            <mat-tab
              *ngIf="activeConsultants"
              #active_consultants_tab
              label="Active">
              <p class="mat-body active-consultants-message">
                Click Consultant to see which files are Shared with them.
              </p>
              <app-active-consultants
                #active_consultants
                *ngIf="activeConsultants"
                [dataFromParent]="activeConsultants"
                [sharedFilesCount]="sharedFilesCount"
                [restrictedProfiles]="patient.restrictedProfiles"
                [allowedProfiles]="patient.allowedProfiles"
                [caseName]="patient.caseName"
                [patientDocId]="patient.patientDocId"
                [selectedFiles]="selection.selected"
                (goToFolderEvent)="goToFolder($event)"
                (unshareEvent)="unShareWith($event)"
                (shareEvent)="shareSelectedFilesWith($event)"
                (rowClickEvent)="handleActiveConsultantClick($event)"
                (needConsultantsReloadEvent)="reloadConsultantsData($event)"></app-active-consultants>
            </mat-tab>
            <mat-tab
              #available_consultants_tab
              *ngIf="availableConsultants && currentFolder !== ''"
              label="Available">
              <app-available-consultants
                #available_consultants
                *ngIf="availableConsultants"
                [caseName]="patient.caseName"
                [availableConsultants]="availableConsultants"
                (parentReload)="clientProfileReload($event)"
                (shareEvent)="shareSelectedFilesWith($event)">
              </app-available-consultants>
            </mat-tab>
          </mat-tab-group>
        </mat-expansion-panel>
        <!-- <mat-expansion-panel #helpPanel>
        <mat-expansion-panel-header>
          <mat-panel-title
            >How to get a DISC &nbsp;<mat-icon style="display: inline-block; vertical-align: middle"
              >album</mat-icon
            ></mat-panel-title
          >
        </mat-expansion-panel-header>
        <app-how-to-burn-a-disc #howToBurnADisc></app-how-to-burn-a-disc>
      </mat-expansion-panel> -->
      </mat-accordion>
    </div>
  </div>
  <div
    *ngIf="infectedFiles && infectedFiles.length"
    class="mat-small">
    <p>
      The followinfg file(s) has been deleted because of malware found in it. Please click
      <a
        (click)="goRefresh($event)"
        class="refresh-link"
        href="#">
        REFRESH
      </a>
      to update the list of files
    </p>
    <ul>
      <li
        *ngFor="let file of infectedFiles"
        class="infected-file">
        {{ file }}
      </li>
    </ul>
  </div>
</div>
