<!-- // NOTE: Compare with PROD-download-disc branch. -->

<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="20px">
  <button
    color="primary"
    mat-button
    (click)="handleListClioUsers()">
    List users from Clio with wrong Clio Access Token
  </button>
  <!-- <button (click)="cleanNotificationsIWithoutNodeId()">Delete Notifications with no noteId</button> -->
  <!-- <button (click)="cleanNotificationsOfDeletedFiles()">Delete Notifications of Files Deleted</button> -->

  <section>
    <h2>Duplicated folders</h2>
    <input
      type="text"
      name="caseName"
      #caseName
      placeholder="Case Name" />

    <div
      *ngIf="caseName.value"
      fxLayoutAlign="center center"
      fxLayout="row wrap"
      fxLayoutGap="30px">
      <button
        mat-flat-button
        color="accent"
        (click)="clearDuplicatedFoldersByCaseName('Clio', '00002-Mayerlen')">
        CLIO
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="clearDuplicatedFoldersByCaseName('Discovery', caseName.value)">
        DISCOVERY
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="clearDuplicatedFoldersByCaseName('Client Upload', caseName.value)">
        CLIENT UPLOAD
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="clearDuplicatedFoldersByCaseName('Other', caseName.value)">
        OTHER
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="clearDuplicatedFoldersByCaseName('Damages', caseName.value)">
        DAMAGES
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="clearDuplicatedFoldersByCaseName('Liability', caseName.value)">
        LIABILITY
      </button>
    </div>
    <button
      mat-flat-button
      color="accent"
      (click)="deleteNotifications('newfile', 'Consultant')">
      DELETE NEWFILE NOTIFICATIONS for Consultants
    </button>
  </section>

  <div
    fxLayoutAlign="center center"
    fxLayout="row wrap"
    fxLayoutGap="30px">
    <!-- CREATE A BUTTON TO RUN listOwners() on click -->
    <button
      mat-flat-button
      color="accent"
      (click)="listOwners()">
      List Owners
    </button>
  </div>

  <!-- Show a list of the owners, scrolled with a maximum height -->
  <div
    fxLayoutAlign="center center"
    fxLayout="column wrap"
    fxLayoutGap="30px">
    <input
      type="text"
      [(ngModel)]="filterText"
      placeholder="Filter owners" />

    <mat-list
      style="max-height: 300px; overflow: auto"
      fxLayoutAlign="center center"
      fxLayout="column">
      <mat-list-item *ngFor="let owner of filteredOwnersList">
        <h3 matLine>{{ owner.firstName }} {{ owner.lastName }} ({{ owner.name }})</h3>
        <!-- email -->
        <p matLine>{{ owner.email }}</p>
        <!-- button Get Storage Size -->
        <button
          mat-button
          color="primary"
          (click)="getStorageSizeByOwnerId(owner.uid)">
          Get Storage Size
        </button>
        <!-- button Get Reported Size -->
        <button
          mat-button
          color="primary"
          (click)="getReportedSizeByOwnerId(owner.uid)">
          Get Reported Size
        </button>
      </mat-list-item>
    </mat-list>
    <!-- <button (click)="userEmailsToLowerCase()">make al users email to lowercase</button> -->
    <!-- <button (click)="seeAllUsersEmails()">See all users emails</button> -->
    <!-- <button (click)="listSuperUsers()">list superusers</button> -->
    <!-- <button (click)="listViewerURLs()">Check vieweruls</button> -->
    <!-- <button (click)="updateFolderColors()">Update folder colors</button> -->
    <!-- <button (click)="allUsersEmailToLowerCase()">allUsersEmailToLowerCase</button> -->
    <!-- <button mat-button (click)="updateFilesSharedUsers()">Update Files Shared Users</button> -->
    <!-- <button mat-flat-button color="warn" (click)="showSpecificFile('MR BRAIN NECK WO IV CONTRAST STROKE')">
      Show specific file
    </button> -->
    <!-- <button mat-flat-button color="warn" (click)="showUserInfo('dmayerlen1+mayer@gmail.com')">Show user info</button> -->
    <!-- <button mat-flat-button color="warn" (click)="updateFilesSharedUsers()">Update Files Shared Users</button> -->
    <!-- <button mat-flat-button color="warn" (click)="removeSharingReferencesOfFilesDeletedInUsers()">
      Fixing the sharing between users and file
    </button> -->
    <!-- <button mat-flat-button color="warn" (click)="checkingData()"> -->
    <!-- <button mat-flat-button color="warn" (click)="removeSharingReferencesOfFilesDeleted()"> -->
    <!-- <button
      mat-flat-button
      color="warn"
      (click)="removeSharingOfUsersDeleted()">
      removeSharingOfUsersDeleted
    </button> -->
    <!-- <button mat-flat-button color="warn" (click)="usersFilesSharingFixing()"> -->
  </div>

  <!-- Notifications Section -->
  <section>
    <h2>Notifications</h2>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      fxLayoutGap="20px">
      <input
        type="text"
        name="fileId"
        #fileId />
      <button (click)="deleteNotificationsByFileId($event, fileId)">Delete Notifications by FileId</button>
      <input
        type="text"
        name="fileId"
        #fileId />
      <button (click)="deleteNotificationsByFileId($event, fileId)">Delete Notifications by FileId</button>
    </div>
  </section>

  <!-- Storage Size Section -->
  <section>
    <h2>Storage Size</h2>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      fxLayoutGap="20px">
      <input
        type="text"
        name="caseName"
        #caseName
        #fileId />
      <button (click)="getStorageSizeByCaseName(caseName.value)">Get Storage By Case Name</button>
      <button (click)="getHealthcareSizeByCaseName(caseName.value)">Get HealthCare Storage By Case Name</button>
    </div>
  </section>

  <!-- Users Section -->
  <section>
    <h2>Users</h2>
    <mat-radio-group
      [(ngModel)]="roleselected"
      (ngModelChange)="onRoleSelectedChange()">
      <mat-radio-button
        [value]="item.value"
        *ngFor="let item of roles"
        >{{ item.label }}</mat-radio-button
      >
    </mat-radio-group>
    <!-- mat-button -->
    <button
      mat-flat-button
      color="accent"
      (click)="listUsersByRole()">
      List User Roles by Role
    </button>

    // Show usersList inside a mat-table
    <mat-table [dataSource]="dataTableSource">
      <!-- Define the columns to be displayed -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
        <mat-cell *matCellDef="let user">{{ user.email }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let user">{{ user.name }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="recurlyaccountcode">
        <mat-header-cell *matHeaderCellDef>Recurly Account Code</mat-header-cell>
        <mat-cell *matCellDef="let user">{{ user.recurlyaccountcode }}</mat-cell>
      </ng-container>

      <!-- Define the header row -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <!-- Define the data rows -->
      <mat-row *matRowDef="let user; columns: displayedColumns"></mat-row>
    </mat-table>
  </section>
</div>
