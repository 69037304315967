// NOTE: Compare with PROD-download-disc branch.

import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { AuthService } from 'src/app/services/auth.service';
import { GapiOperationsService } from 'src/app/services/gapi-operations.service';
import { MatTableDataSource } from '@angular/material/table';

const dicomdirFileName = 'DICOMDIR';
@Component({
  selector: 'app-sysadmin',
  templateUrl: './sysadmin.component.html',
})
export class SysadminComponent implements OnInit {
  ownersList = [];
  roleselected: string;
  usersList: any[] = [];
  dataTableSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['email', 'name', 'recurlyaccountcode'];

  constructor(private gAPI_$: GapiOperationsService, private auth_$: AuthService) {}

  roles = [
    {
      value: UserRoles.admin,
      label: UserRoles.admin,
    },
    {
      value: UserRoles.associate,
      label: UserRoles.associate,
    },
    {
      value: UserRoles.consultant,
      label: UserRoles.consultant,
    },
    {
      value: UserRoles.client,
      label: UserRoles.client,
    },
    {
      value: UserRoles.owner,
      label: UserRoles.owner,
    },
    {
      value: UserRoles.superuser,
      label: UserRoles.superuser,
    },
  ];

  filterText = '';

  get filteredOwnersList() {
    return this.ownersList.filter(owner =>
      `${owner.name || owner.firstName + owner.lastName} `.toLowerCase().includes(this.filterText.toLowerCase()),
    );
  }

  onRoleSelectedChange() {
    this.roleselected = this.roleselected;
  }

  listUsersByRole() {
    if (!this.roleselected) {
      alert('Please select a role');
      return;
    }
    // List all users with the provided role.
    firebase
      .firestore()
      .collection('users')
      .where('role', '==', this.roleselected)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const { name, recurlyaccountcode, email } = doc.data();
          const user = { name, recurlyaccountcode, email };
          this.usersList.push(user);
        });
        this.dataTableSource = new MatTableDataSource(this.usersList);
        this.usersList = [];
      })
      .catch(err => console.error(err));
  }

  listAllFilesWithSharedUsers() {
    firebase
      .firestore()
      .collection('files')
      .where('sharedUsers', '>', [])
      .get()
      .then(querySnapshot => {
        let contador = 0;
        querySnapshot.forEach(doc => {
          const docid = doc.id;
          const sharedUsers = doc.data().sharedUsers;
          if (sharedUsers.length > 0) {
            // console.log('doc :', doc.data());
            const needAction = sharedUsers.some((su, index) => {
              if (su.userEmail > '' && su.userName > '') {
                return true;
              } else {
                return false;
              }
            });
            if (needAction) {
              contador++;
              const newSharedUsers = sharedUsers.map(item => ({
                email: item.userEmail.toLowerCase(),
                name: item.userName,
              }));
              firebase.firestore().collection('files').doc(docid).update({ sharedUsers: newSharedUsers });
              console.log('doc :', doc.data());
            }
          }
        });
        console.log('contador :', contador);
      });
  }

  deleteAllFilesForDelete() {
    firebase
      .firestore()
      .collection('files')
      .where('forDelete', '==', true)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.delete();
        });
      });
  }

  ngOnInit() {
    this.auth_$.preventRedirections = true;
  }

  getReportedSizeByOwnerId(id: string) {
    firebase
      .functions()
      .httpsCallable('admin-getReportedSizeByOwnerId')({ ownerId: id })
      .then(({ data }) => {
        console.log('Files total size (MB) :', data);
      });
  }

  handleListClioUsers() {
    console.log('===========================================================');
    firebase
      .firestore()
      .collection('users')
      .where('lpm', '==', 'clio')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // console.log(doc.data());
          const userData = doc.data();
          if (!userData.clioAccessToken) console.log('no Clio Access Token: ', userData.email);
          else if (JSON.parse(userData.clioAccessToken).error)
            console.log('errored Clio Access Token: ', userData.email);
        });
        console.log('===========================================================');
      });
  }

  getStorageSizeByOwnerId(id: string) {
    firebase
      .functions()
      .httpsCallable('admin-getStorageSizeByOwnerId')({ ownerId: id })
      .then(({ data }) => {
        console.log('Files total size (MB) :', data);
      });
  }

  deleteNotifications(notificationType, userRole) {
    firebase
      .firestore()
      .collection('notifications')
      .where('type', '==', notificationType)
      .where('userRole', '==', userRole)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.delete();
        });
      });
  }

  deleteNotificationsByFileId(event, fileId) {
    console.log('fileId :', fileId.value);
    event.preventDefault();
    firebase
      .firestore()
      .collection('notifications')
      .where('fileId', '==', fileId.value)
      .get()
      .then(querySnapshot => {
        console.log('querySnapshot size :', querySnapshot.size);
        querySnapshot.forEach(doc => {
          console.log('doc :', doc.data());
          doc.ref.delete();
        });
      })
      .catch(err => console.error(err));
  }

  cleanNotificationsIWithoutNodeId() {
    firebase
      .firestore()
      .collection('notifications')
      .where('noteId', '==', '')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log('doc :', doc.data());
          doc.ref.delete();
        });
      });
  }

  checkingData() {
    firebase
      .firestore()
      .collection('users')
      .where('email', '==', 'jandbee14+consultantorr@gmail.com')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log('doc :', doc.data());
        });
      });
  }

  async removeSharingOfUsersDeleted() {
    const listOfUsers = (await firebase.firestore().collection('users').get()).docs.map(doc => doc.data().email);

    firebase
      .firestore()
      .collection('files')
      .where('sharedUsers', '>', [])
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const sharedUsers = doc.data().sharedUsers;
          if (sharedUsers && sharedUsers.length > 0) {
            const newSharedUsers = sharedUsers.filter(item => listOfUsers.includes(item.email));
            if (newSharedUsers.length !== sharedUsers.length) {
              firebase.firestore().collection('files').doc(doc.id).update({ sharedUsers: newSharedUsers });
            }
          }
        });
      });
  }

  async clearDuplicatedFolders(foldername: string, caseName?: string) {
    this.auth_$.showLoader('Clearing duplicated folders...');
    const folders = await this.getFoldersData(foldername);
    console.log('folders: ', folders);
    folders.sort((a, b) => (a['belongsTo'] < b['belongsTo'] ? 1 : -1));

    const result = [];
    let lastFolderName = '';
    let index = 0;

    for (const folder of folders) {
      const belongsTo = folder['belongsTo'];
      const forDelete = folder['forDelete'];
      const folderId = folder['id'];

      if (belongsTo !== lastFolderName && forDelete === false) {
        lastFolderName = belongsTo;
        index++;
        result[index] = [];
        result[index]['name'] = lastFolderName;
      }

      if (Object.keys(folder).length > 0) result[index].push({ ...folder, empty: await this.isEmptyFolder(folderId) });
      else result[index].push({ ...folder, empty: true });

      // result[index].push({ ...folder, empty: await this.isEmptyFolder(folderId) });
      // console.log('sysAdmin:result :', result);
    }

    const duplicatedFolders = result.filter(item => item.length > 1);
    console.log('duplicated ' + foldername + ' folders :', duplicatedFolders);

    // for (const foldersGroup of duplicatedFolders) {
    //   const emptyCount = foldersGroup.filter(folder => folder.empty).length;
    //   const notEmptyCount = foldersGroup.filter(folder => !folder.empty).length;
    //   console.log(foldersGroup.name, { emptyCount: emptyCount, notEmptyCount: notEmptyCount });
    //   if (notEmptyCount > 1) {
    //     console.log('PROBLEMS :', foldersGroup.name);
    //     await this.relocateFiles(foldersGroup.filter(folder => !folder.empty));
    //   } else if (emptyCount > 1) {
    //     console.log('Empties :', foldersGroup.name);
    //   }

    //   if (notEmptyCount > 0 && emptyCount > 0) {
    //     this.deleteEmpties(foldersGroup.filter(folder => folder.empty));
    //   }

    //   if (emptyCount > 1 && notEmptyCount === 0) {
    //     await this.deleteAllButOneEmpty(foldersGroup.filter(folder => folder.empty));
    //   }
    // }
    this.auth_$.hideLoader();
  }
  async clearDuplicatedFoldersByCaseName(foldername: string, caseName: string) {
    console.log('clearDuplicatedFoldersByCaseName: ');
    this.auth_$.showLoader('Clearing duplicated folders...');
    const folders = await this.getFoldersDataByCaseName(foldername, caseName);
    console.log('folders: ', folders);
    folders.sort((a, b) => (a['belongsTo'] < b['belongsTo'] ? 1 : -1));

    const result = [];
    let lastFolderName = '';
    let index = 0;

    for (const folder of folders) {
      const belongsTo = folder['belongsTo'];
      const forDelete = folder['forDelete'];
      const folderId = folder['folderId'];
      const docId = folder['id'];

      if (belongsTo !== lastFolderName && forDelete === false) {
        lastFolderName = belongsTo;
        index++;
        result[index] = [];
        result[index]['name'] = lastFolderName;
      }

      if (Object.keys(folder).length > 0) {
        this.isEmptyFolder(folderId).then(res => {
          if (res) {
            firebase
              .firestore()
              .collection('files')
              .doc(docId)
              .delete()
              .then(() => {
                console.log('deleted:', docId);
              });
          }
        });
        // result[index].push({ ...folder, empty: await this.isEmptyFolder(folderId) });
        // result[index].push({ ...folder, empty: await this.isEmptyFolder(folderId) });
      } /* else {
        result[index].push({ ...folder, empty: true });
      } */

      // result[index].push({ ...folder, empty: await this.isEmptyFolder(folderId) });
      // console.log('sysAdmin:result :', result);
    }

    /*     const duplicatedFolders = result.filter(item => item.length > 1);
    console.log('duplicated ' + foldername + ' folders :', duplicatedFolders); */

    // for (const foldersGroup of duplicatedFolders) {
    //   const emptyCount = foldersGroup.filter(folder => folder.empty).length;
    //   const notEmptyCount = foldersGroup.filter(folder => !folder.empty).length;
    //   console.log(foldersGroup.name, { emptyCount: emptyCount, notEmptyCount: notEmptyCount });
    //   if (notEmptyCount > 1) {
    //     console.log('PROBLEMS :', foldersGroup.name);
    //     await this.relocateFiles(foldersGroup.filter(folder => !folder.empty));
    //   } else if (emptyCount > 1) {
    //     console.log('Empties :', foldersGroup.name);
    //   }

    //   if (notEmptyCount > 0 && emptyCount > 0) {
    //     this.deleteEmpties(foldersGroup.filter(folder => folder.empty));
    //   }

    //   if (emptyCount > 1 && notEmptyCount === 0) {
    //     await this.deleteAllButOneEmpty(foldersGroup.filter(folder => folder.empty));
    //   }
    // }
    this.auth_$.hideLoader();
  }

  async listOwners() {
    this.ownersList = await firebase
      .functions()
      .httpsCallable('admin-listOwners')()
      .then(({ data }) => {
        console.log('data :', data);
        return data;
      });
  }

  private async deleteEmpties(foldersToDelete: any[]) {
    console.log('foldersToDelete :', foldersToDelete);
    for (const folder of foldersToDelete) {
      await firebase.firestore().collection('files').doc(folder.id).update({ forDelete: true });
    }
  }

  private async deleteAllButOneEmpty(folders: any[]) {
    console.log('deleteAllButOneEmpty:', folders);
    const folderChoosen = folders[0];
    for (const folder of folders) {
      if (folder.id !== folderChoosen.id) {
        await firebase.firestore().collection('files').doc(folder.id).update({ forDelete: true });
      }
    }
  }

  private async relocateFiles(folders: any[]) {
    const choosenFolder = folders[0];
    for (const folder of folders) {
      if (folder.id !== choosenFolder.id) {
        const files = await firebase.firestore().collection('files').where('parentFolder', '==', folder.id).get();
        for (const file of files.docs) {
          console.log('file: ', file.data());
          await firebase.firestore().collection('files').doc(file.id).update({ parentFolder: choosenFolder.id });
        }
        await firebase.firestore().collection('files').doc(folder.id).update({ forDelete: true });
      }
    }
  }

  async removeSharingReferencesOfFilesDeletedInUsers() {
    const users = (await firebase.firestore().collection('users').get()).docs
      .map(doc => ({
        filesSharedWith: doc.data().filesSharedWith ? doc.data().filesSharedWith.map(j => j.fileId) : [],
        id: doc.id,
        email: doc.data().email,
      }))
      .filter(u => u.filesSharedWith && u.filesSharedWith.length > 0);

    console.log('users :', users);

    for (const user of users) {
      for (const fileId of user.filesSharedWith) {
        const fileDeleted = await firebase
          .firestore()
          .collection('files')
          .where('fileId', '==', fileId)
          .where('forDelete', '==', true)
          .get();
        if (fileDeleted.docs.length > 0) {
          const sharedUsers = fileDeleted.docs[0].data().sharedUsers;
          if (sharedUsers && sharedUsers.length > 0) {
            const newSharedUsers = sharedUsers.filter(f => f.email !== user.email);

            await firebase
              .firestore()
              .collection('files')
              .doc(fileDeleted.docs[0].id)
              .update({ sharedUsers: newSharedUsers })
              .then(() => {
                console.log('sharedUsers email: ', user.email);
                console.log('sharedUsers deleted: ', fileId);
              });

            const filesSharedWith = await firebase
              .firestore()
              .collection('users')
              .where('email', '==', user.email)
              .limit(1)
              .get();

            const newFilesSharedWith = filesSharedWith.docs[0]
              .data()
              ['filesSharedWith'].filter(f => f.fileId !== fileId);

            await firebase
              .firestore()
              .collection('users')
              .doc(filesSharedWith.docs[0].id)
              .update({ filesSharedWith: newFilesSharedWith })
              .then(() => {
                console.log('sharedUsers deleted: ', fileId);
                console.log('User affected', user.email);
              });
          }
        }
      }
    }
  }

  private async isEmptyFolder(folderId: string): Promise<boolean> {
    const files = await firebase.firestore().collection('files').where('parentFolder', '==', folderId).get();
    if (files.docs.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  async getFoldersData(foldername: string) {
    return (await firebase.firestore().collection('files').where('name', '==', foldername).get()).docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));
  }

  async getFoldersDataByCaseName(foldername: string, caseName: string) {
    return (
      await firebase
        .firestore()
        .collection('files')
        .where('name', '==', foldername)
        .where('belongsTo', '==', caseName)
        .where('type', '==', 'folder')
        .get()
    ).docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));
  }

  removeSharingReferencesOfFilesDeleted() {
    firebase
      .firestore()
      .collection('files')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.data().sharedUsers && doc.data().sharedUsers.length > 0 && doc.data().forDelete === true) {
            doc.ref.update({ sharedUsers: firebase.firestore.FieldValue.delete() }).then(() => {
              console.log('sharedUsers deleted: ', doc.data().fileId);
            });
          }
        });
      });
  }

  usersFilesSharingFixing() {
    const unexistentFiles = [];
    firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const filesSharedWith = doc.data().filesSharedWith;

          if (filesSharedWith && filesSharedWith.length > 0) {
            // const newFilesSharedWith = filesSharedWith.map(fsw => ({ ...fsw, email: fsw.email.toLowerCase() }));
            // firebase.firestore().collection('users').doc(doc.id).update({ filesSharedWith: newFilesSharedWith });
            filesSharedWith.forEach(fsw => {
              firebase
                .firestore()
                .collection('files')
                .where('fileId', '==', fsw.fileId)
                .limit(1)
                .get()
                .then(fileDoc => {
                  if (fileDoc.docs[0]) {
                    console.log('fileDoc[0] :', fileDoc.docs[0].data());
                    const sharedUsers = fileDoc.docs[0].data().sharedUsers;

                    if (!sharedUsers || sharedUsers.length === 0) {
                      firebase
                        .firestore()
                        .collection('files')
                        .doc(fileDoc.docs[0].id)
                        .update({ sharedUsers: [{ email: doc.data().email, name: doc.data().name }] })
                        .then(() => {
                          console.log('file updated: ', fsw.fileId);
                        });
                    }
                  } else {
                    console.log("file doesn't exist: ", fsw.fileId);
                    unexistentFiles.push(fsw.fileId);

                    const newFilesSharedWith = filesSharedWith.filter(fsw => fsw.fileId !== fsw.fileId);

                    firebase
                      .firestore()
                      .collection('users')
                      .doc(doc.id)
                      .update({ filesSharedWith: newFilesSharedWith })
                      .then(() => {
                        console.log('file removed from user: ' + doc.data()['uid'], fsw.fileId);
                      });
                  }
                });
            });
          }
        });
      });
  }

  showUserInfo(email: string) {
    firebase
      .firestore()
      .collection('users')
      .where('email', '==', email)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(
            'doc :',
            doc.data().filesSharedWith.filter(r => r.patientCaseName === 'FlatzvUnionMedical'),
          );
        });
      });
  }

  updateFilesSharedUsers() {
    firebase
      .firestore()
      .collection('files')
      .where('ftype', '==', 'File')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const sharedUsers = doc.data().sharedUsers;
          // console.log('sharedUsers :', sharedUsers);
          if (sharedUsers && sharedUsers.length > 0) {
            let newSharedUsers;
            if (sharedUsers[0] === true) {
              newSharedUsers = [];
            } else {
              newSharedUsers = sharedUsers.map(item => ({ email: item.email.toLowerCase(), name: item.name }));
            }
            // console.log('newSharedUsers :', newSharedUsers);
            firebase.firestore().collection('files').doc(doc.id).update({ sharedUsers: newSharedUsers });
          }
        });
      });
  }

  showSpecificFile(fileDesc: string) {
    firebase
      .firestore()
      .collection('files')
      .where('fileDesc', '==', fileDesc)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log('doc :', doc.data().sharedUsers);
          // if (doc.data().sharedUsers) {
          //   const newSharedUsers = doc.data().sharedUsers.map(su => ({ email: su.email.toLowerCase(), name: su.name }));
          //   firebase.firestore().collection('files').doc(doc.id).update({ sharedUsers: newSharedUsers });
          // }
        });
      });
  }

  allUsersEmailToLowerCase() {
    firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          firebase.firestore().collection('users').doc(doc.id).update({ email: doc.data().email.toLowerCase() });
        });
      });
  }

  userEmailsToLowerCase(): void {
    firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot =>
        querySnapshot.forEach(doc =>
          firebase.firestore().collection('users').doc(doc.id).update({
            email: doc.data().email.toLowerCase(),
          }),
        ),
      );
  }

  updateFolderColors() {
    firebase
      .firestore()
      .collection('files')
      .where('type', '==', 'folder')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const folderName = doc.data().name;
          console.log('folderName :', folderName);
          switch (folderName) {
            case 'Damages':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#ffc1c1' });
              break;
            case 'Discovery':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#ffda81' });
              break;
            case 'Liability':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#d1f3c3' });
              break;
            case 'Client Upload':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#ccff00' });
              break;
            case 'Other':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#e7e7e7' });
              break;
            default:
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#c5f0f6' });
              break;
          }
        });
      })
      .catch(err => console.error(err));
  }

  analyzeViewerURL(viewerurl: string): boolean {
    const i = viewerurl.indexOf('https://');
    const newstring = viewerurl.substring(i + 8);
    const stringSplitted = newstring.split('/');
    const part1 = stringSplitted[5];
    const part2 = stringSplitted[7];
    // console.log({ part1, part2 });
    return part1 === part2;
  }

  hasHTTPSprefix(viewerurl: string): boolean {
    return viewerurl.indexOf('https://') > -1;
  }

  listViewerURLs() {
    firebase
      .firestore()
      .collection('files')
      .where('fileName', '==', dicomdirFileName)
      .get()
      .then(querySnapshot => {
        let counter = 0;
        const data = [];
        querySnapshot.forEach(doc => {
          const viewerurl = doc.data().viewerurl;
          if (this.analyzeViewerURL(viewerurl)) {
            data.push({ data: doc.data(), id: doc.id });
          }
        });
        const belongsTo = data
          .map(item => item.data.belongsTo)
          .filter((item, index, array) => array.indexOf(item) === index);
        belongsTo.forEach(item => {
          this.gAPI_$.checkIfDataSetExists(item).then(res => {
            // console.log('Dataset exists?: ', res);
            if (!res) {
              counter++;
              console.log('Dataset does not exist, lets delete the file');
              console.log(`${data['id']}`);
              // firebase.firestore().collection('files').doc(data['id']).update({ forDelete: true });
            }
          });
        });
        console.log(`${counter} rows has been affected.`);
      })
      .catch(err => console.error(err));
  }

  seeAllUsersEmails(): void {
    firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(doc.data().email);
        });
      });
  }

  listSuperUsers() {
    firebase
      .firestore()
      .collection('users')
      .where('role', '==', 'Super User')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(doc.data().email);
        });
      });
  }

  cleanNotificationsOfDeletedFiles() {
    firebase
      .firestore()
      .collection('notifications')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          firebase
            .firestore()
            .collection('files')
            .where('fileId', '==', doc.data().fileId)
            .get()
            .then(querySnapshot => {
              if (querySnapshot.size === 0) {
                // console.log(' does not exist > doc :', doc.data());
                doc.ref.delete();
              } else if (querySnapshot.docs[0].data().forDelete === true) {
                // console.log(' is deleted > doc :', doc.data());
                doc.ref.delete();
              }
            });
        });
      });
  }

  getStorageSizeByCaseName(caseName: string) {
    console.log('caseName :', caseName);
    firebase
      .functions()
      .httpsCallable('admin-getStorageSizeByCaseName')({ caseName: caseName })
      .then(res => {
        console.log('res :', res);
      })
      .catch(err => console.error(err));
  }

  getHealthcareSizeByCaseName(caseName: string) {
    console.log('caseName :', caseName);
    firebase
      .functions()
      .httpsCallable('admin-getHealthcareSizeByCaseName')({ caseName: caseName })
      .then(res => {
        console.log('res :', res);
      })
      .catch(err => console.error(err));
  }
}
