import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-annually-monthly-switch',
  templateUrl: './annually-monthly-switch.component.html',
  styleUrls: ['./annually-monthly-switch.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnnuallyMonthlySwitchComponent {
  @Input() on: boolean;
  @Output() switchChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  onChange(event: MatSlideToggleChange) {
    if (event.checked === true) {
      const monthlyElement = this.el.nativeElement.querySelector('#monthly-toggle');
      const annualElement = this.el.nativeElement.querySelector('#annual-toggle');
      this.renderer.addClass(monthlyElement, 'active');
      this.renderer.removeClass(annualElement, 'active');
    } else {
      const monthlyElement = this.el.nativeElement.querySelector('#monthly-toggle');
      const annualElement = this.el.nativeElement.querySelector('#annual-toggle');
      this.renderer.addClass(annualElement, 'active');
      this.renderer.removeClass(monthlyElement, 'active');
    }
    this.switchChange.emit(event.checked);
  }
}
