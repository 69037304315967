<h2 class="mat-title">Filter by User</h2>
<ul>
  <li *ngFor="let user of usersData">
    <b>{{ user.name }}</b>
    <ul>
      <li *ngFor="let client of user.clients">
        <a
          style="cursor: pointer"
          (click)="getLogsByClientAndUser(client.client, user.uid)"
          >{{ client.clientname }}</a
        >
      </li>
    </ul>
  </li>
</ul>
