import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { AuthService } from 'src/app/services/auth.service';

import { FilesComparisonComponent } from '../components/ui/files-comparison/files-comparison.component';
import { SimpleListSelectionComponent } from '../components/ui/simple-list-selection/simple-list-selection.component';
import { SimpleMessageWindowComponent } from '../components/ui/simple-message-window/simple-message-window.component';
import { CustomActions } from '../dictionaries/CustomActions';
import { ClientProfileComponent } from './../components/clientprofile/clientprofile.component';
import { ClientMatterService } from './client-matter.service';
import { UIMessagingService } from './uimessaging.service';
import { UtilsService } from './utils.service';
import { WindowsManagerService } from './windows-manager.service';
import { DialogService } from '../dialog.service';

const dbPatients = firebase.firestore().collection('patients');
const dbFiles = firebase.firestore().collection('files');
const CLIO_FOLDER_NAME = 'Clio';

export interface DocumentToImport {
  id: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class MattersService {
  constructor(
    public dialog_$: DialogService,
    public dialog: MatDialog,
    private auth_$: AuthService,
    private ng: NgZone,
    private utils_$: UtilsService,
    private clientMatter_$: ClientMatterService,
    private router: Router,
    private uiMessaging_$: UIMessagingService,
    private windowsManager_$: WindowsManagerService,
  ) {}

  async checkClientMatterExistsFromClioMatterId(matterId: any) {
    const ownerid = this.auth_$.userData.getValue()['ownerID'] || this.auth_$.userData.getValue()['uid'];
    this.auth_$.showLoader('Checking if matter exists...', 'checking-matter-exists');
    const result = await dbPatients
      .where('clioDocId', '==', parseInt(matterId, 10))
      .where('owners', 'array-contains', ownerid)
      .limit(1)
      .get();
    this.auth_$.hideLoader();
    return result;
  }

  async getMatterInfoFromDocumentId(documentId: any) {
    this.auth_$.showLoader('Getting matter info from Clio...', 'getting-matter-info');
    const eu = this.auth_$.userData.getValue()['clioRegion'] === 'eu' ? 1 : 0;
    const result = await firebase.functions().httpsCallable('clio-getMatterInfoFromDocumentIdV2')({
      documentId,
      userdocid: this.auth_$.userData.getValue()['id'],
      eu,
    });
    this.auth_$.hideLoader();
    return result;
  }

  async getMatterIdFromFolderId(folderId: any) {
    this.auth_$.showLoader('Getting matter info from Clio...', 'getting-matter-info');
    const result = await firebase.functions().httpsCallable('clio-getMatterInfoFromFolderId')({
      folderId,
      userdocid: this.auth_$.userData.getValue()['id'],
    });
    this.auth_$.hideLoader();
    return result;
  }

  async getClioDocumentInfo(documentId: any) {
    this.auth_$.showLoader('Getting document info from Clio...', 'getting-document-info');
    const result = await firebase.functions().httpsCallable('clio-getClioDocumentInfo')({
      documentId,
      userdocid: this.auth_$.userData.getValue()['id'],
    });
    this.auth_$.hideLoader();
    return result;
  }

  async importClioMatter(matterId, user_id, options) {
    this.importMatterFromClio(matterId, user_id, options);
  }

  async getDocumentsListFromClioMatterId(matterId) {
    this.auth_$.showLoader('Getting documents list from Clio...', 'getting-documents-list');
    const eu = this.auth_$.userData.getValue()['clioRegion'] === 'eu' ? 1 : 0;
    const result = await firebase.functions().httpsCallable('clio-getDocumentsFromMatterIdV2')({
      matterId,
      userdocid: this.auth_$.userData.getValue()['id'],
      eu,
    });
    this.auth_$.hideLoader();
    return result;
  }

  async getDocumentsListFromCaseName(caseName: any, parentFolder) {
    this.auth_$.showLoader('Getting documents list from Clio...', 'getting-documents-list');
    const result = (
      await dbFiles
        .where('belongsTo', '==', caseName)
        .where('origin', '==', 'clio')
        .where('parentFolder', '==', parentFolder)
        .get()
    ).docs.map(doc => doc.data());
    this.auth_$.hideLoader();
    return result;
  }

  async getClioFolderIdFromCaseName(caseName) {
    this.auth_$.showLoader('Getting Clio folder id...', 'getting-clio-folder-id');
    const folderId = (
      await dbFiles
        .where('belongsTo', '==', caseName)
        .where('type', '==', 'folder')
        .where('name', '==', CLIO_FOLDER_NAME)
        .limit(1)
        .get()
    ).docs[0]?.data().folderId;
    this.auth_$.hideLoader();
    return folderId;
  }

  openFilesComparisonModal(sourceDocuments, localDocuments, matterId, options) {
    const { matterData } = options;
    this.ng.run(() =>
      this.dialog
        .open(FilesComparisonComponent, {
          minWidth: '900px',
          data: { sourceDocuments, localDocuments, matterData, options },
        })
        .afterClosed()
        .subscribe(async result => this.handleComparisonModalResult(result, matterId, options)),
    );
    return this.dialog;
  }

  async handleComparisonModalResult(result, matterId, options) {
    if (result) {
      await this.importDocumentsFromClio(matterId, options.caseName, {
        option: 'selected',
        destiny: options.clioFolderId,
        selectedDocuments: result,
      });
    } else {
      console.log('No documents were selected');
      this.uiMessaging_$.toastMessage(
        'No documents were selected. Please select the documents you want to import.',
        'warning',
      );
    }

    this.openExistingMatter(options.matterData, this.auth_$.userData.getValue()['id'], {
      ...options,
      targetFolder: CLIO_FOLDER_NAME,
      comparison: false,
    });
  }

  async openClioClientMatter(matterId, user_id, options?: any) {
    console.log('openClioClientMatter options :', options);
    if (options.target === CustomActions.openClioDocument) {
      options.clioDocumentId = options.clioObjectId;
    }

    const targetFolder = CLIO_FOLDER_NAME;
    const foundClientMatter = await this.searchClioMatter(matterId);
    let params;
    let ClientMatterData;

    if (foundClientMatter.length > 0) {
      ClientMatterData = foundClientMatter[0].data();
      let { section, clioDocumentId, target, clioObjectId } = options || {};

      if (!section && options && options.target) {
        section = options.target;
      } else if (target === CustomActions.openClioDocument && clioDocumentId) {
        params = { clioDocumentId, targetFolder };
      } else if (section) {
        params = { section, targetFolder };
      } else if (target && target === CustomActions.openClioDocument && clioObjectId) {
        params = { clioDocumentId: clioObjectId, targetFolder };
      } else {
        params = { targetFolder };
      }
    } else {
      ClientMatterData = await this.importMatterOnly(matterId, user_id);
      params = null;
    }

    this.openExistingMatter(ClientMatterData, user_id, params);
  }

  getClioMatterIdFromDocumentId(documentId) {
    return firebase.functions().httpsCallable('clio-getMatterInfoByClioDocumentId')({
      documentId,
      userdocid: this.auth_$.userData.getValue()['id'],
    });
  }

  openExistingMatter(clientMatterData: any, user_id: any, options?: any) {
    const { target, section, clioDocumentId, targetFolder } = options || {};
    if (!this.windowsManager_$.clientProfileOpened) {
      this.ng.run(() => {
        const dialog = this.dialog_$.open(ClientProfileComponent, {
          data: {
            patient: clientMatterData,
            user_id: user_id,
            section: target || section || null,
            clioDocumentId: clioDocumentId || null,
            clioMatterId: clientMatterData.clioDocId,
            targetFolder: targetFolder || null,
          },
          width: '100vw',
          id: 'client-profile',
        });
        dialog.afterClosed().subscribe(() => {
          this.redirectToHome();
          if (this.auth_$.isClioRegistered()) {
            this.auth_$.drawer.open();
          }
        });
        dialog.afterOpened().subscribe(() => {
          this.windowsManager_$.clientProfileOpened = true;
        });
      });
    }
  }

  private redirectToHome() {
    this.router.navigate(['/']);
  }

  async importMatterFromClio(matterId: any, user_id: any, options?: any) {
    let message = `The matter you are trying to open doesn't exist on NuagedX yet. Do you want to import it now?`;
    let modalOptions = [
      { label: 'Select what documents to import', value: 'selected', selected: true },
      { label: 'Import all documents', value: 'all', selected: false },
      { label: 'Do not import any document.', value: 'none', selected: false },
    ];

    if (options.target) {
      switch (options.target) {
        case CustomActions.openClioDocument:
          message =
            `The document "${options.documentMatterInfo.filename}" you are trying to open belongs to a matter ` +
            `that doesn't exist on NuagedX yet. Do you want to import it now?`;
          modalOptions = [
            { label: `Import just "${options.documentMatterInfo.filename}"`, value: 'document', selected: true },
            { label: 'Select what documents to import', value: 'selected', selected: false },
            { label: 'Import all documents', value: 'all', selected: false },
            { label: 'Do not import any document.', value: 'none', selected: false },
          ];
          break;
        case CustomActions.openClioMatter:
          message = `The matter you are trying to open doesn't exist on NuagedX yet. Do you want to import it now?`;
          break;
        case CustomActions.uploadDicomDisk:
          message =
            `You are trying to upload a DICOM disk inside a matter that doesn't exist on NuagedX yet. ` +
            `Do you want to import it now?`;
          break;
        case CustomActions.viewMedicalImages:
          message =
            `You are trying to view Medical Images inside a matter that doesn't exist on NuagedX yet. ` +
            `Do you want to import it now?`;
          break;
        default:
          message = `The matter you are trying to open doesn't exist on NuagedX yet. Do you want to import it now?`;
          break;
      }
    }

    this.ng.run(() => {
      this.dialog
        .open(SimpleMessageWindowComponent, {
          data: {
            title: 'Import Matter from Clio',
            message: message,
            buttons: [
              { text: 'Yes', value: true },
              { text: 'No', value: false },
            ],
            options: modalOptions,
            complexAnswer: true,
          },
          width: '500px',
          closeOnNavigation: false,
          disableClose: true,
        })
        .afterClosed()
        .subscribe(async ({ answer, importOption }) => {
          if (answer) this.handleAnswer(importOption, options, matterId, user_id);
          else {
            this.uiMessaging_$.toastMessage('The import process has been cancelled.', 'INFORMATION');
            this.redirectToHome();
          }
        });
    });
  }

  async importMatterOnly(matterId, user_id) {
    this.auth_$.showLoader('Importing matter from Clio...', 'importing-matter');
    const clioMatterInfo = await this.getClioMatterInfo(matterId);
    console.log('clioMatterInfo :', clioMatterInfo);
    let data;

    if (clioMatterInfo) data = clioMatterInfo['data']['data'];
    else {
      this.auth_$.hideLoader();
      return;
    }
    const cleanMattterDisplayNumber = this.utils_$.cleanString(data.display_number);

    // Create the client/matter in NuagedX.
    const newMatterData = await this.createClioMatter(data, cleanMattterDisplayNumber);

    // Create default folders.
    await this.clientMatter_$.createDefaultFolders(cleanMattterDisplayNumber);

    // Create Clio folder.
    const clioFolderId = await this.clientMatter_$.createCustomFolder(cleanMattterDisplayNumber, CLIO_FOLDER_NAME);

    this.auth_$.hideLoader();
    return { ...newMatterData, clioFolderId };
  }

  async createClioMatter(data, cleanMattterDisplayNumber) {
    this.auth_$.showLoader('Creating the Client/Matter in NuagedX...', 'creating-client-matter');
    const { client, id, number, description } = data;
    const { name, type, initials, first_name, last_name } = client;
    const newClientMatter = await this.clientMatter_$.createClientMatter({
      localData: {},
      matterData: { client, id, number, display_number: cleanMattterDisplayNumber, description },
      clientData: { name, type, initials, first_name, last_name },
    });
    this.auth_$.hideLoader();
    return { ...newClientMatter, clioData: data };
  }

  async handleAnswer(importOption, options, matterId, user_id) {
    this.auth_$.showLoader('Getting Matter info from Clio...', 'getting-matter-info');
    const clioMatterInfo = await this.getClioMatterInfo(matterId);
    this.auth_$.hideLoader();
    let data;

    if (clioMatterInfo) data = clioMatterInfo['data']['data'];

    const { client, id, number, display_number, description } = data;
    const { name, type, initials, first_name, last_name } = client;

    this.auth_$.showLoader('Creating the Client/Matter in NuagedX...', 'creating-client-matter');
    const cleanMattterDisplayNumber = this.utils_$.cleanString(display_number);

    // Create the client/matter in NuagedX.
    await this.clientMatter_$.createClientMatter({
      localData: {},
      matterData: { client, id, number, display_number: cleanMattterDisplayNumber, description },
      clientData: { name, type, initials, first_name, last_name },
    });

    this.auth_$.hideLoader();

    this.auth_$.showLoader('Creating your default folders...', 'creating-default-folders');

    // Create default folders.
    await this.clientMatter_$.createDefaultFolders(cleanMattterDisplayNumber);
    this.auth_$.hideLoader();

    let clioFolderId;

    if (importOption && importOption !== 'none') {
      this.auth_$.showLoader('Creating your Clio folder...', 'creating-clio-folder');
      clioFolderId = await this.clientMatter_$.createCustomFolder(cleanMattterDisplayNumber, CLIO_FOLDER_NAME);
      this.auth_$.hideLoader();
    }

    this.handleImportOption(importOption, matterId, cleanMattterDisplayNumber, clioFolderId, user_id, options);
  }

  async handleAllDocuments(importedDocuments, matterId, cleanMattterDisplayNumber, clioFolderId, options?) {
    this.auth_$.showLoader('Importing all documents from Clio...', 'importing-all-documents');
    importedDocuments = await this.importDocumentsFromClio(matterId, cleanMattterDisplayNumber, {
      option: 'all',
      destiny: clioFolderId,
    });
    console.log('importedDocuments :', importedDocuments);
    if (importedDocuments && importedDocuments.data.length > 0) {
      this.uiMessaging_$.toastMessage('The matter and its documents were imported successfully.', 'INFORMATION');
    }
    this.auth_$.showLoader('Opening the matter...', 'opening-matter');
    this.openClioClientMatter(matterId, this.auth_$.userData.getValue()['uid'], options).then(() =>
      this.auth_$.hideLoader(),
    );
  }

  async handleSelected(matterId, cleanMattterDisplayNumber, clioFolderId, user_id, importedDocuments, options?) {
    this.auth_$.showLoader('Getting your selected documents from Clio...', 'getting-selected-documents');
    const clioDocuments = await this.getClioDocumentsList(matterId);
    this.auth_$.hideLoader();
    if (!clioDocuments) {
      this.dialog
        .open(SimpleMessageWindowComponent, {
          data: {
            title: 'No documents found',
            message: 'No documents found in Clio for this matter. Do you want to import the matter without documents?',
            buttons: [
              { text: 'Yes', value: true },
              { text: 'No', value: false },
            ],
          },
          disableClose: true,
        })
        .afterClosed()
        .toPromise()
        .then(answer => {
          if (!answer) {
            this.redirectToHome();
          } else {
            this.auth_$.showLoader('Importing matter from Clio...', 'importing-matter');
            this.importMatterFromClio(matterId, user_id).then(() => {
              this.auth_$.hideLoader();
            });
          }
        });
      return;
    }

    await this.dialog
      .open(SimpleListSelectionComponent, {
        data: {
          title: 'Select the documents to import',
          items: clioDocuments['data'],
          buttons: [
            { text: 'Continue', value: true },
            { text: 'Cancel', value: false },
          ],
        },
      })
      .afterClosed()
      .toPromise()
      .then(async ({ data }) => {
        if (data.length > 0) {
          this.auth_$.showLoader('Importing selected documents from Clio...', 'importing-selected-documents');
          importedDocuments = await this.importDocumentsFromClio(matterId, cleanMattterDisplayNumber, {
            option: 'selected',
            selectedDocuments: data,
            destiny: clioFolderId,
          });
          this.auth_$.hideLoader();

          if (importedDocuments) {
            if (importedDocuments.data.length > 0) {
              this.uiMessaging_$.toastMessage(
                'The matter and its documents were imported successfully.',
                'INFORMATION',
              );
              this.auth_$.showLoader('Opening the matter...');
              this.openClioClientMatter(matterId, this.auth_$.userData.getValue()['uid'], options).then(() =>
                this.auth_$.hideLoader(),
              );
            }
          } else {
            this.uiMessaging_$.toastMessage("The matter wan't imported successfully.", 'INFO');
          }
        } else {
          console.log('No data');
        }
      });
  }

  async handleImportOption(importOption, matterId, cleanMattterDisplayNumber, clioFolderId, user_id, options) {
    let importedDocuments;
    switch (importOption) {
      case 'all':
        this.handleAllDocuments(importedDocuments, matterId, cleanMattterDisplayNumber, clioFolderId, options);
        break;
      case 'selected':
        this.handleSelected(matterId, cleanMattterDisplayNumber, clioFolderId, user_id, importedDocuments, options);
        break;
      case 'none':
        this.handleNone(matterId, user_id, options);
        break;
      case 'document':
        this.handleDocument(matterId, cleanMattterDisplayNumber, clioFolderId, user_id, importedDocuments, options);
        break;
      default:
        break;
    }
  }

  async handleDocument(matterId, cleanMattterDisplayNumber, clioFolderId, user_id, importedDocuments, options) {
    const data = [{ id: options.documentMatterInfo.id, name: options.documentMatterInfo.filename }];
    this.auth_$.showLoader('Importing selected documents from Clio...', 'importing-selected-documents');
    importedDocuments = await this.importDocumentsFromClio(matterId, cleanMattterDisplayNumber, {
      option: 'selected',
      selectedDocuments: data,
      destiny: clioFolderId,
    });
    this.auth_$.hideLoader();

    if (importedDocuments) {
      if (importedDocuments.data.length > 0) {
        this.uiMessaging_$.toastMessage('The matter and its documents were imported successfully.', 'INFO');
        this.auth_$.showLoader('Opening the matter...', 'opening-matter');
        this.openClioClientMatter(matterId, this.auth_$.userData.getValue()['uid'], options).then(() =>
          this.auth_$.hideLoader(),
        );
      }
    } else {
      this.uiMessaging_$.toastMessage("The matter wan't imported successfully.", 'INFO');
    }
  }

  handleNone(matterId, user_id, options?) {
    this.uiMessaging_$.toastMessage('The matter was imported successfully.', 'Close');

    this.dialog
      .open(SimpleMessageWindowComponent, {
        data: {
          title: 'Import Matter from Clio',
          message: 'The matter has been imported successfully.',
          buttons: [
            {
              text: 'View your imported client/matter',
              value: true,
            },
          ],
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.auth_$.showLoader('Opening the matter...', 'opening-matter');
          this.openExistingMatter(matterId, user_id);
          this.auth_$.hideLoader();
        } else {
          this.dialog.closeAll();
        }
      });
  }

  async getClioDocumentsList(matterId) {
    const documents = (await this.getDocumentsByMatterId(matterId))['data'];
    if (!documents || documents['code'] !== 200) {
      console.error('error :', documents);
      return false;
    }
    return documents['data'];
  }

  getDocumentsByMatterId(matterId) {
    const eu = this.auth_$.userData.getValue()['clioRegion'] === 'eu' ? 1 : 0;
    return firebase.functions().httpsCallable('clio-getDocumentsFromMatterIdV3')({
      matterId: matterId,
      userdocid: this.auth_$.userData.getValue()['id'],
      eu,
    });
  }

  async runImportDocumentsFromClio(documentsToImport, matterId, destiny?): Promise<any> {
    const eu = this.auth_$.userData.getValue()['clioRegion'] === 'eu' ? 1 : 0;
    const importResult: { data: any } = await firebase.functions().httpsCallable('clio-importDocumentsV3')({
      documents: documentsToImport,
      matter: matterId,
      userdocid: this.auth_$.userData.getValue()['id'],
      destiny: destiny,
      eu,
    });

    if (importResult.data === false) {
      this.uiMessaging_$.toastMessage('The import process has been cancelled.', 'INFORMATION');
      this.redirectToHome();
    } else {
      return importResult.data;
    }
  }

  async getDocumentsToImport(matterId): Promise<DocumentToImport[]> {
    return (
      await firebase.functions().httpsCallable('clio-getDocumentsToImport')({
        matterId: matterId,
        userdocid: this.auth_$.userData.getValue()['id'],
      })
    ).data;
  }

  async importDocumentsFromClio(
    matterId: any,
    newClientMattter: string,
    importOptions?: { option: string; destiny: string; selectedDocuments?: any[] },
  ): Promise<any> {
    const { option, selectedDocuments } = importOptions;
    let documentsToImport: DocumentToImport[];

    switch (option) {
      case 'all':
        documentsToImport = await this.getDocumentsToImport(matterId);
        break;
      case 'selected':
        documentsToImport = selectedDocuments;
        break;
      default:
        break;
    }

    if (!documentsToImport || documentsToImport.length === 0) {
      this.uiMessaging_$.toastMessage('No documents to import', 'INFORMATION');
      return false;
    }

    return this.runImportDocumentsFromClio(documentsToImport, newClientMattter, importOptions.destiny);
  }

  getRandomString(length) {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  async searchClioMatter(clioDocId) {
    this.auth_$.showLoader('Searching for this matter in NuagedX...', 'searching-matter');
    const answer = (await dbPatients.where('clioDocId', '==', parseInt(clioDocId)).limit(1).get()).docs;
    this.auth_$.hideLoader();
    return answer;
  }

  async getClioMatterInfo(matterId) {
    this.auth_$.showLoader('Getting matter info from Clio...', 'getting-matter-info');
    const userdocid = this.auth_$.userData.getValue()['id'];
    const fields =
      'client{id,name,first_name,last_name,type,title,primary_email_address,primary_phone_number,initials,middle_name,clio_connect_email},id,number,display_number,description';
    const eu = this.auth_$.userData.getValue()['clioRegion'] === 'eu' ? 1 : 0;
    const response = await firebase
      .functions()
      .httpsCallable('clio-getMatterInfoV2')({ matterId, userdocid, fields, eu })
      .catch(err => {
        console.log(err);
        this.uiMessaging_$.toastMessage('Error getting matter info', 'CLOSE');
        return false;
      });

    if (!response) {
      this.uiMessaging_$.toastMessage('Error getting matter info from Clio', 'ERROR');
      return false;
    }
    this.auth_$.hideLoader();
    return response;
  }
}
