import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { LoaderComponent } from 'src/app/loader/loader.component';
import { environment } from 'src/environments/environment';

import { AuthService } from '../../../services/auth.service';
import { FirebaseUtilitiesService } from '../../../services/firebase-utilities.service';

@Component({
  selector: 'app-practicepantherlistmatters',
  templateUrl: './PracticePantherListMatters.component.html',
  styleUrls: ['./PracticePantherListMatters.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PracticePantherListMattersComponent implements OnInit {
  documents: any;
  caseName: any;
  selectedMatterDescription: string;
  userdocid: string;
  selectedMatter: { name: any; description: any; client: any; };
  matterSelected = false;
  loader: any;
  matters: any[] = [];
  p_length = 100;
  p_pageSize = 10;
  p_pageSizeOptions = [5, 10, 25];
  mattersPaged: any[];
  pageEvent: PageEvent;
  p_pageIndex: any;

  constructor (
    private firebase_utilities_$: FirebaseUtilitiesService,
    private auth_$: AuthService,
    private http: HttpClient,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialogRef<PracticePantherListMattersComponent>) { }

  async ngOnInit() {
    this.caseName = this.data.currentCase;
    this.userdocid = await this.firebase_utilities_$.getUserDocId(this.auth_$.uid);
    for (let index = 0; index < this.data.matters.length; index++) {
      this.matters.push(this.data.matters[index]);
    }
    this.p_length = this.matters.length;
    this.mattersPaged = this.matters.slice(0, this.p_pageSize);
    console.log('this.mattersPaged :', this.mattersPaged);
    for (let index = 0; index < this.mattersPaged.length; index++) {
      const matter = this.mattersPaged[index];
      this.getMatterClient(matter.id)
        .then(clientName => matter.client = clientName)
        .catch(err => console.error(err));
    }
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.p_pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  async getPracticePantherDocuments(matterId: string, matterName, matterAccountRefDisplayName, matterAccountRefID) {
    this.documents = false;
    this.matterSelected = true;
    this.selectedMatter = { name: matterName, description: matterAccountRefDisplayName, client: matterAccountRefID };
    this.selectedMatterDescription = `${matterName}(${matterAccountRefDisplayName})`;
    const url = environment.constants.cloudfunctionsURL + 'practicepanther-getDocuments';
    const userdocid = this.userdocid;
    this.http.post(url, { userdocid, matterId }).toPromise().then(res => {
      this.showDocumentsList(res['data']);
    });
  }

  async getMatterClient(matter_id) {
    const url = environment.constants.cloudfunctionsURL + 'practicepanther-getClient';
    const userdocid = this.userdocid;
    const res = await this.http.post(url, { userdocid, matter_id }).toPromise();
    return res['data'].name;
  }

  showDocumentsList(data) {
    console.log('Documents', data);
    this.documents = data;
  }

  getPracticePantherFolderId() {
    return this.firebase_utilities_$.getFolder('practicepanther', this.caseName);
  }

  handlePageEvent(event) {
    this.p_length = event.length;
    this.p_pageSize = event.pageSize;
    this.p_pageIndex = event.pageIndex;
    const sliceInit = this.p_pageIndex * this.p_pageSize;
    this.mattersPaged = this.matters.slice(sliceInit, sliceInit + this.p_pageSize);
    for (let index = 0; index < this.mattersPaged.length; index++) {
      const matter = this.mattersPaged[index];
      this.getMatterClient(matter.id)
        .then(clientName => matter.client = clientName)
        .catch(err => console.error(err));
    }
  }

  hideLoader() {
    this.loader.close();
  }

  showLoader() {
    this.loader = this._dialog.open(LoaderComponent, { height: 'auto', width: 'auto', });
  }

  async handleImportDocuments(event) {
    this.showLoader();
    const patientCaseName = this.caseName;
    const userdocid = await this.firebase_utilities_$.getUserDocId(this.auth_$.uid);
    const parentFolderId = await this.getPracticePantherFolderId();
    const date = new Date();
    const filesToDownload = [];

    event.forEach(item => {
      const filename = item._element.nativeElement['data-filename'];
      const updated_at = item._element.nativeElement['data-updated_at'];
      const created_at = item._element.nativeElement['data-created_at'];
      const uploadedDate = new Date().toString();
      const docId = item._element.nativeElement['data-id'];
      const fileId = `${new Date().getTime().toString()}_${this.firebase_utilities_$.getRandomString(6)}_${this.caseName}`;
      const month = date.getMonth();
      const _date = date.getDate();
      const fullyear = date.getFullYear();
      const fileDate = `${((month > 8) ? (month + 1) : ((month + 1)))}/${((_date > 9) ? _date : (_date))}/${fullyear}`;
      const userId = this.auth_$.uid;
      const fileDesc = this.selectedMatterDescription;

      filesToDownload.push({ filename, docId, fileId, fileDate, userId, fileDesc, parentFolderId, updated_at, uploadedDate });
    });

    const postBody = { userdocid, filesToDownload, patientCaseName };
    const url = environment.constants.cloudfunctionsURL + 'practicepanther-downloadDocuments';
    const handleResponse = (res) => {
      const { message } = res; if (message === 'OK') {
        this.dialog.close('IMPORTED');
      }
    };
    const observer = {
      next: res => {
        this.hideLoader();
        handleResponse(res);
      }, error: err => console.error(err), completed: console.log('Observer Completed')
    };
    this.http.post(url, postBody).subscribe(observer);
  }

}
