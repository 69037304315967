import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-default-calendar-set-up',
  template: `<mat-dialog-actions align="end">
      <button
        mat-flat-button
        (click)="handleCloseWindow()"
        color="accent">
        X
      </button>
    </mat-dialog-actions>
    <h3
      [ngClass]="'mat-headline'"
      style="display: block; margin-bottom: 0">
      Default Calendar App
    </h3>
    <p>Please choose your favorite app</p>
    <div fxLayout="row">
      <div
        fxLayout="column"
        fxLayoutAlign="space-around center"
        fxflexfill=""
        fxlayoutgap="20px grid"
        [ngStyle]="{ width: '100%' }">
        <a
          *ngFor="let calendar of calendars"
          (click)="chooseCalendar($event, calendar.id)"
          fxFlex="60"
          href="#">
          <img
            src="{{ calendar.image }}"
            alt="{{ calendar.id }}" />
        </a>
      </div>
    </div>
    <br /> `,
})
export class DefaultCalendarSetUpComponent {
  calendars = [
    { id: 'Google', image: 'assets/jpg/google-calendar-logo.jpg' },
    { id: 'Office365', image: 'assets/jpg/office365-calendar-logo.jpg' },
    { id: 'Outlook', image: 'assets/jpg/outlook-calendar-logo.jpg' },
    { id: 'Clio', image: 'assets/jpg/clio-calendar-logo.jpg' },
  ];

  constructor(
    private dialogRef: MatDialogRef<DefaultCalendarSetUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  chooseCalendar(ev, str: string) {
    ev.preventDefault();
    this.dialogRef.close(str);
  }

  handleCloseWindow() {
    this.dialogRef.close(5);
  }
}
