<app-window-title
  class="subtitled"
  [title]="pricing.title"
  [closable]="false"></app-window-title>
<span
  class="subtitle"
  [innerHTML]="pricing.subtitle"></span>

<div id="lastContainer">
  <div>
    <div>
      <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
        <div
          class="table-1 fusion-no-small-visibility fusion-no-medium-visibility pricing-table"
          id="pricing-table">
          <table
            class="pricing-table"
            width="100%">
            <thead>
              <tr>
                <th>The ultimate in file sharing for consultations and collaborations</th>
                <!-- th ngFor iterating plans -->
                <ng-container *ngFor="let plan of _plans">
                  <th [ngClass]="{ 'highlighted-plan': plan.id == lpmpPlanCode }">
                    <app-choose-plan-button
                      (selectPlan)="choosePlan($event)"
                      [planLabel]="plan.content.title"
                      [plan]="plan.id"></app-choose-plan-button>
                    <button
                      *ngIf="plan.annual && plan.annual !== ''"
                      mat-button
                      (click)="choosePlanV2(plan.annual)">
                      Choose annual plan
                    </button>
                  </th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <!-- PRICES -->
              <tr>
                <td align="center">&nbsp;</td>

                <!-- td ngFor iterating prices -->
                <ng-container *ngFor="let price of prices">
                  <td
                    align="center"
                    [ngClass]="{ 'highlighted-plan': price.id == lpmpPlanCode }">
                    <h2
                      data-fontsize="33"
                      data-lineheight="49.5px"
                      class="fusion-responsive-typography-calculated price">
                      <strong>${{ price.number }}<sup>/mo</sup></strong>
                    </h2>
                  </td>
                </ng-container>
              </tr>
              <!-- FEATURES -->

              <!-- ngFot tr iterating features -->
              <tr *ngFor="let feature of _features">
                <td>{{ feature.text }}</td>
                <!-- td ngFor iterating plans -->
                <ng-container *ngFor="let plan of feature.plans">
                  <td
                    align="center"
                    [ngClass]="{ 'highlighted-plan': plan.id == lpmpPlanCode }">
                    <app-check-image
                      [value]="plan.value"
                      *ngIf="plan.value !== 0; else elseBlock"
                      [check]="feature[plan.id]"></app-check-image>
                    <ng-template #elseBlock> &nbsp; </ng-template>
                  </td>
                </ng-container>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <!-- td ngFor iterating plans -->
                <ng-container *ngFor="let plan of _plans">
                  <td [ngClass]="{ 'highlighted-plan': plan.id == lpmpPlanCode }">
                    <app-choose-plan-button
                      (selectPlan)="choosePlan($event)"
                      [planLabel]="plan.content.title"
                      [plan]="plan.id"></app-choose-plan-button>
                    <button
                      *ngIf="plan.annual && plan.annual !== ''"
                      mat-button
                      (click)="choosePlanV2(plan.annual)">
                      Choose annual plan
                    </button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
