<app-window-title
  title="Edit Client"
  [closable]="true"></app-window-title>
<form
  fxLayout="column"
  [formGroup]="clientForm"
  (ngSubmit)="onSubmit()">
  <input
    type="hidden"
    name="caseName"
    formControlName="caseName" />
  <mat-form-field appearance="fill">
    <mat-label>First name:</mat-label>
    <mat-input-container floatPlaceholder="auto">
      <input
        matInput
        formControlName="firstName"
        type="text"
        required
        placeholder="" />
    </mat-input-container>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Last name:</mat-label>
    <mat-input-container floatPlaceholder="auto">
      <input
        matInput
        formControlName="lastName"
        type="text"
        required
        placeholder="" />
    </mat-input-container>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Date of birth:</mat-label>
    <input
      required
      matInput
      [matDatepicker]="picker"
      formControlName="dateOfBirth" />
    <mat-datepicker-toggle
      matSuffix
      [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <section>
    <button
      mat-flat-button
      color="warn"
      [disabled]="!clientForm.valid"
      type="submit">
      SAVE CHANGES
    </button>
  </section>
</form>
