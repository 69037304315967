<div
  *ngFor="let notification of notifications"
  fxLayoutGap="15px grid"
  fxLayoutAlign="space-between center"
  class="inline-notification">
  <p [innerHTML]="notification.message"></p>
  <div
    *ngIf="notification.options"
    fxLayoutGap="15px">
    <button
      mat-flat-button
      color="red"
      *ngFor="let option of notification.options"
      (click)="runClick(option.action, notification.id)">
      {{ option.text }}
    </button>
  </div>
</div>
