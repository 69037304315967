<div *ngIf="selectedMatter">
  <b class="mat-h3" style="color: red; font-weight: bold">{{
    selectedMatter.name
  }}</b>
  <span class="mat-body"> - Client: {{ selectedMatter.client }}</span>
</div>

<div *ngIf="!documentsList" fxLayout="row">
  <mat-spinner
    style="margin-right: 10px"
    diameter="20"
    color="warn"
    strokeWidth="5"
    mode="indeterminate"
  ></mat-spinner
  >&nbsp; We are loading the documents
</div>
<div *ngIf="documentsList && documentsList.length">
  <p>Please <b>select the Practice Panther Documents you want to import</b></p>
  <mat-selection-list
    #matSelectionList
    (change)="handleChange(this)"
    *ngIf="documentsList"
    class="documents-list"
  >
    <mat-list-option
      [data-id]="item.id"
      [data-filename]="item.filename"
      [data-updated_at]="item.updated_at"
      [data-created_at]="item.created_at"
      [data-matter]="item.matter"
      *ngFor="let item of documentsList"
    >
      {{ item.filename }}
    </mat-list-option>
  </mat-selection-list>
  <p>
    <button
      [disabled]="!importButtonsEnabled"
      mat-flat-button
      color="warn"
      (click)="importSelectedDocuments()"
    >
      IMPORT SELECTED</button
    >&nbsp;
    <button
      [disabled]="!importButtonsEnabled"
      mat-flat-button
      color="warn"
      (click)="importAllDocuments()"
    >
      IMPORT ALL
    </button>
  </p>
</div>

<p *ngIf="documentsList && !documentsList.length">
  The matter selected is empty, please try again.
</p>
