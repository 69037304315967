import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from 'src/app/dialog.service';
import { AuthService } from 'src/app/services/auth.service';
import { FilesService } from 'src/app/services/files.service';
import { UIMessagingService } from 'src/app/services/uimessaging.service';
import { DocViewerComponent } from '../ui/doc-viewer/doc-viewer.component';

@Component({
  selector: 'app-file-comments-attachments-list',
  styleUrls: ['./file-comments-attachments-list.component.scss'],
  templateUrl: './file-comments-attachments-list.component.html',
})
export class FileCommentsAttachmentsListComponent {
  @Input() notes: any;
  @Output() markNoteAsViewed: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private auth_$: AuthService,
    private dialog_$: DialogService,
    private files_$: FilesService,
    private uiMessaging_$: UIMessagingService,
  ) {}

  markNoteAsViewedFn($event, idx, note) {
    this.markNoteAsViewed.emit({ $event, idx, note });
  }

  handleOpenAttachment(filePath, $event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.previewDocument(this.cleanFilePath(filePath), 'Preview Attachment');
    // window.open(attachment.url, '_blank');
  }

  private cleanFilePath(filePath) {
    // Remove the first slash
    return filePath.replace(/^\//, '');
  }

  getProperViewer(fileName) {
    let viewer = 'google';
    const extension = fileName.split('.').pop();
    switch (extension) {
      case 'mp4':
      case 'avi':
        viewer = 'video';
        break;
      case 'pdf':
        viewer = 'pdf';
        break;
      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'ppt':
      case 'pptx':
      case 'csv':
        viewer = 'office';
        break;
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        viewer = 'url';
        break;
      default:
        viewer = 'google';
        break;
    }
    return viewer;
  }

  async previewDocument(filePath: string, fileName: string) {
    this.auth_$.showLoader('Getting file metadata...');
    this.auth_$.showLoader('Loading the document ...');
    const fileUrl = await this.files_$.getFileUrl(filePath);
    this.auth_$.hideLoader();
    if (!fileUrl) this.uiMessaging_$.toastMessage('Error getting the file URL', 'ERROR');
    this.auth_$.hideLoader();
    this.dialog_$.open(DocViewerComponent, {
      width: '80%',
      data: {
        src: fileUrl.data.url,
        title: fileName,
        info: fileUrl.data.url,
        viewer: this.getProperViewer(filePath),
      },
    });
  }
}
