import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-check-image',
  templateUrl: './check-image.component.html',
  styleUrls: ['./check-image.component.scss'],
})
export class CheckImageComponent implements OnInit {
  @Input() value: number;
  imagePath: string;

  ngOnInit(): void {
    this.imagePath = this.getImagePath(this.value);
  }

  constructor() {}

  getImagePath(val: number) {
    const basePath = '/assets/png/';
    if (val === 1) {
      return `${basePath}check.png`;
    } else if (val === 2) {
      return `${basePath}blue-asterisk.png`;
    } else {
      return '0';
    }
  }
}
