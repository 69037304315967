import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-profile-info-box',
  templateUrl: './client-profile-info-box.component.html',
  styleUrls: ['./client-profile-info-box.component.scss'],
})
export class ClientProfileInfoBoxComponent {
  discsFolderName = 'Discs';
  // Declare Input Variables
  /*
  [currentFolder]="currentFolder"
  [filesTableDataSource]="filesTableDataSource"
  [permissions_uploadfiles]="permissions_uploadfiles"
  [UserRoles]="UserRoles"
  [realRole]="realRole"
  */
  @Input() currentFolder: any;
  @Input() currentFolderName: string;
  @Input() filesTableDataSource: any;
  @Input() permissions_uploadfiles: any;
  @Input() UserRoles: any;
  @Input() realRole: any;

  constructor() {}
}
