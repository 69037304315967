<div
  *ngIf="permissions_uploadfiles || realRole === 'Client'"
  fxLayout="row"
  fxLayoutAlign="start center">
  <button
    *ngIf="currentFolderName !== discsFolderName"
    (click)="openDialog($event)"
    mat-flat-button
    color="warn">
    <mat-icon>cloud_upload</mat-icon>
    Choose new files or discs to upload to your folders
  </button>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <button
    *ngIf="realRole !== 'Client' && currentFolderName !== discsFolderName"
    [disabled]="!importFromAppsBtn"
    (click)="showImportFromApps()"
    mat-flat-button
    color="warn">
    <mat-icon>download</mat-icon>
    Import from Apps
  </button>

  <button
    mat-flat-button
    style="margin-left: 20px"
    *ngIf="realRole === UserRoles.owner"
    (click)="addConsultant()"
    aria-hidden="false"
    aria-label="Add consultant to this case."
    matTooltip="Add consultant to this case."
    color="warn">
    <mat-icon>person_add</mat-icon>
    Add Consultant
  </button>

  <button
    style="margin-left: 20px; border: 1px solid #d3d3d3"
    *ngIf="clioMatterId"
    (click)="backToClio(clioMatterId)"
    mat-flat-button
    matTooltip="Open this Matter in Clio">
    <img
      src="./../../../assets/png/clio-blue-square.png"
      width="15px"
      alt="Clio logo" />
    Back to Clio
  </button>
</div>
