import { Injectable } from '@angular/core';
import { Client } from '@microsoft/microsoft-graph-client';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

import { AlertsService } from './alert.service';
import { MicrosoftAuthService } from './microsoft.auth.service';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftGraphService {
  private graphClient: Client;
  constructor (
    private authService: MicrosoftAuthService,
    private alertsService: AlertsService
  ) {

    // Initialize the Graph client
    this.graphClient = Client.init({
      authProvider: async (done) => {
        // Get the token from the auth service
        const token = await this.authService.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          done(null, token);
        } else {
          done('Could not get an access token', null);
        }
      }
    });
  }

  async addEventToCalendar(newEvent: MicrosoftGraph.Event): Promise<any> {
    let response;
    try {
      response = await this.graphClient
        .api('/me/events')
        .post(newEvent);
    } catch (error) {
      response = error;
      throw Error(JSON.stringify(error, null, 2));
    }
    return response;
  }
}
