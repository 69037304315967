import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-audit-log-filter-by-user',
  templateUrl: './audit-log-filter-by-user.component.html',
  styleUrls: ['./audit-log-filter-by-user.component.scss']
})
export class AuditLogFilterByUserComponent implements OnInit {
  usersData: any;
  constructor (@Inject(MAT_DIALOG_DATA) private data,
    public dialog: MatDialogRef<AuditLogFilterByUserComponent>) { }

  ngOnInit(): void {
    this.usersData = this.data.usersData;
  }

  getLogFromUser(uid: string) {
    console.log('User uid', uid);
  }

  getLogsByClientAndUser(client, useruid) {
    this.dialog.close({ client, useruid });
  }

}
