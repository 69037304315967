<app-window-title
  [title]="'Select an attorney'"
  [closable]="true"></app-window-title>
<mat-form-field
  style="width: 100%"
  color="warn"
  appearance="standard">
  <mat-label> Attorney </mat-label>
  <mat-select
    (selectionChange)="handleOwnerChange($event)"
    [value]="selectedOwner"
    name="owner"
    aria-label="Label"
    matInput
    placeholder="Attorney"
    color="warn">
    <mat-option
      *ngFor="let owner of owners"
      [value]="owner.value">
      {{ owner.label }}
    </mat-option>
  </mat-select>
  <mat-hint> Please select a lawyer or firm to view their cases. </mat-hint>
</mat-form-field>
