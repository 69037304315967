import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintenance',
  template: `
    <p>
      NuageDx VNA is down for maintenance, If you need more information please contact
      <a href="mailto:support@nuagedx.com">support@nuagedx.com</a>
    </p>
  `,
  styles: [':host { font-family: Arial; text-align: center; }'],
})
export class MaintenancePageComponent {
  constructor() {}
}
