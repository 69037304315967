<div fxLayout="row">
  <div fxFlex="90">
    <h3 [ngClass]="'mat-headline no-margin'" style="display: block">
      Import Documents from PRACTICE PANTHER to {{ data.clientName }}
    </h3>
  </div>

  <div fxFlex="10">
    <button mat-flat-button mat-dialog-close color="warn">X</button>
  </div>
</div>
<div *ngIf="matterSelected === false">
  <p>Please <b>select the matter</b> you want to import documents from</p>
  <mat-selection-list [multiple]="false" class="matters-list">
    <mat-list-option
      (click)="
        getPracticePantherDocuments(
          item.id,
          item.display_name,
          item.account_ref.display_name,
          item.account_ref.id
        )
      "
      *ngFor="let item of mattersPaged"
      ><h3 class="matter-name" matline>
        {{ item.display_name }}
      </h3>
      <span *ngIf="!item.client">...</span>
      <p class="mat-small" *ngIf="item.client">
        Account ref: {{ item.account_ref.display_name }}
      </p>
    </mat-list-option>
  </mat-selection-list>
  <mat-paginator
    [length]="p_length"
    [pageSize]="p_pageSize"
    [pageSizeOptions]="p_pageSizeOptions"
    (page)="handlePageEvent($event)"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
<button
  mat-button
  *ngIf="matterSelected"
  (click)="matterSelected = false"
  color="warn"
>
  Back to matters
</button>
<app-practicepantherlistdocuments
  *ngIf="matterSelected"
  [selectedMatter]="selectedMatter"
  (imporEvent)="handleImportDocuments($event)"
  [documentsList]="documents"
></app-practicepantherlistdocuments>
