<div fxLayout="row" fxLayoutGap="10px">
  <!-- <div fxLayout="row" fxLayoutGap="10px grid" fxLayoutAlign="space-around start"> -->
  <div fxFlex="20" class="plan-container">
    <div>
      <div class="fusion-text-2 price">
        <h2 style="--fontsize: 33; line-height: 1.5; --fontSize: 40;" data-fontsize="40" data-lineheight="60px">$0.00
        </h2>
        <p><sup>USD<br>
            /mo</sup></p>
      </div>
      <div class="title fusion-title-2 ">
        <div>
          <div class="title-sep sep- sep-solid" style="border-color:#e0dede;"></div>
        </div><span class="awb-title-spacer"></span>
        <h2 class="fusion-title-heading title-heading-center "
          style="margin:0;font-size:1em;--fontSize:35;line-height:1.14;">
          Free</h2><span class="awb-title-spacer"></span>
        <div class="title-sep-container title-sep-container-right">
          <div class="title-sep sep- sep-solid" style="border-color:#e0dede;"></div>
        </div>
      </div>
      <div class="fusion-text-3 features">
        <ul>
          <li aria-level="1">One (1) additional user- either associate or admin.</li>
          <li aria-level="1">Consultants: 3 at any one time.</li>
          <li aria-level="1">DICOM image disc upload and Cloud storage (medical imaging studies: (Limit 6 studies at any
            one time).</li>
          <li aria-level="1">Upload from Practice Management systems &amp; Desktop: <span style="color: #00a9d3;">Clio
              users only</span>.</li>
          <li aria-level="1"><span
              style="font-size: var(--awb-font-size); font-style: var(--awb-text-font-style); font-weight: var(--awb-text-font-weight); letter-spacing: var(--awb-letter-spacing); text-align: var(--awb-content-alignment); text-transform: var(--awb-text-transform); background-color: var(--awb-bg-color);">ZOOM
              MEETINGS: 4/month.</span></li>
        </ul>
      </div>
    </div>
  </div>
  <div fxFlex="20" class="plan-container">
    <div>
      <div class="fusion-text-4 price" style="--awb-line-height:40px;" id="silver">
        <h2 style="--fontsize: 33; line-height: 1.5; --fontSize: 40;" data-fontsize="40" data-lineheight="60px">$19.95
        </h2>
        <p><sup>USD<br>
            /mo</sup></p>
      </div>
      <div class="title fusion-title-3 ">
        <div>
          <div class="title-sep sep- sep-solid" style="border-color:#e0dede;"></div>
        </div><span class="awb-title-spacer"></span>
        <h2 class="fusion-title-heading title-heading-center "
          style="margin:0;font-size:1em;--fontSize:35;line-height:1.14;">
          Silver</h2><span class="awb-title-spacer"></span>
        <div class="title-sep-container title-sep-container-right">
          <div class="title-sep sep- sep-solid" style="border-color:#e0dede;"></div>
        </div>
      </div>
      <div class="fusion-text-5 features">
        <ul>
          <li><span
              style="font-size: var(--awb-font-size); font-style: var(--awb-text-font-style); font-weight: var(--awb-text-font-weight); letter-spacing: var(--awb-letter-spacing); text-align: var(--awb-content-alignment); text-transform: var(--awb-text-transform); background-color: var(--awb-bg-color-hover);">Each
              subscriber simultaneously shares same documents with (1) administrator and/or (1) associate.</span></li>
          <li>Unlimited number of consultants &amp; experts.</li>
          <li>Unlimited DICOM image disc upload and Cloud storage (medical imaging studies)</li>
          <li><span
              style="font-size: var(--awb-font-size); font-style: var(--awb-text-font-style); font-weight: var(--awb-text-font-weight); letter-spacing: var(--awb-letter-spacing); text-align: var(--awb-content-alignment); text-transform: var(--awb-text-transform); background-color: var(--awb-bg-color-hover);">Unlimited
              Zoom Video conference time /session.</span></li>
          <li><span
              style="font-size: var(--awb-font-size); font-style: var(--awb-text-font-style); font-weight: var(--awb-text-font-weight); letter-spacing: var(--awb-letter-spacing); text-align: var(--awb-content-alignment); text-transform: var(--awb-text-transform); background-color: var(--awb-bg-color);">2TB
              storage/month</span><span
              style="font-size: var(--awb-font-size); font-style: var(--awb-text-font-style); letter-spacing: var(--awb-letter-spacing); text-align: var(--awb-content-alignment); text-transform: var(--awb-text-transform); background-color: var(--awb-bg-color); color: #ba0000;">**</span><span
              style="font-size: var(--awb-font-size); font-style: var(--awb-text-font-style); font-weight: var(--awb-text-font-weight); letter-spacing: var(--awb-letter-spacing); text-align: var(--awb-content-alignment); text-transform: var(--awb-text-transform); background-color: var(--awb-bg-color);">
              (overage @ $0.20/GB/month).</span></li>
        </ul>
      </div>
    </div>
  </div>
  <div fxFlex="20" class="plan-container">
    <div>
      <div class="fusion-text-6 price" id="gold">
        <h2 data-fontsize="40" style="--fontSize: 40; line-height: 1.5;" data-lineheight="60px">$24.95</h2>
        <p><sup>USD<br>
            /mo</sup></p>
      </div>
      <div class="title fusion-title-4 ">
        <div>
          <div class="title-sep sep- sep-solid" style="border-color:#e0dede;"></div>
        </div><span class="awb-title-spacer"></span>
        <h2 class="fusion-title-heading title-heading-center "
          style="margin:0;font-size:1em;--fontSize:35;line-height:1.14;">
          Gold</h2><span class="awb-title-spacer"></span>
        <div class="title-sep-container title-sep-container-right">
          <div class="title-sep sep- sep-solid" style="border-color:#e0dede;"></div>
        </div>
      </div>
      <div class="fusion-text-7 fusion-text-no-margin features">
        <ul>
          <li><b>Silver features</b><strong> +</strong></li>
          <li><span
              style="font-size: var(--awb-font-size); font-style: var(--awb-text-font-style); font-weight: var(--awb-text-font-weight); letter-spacing: var(--awb-letter-spacing); text-align: var(--awb-content-alignment); text-transform: var(--awb-text-transform); background-color: var(--awb-bg-color-hover);">Add
              one additional user (associate or administrator).</span></li>
          <li>4TB storage/month<span style="color: #ba0000;">**</span> (overage @ $0.20/GB/month).</li>
          <li>Video conference recording and storage.</li>
        </ul>
      </div>
    </div>
  </div>
  <div fxFlex="20" class="plan-container most-popular">
    <div>
      <div class="fusion-text-8 price" id="platinum">
        <h2 data-fontsize="40" style="--fontSize: 40; line-height: 1.5;" data-lineheight="60px">$39.95</h2>
        <p><sup>USD<br>
            /mo</sup></p>
      </div>
      <div class="title fusion-title-5 ">
        <div>
          <div class="title-sep sep- sep-solid" style="border-color:#e0dede;"></div>
        </div><span class="awb-title-spacer"></span>
        <h2 class="fusion-title-heading title-heading-center "
          style="margin:0;font-size:1em;--fontSize:35;line-height:1.14;">
          Platinum</h2><span class="awb-title-spacer"></span>
        <div class="title-sep-container-right">
          <div class="title-sep sep- sep-solid" style="border-color:#e0dede;"></div>
        </div>
      </div>
      <div class="fusion-text-9 fusion-text-no-margin features">
        <ul>
          <li aria-level="1"><b>Gold features +</b></li>
          <li aria-level="1"><span
              style="font-size: var(--awb-font-size); font-style: var(--awb-text-font-style); font-weight: var(--awb-text-font-weight); letter-spacing: var(--awb-letter-spacing); text-align: var(--awb-content-alignment); text-transform: var(--awb-text-transform); background-color: var(--awb-bg-color-hover);">Add
              UNLIMITED additional users (associates and/or administrators).</span></li>
          <li aria-level="1">5TB storage/month<span style="color: #ce0000;">**</span> (overage @ $0.15/GB/month).</li>
          <li aria-level="1">Remote Client upload for documents and discs<span style="color: #0096ed;">*</span>
            (available soon at no extra charge) .</li>
          <li aria-level="1"><b>Download DICOM (duplicate medical imaging discs)</b> allows you tu burn additional
            discs.</li>
          <li aria-level="1">Voice recognition input<span style="color: #00afef;">*</span> (available soon at no extra
            charge).</li>
          <li aria-level="1">Zoom video conference transcription<span style="color: #009cea;">*</span> (available soon
            at no extra charge).</li>
        </ul>
      </div>
    </div>
  </div>
  <div fxFlex="20" class="plan-container most-popular">
    <div>
      <div class="price" id="lpmp">
        <h2 data-fontsize="40" style="--fontSize: 40; line-height: 1.5;" data-lineheight="60px">$35.00</h2>
        <p><sup>USD<br>
            /mo</sup></p>
      </div>
      <div class="title ">
        <div>
          <div class="title-sep sep- sep-solid" style="border-color:#e0dede;"></div>
        </div><span class="awb-title-spacer"></span>
        <h2 class="fusion-title-heading title-heading-center "
          style="margin:0;font-size:1em;--fontSize:35;line-height:1.14;">
          Clio Platinum</h2><span class="awb-title-spacer"></span>
        <div class="title-sep-container title-sep-container-right">
          <div class="title-sep sep- sep-solid" style="border-color:#e0dede;"></div>
        </div>
      </div>
      <div class="fusion-text-11 features features-01">
        <ul>
          <li aria-level="1"><b>Gold features +</b></li>
          <li aria-level="1"><span
              style="font-size: var(--awb-font-size); font-style: var(--awb-text-font-style); font-weight: var(--awb-text-font-weight); letter-spacing: var(--awb-letter-spacing); text-align: var(--awb-content-alignment); text-transform: var(--awb-text-transform); background-color: var(--awb-bg-color-hover);">Add
              UNLIMITED additional users (associates and/or administrators).</span></li>
          <li aria-level="1">5TB storage/month<span style="color: #ce0000;">**</span> (overage @ $0.15/GB/month).</li>
          <li aria-level="1">Remote Client upload for documents and discs<span style="color: #0096ed;">*</span>
            (available soon at no extra charge) .</li>
          <li aria-level="1"><b>Download DICOM (duplicate medical imaging discs)</b> allows you tu burn additional
            discs.</li>
          <li aria-level="1">Voice recognition input<span style="color: #00afef;">*</span> (available soon at no extra
            charge).</li>
          <li aria-level="1">Zoom video conference transcription<span style="color: #009cea;">*</span> (available soon
            at no extra charge).</li>
        </ul>
      </div>
    </div>
  </div>
</div>