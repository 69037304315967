import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

import { SessionService } from './../../../session.service';

@Component({
  template: `
    <app-window-title
      title="Authorize this App"
      [closable]="data.closable"></app-window-title>
    <p *ngIf="data.message">{{ data.message }}</p>
    <button
      (click)="grantClioAccess()"
      mat-flat-button
      color="warn"
      type="button">
      GRANT CLIO ACCESS
    </button>
  `,
})
export class ClioAccessTokenDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ClioAccessTokenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth_$: AuthService,
    private session_$: SessionService,
  ) {}

  ngOnInit() {
    this.auth_$.userData.subscribe(user => {
      if (Object.keys(user).length > 0) {
        if (this.session_$.getAutomaticClioAuthorize()) {
          this.grantClioAccess();
        }
      }
    });
  }

  grantClioAccess(): void {
    this.dialogRef.close(this.data.origin);
  }
}
