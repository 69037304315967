import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clio-import-files-from-matters',
  templateUrl: './clio-import-files-from-matters.component.html',
  styleUrls: ['./clio-import-files-from-matters.component.scss']
})
export class ClioImportFilesFromMattersComponent {
  constructor() { }
}
