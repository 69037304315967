<div fxLayout="row">
  <div fxFlex>
    <h2 class="mat-headline">Create Client/Matter</h2>
  </div>

  <div
    fxFlex="none"
    fxFlexOffset="1">
    <button
      mat-flat-button
      mat-dialog-close
      color="warn">
      X
    </button>
  </div>
</div>
<div mat-dialog-content>
  <app-loader
    *ngIf="!ownerID"
    message="please wait..."></app-loader>
  <div
    fxLayoutAlign="center center"
    *ngIf="ownerID"
    fxLayout="column">
    <form style="width: 100%">
      <div fxLayout="row">
        <div fxFlex>
          <mat-form-field appearance="fill">
            <mat-label>First name</mat-label>
            <input
              matInput
              type="text"
              class="mdc-text-field__input"
              [value]="fname"
              #firstNameInput
              (input)="fname = firstNameInput.value"
              required
              placeholder="First Name" />
          </mat-form-field>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
              Client must have a first name
            </div>
          </div>
        </div>
        <div fxFlex>
          <!-- Column 2 content goes here -->

          <mat-form-field appearance="fill">
            <mat-label>Last name</mat-label>
            <input
              type="text"
              class="mdc-text-field__input"
              placeholder="Last Name"
              #lastNameInput
              matInput
              [value]="lname"
              (input)="lname = lastNameInput.value"
              required />
          </mat-form-field>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
              Client must have a last name
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50">
          <!-- Column 1 content goes here -->
          <mat-form-field appearance="fill">
            <mat-label>Matter name</mat-label>
            <input
              type="text"
              matInput
              class="mdc-text-field__input"
              [value]="patientId"
              #patientIdInput
              (input)="patientId = patientIdInput.value"
              required
              placeholder="Matter Name" />
          </mat-form-field>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
              Client must have a Matter Name
            </div>
          </div>
        </div>
        <div fxFlex="50">
          <!-- Column 2 content goes here -->

          <mat-form-field appearance="fill">
            <mat-label>Legal Case Id</mat-label>
            <input
              matInput
              type="text"
              class="mdc-text-field__input"
              [value]="legalCaseId"
              #legalCaseIdInput
              (input)="legalCaseId = legalCaseIdInput.value"
              required
              placeholder="Legal Case Id" />
          </mat-form-field>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
              Client must have a case id
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50">
          <!-- Column 1 content goes here -->
          <mat-form-field appearance="fill">
            <mat-label>Cell phone number</mat-label>
            <input
              matInput
              type="text"
              class="mdc-text-field__input"
              [value]="cellPhoneNumber"
              #cellPhoneNumberInput
              (input)="cellPhoneNumber = cellPhoneNumberInput.value" />
          </mat-form-field>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
              Client must have a cell phone number
            </div>
          </div>
        </div>
        <div fxFlex="50">
          <!-- Column 2 content goes here -->

          <mat-form-field appearance="fill">
            <mat-label>Email address</mat-label>
            <input
              matInput
              type="text"
              class="mdc-text-field__input"
              [value]="emailAddress"
              #emailAddressInput
              (input)="emailAddress = emailAddressInput.value" />
          </mat-form-field>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
              Client must have an emailAddress
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50">
          <!-- Column 1 content goes here -->
          <mat-form-field appearance="fill">
            <mat-label>Date of birth</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [max]="maxDate"
              placeholder="Date of Birth"
              [formControl]="date" />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <!-- Column 2 content goes here -->
          <mat-form-field appearance="fill">
            <mat-label>Description</mat-label>
            <input
              matInput
              type="text"
              class="mdc-text-field__input"
              [value]="description"
              #descriptionInput
              (input)="description = descriptionInput.value" />
          </mat-form-field>
        </div>
      </div>
    </form>
    <div
      fxLayoutAlign="center center"
      fxLayout="column">
      <h5 style="color: red; font-family: Arial">{{ errorMessage }}</h5>
    </div>
  </div>
</div>
<mat-dialog-actions
  *ngIf="ownerID"
  align="center">
  <button
    mat-flat-button
    (click)="createPatient()"
    color="warn">
    CREATE
  </button>
</mat-dialog-actions>
