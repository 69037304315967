import { Injectable } from '@angular/core';

declare function dumpFile(file: any, option: string): [];

@Injectable({
  providedIn: 'root',
})
export class DicomUtilitiesService {
  constructor() {}

  getBodyAttributes(attributes: string[]): string[] {
    const attributesOutput = [];
    attributes.forEach(attrValue => {
      attributesOutput.push(window.document.body.getAttribute(attrValue));
      window.document.body.setAttribute(attrValue, '');
    });
    return attributesOutput;
  }

  getDicomdirMetadata(file: File): Promise<any> {
    return new Promise(async resolve => {
      // TODO: Check if call dumpFile twice is strictly necessary.
      dumpFile(file, 'StudyInstanceUID');
      dumpFile(file, 'ReferencedFileID');

      setTimeout(() => {
        const studiesData = JSON.parse(window.document.body.getAttribute('studiesData'));
        const filesData = JSON.parse(window.document.body.getAttribute('filesData'));
        window.document.body.removeAttribute('studiesData');
        window.document.body.removeAttribute('filesData');
        resolve({ studiesData, filesData });
      }, 1000);
    });
  }

  getDICOMFileData(file: File): Promise<any> {
    return new Promise(async resolve => {
      dumpFile(file, 'StudyInstanceUID');
      setTimeout(() => resolve({ data: window.document.body.getAttribute('studiesData') }), 1000);
    });
  }
}
