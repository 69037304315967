import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from 'firebase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecurlyService {
  constructor(private http: HttpClient) {}

  updateUserRecurlyCode(userdocid: string, recurlyaccountcode: string, plancode: string) {
    return firebase.functions().httpsCallable('recurly-updateUserRecurlyCode')({
      userdocid,
      recurlyaccountcode,
      plancode,
    });
  }

  async getPlanRestrictions(planDocId: any) {
    const planData = (
      await firebase
        .firestore()
        .doc('plans/' + planDocId)
        .get()
    ).data();
    return planData ? planData['upgrade'] : false;
  }

  getSubscriptionId(accountData: {}): any {
    console.log('accountData :', accountData);
    throw new Error('Method not implemented.');
  }

  getSubscriptions(accountId): any {
    const url = `${environment.constants.cloudfunctionsURL}recurly-getSubscriptions?accountid=${accountId}`;
    return this.http.get(url).toPromise();
  }

  changeSubscriotion(subscriptionId: any, newplan: any) {
    const url = `${environment.constants.cloudfunctionsURL}recurly-changeSubscription?subscriptionid=${subscriptionId}&newplancode=${newplan}`;
    return this.http.get(url).toPromise();
  }

  getAccount(accountcode) {
    console.log('accountcode :', accountcode);
    const url = `${environment.constants.cloudfunctionsURL}recurly-getAccount?accountcode=${accountcode}`;
    return this.http.get(url).toPromise();
  }
}
