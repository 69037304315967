<mat-list
  class="responsive-list mat-expansion-panel-content"
  role="list">
  <ng-container *ngFor="let step of stepsToBurnADisc; index as i">
    <mat-list-item
      class="step"
      role="listitem">
      <div class="step-content">
        <h2 class="step-title">{{ step.title }}</h2>
        <p class="step-description" [innerHTML]="step.description"></p>
      </div>
    </mat-list-item>
  </ng-container>
</mat-list>