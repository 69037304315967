import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';

@Component({
  selector: 'app-simple-list-selection',
  templateUrl: './simple-list-selection.component.html',
  styleUrls: ['./simple-list-selection.component.scss']
})
export class SimpleListSelectionComponent {
  @ViewChild('list') list
  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<SimpleListSelectionComponent>) { }

  onButtonClick(value) {
    const answer = value ? { answer: value, data: this.data.items.filter(i => this.list.selectedOptions.selected.map((option: MatListOption) => option.value).includes(i.id)) } : { answer: value }
    this.dialogRef.close(answer)
  }
}
