import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import firebase from 'firebase/app';

import { DialogService } from 'src/app/dialog.service';
import { AuthService } from '../../services/auth.service';
import { UIMessagingService } from '../../services/uimessaging.service';
import { CreateuserComponent } from '../createuser/createuser.component';

const unshareIconUrl = './../../assets/svg/unshare.svg';
@Component({
  selector: 'app-available-consultants',
  styleUrls: ['./available-consultants.component.scss'],
  templateUrl: './available-consultants.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AvailableConsultantsComponent implements OnInit, OnChanges {
  consultantsList: any[] = [];
  clientId: string;
  currentSignedInUser: firebase.User;
  cachedUsers: any[] = [];
  selection = new SelectionModel<any>(true, []);
  @Input() availableConsultants: any = { role: '', clientid: '', files: [] };
  @Input() caseName: string;
  @Input() selectedFiles: any = [];
  @Output() shareEvent: EventEmitter<any> = new EventEmitter();
  @Output() unshareEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() parentReload: EventEmitter<any> = new EventEmitter();
  @ViewChild('consultants') consultants: MatSelectionList;
  files: any;
  clientfiles: any;
  role: any;
  availableConsultantsList: any[] = [];
  availableCachedUsers: any[];
  optionSelectedClick: number;
  currentValue: string;

  constructor(
    public snackBar: MatSnackBar,
    public auth$: AuthService,
    public router: Router,
    public ref: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private dialog_$: DialogService,
    private domSanitizer: DomSanitizer,
    private uiMessaging_$: UIMessagingService,
  ) {
    this.optionSelectedClick = 0;
    this.currentValue = '';
    this.matIconRegistry.addSvgIcon('unshareIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(unshareIconUrl));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.availableConsultantsList = this.availableConsultants;
    this.auth$.user.subscribe(async user => {
      this.currentSignedInUser = user;
    });
  }

  createConsultant(event: { preventDefault: () => void }) {
    event.preventDefault();
    this.dialog_$
      .open(CreateuserComponent, {
        width: '380px',
        data: {
          userType: 'Consultant',
          title: 'Create Consultant',
          button: 'Create',
          screen: 'full',
        },
      })
      .afterClosed()
      .subscribe(result => {
        console.log('result :', result);
        if (result) {
          this.uiMessaging_$.toastMessage('Consultant created successfully', '');
          this.parentReload.emit(true);
        }
      });
  }

  selectedOptionsClear() {
    this.consultants.selectedOptions.clear();
  }

  handleOptionClick(consultant) {
    if (consultant.email === this.currentValue) {
      this.consultants.selectedOptions.clear();
      this.rowClickEvent.emit('');
      this.currentValue = '';
      this.optionSelectedClick = 0;
    } else {
      this.rowClickEvent.emit(consultant);
      this.currentValue = consultant.email;
      this.optionSelectedClick = 1;
    }
  }

  share(consultant, event) {
    event.stopPropagation();
    this.shareEvent.emit(consultant);
  }

  unShare(consultant) {
    this.unshareEvent.emit(consultant);
  }
}
