<mat-toolbar
  [ngStyle]="{ height: 'auto' }"
  fxLayout
  fxLayoutAlign="space-between center">
  <div fxFlex="72">
    <button
      *ngIf="deleteButton"
      [disabled]="!allowDeleteNote || selection.selected.length === 0"
      (click)="openDeleteConfirmDialog()"
      mat-button>
      <mat-icon>delete</mat-icon>
      &nbsp;
      <span [ngClass]="'mdc-button__label'">DELETE</span>
    </button>
    <button
      *ngIf="fileSelected && currentFolderName !== discsFolderName"
      (click)="openAddNoteDialog()"
      mat-button
      id="marginBFileInfo">
      <mat-icon>note_add</mat-icon>
      &nbsp;
      <span [ngClass]="'mdc-button__label'">ADD COMMENT OR ATTACHMENT</span>
    </button>
    <button
      [disabled]="!refreshButton"
      (click)="handleRefresh()"
      mat-button>
      <mat-icon>refresh</mat-icon>
      &nbsp;
      <span [ngClass]="'mdc-button__label'">REFRESH</span>
    </button>
    <button
      *ngIf="permissions_createfolder && currentFolderName === ''"
      (click)="createFolder(currentFolder, { folderName: currentFolderName })"
      mat-button>
      <mat-icon>create_new_folder</mat-icon>
      &nbsp;
      <span [ngClass]="'mdc-button__label'">CREATE FOLDER</span>
    </button>

    <!-- FILE ACTIONS MENU -->
    <button
      [matMenuTriggerFor]="menu"
      #fileActionsMenu
      class="file-actions-menu"
      [ngClass]="{ show: fileActionsEnabled() }"
      mat-button>
      <mat-icon>description</mat-icon>
      &nbsp;
      <span [ngClass]="'mdc-button__label'">FILE ACTIONS</span>
    </button>
    <mat-menu
      #menu="matMenu"
      xPosition="before">
      <ng-container *ngFor="let item of fileActionsMenuItems">
        <button
          (click)="item.action($event)"
          mat-menu-item
          [class]="item.class"
          *ngIf="item.condition()">
          <mat-icon>{{ item.icon }}</mat-icon>
          {{ item.label }}
        </button>
      </ng-container>
    </mat-menu>
    <!-- END FILE ACTIONS MENU  -->

    <button
      *ngIf="
        currentFolder !== '' &&
        selection.selected.length === 0 &&
        files.length > 0 &&
        realRole !== UserRoles.consultant &&
        realRole !== UserRoles.client
      "
      [disabled]="!refreshButton || files.length <= 1"
      (click)="openFileSearch()"
      mat-button>
      <mat-icon>search</mat-icon>
      &nbsp; SEARCH
    </button>

    <button
      *ngIf="selection.selected.length > 1 && currentFolder !== ''"
      [disabled]="!refreshButton"
      (click)="sortFilesBySelected()"
      mat-button>
      <mat-icon>fact_check</mat-icon>
      &nbsp; SORT FILES BY SELECTED
    </button>

    <button
      *ngIf="selection.selected.length === 0 && currentFolder !== ''"
      [disabled]="files.length <= 1"
      [matMenuTriggerFor]="sortmenu"
      mat-button>
      <mat-icon>sort</mat-icon>
      &nbsp; SORT FILES BY
    </button>

    <mat-chip
      disableRipple="true"
      *ngIf="filesFilter.name !== ''"
      (removed)="removeFilter()">
      {{ filesFilter.name }}
      <button
        matChipRemove
        aria-label="'remove ' + filesFilter.name">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <mat-menu #sortmenu="matMenu">
      <button
        *ngFor="let sort of sortTypes; index as i"
        [value]="i"
        (click)="handleSelect(i)"
        mat-menu-item>
        {{ sort.label }}
      </button>
    </mat-menu>
  </div>
  <button
    *ngIf="activeConsultant && realRole !== 'Consultant'"
    [disabled]="!activeConsultant"
    (click)="unShareAll(activeConsultant)"
    gdAlignColumns
    mat-flat-button
    color="warn">
    {{ unshareAllFilesMsg }}
  </button>
</mat-toolbar>
