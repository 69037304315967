<button
  class="close-btn"
  mat-flat-button
  mat-dialog-close
  color="warn">
  X
</button>
<h2 mat-dialog-title>{{ data.title }}</h2>
<div
  mat-dialog-content
  [innerHTML]="data.message"></div>

<div *ngIf="data.list && data.list.length">
  <ul>
    <li *ngFor="let item of data.list">
      {{ item.name }}
    </li>
  </ul>
</div>

<div
  class="import-radio-group"
  *ngIf="data.options">
  <mat-radio-group
    class="import-radio-group"
    fxLayout="column">
    <mat-radio-button
      name="options"
      #options
      class="import-radio-button"
      *ngFor="let option of data.options"
      [value]="option.value"
      [checked]="option.selected ? true : null">
      {{ option.label }}
    </mat-radio-button>
  </mat-radio-group>
</div>

<div
  class="buttons-container"
  *ngIf="data.buttons"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="15px">
  <button
    mat-flat-button
    color="warn"
    *ngFor="let button of data.buttons"
    (click)="handleClose(button.value)">
    {{ button.text }}
  </button>
</div>
