<div
  [class]="hiddenClass"
  fxLayoutAlign="center center">
  <div class="mdc-card mdc-card--outlined signin-card mdc-elevation--z1 element-1">
    <a
      class="signin-logo"
      href="/">
      <img
        class="element-3"
        src="../../assets/Nuage-Logo.png"
        alt />
    </a>
    <form
      fxLayout="column"
      fxLayoutAlign="center center"
      autocomplete="off">
      <!-- Email Text Field -->
      <mat-form-field
        *ngIf="!twofacode_needed"
        class="element-5"
        color="warn"
        appearance="fill">
        <mat-label>Email</mat-label>
        <input
          #emailInput
          autofocus
          [formControl]="email"
          matInput
          type="email"
          class="mdc-text-field__input"
          placeholder="Enter your username (email address)"
          name="email-input"
          autocomplete="new-email-input" />
        <mat-error *ngIf="email.invalid">
          {{ getErrorMessage(email) }}
        </mat-error>
      </mat-form-field>
      <!-- Password Text Field -->
      <mat-form-field
        *ngIf="!twofacode_needed"
        class="element-4"
        color="warn"
        appearance="fill">
        <mat-label>Password</mat-label>
        <input
          #passwordInput
          [formControl]="password"
          [class.password-input]="hide"
          (keydown.enter)="login($event)"
          matInput
          name="password-input"
          type="text"
          class="mdc-text-field__input"
          autocomplete="off" />
        <mat-error *ngIf="password.invalid">
          {{ getErrorMessage(password) }}
        </mat-error>
        <button
          type="button"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
          (click)="hide = !hide"
          mat-icon-button
          matSuffix>
          <mat-icon>
            {{ hide ? 'visibility_off' : 'visibility' }}
          </mat-icon>
        </button>
      </mat-form-field>
      <!-- Submission Button -->
      <button
        *ngIf="!twofacode_needed"
        [disabled]="!email.valid || !password.valid"
        (click)="signIn()"
        mat-flat-button
        class="sign-in-button"
        name="sign-in-button"
        type="button"
        color="warn">
        <span class="mdc-button__label">LOGIN</span>
      </button>
      <button
        *ngIf="!euLogin"
        type="button"
        (click)="ssoClio()"
        class="sso-clio-button">
        <img
          title="Clio"
          src="./../../../assets/svg/clio-logo-blue.svg" />
        Sign in with Clio
      </button>
      <button
        *ngIf="euLogin"
        type="button"
        (click)="ssoClio(true)"
        class="sso-clio-button">
        <img
          title="Clio"
          src="./../../../assets/svg/clio-logo-blue.svg" />
        Sign in with Clio EU
      </button>
      <h5 class="mat-body error-message">{{ errorMessage }}</h5>
      <!-- Forgot your password Text -->
      <p>
        <a
          (click)="navigateToFGPass()"
          class="fgpass-text-dec">
          Reset your password
        </a>
        &nbsp;
        <a
          (click)="backToHome()"
          class="backtohome-link">
          Back to home.
        </a>
      </p>
    </form>
  </div>
</div>
