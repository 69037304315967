import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-add-attachments',
  template: `
    <div>
      <!-- <div *ngIf="!fileEntries"> -->
      <mat-form-field [ngClass]="'hidden-component'">
        <mat-label>Attachment</mat-label>
        <app-input-file
          label=""
          class="file-browser hidden-component"
          [multiple]="true"
          [directory]="false"
          [allowDirs]="false"
          placeholder=""
          [(ngModel)]="basicFileModel"
          (change)="handleFileSelection($event)">
        </app-input-file>
      </mat-form-field>

      <button
        mat-flat-button
        color="primary"
        [ngClass]="'browse-button'"
        (click)="browseFiles()">
        <mat-icon matSuffix>attach_file</mat-icon>
      </button>

      <div *ngIf="fileEntries.length">
        <h5 style="margin-top:0">Attachments:</h5>
        <ul
          *ngIf="fileEntries"
          [ngClass]="'mat-body'"
          style="padding:0">
          <li
            style="list-style:none"
            *ngFor="let item of fileEntries">
            {{ item.entry.name }} &nbsp;
            <mat-form-field appearance="fill">
              <mat-label>Attachment comment</mat-label>
              <input
                matInput
                name="desc"
                type="text"
                [value]="item.description"
                (keyup)="item.description = getValue($event.target)" />
            </mat-form-field>
          </li>
        </ul>
      </div>
    </div>
  `,
  styles: [
    `
      .hidden-component {
        display: none;
      }
    `,
  ],
})
export class AddAttachmentsComponent {
  basicFileModel: any;
  @Input() fileEntries: { entry: File; description?: string }[];
  @Output() addedAttachment: EventEmitter<any> = new EventEmitter();
  constructor() {}

  browseFiles() {
    console.log('browseFiles');
    (<HTMLElement>document.querySelector(`app-input-file.file-browser input[type="file"`)).click();
  }

  getValue(target: EventTarget): string {
    return (target as HTMLInputElement).value;
  }

  handleFileSelection(event) {
    console.log('handleFileSelection');
    const entries = Array.from((<HTMLInputElement>event.target).files);
    if (!entries.length) return;
    const newEntries = entries.map(entry => ({ entry, description: '' }));
    this.fileEntries = [...this.fileEntries, ...newEntries];
    this.addedAttachment.emit(this.fileEntries);
  }
}
