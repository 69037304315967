import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/dialog.service';
import { LoaderComponent } from 'src/app/loader/loader.component';
import { RedirectionService } from 'src/app/services/redirection.service';
import { UIMessagingService } from 'src/app/services/uimessaging.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  showform = 0;
  password = new FormControl('');
  passwordconfirm = new FormControl('');
  public barLabel = 'Password strength:';
  submitApproval = false;
  loader: MatDialogRef<unknown, any>;

  constructor(
    private utils_$: UtilsService,
    private route: ActivatedRoute,
    private uiMessaging_$: UIMessagingService,
    private router: Router,
    private dialog_$: DialogService,
    private redirect_$: RedirectionService,
  ) {}

  checkPasswordConfirm(input) {
    this.submitApproval = input.target.value === this.password.value;
  }

  handleSubmit(ev) {
    ev.preventDefault();
    if (this.submitApproval && this.password.value !== '') {
      this.handlePasswordChange(this.password.value);
    }
  }

  showLoader() {
    this.loader = this.dialog_$.open(LoaderComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
    });
    this.loader.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  hideLoader() {
    this.loader.close();
  }

  handlePasswordChange(pwd: string) {
    this.route.queryParams.subscribe({
      next: params => {
        this.showLoader();
        this.utils_$.setNewPassword(pwd, params).subscribe({
          next: r => {
            if (r['answer']) {
              this.hideLoader();
              this.uiMessaging_$.toastMessage('You have reset your password successfully', 'RESET DONE');
              this.redirect_$.goToLogin({ replaceUrl: true });
              this.showform = 1;
            } else {
              const errorMessage = r['error'];
              this.uiMessaging_$.toastMessage(errorMessage, 'ERROR');
              this.redirect_$.goToLogin({ replaceUrl: true });
              this.showform = 1;
            }
          },
          error: err => {
            console.log('err :', err);
            this.hideLoader();
            this.uiMessaging_$.toastMessage('There was an error. Please try again', 'ERROR');
            this.redirect_$.goToLogin({ replaceUrl: true });
            console.error('error', err);
          },
        });
      },
      error: err => {
        console.log('err :', err);
        this.hideLoader();
        this.uiMessaging_$.toastMessage('There was an error. Please try again', 'ERROR');
        this.redirect_$.goToLogin({ replaceUrl: true });
        console.error('Error reported in handlePasswordChange', err);
      },
    });
  }

  onStrengthChanged(event) {
    this.submitApproval = event === 100 && this.passwordconfirm.value === this.password.value;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const { code } = params;
      this.utils_$.validatePasswordResetRequest(code).subscribe({
        next: res => {
          if (!Object.keys(res).length) {
            this.redirect_$.goToLogin();
          }
          switch (res['answer']) {
            case 1:
              this.showform = 2;
              break;
            case 0:
              this.showform = 1;
              setTimeout(() => this.redirect_$.goToLogin(), 4000);
              break;
            default:
              this.showform = 1;
              break;
          }
        },
        complete: () => {
          console.log('Completed');
          return '';
        },
        error: err => {
          console.error(err);
          return err;
        },
      });
    });
  }
}
