import { Injectable } from '@angular/core';
import { FilesService } from './files.service';
import { FirebaseUtilitiesService } from './firebase-utilities.service';
import { UtilsService } from './utils.service';

export interface NoteFile {
  notes: Note[];
  attachments: any[];
  fileId: string;
  id: any;
  type: any;
  parentFolder: any;
}

export interface Note {
  title: any;
  note: any;
  createdBy: any;
  attachments: any[];
  noteId: string;
}

export interface NoteObject {
  attachments: any;
  name: string;
  description: string;
  useremail: string;
  noteId: string;
  visibility?: any;
}

@Injectable({
  providedIn: 'root',
})
export class FileCommentsAttachmentsService {
  constructor(
    private files_$: FilesService,
    private firebase_utilities_$: FirebaseUtilitiesService,
    private utils_$: UtilsService,
  ) {}

  private generateNoteId() {
    return this.utils_$.getRandomString_(10);
  }

  private getAttachmentLists(attachments, patientName) {
    return attachments ? this.firebase_utilities_$.storeNoteNewAttachments(attachments, patientName) : [];
  }

  generateNoteObject(name, description, useremail, newAttachments, noteFile, noteId, visibility, userName) {
    return {
      title: name,
      note: description,
      createdBy: useremail,
      attachments: newAttachments.concat(noteFile.attachments || []),
      noteId: noteId || this.generateNoteId(),
      visibility: visibility || { consultants: true, clients: true },
      datetime: new Date().toISOString(),
      fileId: noteFile.fileId,
      authorName: userName,
    };
  }

  async addCommentsAndAttachments(noteFile: NoteFile, note: NoteObject, patientName: string, userName: string) {
    const attachmentLists = await this.getAttachmentLists(note.attachments, patientName);
    const newAttachments = attachmentLists ? [].concat(attachmentLists) : [];
    noteFile.notes = noteFile.notes || [];
    const noteObject = this.generateNoteObject(
      note.name,
      note.description,
      note.useremail,
      newAttachments,
      noteFile,
      note.noteId,
      note.visibility,
      userName,
    );

    noteFile.notes.push(noteObject);

    await this.updateNote(noteFile.id, noteFile.notes);

    return {
      docId: noteFile.id,
      id: noteFile.fileId,
      type: noteFile.type,
      parentFolder: noteFile.parentFolder,
      noteId: noteObject.noteId,
    };
  }

  updateNote(noteFileId, noteFileNotes) {
    return this.files_$
      .updateFileByDocId(noteFileId, { notes: noteFileNotes })
      .then(() => {
        const message = 'Notes Added Successfully';
        this.toastMesaage(message);
        // this.uiMessaging_$.toastMessage('Notes Added Successfully', null);
        return message;
      })
      .catch(error => {
        this.toastMesaage(error.message);
        // this.uiMessaging_$.toastMessage(error.message, null);
        return error.message;
      });
  }

  toastMesaage(message) {
    // this.uiMessaging_$.toastMessage(error.message, null);
    console.log('message :', message);
  }

  getLastCommentsAndAttachments(fileClicked: any) {
    return this.firebase_utilities_$.getNotesByFileId(fileClicked.fileId);
  }
}
