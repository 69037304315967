import { Component } from '@angular/core';
@Component({
  selector: 'app-compare-plans-table',
  templateUrl: './compare-plans-table.component.html',
  styleUrls: ['./compare-plans-table.component.scss'],
})
export class ComparePlansTableComponent {
  constructor() {}
  prices = {
    free: '$0.00<sup>/mo</sup>',
    silver: '$19.95<sup>/mo</sup><br />$14.95 <sup>/mo (annual plan)</sup>',
    gold: '$24.95<sup>/mo</sup><br />$19.95 <sup>/mo (annual plan)</sup>',
    platinum: '$39.95<sup>/mo</sup><br />$34.95 <sup>/mo (annual plan)</sup>',
    lpmp: '$35.00<sup>/mo</sup><br />$30.00 <sup>/mo (annual plan)</sup>',
  };
  features = [
    {
      text: 'One (1) additional user- either associate or admin.',
      plans: {
        free: 1,
        silver: 0,
        gold: 0,
        platinum: 0,
        lpmp: 0,
      },
    },
    {
      text: 'Consultants: 3 at any one time.',
      plans: {
        free: 1,
        silver: 0,
        gold: 0,
        platinum: 0,
        lpmp: 0,
      },
    },
    {
      text: 'DICOM image disc upload and Cloud storage (medical imaging studies: (Limit 6 studies at any one time).',
      plans: {
        free: 1,
        silver: 0,
        gold: 0,
        platinum: 0,
        lpmp: 0,
      },
    },
    {
      text: 'ZOOM MEETINGS: 4/month.',
      plans: {
        free: 1,
        silver: 0,
        gold: 0,
        platinum: 0,
        lpmp: 0,
      },
    },
    {
      text: 'Each subscriber can simultaneously share same documents with one (1) administrator and/or one (1) associate of choice',
      plans: {
        free: 1,
        silver: 1,
        gold: 1,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: 'Add 1 additional user (associate or administrator).',
      plans: {
        free: 0,
        silver: 0,
        gold: 0,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: 'Download DICOM (duplicate medical imaging or discs).',
      plans: {
        free: 0,
        silver: 0,
        gold: 0,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: 'Unlimited number of consultants & experts.',
      plans: {
        free: 0,
        silver: 1,
        gold: 1,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: 'Unlimited Zoom Video conference time/session (300 participants/session).',
      plans: {
        free: 0,
        silver: 1,
        gold: 1,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: 'DICOM image disc upload and Cloud storage (medical imaging studies).',
      plans: {
        free: 1,
        silver: 1,
        gold: 1,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: 'Upload from Practice Management systems & Desktop.',
      plans: {
        free: 1,
        silver: 1,
        gold: 1,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: 'Document and video (MP4, MOV, AVI) upload and Cloud storage.',
      plans: {
        free: 1,
        silver: 1,
        gold: 1,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: 'Document, JPEG, photo, illustration, diagram, video upload and cloud storage.',
      plans: {
        free: 1,
        silver: 1,
        gold: 1,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: 'Built-in Zoom video conference feature with unlimited Video conference time and 300 participants/session.',
      plans: {
        free: 1,
        silver: 1,
        gold: 1,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: 'Built-in DICOM viewer for medical imaging studies.',
      plans: {
        free: 1,
        silver: 1,
        gold: 1,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: '2TB /month storage comes with plan** – Unlimited storage as needed.',
      plans: {
        free: 0,
        silver: 1,
        gold: 0,
        platinum: 0,
        lpmp: 0,
      },
    },
    {
      text: '4TB /month storage comes with plan** – Unlimited storage as needed.',
      plans: {
        free: 0,
        silver: 0,
        gold: 1,
        platinum: 0,
        lpmp: 0,
      },
    },
    {
      text: '5TB /month storage comes with plan** – Unlimited storage as needed.',
      plans: {
        free: 0,
        silver: 0,
        gold: 0,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: 'Client Upload.',
      plans: {
        free: 0,
        silver: 0,
        gold: 0,
        platinum: 1,
        lpmp: 1,
      },
    },
    {
      text: 'Video conference transcription (coming soon) – no extra charge.',
      plans: {
        free: 0,
        silver: 0,
        gold: 0,
        platinum: 2,
        lpmp: 2,
      },
    },
    {
      text: 'Voice Recognition (coming soon).',
      plans: {
        free: 0,
        silver: 0,
        gold: 0,
        platinum: 2,
        lpmp: 2,
      },
    },
  ];
}
