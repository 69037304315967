<p>
  <mat-icon color="warn">verified_user</mat-icon> Hey, it looks like you haven't set up your Two-factor Authentication.
  Would you like to do it now?
</p>
<div
  mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="space-between center">
  <button
    mat-flat-button
    (click)="handleClose(0)">
    NOT INTERESTED
  </button>
  <button
    mat-flat-button
    color="warn"
    (click)="handleClose(1)">
    START 2FA SETUP
  </button>
</div>
