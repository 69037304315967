import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-clio-list-documents',
  templateUrl: './ClioListDocuments.component.html',
  styles: [`.documents-list {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding-top: 0;
}`]
})
export class ClioListDocumentsComponent {
  @Input() documentsList = [];
  @Input() selectedMatter: any;
  @ViewChild('matSelectionList') matSelectionList: MatSelectionList;
  @Output() imporEvent = new EventEmitter<any>();
  importButtonsEnabled = true;

  constructor() { }

  importSelectedDocuments() {
    this.imporEvent.emit(this.matSelectionList.selectedOptions.selected);
  }
  importAllDocuments() {
    this.importButtonsEnabled = false;
    this.matSelectionList.selectAll();
    this.importSelectedDocuments();
  }

  handleChange(obj) {
    console.log(obj);
  }

}
