import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-client-matter-data-info',
  templateUrl: './client-matter-data-info.component.html',
  styleUrls: ['./client-matter-data-info.component.scss'],
})
export class ClientMatterDataInfoComponent {
  @Input() patient: any;
  plancode: any;
  constructor(private auth_$: AuthService) {
    this.auth_$.userData.subscribe(user => (this.plancode = user['plancode']));
  }
}
