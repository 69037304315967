import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';

import { AuthService } from './../../../services/auth.service';
import { FirebaseUtilitiesService } from './../../../services/firebase-utilities.service';
import { MattersService } from '../../../services/matters.service';

@Component({
  selector: 'app-clio-list-matters',
  templateUrl: './ClioListMatters.component.html',
  styleUrls: ['./ClioListMatters.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClioListMattersComponent implements OnInit {
  documents: any;
  caseName: any;
  selectedMatterDescription: string;
  userdocid: string;
  selectedMatter: { name: any; description: any; client: any };
  matterSelected = false;
  loader: any;
  matters: any[] = [];
  p_length = 100;
  p_pageSize = 10;
  p_pageSizeOptions = [5, 10, 25];
  mattersPaged: any[];
  pageEvent: PageEvent;
  p_pageIndex: any;

  constructor(
    private firebase_utilities_$: FirebaseUtilitiesService,
    private auth_$: AuthService,
    private http: HttpClient,
    private matters_$: MattersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<ClioListMattersComponent>,
  ) { }

  async ngOnInit() {
    this.caseName = this.data.currentCase;
    this.userdocid = await this.firebase_utilities_$.getUserDocId(this.auth_$.uid);
    this.matters = [...this.matters, ...this.data.matters];
    this.p_length = this.matters.length;
    this.mattersPaged = this.matters.slice(0, this.p_pageSize);
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.p_pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  async getClioDocuments(matterId: string, matterName, matterDescription, matterClientName) {
    this.documents = false;
    this.matterSelected = true;
    this.selectedMatter = { name: matterName, description: matterDescription, client: matterClientName };
    this.selectedMatterDescription = `${matterName}(${matterDescription})`;
    this.matters_$.getDocumentsByMatterId(matterId).then((res) => {
      this.showDocumentsList(res['data']['data']['data']);
    }).catch(err => {
      console.error(err);
      this.showDocumentsList([]);
    });
  }

  async getMatterClient(matter_id) {
    const url = environment.constants.cloudfunctionsURL + 'clio-getClient';
    const userdocid = this.userdocid;
    const res = await this.http.post(url, { userdocid, matter_id }).toPromise();
    return res['data'].name;
  }

  showDocumentsList(data) {
    console.log('Documents', data);
    this.documents = data;
  }

  getClioFolderId() {
    return this.firebase_utilities_$.getFolder('clio', this.caseName);
  }

  handlePageEvent(event) {
    this.p_length = event.length;
    this.p_pageSize = event.pageSize;
    this.p_pageIndex = event.pageIndex;
    const sliceInit = this.p_pageIndex * this.p_pageSize;
    this.mattersPaged = this.matters.slice(sliceInit, sliceInit + this.p_pageSize);
    for (let index = 0; index < this.mattersPaged.length; index++) {
      const matter = this.mattersPaged[index];
      this.getMatterClient(matter.id)
        .then(clientName => (matter.client = clientName))
        .catch(err => console.error(err));
    }
  }

  async handleImportDocuments(event) {
    this.auth_$.showLoader('Importing documents...');
    const parentFolderId = await this.getClioFolderId();
    const url = `${environment.constants.cloudfunctionsURL}clio-downloadDocumentsV2`;
    const postBody = {
      userdocid: this.auth_$.userData.getValue()['id'],
      filesToDownload: this.buildFilesToDownload(event, new Date(), parentFolderId),
      patientCaseName: this.caseName
    };

    const handleResponse = res => {
      const { message } = res;
      if (message === 'OK') {
        this.dialog.close('IMPORTED');
      }
    };

    const observer = {
      next: res => {
        this.auth_$.hideLoader();
        handleResponse(res);
      },
      error: err => console.error(err),
      completed: console.log('Observer Completed'),
    };

    this.http.post(url, postBody).subscribe(observer);
  }

  getFileDate(date) {
    return `${date.getMonth() > 8 ? date.getMonth() + 1 : date.getMonth() + 1}/${date.getDate() > 9 ? date.getDate() : date.getDate()}/${date.getFullYear()}`
  }

  getFileId(date) {
    return `${date.getTime().toString()}_${this.firebase_utilities_$.getRandomString(6)}_${this.caseName}`
  }

  buildFilesToDownload(event, date, parentFolderId) {
    const files = [];
    event.forEach(item => {
      const element = item._element.nativeElement;
      files.push({
        filename: element['data-filename'],
        docId: element['data-id'],
        fileId: this.getFileId(date),
        fileDate: this.getFileDate(date),
        userId: this.auth_$.uid,
        fileDesc: this.selectedMatterDescription,
        parentFolderId: parentFolderId,
        updated_at: element['data-updated_at'],
        created_at: element['data-created_at'],
        uploadedDate: date.toString(),
      });
    });
    return files;
  }
}
