import { Injectable } from '@angular/core';
import algoliasearch from 'algoliasearch';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AlgoliaService {
  algoliaObjects: any[] = [];
  public algoliaSearchResults: any;

  async searchAlgolia(queryString: string, casename: string, folderId?): Promise<void> {
    const algoliaAppId = environment.config.algolia.appId;
    const algoliaApiKey = environment.config.algolia.apiKey;
    const filesIndex = environment.config.algolia.filesIndex;
    const client = algoliasearch(algoliaAppId, algoliaApiKey);
    const index = client.initIndex(filesIndex);
    return index
      .search(queryString, {
        attributesToRetrieve: [
          'fileName',
          'fileId',
          'fileDesc',
          'fdate',
          'ftype',
          'type',
          'notes',
          'forDelete',
          'type',
          'belongsTo',
          'folderId',
          'uploadedDate',
          'lastModified',
          'parentFolder',
          'parentFolderName',
          'viewerurl',
        ],
        hitsPerPage: 1000,
      })
      .then(({ hits }) => {
        if (!folderId || folderId === 'all') {
          this.algoliaSearchResults = hits
            .filter(el => el['type'] !== 'folder')
            .filter(el => el['forDelete'] === false)
            .filter(el => el['belongsTo'] === casename);
        } else {
          this.algoliaSearchResults = hits
            .filter(el => el['type'] !== 'folder')
            .filter(el => el['forDelete'] === false)
            .filter(el => el['belongsTo'] === casename);
        }
      });
  }

  storeToAlgolia() {
    const algoliaAppId = environment.config.algolia.appId;
    const algoliaApiKey = environment.config.algolia.apiKey;
    const filesIndex = environment.config.algolia.filesIndex;
    const client = algoliasearch(algoliaAppId, algoliaApiKey);
    const index = client.initIndex(filesIndex);
    return index.saveObjects(this.algoliaObjects, { autoGenerateObjectIDIfNotExist: true }).then(({ objectIDs }) => {
      this.clearLocalAlgoliaObjects();
      console.log('The Algolia objectIDs that has been updated are', objectIDs);
    });
  }

  clearLocalAlgoliaObjects() {
    this.algoliaObjects = [];
  }

  deleteManyFromAlgolia(fileIds: string[]) {
    const algoliaAppId = environment.config.algolia.appId;
    const algoliaApiKey = environment.config.algolia.apiKey;
    const filesIndex = environment.config.algolia.filesIndex;
    const client = algoliasearch(algoliaAppId, algoliaApiKey);
    const index = client.initIndex(filesIndex);
    return index.deleteObjects(fileIds);
  }
}
