import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { FolderButton } from '../../models/FolderButton';
import { FirebaseUtilitiesService } from './../../services/firebase-utilities.service';
import { UtilsService } from './../../services/utils.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-choose-target-directory',
  styleUrls: ['./choose-target-directory.component.scss'],
  templateUrl: './choose-target-directory.component.html',
})
export class ChooseTargetDirectoryComponent implements OnInit {
  predefinedFolders: FolderButton[];
  toggleCustomFolders: boolean;
  selectedFolder: string;
  caseName: string;

  @Input() casename: string;
  @Input() currentFolder: any;
  @Output() selectFolderEv = new EventEmitter<string>();
  @Output() selectFolderAndQuitEv = new EventEmitter<string>();
  @Output() justQuit = new EventEmitter<string>();
  customFolderFilter: string;
  eventsSubject: Subject<void> = new Subject<void>();
  ownerHasAccess: boolean = false;

  @HostListener('document:click', ['$event.target'])
  clickOutside(ex) {
    if ((<HTMLElement>ex).innerText === 'keyboard_arrow_right' || (<HTMLElement>ex).className === 'arrow-button')
      return false;
    else if (
      this.predefinedFolders.length > 0 &&
      (<HTMLElement>ex).id !== 'foldernameValue' &&
      !(<HTMLElement>ex).classList.contains('search-folder')
    )
      this.justQuit.emit();
  }

  constructor(
    private firebase_$: FirebaseUtilitiesService,
    private utils_$: UtilsService,
    private auth_$: AuthService,
  ) {}

  ngOnInit() {
    this.auth_$.doesOwnerHasAccess().then(res => {
      this.ownerHasAccess = res;
    });
    this.toggleCustomFolders = false;
    this.predefinedFolders = [];

    this.getPredefinedFolders();
  }

  /**
   * Function to sort alphabetically an array of objects by some specific key.
   * @param property Key of the object to sort.
   */
  dynamicSort(property: string) {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  emitEventToChild() {
    this.eventsSubject.next();
  }

  getButtonStyle(name) {
    return { ...this.utils_$.getButtonStyle(name) };
  }

  getButtonClassName(name): string {
    return `boxed-button-${name}`;
  }

  async getPredefinedFolders() {
    this.firebase_$.getPredefinedFolders(this.casename, this.ownerHasAccess).then(predefinedFolders => {
      let folderId, folderName;
      const sortedFolders = predefinedFolders.sort(this.dynamicSort('name'));
      const localArray = [];
      sortedFolders.forEach(item => {
        localArray.push({
          label: item.name,
          color: item.color || 'lightgray',
          type: 'normal',
          folderId: item.folderId,
        });
        if (this.currentFolder.folderId === item.folderId) {
          folderId = item.folderId;
          folderName = item.name;
        }
      });
      this.predefinedFolders = localArray;
    });
  }

  handleCreateAndSelect(ev) {}

  handleSearchFolder(event, value) {
    this.customFolderFilter = event.currentTarget.value;
    this.emitEventToChild();
    if (value === '') this.getPredefinedFolders();
    else
      this.predefinedFolders = this.predefinedFolders.filter(
        folder => folder.label.toLowerCase().indexOf(value.toLowerCase()) === 0,
      );
  }

  handleSelectFolder(event, folderObj): void {
    if (!event) {
      this.selectFolder(folderObj);
    } else {
      this.selectFolderAndQuit(folderObj);
    }
  }

  randomHexColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  selectFolder(folderObj: any): void {
    this.selectedFolder = folderObj.folderId;
    this.selectFolderEv.emit(Object.entries(folderObj).join('*'));
  }

  selectFolderAndQuit(folderObj: any): void {
    this.selectedFolder = folderObj.folderId;
    this.selectFolderAndQuitEv.emit(Object.entries(folderObj).join('*'));
  }
}
