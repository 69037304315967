import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { UtilsService } from './../../services/utils.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnChanges, OnInit {
  @Input() currentFolder = '';
  @Input() folderColor = '';
  @Input() currentFolderName = '';
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  folderColors: {
    INDEX: string;
    Damages: string;
    ClientUpload: string;
    Discovery: string;
    Liability: string;
    Other: string;
    custom: string;
  };
  folderAliases: any[];
  currentFolderLabel: string;
  constructor(private utils_$: UtilsService) {}

  ngOnInit() {
    // Define folder aliases
    this.folderAliases = [{ name: 'Discs', label: 'Discs files' }];

    // Find the label for the current folder, if it exists in the aliases
    // If it doesn't exist, use the folder name as the label
    this.currentFolderLabel =
      this.folderAliases?.find(f => f.name === this.currentFolderName)?.label || this.currentFolderName;
    // console.log('this.currentFolderLabel: ', this.currentFolderLabel);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentFolderName) {
      this.currentFolderLabel =
        this.folderAliases?.find(f => f.name === this.currentFolderName)?.label || this.currentFolderName;
    }
  }

  getButtonStyle(name) {
    return this.utils_$.getButtonStyle(name);
  }

  handleGo(target: string) {
    this.buttonClick.emit(target);
  }
}
