import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderComponent } from 'src/app/loader/loader.component';

import { AuthService } from '../../services/auth.service';

@Component({
  template: `
    <app-window-title
      [title]="'Forgot Password'"
      [closable]="true"></app-window-title>
    <form
      [formGroup]="forgotEmail"
      (ngSubmit)="onSubmit()">
      <div mat-dialog-content>
        <div
          fxLayout="column"
          fxLayoutAlign="center center">
          <p>Enter your email and we will send you a password reset link</p>
          <mat-form-field
            color="warn"
            class="full-width"
            appearance="fill">
            <mat-label>Email</mat-label>
            <input
              formControlName="email"
              color="warn"
              placeholder="Enter a valid email address"
              matInput
              required />
            <mat-error *ngIf="emailHasError()">
              {{ getErrorMessage() }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <button
        type="button"
        [disabled]="forgotEmail.controls.email.invalid || loading"
        type="submit"
        mat-flat-button
        color="warn">
        RESET PASSWORD
      </button>
    </form>
  `,
  styles: [
    `
      .reset-card {
        height: 350px;
        width: 300px;
      }

      .email-reset {
        width: 80%;
        margin-top: 10px;
      }

      .sign-in-text {
        font-family: Roboto, sans-serif;
        text-align: center;
      }

      .submission-button {
        margin-top: 10px;
      }

      .full-width {
        width: 100%;
      }

      .dialog-actions {
        margin-bottom: 0px;
      }
    `,
  ],
})
export class FgpasswordComponent implements OnInit {
  errorMessage: string;
  loading: boolean;
  loader: MatDialogRef<LoaderComponent, any>;
  forgotEmail = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(public auth_$: AuthService, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.forgotEmail.controls.email.setValue(this.data.email);
  }

  emailHasError() {
    return this.forgotEmail.controls.email.invalid;
  }

  getErrorMessage(): string {
    if (this.forgotEmail.controls.email.hasError('required')) return 'You must enter a value';
    return this.forgotEmail.controls.email.hasError('email') ? 'Not a valid email' : '';
  }

  onSubmit() {
    this.auth_$.showLoader('Sending password reset email...');
    this.loading = true;
    const emailValue = new String(this.forgotEmail.controls.email.value);
    this.auth_$.passwordForgetEmail(emailValue.trim().toLowerCase()).then(() => {
      this.auth_$.hideLoader();
      this.loading = false;
    });
  }
}
