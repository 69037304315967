import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-details',
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.scss'],
})
export class PlanDetailsComponent implements OnInit {
  plans: any;

  constructor() {
    this.plans = [
      {
        label: 'SILVER',
        content: `<li class="list-group-item normal-row">
    Each subscriber can simultaneously share same documents with one (1) administrator and/or one (1) associate of
    choice
  </li>
  <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
  <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
  <li class="list-group-item normal-row">DICOM image disc upload and Cloud Storage (medical imaging studies).</li>
  <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
  <li class="list-group-item normal-row">Document and video (MP4, MOV, AVI) upload and Cloud storage</li>
  <li class="list-group-item normal-row">HIPAA Compliant Storage.</li>
  <li class="list-group-item normal-row">
    Built-in Zoom video conference feature with unlimited Video conference time and 300 participants/session
  </li>
  <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
  <li class="list-group-item normal-row">Video conference recording and storage</li>
  <li class="list-group-item normal-row">2TB /month storage comes with plan** Unlimited storage as needed</li>`,
        price: `<span class="currency">$</span><span class="integer-part">19</span><sup class="decimal-part">95</sup
      ><span class="time">mo</span>`,
      },
      {
        label: 'GOLD',
        content: `<li class="list-group-item normal-row">
    Each subscriber can simultaneously share same documents with one (1)
    administrator and one (1) associate of choice
  </li>
  <li class="list-group-item normal-row">
    Owner (subscriber) can add additional users.
  </li>
  <li class="list-group-item normal-row">
    Unlimited number of consultants/experts
  </li>
  <li class="list-group-item normal-row">
    DICOM image disc upload and Cloud storage (medical imaging studies)
  </li>
  <li class="list-group-item normal-row">
    Upload from Practice Management systems &amp; Desktop
  </li>
  <li class="list-group-item normal-row">
    Document and video (MP4, MOV, AVI) upload and Cloud storage
  </li>
  <li class="list-group-item normal-row">HIPAA Compliant Storage</li>
  <li class="list-group-item normal-row">
    Built-in Zoom video conference feature with unlimited Video conference time
    and 300 participants/session
  </li>
  <li class="list-group-item normal-row">
    Built-in DICOM viewer for medical imaging studies
  </li>
  <li class="list-group-item normal-row">
    Video conference recording and storage
  </li>
  <li class="list-group-item normal-row">
    4TB /month storage comes with plan** Unlimited storage as needed
  </li>`,
        price: `<span class="currency">$</span><span class="integer-part">24</span
    ><sup class="decimal-part">95</sup><span class="time">mo</span>`,
      },
      {
        label: 'PLATINUM',
        content: `<li class="list-group-item normal-row">
    Each subscriber can simultaneously share same documents with one (1)
    administrator and one (1) associate of choice
  </li>
  <li class="list-group-item normal-row">
    Owner (subscriber) can add additional users.
  </li>
  <li class="list-group-item normal-row">
    Unlimited number of consultants/experts
  </li>
  <li class="list-group-item normal-row">
    DICOM image disc upload and Cloud storage (medical imaging studies)
  </li>
  <li class="list-group-item normal-row">
    Upload from Practice Management systems &amp; Desktop
  </li>
  <li class="list-group-item normal-row">
    Document and video (MP4, MOV, AVI) upload and Cloud storage
  </li>
  <li class="list-group-item normal-row">HIPAA Compliant Storage</li>
  <li class="list-group-item normal-row">
    Built-in Zoom video conference feature with unlimited Video conference time
    and 300 participants/session
  </li>
  <li class="list-group-item normal-row">
    Built-in DICOM viewer for medical imaging studies
  </li>
  <li class="list-group-item normal-row">
    Video conference recording and storage
  </li>
  <li class="list-group-item normal-row">
    5TB /month storage comes with plan** Unlimited storage as needed
  </li>
  <li class="list-group-item normal-row">
    Remote Client upload â€“ discs and documents
  </li>
  <li class="list-group-item normal-row">
    Video conference transcription (*coming soon) â€“ no extra charge
  </li>
  <li class="list-group-item normal-row">
    Voice recognition (*coming soon) â€“ no extra charge
  </li>`,
        price: ` <span class="currency">$</span><span class="integer-part">49</span
    ><sup class="decimal-part">95</sup><span class="time">mo</span>`,
      },
    ];
  }

  ngOnInit(): void {}
}
