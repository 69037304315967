<div fxLayout="row">
  <div
    fxLayout="column"
    fxFlex="5">
    <button
      *ngIf="isRestrictedProfile(consultant); else notRestrictedProfile"
      (click)="makeProfilePublic($event, consultant)"
      mat-icon-button
      matTooltip="Click to toggle Visibility profile">
      <mat-icon color="warn">toggle_off</mat-icon>
    </button>
    <ng-template #notRestrictedProfile>
      <button
        (click)="makeProfileRestricted($event, consultant)"
        mat-icon-button
        matTooltip="Click to toggle Visibility profile">
        <mat-icon>toggle_on</mat-icon>
      </button>
    </ng-template>
  </div>

  <div
    fxLayout="column"
    fxFlexOffset="5"
    fxFlex>
    <div [ngClass]="'mat-line small-font'">
      <b [ngStyle]="{ color: consultant.clientMatter === caseName ? 'red' : '' }">{{ consultant.name }}</b>
      <mat-icon
        class="person-icon"
        *ngIf="consultant.clientMatter === caseName"
        color="warn"
        matTooltip="Client Profile Account user"
        >person</mat-icon
      >
      <span
        class="client-user-label"
        *ngIf="consultant.clientMatter === caseName"
        >(Client user)</span
      >
      <mat-icon
        *ngIf="consultant.highlighted"
        style="margin-left: 5px; vertical-align: middle; display: inline-block"
        aria-hidden="false"
        color="warn"
        [ngClass]="'file-icon'"
        aria-label="The file is shared with this consultant."
        matTooltip="The file is shared with this consultant.">
        people</mat-icon
      >
    </div>

    <div [ngClass]="'mat-line small-font'">
      {{ consultant.email }}
    </div>

    <div [ngClass]="'mat-line small-font'">
      <span>
        {{
          isRestrictedProfile(consultant)
            ? 'This user can not see file comments and attachments.'
            : 'This user can see file comments and attachments.'
        }}
      </span>
    </div>

    <div
      style="margin-top: 10px"
      fxLayout="column"
      *ngIf="
        (selected && checkSelected(consultant)) || (selected && selectedFiles.length > 1 && !checkSelected(consultant))
      "
      fxFlex>
      <button
        mat-flat-button
        color="warn"
        [ngClass]="'small-font'"
        (click)="$event.stopPropagation(); unShare(consultant)"
        matTooltip="Stop sharing selected files with this consultant."
        *ngIf="selected && checkSelected(consultant)">
        Unshare selected
      </button>

      <button
        mat-flat-button
        color="warn"
        matTooltip="Share selected files with this consultant."
        [ngClass]="'small-font'"
        (click)="$event.stopPropagation(); share(consultant)"
        *ngIf="selected && selectedFiles.length > 1 && !checkSelected(consultant)">
        Share selected
      </button>
    </div>

    <div
      *ngIf="sharedFilesCount === 0 && selected"
      class="note">
      This user has no shared files in this folder. To see the files shared with this consultant go to the
      <button
        (click)="goToIndex($event)"
        class="small-font red-background">
        INDEX
      </button>
      folder.
    </div>
  </div>
</div>
