import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-owner',
  templateUrl: './select-owner.component.html',
  styleUrls: ['./select-owner.component.scss'],
})
export class SelectOwnerComponent {
  title: string;
  owners: { key: string; label: string; value: string }[];
  selectOwnerForm = new FormGroup({
    owner: new FormControl('', Validators.required),
  });
  message: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.owners = data.owners;
    this.message = data.message;
  }

  onSubmit() {}

  onNoClick(owner): void {
    console.log(owner);
    this.data.dialogRef.close();
  }
}
