<app-window-title
  [title]="data.title"
  [closable]="true"></app-window-title>
<mat-selection-list #list>
  <mat-list-option
    *ngFor="let item of data.items"
    [value]="item.id">
    {{ item.filename }}
  </mat-list-option>
</mat-selection-list>
<mat-dialog-actions *ngIf="data.buttons.length">
  <div *ngFor="let button of data.buttons">
    <button
      mat-flat-button
      color="warn"
      (click)="onButtonClick(button.value)">
      {{ button.text }}
    </button>
  </div>
</mat-dialog-actions>
