import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

import { LogLevelsDictionary } from '../../dictionaries/LogLevels';
import { AlgoliaService } from '../../services/algolia.service';
import { LogService } from './../../log.service';

@Component({
  template: `
    <app-window-title
      [title]="'Search for files'"
      [closable]="true"
      [color]="'warn'"></app-window-title>
    <mat-form-field
      class="full-width"
      color="warn"
      appearance="fill">
      <mat-label>Enter keywords</mat-label>
      <input
        matInput
        #textSearch
        (keyup.enter)="handleFilesSearch(textSearch.value)"
        placeholder="Enter a text to search" />
    </mat-form-field>
    <p [ngClass]="'mat-body'">Search criterias:</p>
    <ul [ngClass]="'mat-body'">
      <li>By file name.</li>
      <li>By file extension. (e.g. <i>.jpg</i>)</li>
      <li>By file content. (e.g. <i>some text</i>)</li>
      <li>By date of file. (e.g. <i>12/12/2020</i>)</li>
    </ul>
    <button
      mat-flat-button
      color="warn"
      (click)="handleFilesSearch(textSearch.value)">
      SEARCH
    </button>
  `,
  styles: ['.mat-button-close { position: absolute; right: 20px; top:20px }', '.full-width { width: 100% }'],
})
export class SearchFilesComponent {
  casename: string;
  folderId: any;
  @Output() updateResultsEvent = new EventEmitter<boolean>();
  constructor(
    public dialogRef: MatDialogRef<SearchFilesComponent>,
    private algolia_$: AlgoliaService,
    private log_$: LogService,
    private auth_$: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.casename = data.casename;
    this.folderId = data.folder;
  }

  handleFilesSearch(searchtext: string) {
    this.log_$.storeLog(
      this.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      { text: searchtext },
      'Search',
      this.casename,
    );
    this.algolia_$
      .searchAlgolia(searchtext, this.casename, this.folderId)
      .then(() => this.EmitUpdateFilesResults())
      .then(() => this.dialogRef.close());
  }

  EmitUpdateFilesResults() {
    this.updateResultsEvent.emit(true);
  }
}
