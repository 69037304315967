import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import firebase from 'firebase/app';

import { DialogService } from 'src/app/dialog.service';
import { NotificationTypes } from 'src/app/models/NotificationTypes';
import { NotificationsService } from 'src/app/notifications.service';
import { environment } from 'src/environments/environment';
import { FirebaseUtilitiesService } from '../../../services/firebase-utilities.service';
import { EditNoteComponent } from '../../edit-note/edit-note.component';
import { ViewNoteComponent } from '../../view-note/view-note.component';
import { ViewAllNotesDialogData } from 'src/app/models/ViewAllNotesDialogData';
import { DomSanitizer } from '@angular/platform-browser';
import { FileCommentsAttachmentsService } from 'src/app/services/file-comments-attachments.service';

export interface Note {
  title: any;
  note: any;
  createdBy: any;
  attachments: any[];
  noteId: string;
}

export interface NoteFile {
  notes: Note[];
  attachments: any[];
  fileId: string;
  id: any;
  type: any;
  parentFolder: any;
}

@Component({
  templateUrl: './all-notes-dialog.component.html',
  styleUrls: ['./all-notes-dialog.component.scss'],
})
export class AllNotesDialogComponent implements OnInit {
  title: string;
  message: string;
  selected_files: any[];
  notes: any;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[];
  fileid: any;
  patientName: any;
  currentFolder: any;
  currentFolderName: any;
  fileDocId: any;
  fileType: any;
  parentFolder: any;
  notifications: any;
  userRole: any;
  userEmail: string;
  restrictedProfiles: any;
  allowedProfiles: any;
  currentUserUID: any;
  fileViewData: any;
  viewerUrl: any;
  fileClicked: any;

  constructor(
    private dialogRef: MatDialogRef<AllNotesDialogComponent>,
    private firebaseUtilities_$: FirebaseUtilitiesService,
    public snackBar: MatSnackBar,
    private notifications_$: NotificationsService,
    private dialog_$: DialogService,
    private sanitizer: DomSanitizer,
    private fileCommentsAttachments_$: FileCommentsAttachmentsService,
    @Inject(MAT_DIALOG_DATA) data: ViewAllNotesDialogData,
  ) {
    const {
      title,
      message,
      selected_files,
      notes,
      fileid,
      fileViewData,
      fileDocId,
      fileType,
      parentFolder,
      patientName,
      currentFolder,
      currentFolderName,
      allowedProfiles,
      restrictedProfiles,
      notifications,
      userRole,
      userEmail,
      fileClicked,
    } = data;
    this.displayedColumns = ['name', 'description', 'createdby', 'attachments', 'delete'];
    this.title = title;
    this.fileViewData = fileViewData;
    this.patientName = patientName;
    this.currentFolder = currentFolder;
    this.currentFolderName = currentFolderName;
    this.message = message;
    this.selected_files = selected_files;
    this.userRole = userRole;
    this.restrictedProfiles = restrictedProfiles;
    this.allowedProfiles = allowedProfiles;
    this.currentUserUID = this.firebaseUtilities_$.getCurrentUserUID();
    this.userEmail = userEmail;
    this.notes = this.filterByUserProfile(
      this.applyNotifications(this.adaptLegacyNotes(notes), notifications, fileid),
      this.currentUserUID,
      restrictedProfiles,
      allowedProfiles,
    );
    this.dataSource = new MatTableDataSource<any>(this.notes);
    this.fileid = fileid;
    this.fileDocId = fileDocId;
    this.fileType = fileType;
    this.parentFolder = parentFolder;
    this.notifications = notifications;
    this.fileClicked = fileClicked;
  }

  ngOnInit() {
    this.viewerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileViewData.viewerUrl);
  }

  deleteNoteHandler(ev: any, note: any) {
    ev.stopPropagation();
    ev.preventDefault();
    this.notes = this.notes.filter((n: { noteId: any }) => n.noteId !== note.noteId);
    this.dataSource = new MatTableDataSource<any>(this.notes);
    if (this.dataSource.data.length === 0) this.message = 'This file has 0 notes attached.';
    this.firebaseUtilities_$.updateNotesByFile(this.fileid, this.notes);
  }

  jsonParse(jsonString) {
    return jsonString ? JSON.parse(jsonString) : {};
  }

  browseFile(path: string) {
    firebase
      .storage()
      .ref()
      .child(path)
      .getDownloadURL()
      .then(url => window.open(url, '_blank'));
  }

  editNoteHandler(ev: any, idx: string | number) {
    const dialogConfig = new MatDialogConfig();
    this.dialog_$.dialog.openDialogs.pop();

    dialogConfig.width = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxHeight = '80vw';

    const { title, note, createdBy, attachments, noteId, visibility } = this.notes[idx];

    dialogConfig.data = {
      title,
      createdBy,
      note,
      noteId,
      index: idx,
      attachments,
      patientName: this.patientName,
      fileDocId: this.fileDocId,
      fileType: this.fileType,
      fileId: this.fileid,
      parentFolder: this.parentFolder,
      userRole: this.userRole,
      visibility: visibility || { clients: true, consultants: true },
    };

    const dialogRef = this.dialog_$.open(EditNoteComponent, dialogConfig);

    dialogRef.keydownEvents().subscribe(e => {
      if (e.key === 'Escape') {
        e.preventDefault();
        dialogRef.close({ currentFolder: this.currentFolder, currentFolderName: this.currentFolderName });
      }
    });

    dialogRef
      .afterClosed()
      .toPromise()
      .then(result => {
        if (!result) return false;
        if (result.type === 'save') this.saveEditedNote(result);
      });
  }

  close() {
    this.dialogRef.close({ currentFolder: this.currentFolder, currentFolderName: this.currentFolderName });
  }

  private async handleNewAttachments(newAttachments: any[], note: { attachments: any[] }) {
    // NOTE: Store new files and get file paths.
    const attachmentLists = await this.firebaseUtilities_$.storeNoteNewAttachments(newAttachments, this.patientName);
    const oldAttachments = note.attachments || [];
    const localNewAttachments = attachmentLists ? oldAttachments.concat(attachmentLists) : [];
    return localNewAttachments;
  }

  async saveEditedNote(result: {
    newAttachments: any;
    fileId: any;
    noteIndex: any;
    note: any;
    patientName: any;
    parentFolder: any;
    fileType: any;
  }) {
    const { newAttachments, fileId, noteIndex, note, patientName, parentFolder, fileType } = result;
    if (newAttachments.length) note.attachments = await this.handleNewAttachments(newAttachments, note);
    this.notes[noteIndex] = note;
    await this.firebaseUtilities_$.updateNotesByFile(this.fileid, this.notes);

    this.firebaseUtilities_$.createNotifications(
      { id: fileId },
      { type: fileType === 'file' ? 1 : 2, uploadBlock: { casename: patientName } },
      { parentFolderId: parentFolder },
      NotificationTypes.modifiednote,
    );

    this.dataSource = new MatTableDataSource<any>(this.notes);
  }

  applyNotifications(notes: any[], notifications: { noteId: any }[], fileid: any) {
    if (notifications.length === 0) return notes;
    const notificationNoteIds = notifications.map((notification: { noteId: any }) => notification.noteId);
    const newNotes = [];
    if (environment.config.notifications === 0) return notes;
    notes.forEach(note =>
      notificationNoteIds.find(n => n === note.noteId)
        ? newNotes.push({ ...note, hasNotifications: true })
        : newNotes.push({ ...note, hasNotifications: false }),
    );

    return newNotes;
  }

  substringFileName(path: string) {
    if (typeof path !== 'string') return '';
    if (!path || path === '') return '';
    const splitted = path.split('/');
    if (splitted.length === 1) return splitted[0];
    return splitted[3];
  }

  cleanDataSource(dataSource: { data: any[] }) {
    this.dataSource = new MatTableDataSource<any>(dataSource.data);
  }

  async cleanUserDataNotifications(userId: any, fileId: any, pendingNotifications = []) {
    // this.notifications_$.getNotificationsByUserId(userId)

    if (pendingNotifications.length > 0) {
      this.firebaseUtilities_$.cleanUserDataNotifications(userId, fileId, [
        NotificationTypes.modifiednote,
        NotificationTypes.newnote,
      ]);
    }
  }

  //FIXME: This method has not been tested.
  private cleanDataSourceNotifications(dataSource: MatTableDataSource<any>, pendingNotifications: any[]) {
    this.dataSource = new MatTableDataSource<any>(
      dataSource.data.map(note => {
        const noteNotifications = pendingNotifications.filter(notification => notification.noteId === note.id);
        return { ...note, hasNotifications: noteNotifications.length > 0 };
      }),
    );
  }

  private async updateFilesArrayNotifications(fileId: string) {
    const pendingNotifications = (await this.notifications_$.getNotificationsByFileId(fileId)).docs
      .map(doc => doc.data())
      .filter(notification => notification.userId === this.currentUserUID)
      .filter(notification => [NotificationTypes.modifiednote, NotificationTypes.newnote].includes(notification.type));
    this.cleanUserDataNotifications(this.currentUserUID, fileId);
    this.cleanDataSourceNotifications(this.dataSource, pendingNotifications);
  }

  private handleDeleteNotifications(fileElement: { fileId: any }) {
    const { fileId } = fileElement;
    this.notifications_$
      .deleteNoteNotificationsByFileIdAndUserId(fileId, this.firebaseUtilities_$.getCurrentUserUID())
      .then(() => this.updateFilesArrayNotifications(fileId));
  }

  private getViewNotesComponentData({ title, createdBy, note, attachments }) {
    return { title, createdBy, note, attachments, patientName: this.patientName };
  }

  viewNoteHandler(ev: any, idx: any, element) {
    this.handleDeleteNotifications({ ...element, fileId: this.fileid });

    const dialogConfig = new MatDialogConfig();
    this.dialog_$.dialog.openDialogs.pop();
    dialogConfig.width = '50vw';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.getViewNotesComponentData(element);

    const dialogRef = this.dialog_$.open(ViewNoteComponent, dialogConfig);

    dialogRef.keydownEvents().subscribe(e => {
      if (e.key === 'Escape') {
        e.preventDefault();
        dialogRef.close({ currentFolder: this.currentFolder, currentFolderName: this.currentFolderName });
      }
    });

    dialogRef
      .afterClosed()
      .toPromise()
      .then(result => this.viewNoteCloseHandler(result));
  }

  private adaptLegacyNotes(notes: any[]) {
    console.log('notes', notes);
    notes.forEach(note => {
      if (typeof note.attachments === 'string') {
        note.attachments = JSON.parse(note.attachments);

        note.attachments =
          note.attachments.length < 1
            ? []
            : note.attachments
                .map(attachment => {
                  if (typeof attachment === 'object' && attachment.toString().substring(0, 1) !== '/') return false;
                  if (typeof attachment === 'string') return { description: '', filePath: attachment };
                  else return attachment;
                })
                .filter(u => u !== false);
      }
    });
    return notes;
  }

  private viewNoteCloseHandler(result) {
    if (!result) return false;
    if (result.type === 'save') this.saveEditedNote(result);
  }

  private filterByUserProfile(
    notes: any[],
    currentUserUID: string,
    restrictedProfiles: string[],
    allowedProfiles: string[],
  ) {
    if (!restrictedProfiles || restrictedProfiles.length === 0 || allowedProfiles.includes(currentUserUID))
      return notes;

    if (restrictedProfiles.includes(currentUserUID)) return notes.filter(note => note.createdBy === this.userEmail);
    else return notes;
  }

  markNoteAsViewed(event) {
    const { ev, idx, note } = event;

    if (note.hasNotifications) {
      this.handleDeleteNotifications({ ...note, fileId: this.fileid });
      this.notes[idx].hasNotifications = false;
      this.dataSource = new MatTableDataSource<any>(this.notes);
    } else return false;
  }

  addCommentAndAttachments(data) {
    const { commentText, attachments } = data;
    const noteFile = this.fileClicked;
    const noteObject = this.generateNoteObject(commentText, attachments || [], this.userEmail);
    this.fileCommentsAttachments_$
      .addCommentsAndAttachments(noteFile, noteObject, this.patientName, this.userEmail)
      .then(() => {
        this.snackBar.open('Comment added successfully', 'Close', {
          duration: 2000,
        });
        this.getLastCommentsAndAttachments();
      });
  }

  private generateNoteObject(commentText, attachments, userEmail) {
    return {
      attachments,
      name: '',
      description: commentText,
      useremail: userEmail,
      noteId: '',
      visibility: { clients: true, consultants: true },
    };
  }

  private buildNoteFile(attachments): NoteFile {
    return {
      notes: this.notes || [],
      id: this.fileDocId,
      fileId: this.fileid,
      type: this.fileType,
      parentFolder: this.parentFolder,
      attachments: attachments || [],
    };
  }

  private async getLastCommentsAndAttachments() {
    const latestNotes = await this.fileCommentsAttachments_$.getLastCommentsAndAttachments(this.fileClicked);
    this.notes = this.filterByUserProfile(
      this.applyNotifications(this.adaptLegacyNotes(latestNotes), this.notifications, this.fileid),
      this.currentUserUID,
      this.restrictedProfiles,
      this.allowedProfiles,
    );
  }
}
