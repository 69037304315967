import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UIMessagingService } from 'src/app/services/uimessaging.service';

@Component({
  selector: 'app-note-dialog',
  template: `
    <app-window-title
      [closable]="true"
      [subtitle]="'Filename: ' + fileName"
      [title]="title"></app-window-title>
    <mat-dialog-content>
      <div fxLayout="column">
        <mat-form-field
          class="display-block hidden-component"
          appearance="fill">
          <mat-label>Note name</mat-label>
          <input
            [(ngModel)]="this.name"
            matInput
            placeholder="Ex. Note name"
            (ngModelChange)="this.name = $event" />
        </mat-form-field>

        <mat-form-field
          class="display-block"
          appearance="fill">
          <mat-label>Comment</mat-label>
          <textarea
            (ngModelChange)="this.description = $event"
            matInput
            [(ngModel)]="this.description"
            rows="4"
            placeholder="Ex. This is my note content..."></textarea>
        </mat-form-field>

        <div
          class="hidden"
          *ngIf="['Owner', 'Associate'].includes(userRole)">
          <h5 style="margin-top:0">Visibility:</h5>

          <mat-checkbox
            [checked]="true"
            [(ngModel)]="this.visibility.consultants"
            (change)="visibility.consultants = $event.checked"
            >Consultants</mat-checkbox
          >
          <mat-checkbox
            [checked]="true"
            [(ngModel)]="this.visibility.clients"
            (change)="visibility.clients = $event.checked"
            >Clients</mat-checkbox
          >
          <p class="mat-body">
            Please remember that private notes are only visible to you and other users with the role of admin or
            associate.
          </p>
        </div>

        <div>
          <div *ngIf="!fileEntries">
            <mat-form-field [ngClass]="'hidden-component'">
              <mat-label>Attachment</mat-label>
              <app-input-file
                label=""
                [ngClass]="'file-browser'"
                [multiple]="true"
                [directory]="false"
                [allowDirs]="false"
                placeholder=""
                (change)="handleFileSelection($event)"
                accept="{{ uploadFileType }}"
                [(ngModel)]="basicFileModel">
              </app-input-file>
            </mat-form-field>

            <button
              style="margin-left: 10px"
              mat-flat-button
              color="warn"
              [ngClass]="'browse-button'"
              (click)="browseFiles()">
              Browse for files <mat-icon matSuffix>note</mat-icon>
            </button>
          </div>

          <h5
            *ngIf="fileEntries"
            style="margin-top:0">
            Attachments:
          </h5>
          <ul
            *ngIf="fileEntries"
            [ngClass]="'mat-body'"
            style="padding:0">
            <li
              style="list-style:none"
              *ngFor="let item of fileEntries">
              {{ item.entry.name }} &nbsp;
              <mat-form-field appearance="fill">
                <mat-label>Attachment comment</mat-label>
                <input
                  matInput
                  name="desc"
                  type="text"
                  [value]="item.description"
                  (keyup)="item.description = getValue($event.target)" />
              </mat-form-field>
            </li>
          </ul>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        type="button"
        mat-flat-button
        color="warn"
        (click)="confirm()">
        SAVE
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .display-block {
        display: block;
      }

      .hidden,
      .hidden-component {
        display: none;
      }
    `,
  ],
})
export class NoteDialogComponent {
  title: string;
  message: string;
  name: string;
  description: string;
  selected_file: any;
  useremail: string;
  uploadFileType: string;
  fileEntries: { entry: File; description: string }[];
  basicFileModel: any;
  fileName: string;
  userRole: any;
  visibility: { clients: boolean; consultants: boolean };

  constructor(
    private dialogRef: MatDialogRef<NoteDialogComponent>,
    public snackBar: MatSnackBar,
    private uiMessaging_$: UIMessagingService,
    @Inject(MAT_DIALOG_DATA) { title, message, name, description, userRole, useremail, selected_file },
  ) {
    this.userRole = userRole;
    this.title = title;
    this.message = message;
    this.name = name;
    this.description = description;
    this.selected_file = selected_file;
    console.log('this.selected_file :', this.selected_file);
    this.fileName = this.selected_file.fileName;
    this.useremail = useremail;
    this.visibility = { clients: true, consultants: true };
  }

  handleFileSelection(event: Event): void {
    const entries = Array.from((<HTMLInputElement>event.target).files);
    if (!entries.length) {
      return;
    }
    this.fileEntries = entries.map(entry => ({ entry, description: '' }));
  }

  getValue(target: EventTarget): string {
    return (target as HTMLInputElement).value;
  }

  browseFiles() {
    (<HTMLElement>document.querySelector(`app-input-file.file-browser input[type="file"`)).click();
  }

  confirm() {
    this.uiMessaging_$.toastMessage(`The note has been added.`, null);
    this.close({
      type: 'notesave',
      selected_file: this.selected_file,
      data: {
        title: this.title,
        message: this.message,
        name: this.name,
        useremail: this.useremail,
        visibility: this.visibility,
        description: this.description,
        attachments: this.fileEntries,
      },
    });
  }

  close(type?) {
    this.dialogRef.close(type);
  }
}
