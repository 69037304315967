<div
  [ngClass]="'mat-body'"
  fxLayout="row"
  fxLayoutAlign="start center">
  <div
    *ngIf="currentFolder === ''"
    class="breadcrumb-home-button no-link">
    <mat-icon>home</mat-icon>
    <span>&nbsp;Home</span>
  </div>

  <button
    mat-button
    (click)="handleGo('')"
    aria-label="Back to home"
    *ngIf="currentFolder !== ''"
    class="breadcrumb-home-button">
    <mat-icon>home</mat-icon> &nbsp;<span class="mat-body">Home</span>
  </button>

  <mat-icon *ngIf="currentFolder !== ''">keyboard_arrow_right</mat-icon>

  <span
    class="folder-name"
    [ngStyle]="getButtonStyle(currentFolderName)"
    *ngIf="currentFolderName !== ''">
    {{ currentFolderLabel }}
  </span>
</div>
