<div id="uploadProgress-container" [ngClass]="{ maximized: state === 1 }">
  <button
    (click)="toggleState()"
    class="upload-progress-button"
    *ngIf="state === 1"
  >
    -
  </button>
  <div class="summary mat-body">
    <span class="mat-title mat-h1">Upload progress</span> (Click
    <a (click)="toggleState()" style="color: blue; cursor: pointer">{{
      state === 1 ? "-" : "here"
    }}</a>
    for more details)
  </div>

  <mat-accordion multi>
    <mat-expansion-panel *ngFor="let stack of uploadStacks" hideToggle>
      <mat-expansion-panel-header>
        <span class="mat-body-strong">{{ stack.clientName }}</span
        >&nbsp;
        <mat-chip-list>
          <mat-chip>{{ stack.caseName }}</mat-chip>
          <mat-chip>{{ stack.desc }}</mat-chip>
          <mat-chip>{{ stack.fdate }}</mat-chip>
          <mat-chip>{{ stack.status }}</mat-chip>
          <mat-chip
            [ngStyle]="{ background: '#ccc' }"
            [ngClass]="{
              iconChipGreen: stack.status === 'completed',
              iconChipGray: stack.status === 'inprogress',
              iconChipWhite: stack.status === 'new'
            }"
          >
            <mat-icon
              *ngIf="stack.status === 'completed'"
              aria-hidden="false"
              aria-label="Example done icon"
              >done
            </mat-icon>
            <mat-icon
              *ngIf="stack.status === 'new'"
              aria-hidden="false"
              aria-label="Example watch_later icon"
              >watch_later
            </mat-icon>
            <mat-icon
              *ngIf="stack.status === 'inprogress'"
              aria-hidden="false"
              aria-label="Example hourglass_full icon"
            >
              hourglass_full</mat-icon
            >
          </mat-chip>
        </mat-chip-list>
      </mat-expansion-panel-header>
      <hr />
      <mat-list role="list">
        <mat-list-item
          role="listitem"
          class="mat-body files-list-item"
          *ngFor="let file of stack.blockFiles"
        >
          {{ file.entry.name }}
          <mat-icon
            class="green-icon"
            style="vertical-align: middle"
            *ngIf="file.entryMeta.status === 'completed'"
            aria-hidden="false"
            aria-label="Example done icon"
            >done
          </mat-icon>
          <mat-icon
            class="red-icon"
            style="vertical-align: middle"
            *ngIf="file.entryMeta.status === 'new'"
            aria-hidden="false"
            aria-label="Example watch_later icon"
            >watch_later
          </mat-icon>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
</div>
