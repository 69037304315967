import { Component, ViewEncapsulation } from '@angular/core';
import { ContentHandlerService } from 'src/app/content-handler.service';
import { AuthService } from 'src/app/services/auth.service';

export interface ListItem {
  title: string;
  component?: string;
  completed?: boolean;
  running?: boolean;
  ready?: boolean;
  id: number;
}

@Component({
  selector: 'app-how-to-burn-a-disc',
  templateUrl: './how-to-burn-a-disc.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./how-to-burn-a-disc.component.scss'],
})
export class HowToBurnADiscComponent {
  steps: { title: string; content: string }[];
  howToBurnADiscSteps: ListItem[];

  constructor(private contentHandler_$: ContentHandlerService, private auth_$: AuthService) {
    this.howToBurnADiscSteps = this.contentHandler_$.howToBurnADiscComponent('getStepsData');
  }

  openNextPanel(item) {
    // Close the current panel and open the next one.
    item.ready = false;
    item.completed = true;
    item.running = false;

    const nextItem = this.howToBurnADiscSteps[this.howToBurnADiscSteps.indexOf(item) + 1];
    console.log('nextItem', nextItem);
    switch (nextItem.id) {
      case 2:
        nextItem.ready = true;
        nextItem.running = false;
        // this.auth_$.clientProfileActions.next('startthedownload');
        // this.auth_$.clientProfileActionsData = { fileId: nextItem.id };
        break;
      case 3:
        nextItem.ready = true;
        nextItem.running = false;
        this.auth_$.clientProfileActions.next('startthedownload');
        this.auth_$.clientProfileActionsData = { fileId: nextItem.id };
        // this.auth_$.clientProfileActions.next('gotodownloadlocation');
        // this.auth_$.clientProfileActionsData = { fileId: nextItem.id };
        break;
      default:
        nextItem.running = true;
        break;
    }
  }

  isString(item: string | ListItem): item is string {
    return typeof item === 'string';
  }

  isObject(item: string | ListItem): item is ListItem {
    return typeof item === 'object' && item !== null;
  }
}
