<div fxLayout="column">
  <input
    (keyup)="handleSearchFolder($event, searchFolder.value)"
    #searchFolder
    class="search-folder"
    placeholder="Search for folder"
    autocomplete="off" />
  <div class="folders-list-container">
    <div
      [ngClass]="'predefined-folders'"
      fxflexfill=""
      fxlayoutgap="0px grid">
      <button
        mat-button
        [class]="getButtonClassName(folder.label)"
        (click)="
          handleSelectFolder(null, { folderId: folder.folderId, folderName: folder.label }); toggleCustomFolders = false
        "
        [ngClass]="{ 'selected-folder': selectedFolder === folder.folderId, 'folder-boxed-button': true }"
        *ngFor="let folder of predefinedFolders">
        <mat-icon class="material-icons">folder</mat-icon> {{ folder.label }}
        <mat-icon
          class="check-icon"
          *ngIf="selectedFolder === folder.folderId"
          >check</mat-icon
        >
      </button>
    </div>
    <div fxLayout="column">
      <app-custom-folders
        fxFill="100"
        [filter]="customFolderFilter"
        (selectFolder)="handleSelectFolder(null, $event)"
        (createAndSelect)="handleCreateAndSelect($event)"
        [selectedFolder]="selectedFolder"
        [currentFolder]="currentFolder"
        [casename]="casename">
      </app-custom-folders>
    </div>
  </div>
</div>
