import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  clearStoppedGeneratedDiscAlert() {
    sessionStorage.setItem('stoppedGeneratedDiscAlert', JSON.stringify([]));
  }

  getStoppedGeneratedDiscAlert(caeName: any) {
    const va = sessionStorage.getItem('stoppedGeneratedDiscAlert');
    if (JSON.parse(va).indexOf(caeName) === -1) {
      return false;
    } else {
      return true;
    }
  }

  setAddToClioEU(arg0: boolean) {
    sessionStorage.setItem('addtoclio_eu', arg0.toString());
  }

  getAddToClioEU() {
    return sessionStorage.getItem('addtoclio_eu') === 'true';
  }

  toggleStoppedGeneratedDiscAlert(caseName: any) {
    const va = sessionStorage.getItem('stoppedGeneratedDiscAlert');
    if (JSON.parse(va).indexOf(caseName) === -1) {
      const arr = JSON.parse(va);
      arr.push(caseName);
      sessionStorage.setItem('stoppedGeneratedDiscAlert', JSON.stringify(arr));
    } else {
      const arr = JSON.parse(va);
      const index = arr.indexOf(caseName);
      arr.splice(index, 1);
      sessionStorage.setItem('stoppedGeneratedDiscAlert', JSON.stringify(arr));
    }
  }

  getGeneratedDiscNotification() {
    return sessionStorage.getItem('generatedDiscNotification');
  }

  setGeneratedDiscNotification(arg0: string) {
    sessionStorage.setItem('generatedDiscNotification', arg0);
  }

  setAddToClioStarted(arg0: boolean) {
    sessionStorage.setItem('addToClioStarted', arg0.toString());
  }

  getAddToClioStarted() {
    return sessionStorage.getItem('addToClioStarted');
  }

  setClioSSOLogin(arg0: boolean) {
    sessionStorage.setItem('clioSSOLogin', arg0.toString());
  }

  setSignedIn(arg0: boolean) {
    sessionStorage.setItem('signedIn', arg0.toString());
  }

  setClioRegistered(val: boolean) {
    console.log('val :', val);
    sessionStorage.setItem('clioregistered', val.toString());
  }

  getClioRegistered() {
    return sessionStorage.getItem('clioregistered') === 'true';
  }

  cleanAll() {
    sessionStorage.clear();
  }

  removeClioRegistered() {
    sessionStorage.removeItem('clioregistered');
  }

  getSignedIn() {
    return sessionStorage.getItem('signedIn');
  }

  setCodeVerified(arg0: boolean) {
    sessionStorage.setItem('codeVerified', arg0.toString());
  }

  setReferer(referer: string) {
    sessionStorage.setItem('referer', referer);
  }

  getReferer() {
    return sessionStorage.getItem('referer');
  }

  removeReferer() {
    sessionStorage.removeItem('referer');
  }

  setSSO(val) {
    sessionStorage.setItem('sso', val);
  }

  getSSO() {
    return sessionStorage.getItem('sso');
  }

  removeSSO() {
    sessionStorage.removeItem('sso');
  }

  clearCodeVerified() {
    sessionStorage.removeItem('codeVerified');
  }

  getScanIds() {
    return sessionStorage.getItem('scanIds');
  }

  setPendingScan(scanid: string, temporalScanCode?: string) {
    const scanIds = this.getScanIds() !== null ? this.getScanIds().split(',') : [];
    scanIds.push(temporalScanCode ? `${scanid}*${temporalScanCode}` : scanid);
    sessionStorage.setItem('scanIds', scanIds.join(','));
  }

  removePendingScan(scanid: string, separator?: string) {
    const scanIds = this.getScanIds()
      .split(',')
      .map(item => item.split(separator)[0]);
    const location = scanIds.indexOf(scanid);
    scanIds.splice(location, 1);
    sessionStorage.setItem('scanIds', scanIds.join(','));
  }

  setClioGetAuthorizationCodeV4Executed(arg0: string) {
    sessionStorage.setItem('clioGetAuthorizationCodeV4Executed', arg0);
  }

  getClioGetAuthorizationCodeV4Executed() {
    return sessionStorage.getItem('clioGetAuthorizationCodeV4Executed');
  }

  setUID(uid: string) {
    sessionStorage.setItem('uid', uid);
  }

  getUID() {
    return sessionStorage.getItem('uid');
  }

  removeUID() {
    sessionStorage.removeItem('uid');
  }

  getOpenAppStarted() {
    return sessionStorage.getItem('openAppStarted');
  }

  setOpenAppStarted(arg0: boolean) {
    sessionStorage.setItem('openAppStarted', arg0.toString());
  }

  cleanSSO() {
    sessionStorage.removeItem('sso');
  }
}
