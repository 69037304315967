<div class="tabs-header"
     fxFlexFill
     fxLayout="row">
  <div *ngFor="let plan of plans"
       fxFlex
       [class]="plan.yearly ? 'plan-tab-yearly' : 'plan-tab-monthly'"
       [id]="'tab-tile-' + plan.key">
    <button class="plan-button"
            [ngClass]="{ selected: plan.key === planSelected, current: plan.key === planAlias[data.currentPlan.id], 'plan-button': true }"
            (click)="handleSelectPlanV2(plan, plan.yearly)">
      {{ plan.content.title }} {{ plan.key === planAlias[data.currentPlan] ? '(current)' : '' }}
    </button>
  </div>
</div>

<mat-tab-group #matTabs
               color="warn"
               [(selectedIndex)]="tabIndex">
  <mat-tab label="{{ planTitle }} (plan details)">
    <div [innerHtml]="planValue | safeHtml"></div>
    <button *ngIf="planSelected !== planAlias[data.currentPlan]"

            class="upgrade-button"
            color="warn"
            mat-flat-button

            [disabled]="isUpgradable(planSelected)"
            (click)="handleUpgrade(planSelected)">
      UPGRADE
    </button>
    <app-annually-monthly-switch *ngIf="planSelected !== 'free'&& planSelected !== 'fp'"
                                 #switchControl
                                 [on]="switchValue"
                                 (switchChange)="switchChange($event)"></app-annually-monthly-switch>
  </mat-tab>
  <mat-tab label="Compare plans">
    <app-compare-plans-columns></app-compare-plans-columns>
    <button id="compare-plans-button"
            class="compare-plans"
            mat-button
            (click)="conparePlansInDetail()">
      Compare plans in detail
    </button>
    <app-compare-plans-table id="comparePlansTable"></app-compare-plans-table>
  </mat-tab>
</mat-tab-group>
