<div fxLayout="row">
  <div fxFlex>
    <h2
      *ngIf="!title && close"
      [ngClass]="'mat-headline'">
      {{ roleSelected === UserRoles.admin ? 'Admin' : roleSelected }} List
    </h2>
    <h1
      *ngIf="title && close"
      [ngClass]="'mat-h1'">
      {{ title }}
    </h1>
    <span
      *ngIf="subtitle && close"
      [ngClass]="'mat-small'">
      {{ subtitle }}
    </span>
  </div>
  <div
    fxFlex="none"
    fxFlexOffset="1">
    <button
      type="button"
      mat-flat-button
      mat-dialog-close
      class="close-button"
      color="warn">
      X
    </button>
  </div>
</div>
<div mat-dialog-content>
  <div
    *ngIf="search"
    fxLayoutAlign="center center"
    fxLayout="column">
    <div
      [ngClass]="
        'mdc-text-field mdc-text-field--outlined mdc-text-field--no-label mdc-text-field--with-leading-icon div-container'
      ">
      <mat-form-field
        [ngStyle]="{ width: '100%' }"
        color="warn"
        appearance="fill">
        <mat-label> Search {{ roleSelected | titlecase }} </mat-label>
        <input
          #searchClientsInput
          #searchCriteriaInput
          [value]="searchCriteria"
          (input)="searchCriteria = searchCriteriaInput.value"
          (keyup)="filterUsers($event)"
          aria-label="Label"
          matInput
          placeholder="Search {{ roleSelected | lowercase }} by name"
          color="warn" />
      </mat-form-field>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      class="div-container-2">
      <button
        [ngClass]="'mdc-button submission-button'"
        (click)="cancelSearch()"
        id="cancelSearch">
        <span [ngClass]="'mdc-button__label'">Cancel</span>
      </button>
    </div>
  </div>
  <ul
    *ngIf="allUsers.length > 0"
    [ngClass]="'mdc-list mdc-list--two-line'"
    id="ulStyle">
    <div
      *ngFor="let user of allUsers"
      [ngClass]="'list-item'">
      <li
        [ngClass]="'mdc-list-item'"
        (click)="openUser(user)"
        tabindex="0">
        <div
          fxLayout="row"
          fxLayoutAlign="start center">
          <mat-icon
            fxFlex="nogrow"
            aria-hidden="false"
            aria-label="user icon"
            class="icon-item">
            account_circle
          </mat-icon>
          <span
            [ngClass]="'mdc-list-item__text'"
            fxFlex>
            <span [ngClass]="'mdc-list-item__primary-text'">
              <div [ngClass]="'mat-line'">{{ user.name }}</div>
              <div [ngClass]="'mat-line mat-small'">{{ user.email }}</div>
            </span>
          </span>
          <mat-icon
            fxFlex="nogrow"
            aria-hidden="false"
            aria-label="info icon">
            info
          </mat-icon>
        </div>
      </li>
    </div>
  </ul>
  <div
    [ngClass]="'mdc-dialog userdiag'"
    role="alertdialog"
    aria-modal="true"
    aria-labelledby="my-dialog-title"
    aria-describedby="my-dialog-content"
    id="fileDiag">
    <div [ngClass]="'mdc-dialog__container'">
      <div [ngClass]="'mdc-dialog__surface div-container-3'">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between"
          *ngIf="currentUser">
          <div>
            <h4 class="mat-title">{{ currentUser.name }}</h4>
          </div>
          <button
            mat-flat-button
            (click)="closeModal()"
            color="warn">
            X
          </button>
        </div>
        <div
          *ngIf="currentUser"
          style="margin: 10px">
          <ul [ngClass]="'mdc-list'">
            <li
              [ngClass]="'mdc-list-item'"
              tabindex="0">
              <span [ngClass]="'mdc-list-item__text'"> Email - {{ currentUser.email }} </span>
            </li>
            <li [ngClass]="'mdc-list-item'">
              <span [ngClass]="'mdc-list-item__text'"> Role - {{ currentUser.role }} </span>
            </li>
          </ul>
          <div
            style="text-align: center"
            *ngIf="userPermissions && deleteadmin">
            <button
              mat-flat-button
              color="warn"
              (click)="openDeleteConfirmDialog()">
              Remove {{ data.role | uppercase }}
            </button>
            <br />
          </div>
          <div *ngIf="currentUser.role === UserRoles.consultant">
            <mat-divider></mat-divider>
            <p
              *ngIf="!currentUser.filesSharedWith.length"
              style="text-align: center">
              This user has no shared files yet.
            </p>
            <div
              *ngIf="currentUser.filesSharedWith.length"
              fxLayout="column"
              fxLayoutAlign="center center"
              fxLayoutGap="gappx">
              <h4 id="profileFont">This user has shared files of these clients:</h4>
              <ul
                *ngIf="currentUser.filesSharedWith"
                [ngClass]="'mdc-list'">
                <li
                  *ngFor="let sharedFile of currentUser.filesSharedWith"
                  [ngClass]="'mdc-list-item'"
                  tabindex="0">
                  <span [ngClass]="'mdc-list-item__text'">
                    {{ sharedFile.patientCaseName }} -
                    <span class="title-container">
                      {{ sharedFile.patientName | titlecase }}
                    </span>
                  </span>
                </li>
              </ul>
              <h4
                *ngIf="!currentUser.filesSharedWith"
                id="profileFont">
                No files shared with this {{ data.role }}
              </h4>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column"></div>
        </div>
      </div>
    </div>
    <div [ngClass]="'mdc-dialog__scrim'"></div>
  </div>
  <div
    [ngClass]="'mdc-dialog deleteconfirm'"
    role="alertdialog"
    aria-modal="true"
    aria-labelledby="my-dialog-title"
    aria-describedby="my-dialog-content"
    id="fileDiag">
    <div [ngClass]="'mdc-dialog__container'">
      <div [ngClass]="'mdc-dialog__surface'">
        <div class="div-container-4">
          <h2
            [ngClass]="'mdc-dialog__title'"
            id="my-dialog-title">
            Confirm Remove {{ data.role }}
          </h2>
        </div>
        <div fxLayoutAlign="center center">
          <h4
            *ngIf="currentUser"
            id="profileFont">
            Are you sure you want to delete {{ currentUser.name }}?
          </h4>
        </div>
        <footer [ngClass]="'mdc-dialog__actions'">
          <button
            (click)="markDeleted(currentUser.uid)"
            type="button"
            data-mdc-dialog-action="accept"
            color="warn"
            data-mdc-dialog-button-default
            mat-flat-button>
            <span [ngClass]="'mdc-button__label'">Yes</span>
          </button>
          &nbsp; &nbsp;
          <button
            mat-flat-button
            color="warn"
            data-mdc-dialog-action="no"
            (click)="closeModal()">
            <span [ngClass]="'mdc-button__label'">No</span>
          </button>
        </footer>
      </div>
    </div>
    <div [ngClass]="'mdc-dialog__scrim'"></div>
  </div>
</div>
