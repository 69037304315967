<tr>
  <!-- td ngFor iterating plans -->
  <td>&nbsp;</td>
  <ng-container *ngFor="let plan of plans">
    <td class="highlighted-plan">
      <app-choose-plan-button
        (selectPlan)="handleChoosePlan($event)"
        [planLabel]="plan.content.title"
        [plan]="plan.id"></app-choose-plan-button>
    </td>
  </ng-container>
</tr>
