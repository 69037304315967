<div
  class="fusion-text fusion-text-1"
  style="display: none">
  <div
    class="fusion-pricing-table pricing-table-1 sep-boxed-pricing row fusion-columns-4 columns-4 fusion-clearfix fusion-no-large-visibility">
    <!-- SILVER -->
    <app-plan-details plan="silver"></app-plan-details>
    <!-- GOLD -->
    <app-plan-details plan="gold"></app-plan-details>
    <!-- PLATINUM -->
    <app-plan-details plan="platinum"></app-plan-details>
    <!-- OTHER PLANS -->
    <app-other-plans-details></app-other-plans-details>
  </div>
</div>
