<div [ngClass]="'active-consultants-container'">
  <mat-selection-list
    #consultants
    [multiple]="false">
    <mat-list-option
      *ngFor="let consultant of dataFromParent"
      [value]="consultant"
      [disableRipple]="true"
      #consultantOption
      [ngClass]="{ 'small-font': true, 'client-upload-user-row': consultant.clientMatter === caseName }"
      class="active-consultants-list-option"
      (click)="handleOptionClick(consultant, $event)">
      <app-active-consultant
        [patientDocId]="patientDocId"
        [caseName]="caseName"
        [selected]="consultantOption.selected"
        [selectedFiles]="selectedFiles"
        [sharedFilesCount]="sharedFilesCount"
        [consultants]="consultants"
        [restrictedProfiles]="restrictedProfiles"
        [consultant]="consultant"
        (needConsultantsReloadEvent)="handleNeedConsultantsReload()"
        (goToFolderEvent)="handleGoToFolderEvent($event)"
        (shareEvent)="handleShareEvent($event)"
        (unShareEvent)="handleUnshareEvent($event)">
      </app-active-consultant>
      <!-- <mat-divider></mat-divider> -->
    </mat-list-option>
  </mat-selection-list>
  <p
    *ngIf="dataFromParent.length === 0"
    [ngClass]="['small-font', 'margin-left-20']">
    There are <b>no active consultants</b> for this client.
  </p>
</div>
