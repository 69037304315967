import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import firebase from 'firebase/app';

@Component({
  selector: 'app-edit-note',
  templateUrl: './edit-note.component.html',
  styles: [
    `
      .link-style {
        color: red;
        cursor: pointer;
      }
      .link-style:hover {
        color: orangered;
      }
      .hidden {
        display: none;
      }
    `,
  ],
})
export class EditNoteComponent implements OnInit {
  finish: any;
  uploadFileType: string;
  noteTitle: any;
  createdBy: any;
  noteIndex: any;
  basicfile: any;
  note: any;
  formDoc: FormGroup;
  patientName: any;
  fileEntries: { entry: File; description: string }[];
  fileDocId: any;
  parentFolder: any;
  fileId: any;
  fileType: string;
  noteId: any;
  noteAttachments: any;
  privacy: any;
  visibility: { clients: boolean; consultants: boolean };
  userRole: string;

  constructor(
    private dialogRef: MatDialogRef<EditNoteComponent>,
    private fb: FormBuilder,
    public ng: NgZone,
    @Inject(MAT_DIALOG_DATA) public noteData: any,
  ) {
    const {
      title,
      createdBy,
      visibility,
      userRole,
      note,
      fileDocId,
      fileId,
      fileType,
      index,
      privacy,
      patientName,
      attachments,
      parentFolder,
      noteId,
      basicfile,
    } = noteData;

    this.noteTitle = title;
    this.createdBy = createdBy;
    this.visibility = visibility;
    this.userRole = userRole;
    this.note = note;
    this.fileDocId = fileDocId;
    this.fileType = fileType;
    this.noteIndex = index;
    this.privacy = privacy || false;
    this.patientName = patientName;
    this.noteAttachments = attachments && attachments.length ? attachments : [];
    this.parentFolder = parentFolder;
    this.fileId = fileId;
    this.noteId = noteId;
    this.basicfile = basicfile;
    this.formDoc = this.fb.group({
      notetitle: [this.noteTitle, []],
      note: [this.note, []],
      basicfile: [this.basicfile, []],
      visibility_consultants: [this.visibility.consultants, []],
      visibility_clients: [this.visibility.clients, []],
    });
  }

  ngOnInit(): void {
    this.fileEntries = [];
  }

  jsonParse(jsonString) {
    return JSON.parse(jsonString);
  }

  async browseFile(path: string) {
    console.log('****************************', path);
    this.ng.run(async () => {
      console.log('>>>>>>>>>>>>>>>', path);
      // await this.viewAttachment(path);
    });
    // console.log('>>>>>>>>>>>>>>>', path);
    await firebase
      .storage()
      .ref()
      .child(path)
      .getDownloadURL()
      .then(url => {
        console.log(url);
        window.open(url, '_blank');
      });
  }

  substringFileName(path) {
    if (typeof path !== 'string') return '';
    if (!path || path === '') return '';
    const splitted = path.split('/');
    if (splitted.length === 1) return splitted[0];
    return splitted[3];
  }

  viewAttachment(event) {
    this.browseFile(event.target.value);
  }

  deleteAttachment(event) {
    this.browseFile(event.target.value);
  }

  handleFileSelection(event: Event): void {
    const entries = Array.from((<HTMLInputElement>event.target).files);
    if (!entries.length) return;
    this.fileEntries = entries.map(entry => ({ entry, description: '' }));
  }

  filterDuplicatedFiles(entries: any, entriesSource: any) {
    return entries.filter(entry => this.locateEntryByName(entry, entriesSource));
  }

  locateEntryByName(entry, entries: any) {
    return entries.filter(item => entry.name === item.fileName).length === 0;
  }

  openInfoDialog(event, formcontrolname) {
    (<HTMLElement>(
      document.querySelector(`app-input-file[formcontrolname="${formcontrolname}"] input[type="file"`)
    )).click();
  }

  browseFiles(event, formcontrolname) {
    this.openInfoDialog(event, formcontrolname);
  }

  getValue(target: EventTarget): string {
    return (target as HTMLInputElement).value;
  }

  saveNote() {
    const data = {
      type: 'save',
      noteIndex: this.noteIndex,
      fileDocId: this.fileDocId,
      fileId: this.fileId,
      fileType: this.fileType,
      patientName: this.patientName,
      parentFolder: this.parentFolder,
      note: {
        title: this.noteTitle,
        note: this.note,
        createdBy: this.createdBy,
        attachments: this.noteAttachments,
        visibility: { clients: this.visibility.clients, consultants: this.visibility.consultants },
      },
      newAttachments: this.fileEntries,
    };
    this.dialogRef.close(data);
  }
}
