<app-window-title
  [closable]="false"
  [title]="'User Profile'"></app-window-title>
<div
  fxLayoutAlign="center center"
  fxLayout="column">
  <!-- <form
    *ngIf="clientForm"
    [formGroup]="clientForm"
    class="full-width"
    (ngSubmit)="onSubmit()"
  >
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Name</mat-label>
      <input formControlName="name" required matInput />
      <mat-error *ngIf="clientForm.controls['name'].invalid">{{
        getErrorMessage(clientForm.controls["name"], "name")
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Email</mat-label>
      <input formControlName="email" type="email" matInput />
      <mat-error *ngIf="clientForm.controls['email'].invalid">{{
        getErrorMessage(clientForm.controls["email"], "email")
      }}</mat-error>
    </mat-form-field>
    <br />
    <section>
      <button mat-raised-button type="submit" color="warn">
        UPDATE USER INFORMATION
      </button>
    </section>
  </form> -->
  <section class="full-width">
    <h4>Session settings</h4>
    <i>This session will time out in 60 seconds. Check (Continune to work box) if you want to continue working</i>
    <br />
    <br />
    <i>The sessions will timeout after 2 hrs. of idle time</i>
    <br />
    <br />
    <mat-slide-toggle
      (change)="handleChange($event)"
      checked="{{ sessionTimeOutState === true }}"
      color="warn"
      >Session TimeOut</mat-slide-toggle
    >
  </section>
</div>
