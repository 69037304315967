import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-import-apps',
  template: `Select the app you want to import files from:
    <hr />
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <img
        src="./../../../assets/svg/clio_logo.svg"
        width="90"
        alt="" />&nbsp;
      <a
        matTooltip="If you have a Clio account."
        (click)="SignInAndAuthorize()"
        mat-stroked-button
        *ngIf="!clioAccessToken">
        Sign In and Authorize
      </a>

      <a
        matTooltip="If you have a Clio account."
        (click)="showClioMatters()"
        mat-stroked-button
        *ngIf="clioAccessToken">
        Show Clio Matters
      </a>

      <a
        matTooltip="If you have a Clio account."
        (click)="removeClioToken()"
        mat-stroked-button
        *ngIf="clioAccessToken">
        Remove Authorization
      </a>

      <span *ngIf="!clioAccessToken">&nbsp;or&nbsp;</span>
      <a
        rel="noopener"
        matTooltip="If you don't have a Clio account."
        mat-stroked-button
        href="https://www.clio.com/signup/"
        target="_blank"
        *ngIf="!clioAccessToken"
        >Sign Up</a
      >
    </div>`,
})
export class ImportAppsComponent implements OnInit {
  clioAccessToken = false;
  practicepantherAccessToken = false;
  button: any;
  constructor(
    public dialogRef: MatDialogRef<ImportAppsComponent>,
    public auth_$: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    this.clioAccessToken = this.data['clioAccessToken'];
    this.practicepantherAccessToken = this.data['practicepantherAccessToken'];
    this.button = this.data['button'];
    if (this.button === 'clio') {
      this.showClioMatters();
    }
    if (this.button === 'practicepanther') {
      this.showPracticePantherMatters();
    }
  }

  async removeClioToken() {
    await this.removeClioWebHooks();
    this.auth_$
      .removeClioToken()
      .then(result => {
        console.log('Result', result);
        this.clioAccessToken = false;
        this.dialogRef.close('remove-clio-token');
      })
      .catch(error => {
        console.log('Error', error);
      });
  }

  removeClioWebHooks() {
    return this.auth_$
      .removeClioWebHooks()
      .then(result => {
        console.log('Result', result);
      })
      .catch(error => {
        console.log('Error', error);
      });
  }

  SignInAndAuthorize() {
    this.dialogRef.close('clio-signin-and-authorize');
  }

  SignInAndAuthorizePracticePanther() {
    this.dialogRef.close('practicepanther-signin-and-authorize');
  }

  showClioMatters() {
    this.dialogRef.close('show-clio-matters');
  }

  showPracticePantherMatters() {
    this.dialogRef.close('show-practicepanther-matters');
  }
}
