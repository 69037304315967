import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  sessionTimeout;
  rDialog: any;
  sessionTimeOutState: string;

  constructor(public dialog: MatDialog) {}

  clearSessionTimeOutState() {
    sessionStorage.removeItem('sessionTimeOutState');
  }

  setAutomaticClioAuthorize(value) {
    sessionStorage.setItem('automaticClioAuthorize', value);
  }

  unsetAutomaticClioAuthorize() {
    sessionStorage.removeItem('automaticClioAuthorize');
  }

  getAutomaticClioAuthorize() {
    return sessionStorage.getItem('automaticClioAuthorize') === 'true';
  }

  disableSessionTimeOut() {
    clearTimeout(this.sessionTimeout);
    sessionStorage.setItem('sessionTimeOutState', 'false');
  }

  enableSessionTimeOut() {
    clearTimeout(this.sessionTimeout);
    sessionStorage.setItem('sessionTimeOutState', 'true');
  }

  getSessionTimeOutState() {
    const sessionTimeOutState = sessionStorage.getItem('sessionTimeOutState') || 'true';
    return sessionTimeOutState === 'true';
  }

  handleTimeEnd(action) {
    this.dialog
      .open(SessionTimeoutComponent, { width: '400px', disableClose: false })
      .afterClosed()
      .subscribe(v => {
        action(v);
      });
  }

  clearSessionTimeout() {
    clearTimeout(this.sessionTimeout);
  }
}
