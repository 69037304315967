<app-window-title
  title="Edit note"
  [closable]="true"></app-window-title>
<form
  [formGroup]="formDoc"
  novalidate>
  <mat-form-field
    style="width: 100%"
    appearance="fill">
    <mat-label>Title</mat-label>
    <input
      matInput
      formControlName="notetitle"
      placeholder="Ex. Note title."
      (keyup)="noteTitle = getValue($event.target)"
      [value]="noteTitle" />
  </mat-form-field>
  <br />
  <div
    class="hidden"
    *ngIf="['Owner', 'Associate'].includes(userRole)">
    <h4>Visibility:</h4>
    <mat-checkbox
      [checked]="visibility.consultants"
      formControlName="visibility_consultants"
      (change)="visibility.consultants = $event.checked"
      >Consultants</mat-checkbox
    >
    <mat-checkbox
      [checked]="visibility.clients"
      formControlName="visibility_clients"
      (change)="visibility.clients = $event.checked"
      >Clients</mat-checkbox
    >
    <p class="mat-body">
      Please remember that private notes are only visible to you and other users with the role of admin or associate.
    </p>
  </div>

  <br />
  <mat-form-field
    style="width: 100%"
    appearance="fill">
    <mat-label>Content</mat-label>
    <textarea
      matInput
      formControlName="note"
      placeholder="Ex. Note text."
      (keyup)="note = getValue($event.target)"
      [value]="note"
      rows="3"></textarea>
  </mat-form-field>
  <div
    class="mat-body"
    *ngIf="noteAttachments.length">
    <h3>Attachments:</h3>
    <div style="flex-wrap: wrap; display: flex">
      <div *ngFor="let attachment of noteAttachments">
        <div fxLayout="row">
          <div fxLayout="column">
            <b>{{ substringFileName(attachment.filePath) }}</b>
            <div>{{ attachment.description }}</div>
          </div>
          <div fxLayout="row">
            <button
              (click)="browseFile(attachment.filePath)"
              mat-icon-button>
              <mat-icon>visibility</mat-icon>
            </button>
            <button
              (click)="deleteAttachment($event)"
              mat-icon-button>
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="fileEntries.length"
    [ngClass]="'mat-body'">
    <h3>File to upload:</h3>
    <div *ngFor="let item of fileEntries">
      {{ item.entry.name }}

      <mat-form-field appearance="fill">
        <mat-label>File note</mat-label>
        <input
          matInput
          name="desc"
          type="text"
          [value]="item.description"
          (keyup)="item.description = getValue($event.target)" />
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row">
    <mat-form-field
      style="display: none"
      [ngClass]="'hidden-component'">
      <app-input-file
        #fileBrowser
        [name]="'filebrowser'"
        label="Select a file to upload"
        formControlName="basicfile"
        [ngClass]="'file-browser'"
        [multiple]="true"
        [directory]="false"
        [allowDirs]="false"
        placeholder=""
        (change)="handleFileSelection($event)"
        accept="{{ uploadFileType }}">
      </app-input-file>
      <br />
    </mat-form-field>

    <button
      style="margin-left: 10px"
      mat-flat-button
      color="warn"
      [ngClass]="'browse-button'"
      (click)="browseFiles($event, fileBrowser.ngControl.name)">
      ADD ATTACHMENT <mat-icon matSuffix>note_add</mat-icon>
    </button>

    <button
      mat-button
      mat-flat-button
      color="warn"
      (click)="saveNote()">
      SAVE
    </button>
    <button
      mat-button
      mat-flat-button
      color="warn">
      CANCEL
    </button>
  </div>
</form>
