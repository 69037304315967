<app-window-title [title]="'Audit Log'"
	[closable]="true"></app-window-title>

<button (click)="filterByClient()"
	mat-button>
	Filter by client
</button>
&nbsp;
<button (click)="filterByUser()"
	mat-button>
	Filter by user
</button>
<button (click)="resetFilters()"
	mat-button>
	Reset
</button>
<p *ngIf="filteredClient">
	The results are filtered by the client: {{ filteredClient }}
</p>
<br>
<br>
<div>
	<table [dataSource]="dataSource"
		mat-table
		matSort>
		<!-- - Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

		<!-- IP Column -->
		<ng-container matColumnDef="ip">
			<th *matHeaderCellDef
				mat-header-cell>
				IP
			</th>
			<td *matCellDef="let element"
				mat-cell>
				{{ element.ip }}
			</td>
		</ng-container>

		<!-- Time Column -->
		<ng-container matColumnDef="created">
			<th *matHeaderCellDef
				mat-header-cell
				class="header-created">
				Time
			</th>
			<td *matCellDef="let element"
				mat-cell>
				{{ element.created }}
			</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<th *matHeaderCellDef
				mat-header-cell>
				Name
			</th>
			<td *matCellDef="let element"
				mat-cell>
				<a (click)="showUserInfo(element)"
					style="cursor: pointer;">
					{{ element.name }}
				</a>
			</td>
		</ng-container>

		<!-- Role Column -->
		<ng-container matColumnDef="role">
			<th *matHeaderCellDef
				mat-header-cell>
				Role
			</th>
			<td *matCellDef="let element"
				mat-cell>
				{{ element.role }}
			</td>
		</ng-container>

		<!-- Action Column -->
		<ng-container matColumnDef="action">
			<th *matHeaderCellDef
				mat-header-cell>
				Action
			</th>
			<td *matCellDef="let element"
				mat-cell>
				{{ element.action }}
			</td>
		</ng-container>

		<!-- User Email Column -->
		<ng-container matColumnDef="useremail">
			<th *matHeaderCellDef
				mat-header-cell>
				Email
			</th>
			<td *matCellDef="let element"
				mat-cell>
				{{ element.useremail }}
			</td>
		</ng-container>

		<!-- Content Column -->
		<ng-container matColumnDef="content">
			<th *matHeaderCellDef
				mat-header-cell>
				Content
			</th>
			<td *matCellDef="let element"
				mat-cell>
				<app-json-to-table jsondata="{{ element.content | json }}"></app-json-to-table>
			</td>
		</ng-container>

		<tbody>
			<tr *matHeaderRowDef="displayedColumns"
				mat-header-row></tr>
			<tr *matRowDef="let row; columns: displayedColumns"
				mat-row></tr>
		</tbody>
	</table>

	<mat-paginator [pageSizeOptions]="[5, 10]"></mat-paginator>
</div>
