<app-window-title
  [title]="'Schedule a meeting'"
  [closable]="true"></app-window-title>
<div *ngIf="!showAddToCalendar">
  <form action="">
    <div fxLayout="column">
      <div fxFlex="100">
        <mat-form-field
          color="warn"
          appearance="fill"
          [ngStyle]="{ width: '100%' }">
          <mat-label>Topic</mat-label>
          <input
            #topic
            matInput
            [value]="meetingTopic"
            (change)="meetingTopic = topic.value"
            required />
        </mat-form-field>
      </div>

      <div fxFlex="100">
        <mat-form-field
          color="warn"
          appearance="fill"
          [ngStyle]="{ width: '100%' }">
          <mat-label>What is your agenda?</mat-label>
          <textarea
            rows="3"
            #agenda
            matInput
            (change)="meetingAgenda = agenda.value"
            [value]="meetingAgenda"
            placeholder="Ex. Review cases with associates."></textarea>
        </mat-form-field>
      </div>
    </div>
    <br />

    <div
      fxLayout="row"
      fxLayoutAlign="space-between start">
      <div
        fxLayout="column"
        fxFlex="25">
        <mat-form-field
          color="warn"
          appearance="fill"
          [ngStyle]="{ width: '100%' }">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            #date
            (dateInput)="addEvent('input', $event)"
            (dateChange)="addEvent('change', $event)"
            [value]="meetingDate"
            [matDatepicker]="pickerFrom"
            required />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <!-- TIME -->
        <mat-form-field
          color="warn"
          appearance="fill"
          [ngStyle]="{ width: '100%' }">
          <mat-label>Choose a time</mat-label>
          <input
            matInput
            #time
            [ngxTimepicker]="picker"
            [format]="12"
            required
            readonly
            [minutesGap]="5"
            [value]="meetingTime" />
          <mat-icon [ngClass]="'timepicker-icon'">schedule</mat-icon>
          <ngx-material-timepicker
            [ngClass]="'timepicker-control'"
            (timeChanged)="meetingTime = $event"
            #picker>
          </ngx-material-timepicker>
        </mat-form-field>

        <!-- DURATION -->
        <mat-form-field
          color="warn"
          appearance="fill"
          [ngStyle]="{ width: '100%' }">
          <mat-label>Duration (minutes)</mat-label>
          <mat-select
            #duration
            [(value)]="meetingDuration"
            (change)="meetingDuration = duration.value"
            required>
            <mat-option [value]="15"> 15 </mat-option>
            <mat-option [value]="30"> 30 </mat-option>
            <mat-option [value]="45"> 45 </mat-option>
            <mat-option [value]="60"> 60 </mat-option>
            <mat-option [value]="120"> 120 </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- SELECT A TIMEZONE -->
        <mat-form-field appearance="fill">
          <mat-label>Select a timezone:</mat-label>
          <mat-select
            #timezone
            [(value)]="meetingTimezone"
            (change)="meetingTimezone = timezone.value"
            required>
            <mat-option
              *ngFor="let item of timezones"
              [value]="item.value">
              {{ item.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- ATTENDEES -->
      <div
        fxFlex="73"
        fxFlexOffset="2"
        class="attendees"
        fxLayout="column"
        fxLayoutAlign="space-between start">
        <div>
          <mat-label><b>Choose consultants to be invited</b></mat-label>
          <mat-selection-list
            #guests
            [(value)]="selectedOptions"
            class="consultants-list"
            (selectionChange)="handleGuestsSelection(guests.selectedOptions)">
            <mat-list-option
              [ngClass]="'guest-item'"
              [value]="item.email"
              *ngFor="let item of consultantsList"
              [selected]="item.checked"
              >{{ item.name }} {{ item.jobDescription ? '(' + item.jobDescription + ') -' : '-' }}
              {{ item.email }}</mat-list-option
            >
          </mat-selection-list>
        </div>

        <div>
          <mat-label>
            <b>Add custom guests</b>
          </mat-label>
          <div fxLayout="field">
            <div fxFlex="80%">
              <form [formGroup]="guestsForm">
                <mat-form-field [ngStyle]="{ width: '100%' }">
                  <mat-label>Guest email</mat-label>
                  <input
                    type="email"
                    matInput
                    #customGuest
                    formControlName="emailFormControl"
                    placeholder="Ex. pat@example.com"
                    [matAutocomplete]="auto" />
                  <mat-autocomplete
                    autoActiveFirstOption
                    #auto="matAutocomplete">
                    <mat-optgroup
                      *ngFor="let group of userGroupOptions | async"
                      [label]="group.role">
                      <mat-option
                        *ngFor="let item of group.emails"
                        [value]="item.email"
                        >{{ getItemFullName(item) }}
                      </mat-option>
                    </mat-optgroup>
                  </mat-autocomplete>
                  <mat-error
                    *ngIf="
                      guestsForm.get('emailFormControl').hasError('email') &&
                      !guestsForm.get('emailFormControl').hasError('required')
                    ">
                    Please enter a valid email address</mat-error
                  >
                  <mat-error *ngIf="guestsForm.get('emailFormControl').hasError('required')">
                    Email is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </form>
            </div>
            <div fxFlex="20%">
              <button
                mat-mini-fab
                (click)="addCustomGuest(customGuest.value); customGuest.value = ''">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <br />

  <span
    [ngClass]="'error-message'"
    *ngIf="validationMessage !== ''">
    {{ validationMessage }}
  </span>

  <button
    mat-flat-button
    color="warn"
    (click)="handleCreateMeeting()">
    <mat-icon>forward_to_inbox</mat-icon> &nbsp; SCHEDULE AND INVITE
  </button>
</div>

<p *ngIf="showAddToCalendar">{{ message }}</p>

<p
  *ngIf="showAddToCalendar"
  [innerHTML]="nextActionMessage"></p>
