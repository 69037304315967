<div
  style="display: none"
  class="table-1">
  <!-- SILVER PLAN -->
  <div class="table-1">
    <div class="table-1">
      <div class="table-1">
        <div class="table-1">
          <h3
            class="title-row"
            style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
            data-fontsize="18"
            data-lineheight="27px">
            SILVER
          </h3>
        </div>
        <div class="table-1">
          <div class="table-1">
            <span class="currency">$</span><span class="integer-part">19</span><sup class="decimal-part">95</sup
            ><span class="time">mo</span>
          </div>
        </div>
        <ul class="list-group">
          <li class="list-group-item normal-row">
            Each subscriber can simultaneously share same documents with one (1) administrator and/or one (1) associate
            of choice
          </li>
          <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
          <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
          <li class="list-group-item normal-row">
            DICOM image disc upload and Cloud Storage (medical imaging studies).
          </li>
          <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
          <li class="list-group-item normal-row">Document and video (MP4, MOV, AVI) upload and Cloud storage</li>
          <li class="list-group-item normal-row">HIPAA Compliant Storage.</li>
          <li class="list-group-item normal-row">
            Built-in Zoom video conference feature with unlimited Video conference time and 300 participants/session
          </li>
          <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
          <li class="list-group-item normal-row">Video conference recording and storage</li>
          <li class="list-group-item normal-row">2TB /month storage comes with plan** Unlimited storage as needed</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- GOLD PLAN -->
  <div class="table-1">
    <div class="table-1">
      <div class="table-1">
        <div class="table-1">
          <h3
            class="title-row"
            style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
            data-fontsize="18"
            data-lineheight="27px">
            GOLD
          </h3>
        </div>
        <div class="table-1">
          <div class="table-1">
            <span class="currency">$</span><span class="integer-part">24</span><sup class="decimal-part">95</sup
            ><span class="time">mo</span>
          </div>
        </div>
        <ul class="list-group">
          <li class="list-group-item normal-row">
            Each subscriber can simultaneously share same documents with one (1) administrator and one (1) associate of
            choice
          </li>
          <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
          <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
          <li class="list-group-item normal-row">
            DICOM image disc upload and Cloud storage (medical imaging studies)
          </li>
          <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
          <li class="list-group-item normal-row">Document and video (MP4, MOV, AVI) upload and Cloud storage</li>
          <li class="list-group-item normal-row">HIPAA Compliant Storage</li>
          <li class="list-group-item normal-row">
            Built-in Zoom video conference feature with unlimited Video conference time and 300 participants/session
          </li>
          <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
          <li class="list-group-item normal-row">Video conference recording and storage</li>
          <li class="list-group-item normal-row">4TB /month storage comes with plan** Unlimited storage as needed</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- PLATINUM PLAN -->
  <div class="table-1">
    <div class="table-1">
      <div class="table-1">
        <div class="table-1">
          <h3
            class="title-row"
            style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
            data-fontsize="18"
            data-lineheight="27px">
            PLATINUM
          </h3>
        </div>
        <div class="table-1">
          <div class="table-1">
            <span class="currency">$</span><span class="integer-part">49</span><sup class="decimal-part">95</sup
            ><span class="time">mo</span>
          </div>
        </div>
        <ul class="list-group">
          <li class="list-group-item normal-row">
            Each subscriber can simultaneously share same documents with one (1) administrator and one (1) associate of
            choice
          </li>
          <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
          <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
          <li class="list-group-item normal-row">
            DICOM image disc upload and Cloud storage (medical imaging studies)
          </li>
          <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
          <li class="list-group-item normal-row">Document and video (MP4, MOV, AVI) upload and Cloud storage</li>
          <li class="list-group-item normal-row">HIPAA Compliant Storage</li>
          <li class="list-group-item normal-row">
            Built-in Zoom video conference feature with unlimited Video conference time and 300 participants/session
          </li>
          <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
          <li class="list-group-item normal-row">Video conference recording and storage</li>
          <li class="list-group-item normal-row">5TB /month storage comes with plan** Unlimited storage as needed</li>
          <li class="list-group-item normal-row">Remote Client upload â€“ discs and documents</li>
          <li class="list-group-item normal-row">Video conference transcription (*coming soon) â€“ no extra charge</li>
          <li class="list-group-item normal-row">Voice recognition (*coming soon) â€“ no extra charge</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- ENTERPRISE PLATINUM and ENTERPRISE PLATINUM PLUS PLAN -->
  <div class="table-1">
    <div class="table-1">
      <div class="table-1">
        <div class="table-1">
          <h3
            class="title-row"
            style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
            data-fontsize="18"
            data-lineheight="27px">
            Enterprise Platinum and Enterprise Platinum Plus (+)
          </h3>
        </div>
        <div class="table-1">
          <div class="table-1">
            <span class="currency">$</span><span class="integer-part">44</span><sup class="decimal-part">95-39</sup
            ><span class="time">mo</span>
          </div>
        </div>
        <ul class="list-group">
          <li class="list-group-item normal-row">
            Each subscriber can simultaneously share same documents with one (1) administrator and one (1) associate of
            choice
          </li>
          <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
          <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
          <li class="list-group-item normal-row">
            DICOM image disc upload and Cloud storage (medical imaging studies)
          </li>
          <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
          <li class="list-group-item normal-row">Document and video (MP4, MOV, AVI) upload and Cloud storage</li>
          <li class="list-group-item normal-row">HIPAA Compliant Storage</li>
          <li class="list-group-item normal-row">
            Built-in Zoom video conference feature with unlimited Video conference time and 300 participants/session
          </li>
          <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
          <li class="list-group-item normal-row">Video conference recording and storage</li>
          <li class="list-group-item normal-row">5TB /month storage comes with plan** Unlimited storage as needed</li>
          <li class="list-group-item normal-row">Remote Client upload â€“ discs and documents</li>
          <li class="list-group-item normal-row">Video conference transcription (*coming soon) â€“ no extra charge</li>
          <li class="list-group-item normal-row">Voice recognition (*coming soon) â€“ no extra charge</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div
  class="fusion-text fusion-text-2 md-text-align-center pricing-text"
  style="--awb-font-size: 15px; --awb-line-height: 18px; --awb-text-transform: none; display: none">
  <h4
    style="text-align: left; --fontsize: 19; line-height: 1.5; --minfontsize: 19; --fontSize: 19; --minFontSize: 19"
    data-fontsize="19"
    data-lineheight="28.5px">
    <span
      class="class-1"
      data-darkreader-inline-color=""
      >1st year Introductory offer</span
    >
  </h4>
  <ul style="text-align: left">
    <li>
      1<sup>st</sup> month no charge with Silver, Gold, Platinum, Enterprise Platinum and Enterprise Platinum-Plus
      subscription with 30-day trial period 1<sup>st</sup> year.
    </li>
  </ul>
  <h4
    style="--fontsize: 19; --minfontsize: 19; --fontSize: 19; line-height: 1.5; --minFontSize: 19"
    data-fontsize="19"
    data-lineheight="28.5px">
    <span
      class="class-1"
      data-darkreader-inline-color=""
      >Silver $19.95/month with yearly renewable subscription</span
    >
  </h4>
  <ul>
    <li>
      <p style="padding-left: 0; display: inline !important">
        <span
          style="color: #666; --darkreader-inline-color: #60686c"
          data-darkreader-inline-color=""
          >DICOM </span
        ><span
          style="color: #666; --darkreader-inline-color: #60686c"
          data-darkreader-inline-color=""
          >image disc upload and Cloud Storage.</span
        >
      </p>
    </li>
    <li>
      <span
        style="color: #666; --darkreader-inline-color: #60686c"
        data-darkreader-inline-color=""
        >Each subscriber simultaneously shares same documents with (1) administrator and/or (1) associate</span
      >
    </li>
    <li>Unlimited number of consultants &amp; experts.</li>
    <li>Upload from Practice Management systems &amp; Desktop.</li>
    <li>Document, JPEG, photo, illustration, diagram, video upload and cloud storage.</li>
    <li>HIPAA Compliant Storage.</li>
    <li>Unlimited Zoom Video conference time /session.</li>
    <li>300 participants/session.</li>
    <li>Unlimited storage as needed.</li>
    <li>
      2TB storage/month<span
        class="class-1"
        data-darkreader-inline-color=""
        >**</span
      >
      (overage @ $0.20/GB/month).
    </li>
    <li>Built-in DICOM viewer for medical imaging studies.</li>
  </ul>
  <h4
    style="--fontsize: 19; --minfontsize: 19; --fontSize: 19; line-height: 1.5; --minFontSize: 19"
    data-fontsize="19"
    data-lineheight="28.5px">
    <span
      class="class-1"
      data-darkreader-inline-color=""
      >Gold $24.95/month with yearly renewable subscription</span
    >
  </h4>
  <p style="padding-left: 40px">
    All the benefits of<i> </i>Silver plus<span
      class="class-6"
      data-darkreader-inline-bgcolor=""
      >:</span
    >
  </p>
  <ul>
    <li>
      <span
        style="color: #5c5c5c; --darkreader-inline-color: #596164"
        data-darkreader-inline-color=""
        >Add one additional user (associate or administrator).</span
      >
    </li>
    <li>
      <span
        style="color: #5c5c5c; --darkreader-inline-color: #596164"
        data-darkreader-inline-color=""
        >4TB storage/month<span
          class="class-1"
          data-darkreader-inline-color=""
          >**</span
        >
        (overage @ $0.20/GB/month).</span
      >
    </li>
    <li>
      <span
        style="color: #5c5c5c; --darkreader-inline-color: #596164"
        data-darkreader-inline-color=""
        >Video conference recording and storage.</span
      >
    </li>
  </ul>
  <h4
    style="--fontsize: 19; --minfontsize: 19; padding-left: 0; --fontSize: 19; line-height: 1.5; --minFontSize: 19"
    data-fontsize="19"
    data-lineheight="28.5px">
    <span
      class="class-1"
      data-darkreader-inline-color=""
      >Platinum $49.95/month with yearly renewable subscription</span
    >
  </h4>
  <p style="padding-left: 40px">
    All the benefits of Gold plus:<span
      style="color: #000; --darkreader-inline-color: #181a1b"
      data-darkreader-inline-color=""
      ><br />
    </span>
  </p>
  <ul>
    <li>Add UNLIMITED additional users (associates and/or administrators).</li>
    <li>
      5TB storage/month<span
        class="class-1"
        data-darkreader-inline-color=""
        >**</span
      >
      (overage @ $0.15/GB/month).
    </li>
    <li>
      Remote Client upload for documents and discs<span
        style="color: #009ac9; --darkreader-inline-color: #3d7a8d"
        data-darkreader-inline-color=""
        >*</span
      >.
    </li>
    <li>
      Voice recognition input<span
        style="color: #009ac9; --darkreader-inline-color: #3d7a8d"
        data-darkreader-inline-color=""
        >*</span
      >
      (available soon at no extra charge).
    </li>
    <li>
      Zoom video conference transcription<span
        style="color: #009ac9; --darkreader-inline-color: #3d7a8d"
        data-darkreader-inline-color=""
        >*</span
      >
      (available soon at no extra charge).
    </li>
  </ul>
  <div>
    <h4
      class=""
      style="--fontsize: 19; line-height: 1.5; --minfontsize: 19"
      data-fontsize="19"
      data-lineheight="28.5px">
      <span
        class="class-1"
        data-darkreader-inline-color=""
        >Enterprise Platinum $44.95/ month with prepaid 1-year subscription and yearly renewable subscription</span
      >
    </h4>
    <p style="padding-left: 40px">All the benefits of Platinum.</p>
    <p style="text-align: left; padding-left: 400px">Or</p>
    <h4
      class=""
      style="--fontsize: 19; line-height: 1.5; --minfontsize: 19"
      data-fontsize="19"
      data-lineheight="28.5px">
      <span
        class="class-1"
        data-darkreader-inline-color=""
        >Enterprise Platinum-Plus $39.95/ month with prepaid 3-year subscription and 3-year renewable subscription</span
      >
    </h4>
    <p style="padding-left: 40px">All the benefits of Platinum.</p>
  </div>
  <hr />
  <p>
    <span
      class="class-5"
      data-fusion-font="true"
      data-darkreader-inline-color=""
      ><strong
        style="color: red; --darkreader-inline-color: #b63d3d"
        data-darkreader-inline-color=""
        >**</strong
      ></span
    ><span
      class="class-3"
      data-darkreader-inline-color=""
      data-darkreader-inline-bgcolor=""
      >Cumulative storage/month for all documents, images, recordings, and video is included in your monthly
      subscription pricing. You will incur an extra fee based on the accumulated storage above your subscription level
      allocation (overage).</span
    >
  </p>
</div>
<div class="fusion-builder-row fusion-builder-row-inner fusion-row fusion-flex-align-items-flex-start class-4">
  <div
    class="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-0 fusion_builder_column_inner_1_3 1_3 fusion-flex-column">
    <div
      class="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column"></div>
  </div>
  <div
    class="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-1 fusion_builder_column_inner_1_3 1_3 fusion-flex-column">
    <div
      class="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column"></div>
  </div>
  <div
    class="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-2 fusion_builder_column_inner_1_3 1_3 fusion-flex-column">
    <div
      class="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column"></div>
  </div>
</div>
