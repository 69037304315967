import { SessionStorageService } from './../../services/session-storage.service';
import 'firebase/auth';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { UIMessagingService } from 'src/app/services/uimessaging.service';
import { LogLevelsDictionary } from '../../dictionaries/LogLevels';
import { UserRoles } from '../../dictionaries/UserRoles';
import { LogService } from '../../log.service';
import { AuthService } from '../../services/auth.service';
import { FirebaseUtilitiesService } from '../../services/firebase-utilities.service';
import { TwoFactorAuthenticationService } from '../../two-factor-authentication.service';
import { FgpasswordComponent } from '../fgpassword/fgpassword.component';
import { DialogService } from 'src/app/dialog.service';
import { MatInput } from '@angular/material/input';

// import {TwoFactorAuthenticationComponent} from '../';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  hide = true;
  twofacode: string;
  twofacode_needed: boolean;
  errorMessage: string;
  userSignedIn: boolean;
  hiddenClass: string;
  UserRoles: {
    owner: string;
    admin: string;
    associate: string;
    consultant: string;
    superuser: string;
  };
  dialogConfig: MatDialogConfig;
  dialogRef: any;
  codeValidated: Promise<true | Error>;
  emailFieldDisabled: boolean;
  forbidSignIn = false;
  password = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  euLogin: boolean = false;
  // @ViewChild('emailInput', { static: true }) emailInput: MatInput;

  constructor(
    private router: Router,
    private auth_$: AuthService,
    private route: ActivatedRoute,
    private dialog_$: DialogService,
    private sessionStorage_$: SessionStorageService,
    private firebase_$: FirebaseUtilitiesService,
    private uiMessaging_$: UIMessagingService,
    private log_$: LogService,
  ) {
    this.emailFieldDisabled = false;
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
    this.userSignedIn = false;
    this.twofacode = '';
    this.twofacode_needed = false;
    this.errorMessage = '';
    window.document.body.focus();
  }

  ngOnInit() {
    const lastUrl = this.route.snapshot.url[this.route.snapshot.url.length - 1];
    if (lastUrl.path === 'login_eu') {
      // this.sessionStorage_$.setAddToClioEU(true);
      this.euLogin = true;
    }

    this.email.valueChanges.subscribe(r => {
      if (r.trim() !== r) {
        this.email.setValue(r.trim(), { emitEvent: false });
        return;
      }
    });

    this.password.valueChanges.subscribe(r => {
      if (r.trim() !== r) {
        this.password.setValue(r.trim(), { emitEvent: false });
        return;
      }
    });

    this.errorAuthSub();
  }

  backToHome() {
    this.router.navigateByUrl('/');
  }

  errorAuthSub() {
    this.auth_$.errorAuth.subscribe(error => {
      this.errorMessage = error;
    });
  }

  getPrompt2faTitle() {
    return 'Two-factor authentication';
  }

  getPrompt2faMessage() {
    return 'Please enter the code sent to your phone or email.';
  }

  handleCancel(ev) {
    window.location.reload();
  }

  handleConfirm(ev) {
    if (!ev) {
      console.error('The code is incorrect');
      this.auth_$.errorAuth.next('The code is incorrect');
    } else {
      console.log('Ready to signin');
      this.handleLogin().then(r => console.log(r));
    }
  }

  getErrorMessage(formControl) {
    if (formControl.hasError('required')) {
      return 'You must enter a value';
    }

    return formControl.hasError('email') ? 'Not a valid email' : '';
  }

  async login(event) {
    event.preventDefault();
    event.stopPropagation();
    this.hide = true;
    if (this.email.valid && this.password.valid) {
      await this.handleLogin();
      return;
    }
    return;
  }

  handleLoginError(error) {
    console.log(error);
    const { code } = error;
    switch (code) {
      case 'auth/too-many-requests':
        this.uiMessaging_$.toastMessage('Too many requests. Please try again later or reset your password.', 'Error');
        break;
      case 'auth/wrong-password':
        this.uiMessaging_$.toastMessage(
          'There was an error with your credentials. Please check and try again.',
          'Error',
        );
        break;
      default:
        this.uiMessaging_$.toastMessage(
          'There was an error with your credentials. Please check and try again.',
          'Error',
        );
        break;
    }
    return;
  }

  ssoClio(eu = false) {
    // if (eu) this.sessionStorage_$.setAddToClioEU(true);
    if (this.route.snapshot.queryParams.referer)
      this.sessionStorage_$.setReferer(this.route.snapshot.queryParams.referer);

    this.router.navigate(['clio'], { queryParams: { sso: 0 }, relativeTo: this.route });
  }

  async handleLogin() {
    let user;

    const email = this.email.value.toLowerCase();
    const existingRestrictions = await this.validateAccountRestrictions(email);
    if (!existingRestrictions) {
      return;
    }

    try {
      this.setEmailAndPasswordValues();
      const email = this.email.value.toLowerCase();
      const password = this.password.value;
      user = await this.auth_$.login(email, password).catch(error => {
        console.log(error);
      });
    } catch (error) {
      this.handleLoginError(error);
    }

    if (this.auth_$.redirectURL) {
      this.router.navigateByUrl(this.auth_$.redirectURL);
    } else {
      return this.runUserReady(user);
    }
  }

  runUserReady(user) {
    try {
      this.auth_$.userReady(user.user, 'runUserReady 2').then(r => {
        this.log_$.storeLog(this.auth_$.userData.getValue(), LogLevelsDictionary.info, {}, 'Login');
        this.auth_$.checkOwnerPlan();
      });
      return 200;
    } catch (error) {
      return this.handleUserReadyError(error);
    }
  }

  async handle2FAData(email = this.email.value) {
    const res = await this.handleLogin().catch(error => {
      console.log(error);
      this.auth_$.errorAuth.next('There was an error with your credentials. Please check and try again.');
      return;
    });
    if (!res || res.a === null) {
      return;
    }
    // await this.handleTwoFactorSignIn(email);
  }

  handleChange() {
    this.forbidSignIn = false;
  }

  navigateToFGPass() {
    this.dialog_$.open(FgpasswordComponent, { width: '350px', data: { email: this.email.value } });
  }

  async signIn() {
    this.forbidSignIn = true;
    this.handle2FAData();
    this.route.queryParams.subscribe(params => {
      if (params['referer']) {
        const cloneObject = { ...params };
        delete cloneObject['referer'];
        const url = this.router.createUrlTree([params['referer']], { queryParams: cloneObject });
        this.router.navigateByUrl(url, { replaceUrl: true });
      }
    });
    this.forbidSignIn = false;
  }

  async validateCode(code) {
    return await this.firebase_$.validate2FACode(this.auth_$.uid, code, 'email');
  }

  setEmailAndPasswordValues() {
    this.email.setValue(this.email.value.trim().toLowerCase());
    this.password.setValue(this.password.value.trim());
  }

  handleUserReadyError(error) {
    switch (error.code) {
      case 'auth/wrong-password':
        this.auth_$.errorAuth.next(`There was an error with your credentials, please try again or sign up.`);
        break;
      case 'auth/user-not-found':
        this.auth_$.errorAuth.next(`There was an error with your credentials, please try again or sign up.`);
        break;
      default:
        break;
    }
    this.twofacode_needed = false;
    return error;
  }

  private getOwnerIDByEmail(email) {
    return this.auth_$
      .getOwnerIDByEmail(email)
      .then(ownerID => {
        return ownerID;
      })
      .catch(error => {
        console.error('Error getting owner ID:', error);
        throw error;
      });
  }

  private getOwnerPlan(ownerID) {
    return this.auth_$
      .getOwnerPlan(ownerID)
      .then(ownerPlan => {
        return ownerPlan;
      })
      .catch(error => {
        console.error('Error getting owner plan:', error);
        throw error;
      });
  }

  // If the userRole is Client and the owner plan is gold or silver return true, else false.
  private async validateAccountRestrictions(email) {
    const userRole = this.auth_$.getUserRoleV2();
    if (userRole !== UserRoles.client) {
      return true;
    }
    const ownerID = await this.getOwnerIDByEmail(email);
    const ownerPlan = await this.getOwnerPlan(ownerID);
    let answer;

    if (userRole === UserRoles.client) {
      if (!['gold', 'silver'].includes(ownerPlan)) {
        answer = true;
      } else {
        answer = false;
      }
    }

    return answer;
  }
}
