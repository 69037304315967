import 'firebase/firestore';

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { UIMessagingService } from 'src/app/services/uimessaging.service';
import { WindowsManagerService } from 'src/app/services/windows-manager.service';
import { environment } from 'src/environments/environment';

import { AuthService } from '../../../services/auth.service';
import { FirebaseUtilitiesService } from '../../../services/firebase-utilities.service';
import { ClientProfileComponent } from '../../clientprofile/clientprofile.component';
import { DialogService } from 'src/app/dialog.service';

@Component({
  selector: 'app-practicepantherauth',
  templateUrl: './PracticePantherAuth.component.html',
  styleUrls: ['./PracticePantherAuth.component.scss'],
})
export class PracticePantherAuthComponent implements OnInit {
  profileDialogRef: any;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private auth_s: AuthService,
    private firebaseUtilities_s: FirebaseUtilitiesService,
    private dialog_$: DialogService,
    private router: Router,
    private uiMessaging_$: UIMessagingService,
    private windowsManager_$: WindowsManagerService,
  ) {}
  async ngOnInit() {
    this.auth_s.user.subscribe({
      next: async v => {
        if (v) {
          const userdocid = await this.firebaseUtilities_s.getUserDocId(this.auth_s.uid);
          console.log('userdocid', userdocid);
          this.route.queryParams.subscribe(params => {
            const { code } = params;
            const url = `${environment.constants.cloudfunctionsURL}practicepanther-getAuthorizationCodeByUserPassword`;
            const observer = {
              next: async x => {
                console.log(x);
                await this.auth_s.userReady(v, 'PracticePantherAuth ngOnInit');
                this.router.navigate(['/'], { replaceUrl: true });
                this.getUserLastSession();
              },
              error: err => {
                console.error(err);
                this.uiMessaging_$.toastMessage('There was an error, please try again.', null);
              },
              complete: () => console.log('Observer completed'),
            };

            this.http.post(url, { code, userdocid }).subscribe(observer);
          });
        }
      },
      error: e => {
        console.log('error', e);
      },
      complete: () => {
        console.log('=== end ===');
      },
    });
  }

  async getUserLastSession() {
    const lastSession = this.auth_s.userData.value['lastSession'];
    console.log('lastSession :', lastSession);
    if (!lastSession || lastSession === '') {
      console.log('== no session saved ==');
      return false;
    }
    const { patientDocId } = lastSession;
    const data = (await firebase.firestore().collection('patients').doc(patientDocId).get()).data();
    const FirstName = data.FirstName.charAt(0).toUpperCase() + data.FirstName.slice(1);
    const LastName = (data.LastName = data.LastName.charAt(0).toUpperCase() + data.LastName.slice(1));
    const caseName = data.caseName;
    const LegalCaseId = data.LegalCaseId;
    const DateOfBirth = data.DateOfBirth;
    const sharedUserFiles = data.files;
    const ownerID = data.ownerID;
    const defaultFolders = data.defaultFolders;
    const patient = {
      FirstName,
      LastName,
      caseName,
      LegalCaseId,
      DateOfBirth,
      sharedUserFiles,
      ownerID,
      patientDocId,
      defaultFolders,
    };
    this.routeToProfile(patient);
  }

  routeToProfile(patient) {
    const { ownerID } = patient;
    if (!ownerID) {
      this.uiMessaging_$.toastMessage(
        `This profile does not have a valid ownerID, please contact support before retry.`,
        'ALERT',
      );
      return;
    }
    if (!this.windowsManager_$.clientProfileOpened) {
      this.profileDialogRef = this.dialog_$.open(ClientProfileComponent, {
        width: '100vw',
        height: '90vh',
        id: 'client-profile',
        data: { patient: patient, button: 'practicepanther-matters' },
      });
    }
  }
}
