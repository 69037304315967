import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MailerService {
  dialogConfig: MatDialogConfig;
  constructor(private http: HttpClient) {}

  sendOperationStatusEmail(messageData, action, status, email) {
    console.log('sendOperationStatusEmail');
    const url = `${environment.constants.cloudfunctionsURL}email-simpleEmail`;
    const dest = [email].join(',');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'secret-key',
      }),
    };

    const body = action + ': ' + status + '\n' + messageData;
    console.log('body :', body);
    const subject = `NuagedX has uploaded a disc.`;

    return this.http.post(url, { dest, body, subject }, httpOptions).subscribe({
      next: res => {
        if (res['data']['status'] === 200) console.log('Email sent');
      },
      complete: () => {
        console.log('Completed');
      },
      error: err => {
        console.error(err);
      },
    });
  }
}
