<app-window-title
  [title]="'Select a destination'"
  [closable]="true"></app-window-title>

<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  [ngClass]="'folder-selector'">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodePadding>
    <!-- use a disabled button to provide padding for tree leaf -->
    <mat-button
      class="folder-button"
      [ngStyle]="{ cursor: 'pointer', background: node.color }"
      (click)="selectFolder(node.id)">
      <mat-icon
        aria-hidden="false"
        aria-label="Example home icon"
        class="material-icons-outlined"
        >folder</mat-icon
      >
      {{ node.name }}
    </mat-button>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node
    *matTreeNodeDef="let node; when: hasChild"
    matTreeNodePadding>
    <button
      mat-icon-button
      matTreeNodeToggle
      [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
    <mat-button
      [ngStyle]="{ cursor: 'pointer' }"
      (click)="selectFolder(node.id)">
      <mat-icon
        aria-hidden="false"
        aria-label="Example home icon"
        class="material-icons-outlined"
        >folder</mat-icon
      >
      {{ node.name }}
    </mat-button>
  </mat-tree-node>
</mat-tree>
