import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../ui/optionChooser/optionChooser.component';

@Component({
  selector: 'app-show-user-info',
  templateUrl: './show-user-info.component.html',
  styleUrls: ['./show-user-info.component.scss']
})
export class ShowUserInfoComponent implements OnInit {
  userData: any;

  constructor (@Inject(MAT_DIALOG_DATA) private data: {
    companyName: string, phoneNumber: string, email: string, name: string, role: string
  }) { }

  ngOnInit(): void {
    this.userData = {
      company: this.data.companyName,
      email: this.data.email,
      name: this.data.name,
      role: this.data.role,
      phonenumber: this.data.phoneNumber
    };
  }

}
