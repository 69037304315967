<mat-card>
  <mat-card-content style="display: block; height: 100%">
    <iframe
      *ngIf="fileViewData.type === 'dicomdisk'"
      style="width: 100%; height: 100%"
      frameborder="0"
      #viewerIframe
      id="viewerFrame"
      (load)="showIframe()"
      [src]="viewerUrl"
      allowfullscreen></iframe>
    <div *ngIf="fileViewData.type === 'dicomdisk' && loadingIframe === false">Loading viewer...</div>
    <app-doc-viewer-no-data
      *ngIf="fileViewData.type === 'regular'"
      style="height: 100%; display: block; width: 100%"
      [noTitle]="true"
      [src]="fileViewData.viewerData.src"
      [title]="fileViewData.viewerData.title"
      [info]="fileViewData.viewerData.info"
      [viewer]="fileViewData.viewerData.viewer"></app-doc-viewer-no-data>

    <img
      *ngIf="fileViewData.type === 'image'"
      style="max-width: 100%; max-height: 100%; display: block; margin: auto"
      [src]="fileViewData.viewerData.src" />
  </mat-card-content>
</mat-card>
