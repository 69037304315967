<mat-dialog-content
  fxLayout="row"
  class="dialog-content">
  <p
    [innerHTML]="message"
    class="mat-body"
    fxFlex></p>
  <div
    fxLayout="row"
    fxLayoutAlign="space-around center"
    fxLayoutGap="10px"
    style="margin-bottom: 0px">
    <button
      (click)="confirm()"
      mat-flat-button
      color="warn"
      style="margin-left: 10px">
      {{ btnOkText }}
    </button>

    <!-- <button
      *ngIf="options?.markAsRead"
      (click)="markAsRead()"
      mat-flat-button
      color="warn"
      style="margin-left: 10px">
      <mat-icon>check_circle</mat-icon>
      Mark as read.
    </button> -->

    <button
      mat-flat-button
      color="warn"
      (click)="markAsRead()">
      <!-- {{ btnCancelText }} -->
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-dialog-content>
