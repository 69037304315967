import { Injectable } from '@angular/core';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor() {}
  updateUserByDocId(id: string, data: any) {
    return firebase.firestore().collection('users').doc(id).update(data);
  }
  getUsersByEmailsArray(emailsArray: any[]) {
    return firebase.firestore().collection('users').where('email', 'in', emailsArray).get();
  }
  getUserByUID(uid: string) {
    return firebase.firestore().collection('users').where('uid', '==', uid).limit(1).get();
  }
  getUserByEmail(email: string) {
    return firebase.firestore().collection('users').where('email', '==', email).limit(1).get();
  }
}
