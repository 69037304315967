import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import firebase from 'firebase/app';

import { UploadHelperService } from '../../services/upload-helper.service';

// TODO: Implement a Logging Service.
@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss'],
})
export class UploadProgressComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  uploadStacks: any;
  state: number;
  healthcare: any;

  up_storageRef: firebase.storage.Reference;
  up_db: firebase.firestore.Firestore;
  up_params: any;
  up_storecomplementname: string;
  up_lastime: any;
  up_datasetname: any;
  up_datastorename: string;
  up_data: any;
  uploadGroups: any;

  constructor(private uploadHelper_$: UploadHelperService) {
    this.state = 0; // window state
    // Healthcare Ref
    this.healthcare = null;
  }

  ngOnInit() {
    this.uploadHelper_$.scannedBlocksSource.subscribe(obs => {
      this.uploadStacks = this.uploadHelper_$.getScannedUploadBlocksList();
    });

    this.uploadHelper_$.uploadDaemon.subscribe(obs => {
      // console.log('uploadDaemon obs:', obs);
    });

    // Monitoring upload files
    this.uploadHelper_$.getScannedFiles().subscribe(scannedFiles => {
      // console.log('scannedFiles', scannedFiles);
    });
  }

  toggleState() {
    if (this.state === 0) {
      this.state = 1;
    } else {
      this.state = 0;
    }
  }
}
