import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncrDecrService {
  set(key: string, value: string): string {
    const encrypted = CryptoJS.AES.encrypt(value, key).toString();
    return encrypted;
  }

  get(key: string, encrypted: string): string {
    const decrypted = CryptoJS.AES.decrypt(encrypted, key).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }
}
