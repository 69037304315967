import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  constructor() {}

  setCookie(cookieName: string, cookieValue: string, arg2: number) {
    this.eraseCookie(cookieName);
    const d = new Date();
    d.setTime(d.getTime() + arg2 * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    const cookieVal = cookieName + '=' + cookieValue + '; ' + expires + '; path=/;' + ' domain=nuagedx.com';
    document.cookie = cookieVal;
  }

  getCookie(name: string) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      // tslint:disable-next-line: no-unused-expression
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  eraseCookie(name: string) {
    document.cookie = `${name}=; expires=Thu, 18 Dec 1970 12:00:00 UTC; path=/; domain=nuagedx.com;`;
    console.log('NuageLogin::deleteCookie', name);
  }

  eraseAllCookies() {
    const cookies = document.cookie.split(';');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      this.eraseCookie(name);
    }
  }
}
