import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-client-list-item',
  templateUrl: './client-list-item.component.html',
  styleUrls: ['./client-list-item.component.scss'],
})
export class ClientListItemComponent {
  @Input() client: any;
  @Input() hasNotifications: boolean;
  @Input() permissions: any;
  @Output() editClient = new EventEmitter<any>();

  constructor() {}

  public handleEditClient(event, item) {
    event.preventDefault();
    event.stopPropagation();
    this.editClient.emit(item);
  }
}
