import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import domtoimage from 'dom-to-image';
import * as jsPDF from 'jspdf';

import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit {
  allFilesAndCount = {};
  filesWithSize: any[];
  totalSize: number;
  email: string;
  numberOfUploadsClient: number;
  numberOfClients: number;
  allOwners: any[];
  ownerID: string;

  constructor (public rs: ReportService, public route: ActivatedRoute, public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReportComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.ownerID = '';
  }

  ngOnInit() {
    this.rs.getNumberOfClients().then((allOwners: any[]) => {
      this.numberOfClients = allOwners.length;
      this.allOwners = allOwners;
    });
  }

  downloadPDF() {
    const node = document.getElementById('toPDF');
    let img;
    let filename;
    let newImage;

    domtoimage.toPng(node, { bgcolor: '#fff' }).then(function (dataUrl) {
      img = new Image();
      img.src = dataUrl;
      newImage = img.src;
      img.onload = function () {

        const pdfWidth = img.width;
        const pdfHeight = img.height;
        let doc;

        if (pdfWidth > pdfHeight) {
          doc = new jsPDF('l', 'px', [pdfWidth, pdfHeight]);
        } else {
          doc = new jsPDF('p', 'px', [pdfWidth, pdfHeight]);
        }
        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();
        doc.addImage(newImage, 'PNG', 10, 10, width, height);
        filename = 'report' + '.pdf';
        doc.save(filename);
      };
    }).catch(function (error) {
    });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  numberOfUploads(ownerID) {
    let i = 0;
    this.filesWithSize.forEach((file) => {
      if (file.creator === ownerID) {
        i++;
      }
    });
    this.numberOfUploadsClient = i;
  }

  async generateReportInfo() {
    this.allFilesAndCount = await this.rs.getAllFilesAndCountForClient(this.ownerID);
    this.filesWithSize = await this.rs.getSizeForEachFile(this.ownerID, this.allFilesAndCount);
    this.numberOfUploads(this.ownerID);
    this.totalSize = await this.rs.getTotalFileUsageSizeForClient(this.ownerID);
  }
}
