import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { RecurlyService } from 'src/app/services/recurly.service';
import { RedirectionService } from 'src/app/services/redirection.service';

import { AnnuallyMonthlySwitchComponent } from '../annually-monthly-switch/annually-monthly-switch.component';
import { AuthService } from './../../services/auth.service';
import { FirebaseUtilitiesService } from './../../services/firebase-utilities.service';
import { UIMessagingService } from './../../services/uimessaging.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const freeplans = ['free', 'fp'];

@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UpgradePlanComponent implements OnInit {
  @ViewChild('matTabs') matTabs;
  @ViewChild('switchControl') switchControl: AnnuallyMonthlySwitchComponent;
  checkImage = './../../../assets/png/1398911_correct_mark_success_tick_valid_icon.png';
  blueAsterisk = 'https://nuagedx.com/wp-content/uploads/2021/03/blue.png';
  planSelected: any;
  plans: any;
  planValue = '';
  planTitle = '';
  subscriptionId: any;
  upgradablePlans = [];
  dialogConfig: MatDialogConfig;
  yearly: boolean;
  tabIndex = 0;
  switchValue = false;
  originalPlans: any;
  planPrice: any;

  marks = {
    check: `<img src="./../../assets/png/check.png" alt="" class="aligncenter" width="18" height="18" />`,
    asterisk: `<img src="./../../assets/png/check.png" alt="" class="aligncenter" width="18" height="18" />`,
    nothing: ``,
  };

  planAlias = {
    free: 'free',
    fp: 'free',
    silver: 'silver',
    gold: 'gold',
    platinum: 'platinum',
    goldyearly: 'goldyearly',
    platinumyearly: 'platinumyearly',
    lpmyearly: 'lpmyearly',
    lpmp: 'lpmp',
    silveryearly: 'silveryearly',
  };

  infoRows = [
    [
      {
        content: `Each subscriber can simultaneously share same documents with one (1) administrator and one (1) associate of choice`,
      },
      { content: this.marks.check },
      { content: this.marks.check },
      { content: this.marks.check },
      { content: this.marks.check },
    ],
    [
      {
        content: `Owner (subscriber) can add additional users.`,
      },
      { content: this.marks.nothing },
      { content: this.marks.check },
      { content: this.marks.check },
      { content: this.marks.check },
    ],
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public auth_$: AuthService,
    private http: HttpClient,
    public recurly_$: RecurlyService,
    private uiMessaging_$: UIMessagingService,
    public firebaseUtilitiesS: FirebaseUtilitiesService,
    public dialog: MatDialogRef<UpgradePlanComponent>,
    private redirection_$: RedirectionService,
  ) {
    this.planSelected = this.data.currentPlan === 'fp' ? 'free' : this.data.currentPlan;
  }

  async ngOnInit() {
    this.tabIndex = 0; // Ensure the first tab is active on initialization
    this.getPlansData(this.planSelected);

    /** */
    // 1. Get Subscription.

    // a.0 Get current user
    const userData = this.auth_$.userData.getValue();
    const planDocId = userData['plan']['id'];

    // a. Get accountcode.
    const recurlyaccountcode = userData['recurlyaccountcode'];

    // b. Get account from Recurly.
    const recurlyAccounts = await this.recurly_$.getAccount(recurlyaccountcode);

    // FIXME: Uncomment this block when the app is ready to be deployed.
    if (!recurlyAccounts['accounts'].length) {
      const adminEmail = await this.firebaseUtilitiesS.getAdminEmail();
      this.uiMessaging_$.toastMessage(
        `There is no Recurly account that matches with the current user. Please contact the app admin: ${adminEmail}`,
        null,
      );

      this.redirection_$.redirectToCompleteClioRegistration();
      this.dialog.close('thanks');
      return;
    }
    const accountId = recurlyAccounts['accounts'][0].id;

    // b.1 Get plan
    // const planData = await this.firebaseUtilitiesS.getPlan(planDocId);

    // c. Get subscriptions
    const accountSubscriptions = await this.recurly_$.getSubscriptions(accountId);

    // c.1 Filter active.
    this.subscriptionId = accountSubscriptions.subscriptions[0]['id'];

    // c.1 Get subscription data.
    // const subscriptionData = this.recurly_$.getSubscription(subscriptionId);

    // d. Load restrictions.
    this.upgradablePlans = await this.recurly_$.getPlanRestrictions(planDocId === 'fp' ? 'free' : planDocId);
  }

  isUpgradable(plancode) {
    if (!this.upgradablePlans) {
      return false;
    }

    if (this.upgradablePlans && this.upgradablePlans.length === 0) {
      return true;
    }
    return !this.upgradablePlans.includes(plancode);
  }

  updateUserPlan(newplan) {
    this.auth_$.setPlan(newplan);
    this.auth_$.userReady(this.auth_$.currentUser.getValue(), 'updateUserPlan');
    this.showThanks();
  }

  showThanks() {
    this.dialog.close('thanks');
  }

  checkIfYearly() {
    return (<HTMLInputElement>document.querySelector('#yearly')).checked;
  }

  getPlan(planname, yearly: boolean) {
    const planFound = () => this.plans.find(p => p.key === planname);
    const planKey = planFound().recurlycode;
    return planKey;
  }

  getPlanFromKey(key: string = 'silver') {
    const defaultPlan = this.plans.find(p => p.key === key);

    if (!this.plans) {
      return { plan: defaultPlan, yearly: false };
    }

    const plan = this.plans.filter(p => p.id === key)[0];

    if (plan.monthly === true) {
      return { plan: plan, yearly: false };
    }

    if (plan.yearly === true) {
      return { plan: plan, yearly: true };
    }

    return { plan: defaultPlan, yearly: false };
  }

  async handleUpgrade(newplan) {
    /*
    1. Check if the plan is free.
    1.1. If the plan is free and it is not a clio user redirect to complete nuagedx registration.
    1.2. If the plan is free and it is a clio user, should not reach this point.
    2.2. If the plan is not free, proceed to modify subscription.
    */
    // 1.1. If the plan is free, redirect to complete nuagedx registration.
    const currentPlan = this.data.currentPlan;

    const userData = this.auth_$.userData.getValue();
    const [nuagedxregistered, lpm, clioregistered] = [
      userData['nuagedxregistered'],
      userData['lpm'],
      userData['clioregistered'],
    ];
    // It is not a CLIO user and it is not registered in NuageDX.
    const condition1 = !nuagedxregistered && lpm !== 'clio';

    // It is a CLIO user and it is registered as Clio user (clioregistered = true).
    const condition2 = lpm === 'clio' && clioregistered;

    if (freeplans.includes(currentPlan) && (condition1 || condition2)) {
      this.auth_$.userData.next({ ...userData, nuagedxplan: newplan });
      this.redirection_$.redirectToCompleteNuagedxRegistration(newplan);
      this.closeUpgradePlanDialog();
      return;
    }

    const newplanRecurlyCode = this.getPlan(newplan, false);
    // 2. Modify Subscription.
    const result = await this.recurly_$.changeSubscriotion(this.subscriptionId, newplanRecurlyCode);

    if (result['subscriptions']['error']) {
      console.error(result['subscriptions']['error']['message']);
    } else {
      const planUpdateResult = await this.auth_$.updateRecurlyPlan(newplan);
      console.log('planUpdateResult: ', planUpdateResult);
      this.updateUserPlan(newplan);
    }

    // 3. Confirm change.
    if (result['code'] === 200) {
      console.log(result); // 3.1 Update user plan.
    } else {
      console.log('Result ERROR?: ', result);
    }

    this.uiMessaging_$.toastMessage('Your account plan has been upgraded', 'PLAN UPGRADED');
  }

  handleSelectPlan(plan, yearly: boolean) {
    plan.id = plan.key;
    const found = plan.subscriptions.find(s => (s.type === yearly ? 'yearly' : 'monthly'));
    this.plans.forEach(cplan => (cplan.active = cplan.id === plan.id));
    this.planSelected = found.key;

    ajax(`https://nuagedx.com/getplandata.v2.php?type=${this.planSelected === 'free' ? 'fp' : this.planSelected}`)
      .pipe(map(r => r.response))
      .subscribe({
        next: val => {
          this.planValue = val.body;
          this.planTitle = val.title;
          this.yearly = true;
        },
        error: err => console.error(err),
        complete: () => {
          const currentPlanKey = this.data.currentPlan;
        },
      });
    this.tabIndex = 0;
  }

  getPlansData(param?) {
    this.auth_$.showLoader('Loading plans...');

    const handleData = data => {
      this.originalPlans = data;
      this.plans = data.map(p => ({ ...p, key: p.id })).sort((a, b) => a.index - b.index);

      this.checkHiddenPlan();
      const planFromKey = this.getPlanFromKey(param);

      if (!planFromKey) {
        return;
      }

      const { plan, yearly } = planFromKey;
      plan.id = plan.key;

      this.handleSelectPlanV2(plan, yearly);
    };

    const handleComplete = () => {
      this.auth_$.hideLoader();
      console.log('Completed');
    };

    const handleError = err => {
      console.error(err);
      this.auth_$.hideLoader();
    };

    const getPlans = () => {
      // Call http get a cloud function admin-getPlans
      const cloudFunctionsUrl = environment.constants.cloudfunctionsURL;
      return this.http.get(`${cloudFunctionsUrl}admin-getPlansV2`);
    };

    getPlans().subscribe({
      next: data => handleData(data),
      complete: () => handleComplete(),
      error: err => handleError(err),
    });
  }

  conparePlansInDetail() {
    const matTab = document.querySelector('#mat-tab-content-0-1');
    const comparePlansTable = document.querySelector('#comparePlansTable');

    if (matTab && comparePlansTable) {
      comparePlansTable.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.error('Element not found');
    }
  }

  updatePlanValue(plan, annually) {
    let newplan;
    if (annually) {
      newplan = this.originalPlans.filter(p => p.id === plan)[0].switch;
    } else {
      newplan = this.plans.filter(p => p.id === plan)[0].key;
    }
    console.log('newplan :', newplan);
    const planContent = this.originalPlans.filter(p => p.id === newplan)[0].content;
    this.planValue = this.generateContent(planContent);
  }

  switchChange(annually: boolean) {
    const newplan = this.plans.filter(p => p.id === this.planSelected)[0].switch;
    this.handleSelectPlanV2({ id: newplan, key: newplan, switch: this.planSelected }, annually);
  }

  checkHiddenPlan() {
    console.log(`planSelected: ${this.planSelected}`);
  }

  generateContent(content) {
    const { features, soonFeatures, footerHtml } = content;
    // Make a ul li list of features.
    const featuresList = features.map(f => `<li>${f}</li>`).join('');
    const comingSoonList = soonFeatures && soonFeatures.length ? soonFeatures.map(f => `<li>${f}</li>`).join('') : '';
    const commingSoonContent = comingSoonList !== '' ? `<h3>Coming Soon</h3><ul>${comingSoonList}</ul>` : '';
    const footerContent = footerHtml ? `<div>${footerHtml}</div>` : '';
    const output = `<ul>${featuresList}</ul>${commingSoonContent}${footerContent}`;
    return output;
  }

  handleSelectPlanV2(plan, yearly: boolean) {
    this.tabIndex = 0; // Ensure the first tab is active on initialization
    const dPlan = this.plans.filter(p => p.id === plan.id)[0];
    const { content, id } = dPlan;

    this.planValue = this.generateContent(content);
    this.planTitle = content.title;
    this.planSelected = plan.key;

    if (this.switchControl) {
      this.switchControl.on = yearly;
    }
  }

  private toggleVisibility(id: string, show: boolean) {
    const element = <HTMLDivElement>document.querySelector(`#tab-tile-${id}`);
    if (element) {
      element.style.display = show ? 'block' : 'none';
    }
  }

  closeUpgradePlanDialog() {
    // This component is already inside a dialog, I need to self close it.
    this.dialog.close();
    // Close the side drawer.
    this.auth_$.drawer.close();
  }
}
