import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FirebaseUtilitiesService } from './../services/firebase-utilities.service';
import { UtilsService } from './../services/utils.service';

export interface DialogData {
  DateOfBirth: string;
  FirstName: string;
  LastName: string;
  caseName: string;
}

@Component({
  templateUrl: './edit-client-form.component.html',
  styles: [
  ]
})
export class EditClientFormComponent {
  clientForm: FormGroup;
  constructor (
    private utils_$: UtilsService,
    private firebase_utilities_$: FirebaseUtilitiesService,
    public dialogRef: MatDialogRef<EditClientFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.clientForm = new FormGroup({
      dateOfBirth: new FormControl(new Date(this.data.DateOfBirth)),
      firstName: new FormControl(this.data.FirstName),
      lastName: new FormControl(this.data.LastName),
      caseName: new FormControl(this.data.caseName)
    });
  }

  async onSubmit() {
    const { caseName, dateOfBirth, firstName, lastName } = this.clientForm.value;
    await this.firebase_utilities_$.updateClient({
      CaseName: caseName,
      DateOfBirth: this.utils_$.getDateToString(dateOfBirth),
      FirstName: firstName,
      LastName: lastName
    });
    this.dialogRef.close(1);
  }
}
