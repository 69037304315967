<app-window-title
  [title]="title"
  [subtitle]="message"
  [closable]="true">
</app-window-title>
<div
  fxLayout="row"
  fxLayoutGap="15px"
  style="height: 88%">
  <div fxFlex="70">
    <app-file-viewer
      [fileViewData]="fileViewData"
      [viewerUrl]="viewerUrl"></app-file-viewer>
  </div>
  <div
    fxFlex="30"
    fxLayout="column">
    <app-file-comments-attachments-list
      fxFlex="80"
      class="comments-section"
      (markNoteAsViewed)="markNoteAsViewed($event)"
      [notes]="notes"></app-file-comments-attachments-list>
    <div
      fxFlex="20"
      class="actions-section">
      <div fxLayout="row">
        <app-add-comments
          (close)="close()"
          (addCommentAndAttachmentsEvent)="addCommentAndAttachments($event)"
          fxFlex="100"></app-add-comments>
      </div>
    </div>
  </div>
</div>
