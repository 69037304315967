import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

import { AuthService } from '../services/auth.service';
import { SessionStorageService } from '../services/session-storage.service';

@Component({
  template: `<app-signin *ngIf="!userSignedIn"></app-signin>`,
  styles: [],
})
export class LoginPageComponent implements OnInit {
  subscription: Subscription;
  userSignedIn = false;
  constructor(
    private router: Router,
    private auth_$: AuthService,
    private route: ActivatedRoute,
    private sessionStorage_$: SessionStorageService,
  ) {}

  ngOnInit() {
    const lastUrl = this.route.snapshot.url[this.route.snapshot.url.length - 1];
    if (lastUrl.path === 'eu') this.sessionStorage_$.setAddToClioEU(true);
    this.auth_$.auth.onAuthStateChanged(user => {
      if (user) {
        this.userSignedIn = true;
        this.router.navigate(['/']);
      }
    });

    this.route.params.subscribe(params => {
      if (params['sso'] === 'clio') {
        if (Object.keys(this.auth_$.userData.getValue()).length === 0) {
          const sso = this.route.snapshot.queryParams['sso'] === '0' ? 0 : 1;
          this.auth_$.handleSSOClioLogin({ sso });
        } else {
          console.log('You are trying to SSO login when already logged in.');
        }
      }
    });
  }
}
