import { Injectable } from '@angular/core';
import firebase from 'firebase/app';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  constructor (private authentication_$: AuthService) { }
  public async validateRole(object: string) {
    const role = await this.authentication_$.getUserRole(this.authentication_$.uid);
    const dbPermissions = firebase.firestore().collection('permissions');
    return (await dbPermissions.doc((await dbPermissions
      .where('role', '==', role)
      .where('object', '==', object)
      .limit(1).get()).docs[0].id).get()).data().access;
  }

  public async validateRoleMulti(objectList: any) {
    const role = await this.authentication_$.getUserRole(this.authentication_$.uid);
    const dbPermissions = firebase.firestore().collection('permissions');
    const resultData = [];
    const docs = (await dbPermissions
      .where('role', '==', role)
      .where('object', 'in', objectList)
      .get()).docs;
    for (let index = 0; index < docs.length; index++) {
      const document = docs[index];
      resultData.push((await dbPermissions.doc(document.id).get()).data());
    }
    return resultData;
  }
}
