import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-json-to-table',
  templateUrl: './json-to-table.component.html',
  styleUrls: ['./json-to-table.component.scss']
})
export class JsonToTableComponent implements OnInit {
  @Input() jsondata: any;
  dataArr: any[] = [];
  constructor () { }

  ngOnInit(): void {
    if (this.jsondata) {
      Object.entries(JSON.parse(this.jsondata)).forEach(([key, value]) => this.dataArr.push({ key, value }));
    }
  }

}
