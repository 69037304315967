import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as dicomParser from 'dicom-parser';
import { UploadHelperService } from './../../../services/upload-helper.service';
// TODO: Implement a Logging Service.

@Component({
  selector: 'app-drag-and-drop-upload',
  templateUrl: './drag-and-drop-upload.component.html',
  styleUrls: ['./drag-and-drop-upload.component.scss']
})

export class DragAndDropUploadComponent {
  canDropFolder = typeof DataTransferItem.prototype.webkitGetAsEntry === 'function';
  uploadPaths = [];
  files: Object;
  scannedFiles: any = [];
  upload_count = 0;

  @Input() dragMessage;
  @Output() fileSelection = new EventEmitter();
  // @Output() uploadBlockSelection = new EventEmitter();
  @Output() BlockDragged = new EventEmitter();

  constructor (public uh$: UploadHelperService) {
    this.files = {};
  }

  dragenter(ev) {
    return Array.prototype.every.call(
      ev.dataTransfer.items,
      item => item.kind !== 'file'
    );
  }

  dragover(ev) {
    return Array.prototype.every.call(
      ev.dataTransfer.items,
      item => item.kind !== 'file'
    );
  }

  uploadCallBack() {
    console.log('this.scannedFiles', JSON.stringify(this.scannedFiles));
  }

  async drop(event) {
    event.preventDefault();
    const entries = [];
    const dTEntries = await this.getAllFileEntries(event.dataTransfer.items);
    dTEntries.forEach(entry => {
      entries.push(entry);
    });
    this.BlockDragged.emit(entries);
  }

  async getAllFileEntries(dataTransferItemList) {
    const fileEntries = [];
    // Use BFS to traverse entire directory/file structure
    const queue = [];
    // Unfortunately dataTransferItemList is not iterable i.e. no forEach
    for (let i = 0; i < (<DataTransferItemList>dataTransferItemList).length; i++) {
      queue.push(<DataTransferItem>dataTransferItemList[i].webkitGetAsEntry());
    }
    while (queue.length > 0) {
      const entry = queue.shift();
      if (entry.isFile) {
        fileEntries.push(await this.getFile(entry));
      } else if (entry.isDirectory) {
        const reader = entry.createReader();
        queue.push(...await this.readAllDirectoryEntries(reader));
      }
    }
    return fileEntries;
  }

  async getFile(fileEntry) {
    try {
      return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    } catch (err) {
      console.log(err);
    }
  }

  // Get all the entries (files or sub-directories) in a directory by calling readEntries until it returns empty array
  async readAllDirectoryEntries(directoryReader) {
    const entries = [];
    let readEntries = await this.readEntriesPromise(directoryReader);
    while ((<String[]>readEntries).length > 0) {
      entries.push(...(<String[]>readEntries));
      readEntries = await this.readEntriesPromise(directoryReader);
    }
    return entries;
  }

  // Wrap readEntries in a promise to make working with readEntries easier
  async readEntriesPromise(directoryReader) {
    try {
      return await new Promise((resolve, reject) => {
        directoryReader.readEntries(resolve, reject);
      });
    } catch (err) {
      console.log(err);
    }
  }

  scanFiles(item, container) {
    const elem = document.createElement('li');
    elem.innerHTML = item.name;
    container.appendChild(elem);

    if (item.isDirectory) {
      const directoryReader = item.createReader();
      const directoryContainer = document.createElement('ul');
      container.appendChild(directoryContainer);
      directoryReader.readEntries(entries => {
        entries.forEach(entry => {
          this.scanFiles(entry, directoryContainer);
        });
      });
    }
  }

  async get_scanedFiles(item, container) {
    const elem = document.createElement('li');
    elem.innerHTML = item.name;
    container.appendChild(elem);

    if (item.isFile) {
      item.file(file => {
        this.scannedFiles.push(file);
      });
    }

    if (item.isDirectory) {
      const directoryReader = item.createReader();
      const directoryContainer = document.createElement('ul');
      container.appendChild(directoryContainer);
      directoryReader.readEntries(entries => {
        entries.forEach(entry => {
          this.get_scanedFiles(entry, directoryContainer);
        });
      });
    }

    return true;
  }

  fileApplyExtensionFilter(file) {
    return new Promise((resolve, reject) => {
      let dataset;
      const reader = new FileReader();
      reader.onload = () => {
        const data = reader.result;
        const array = new Uint8Array(<ArrayBuffer>data);
        dataset = dicomParser.parseDicom(array).elements['x0020000d'];
        resolve(dataset);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  async util_asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  upload(tree, path) {
    tree.files.forEach(file => {
      this.uploadPaths.push(path + file.name);
    });
    tree.directories.forEach(directory => {
      const newPath = path + directory.name + '/';
      this.uploadPaths.push(newPath);
      this.upload(directory, newPath);
    });
  }
}
