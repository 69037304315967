import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  template: `<app-window-title
      [title]="title"
      [closable]="true"></app-window-title>
    <video
      class="video-viewer"
      *ngIf="viewer === 'video'"
      controls>
      <source
        src="{{ src }}"
        type="video/mp4" />
    </video>

    <img
      style="max-width: 100%; max-height: 100%; display: block; margin: auto"
      *ngIf="viewer === 'url'"
      [src]="src"
      alt="" />

    <ngx-doc-viewer
      *ngIf="viewer !== 'video' && viewer !== 'url'"
      [url]="src"
      [ngClass]="{ hidden: !docViewerLoaded }"
      disableContent="none"
      [viewer]="viewer"
      (loaded)="onDocumentLoaded($event)"
      class="viewer"></ngx-doc-viewer>

    <app-loader
      *ngIf="viewer !== 'video' && viewer !== 'url' && !docViewerLoaded"
      message="Document loading..."></app-loader>`,
  styles: [
    `
      .video-viewer {
        width: 100%;
      }

      :host {
        display: block;
      }

      .viewer {
        width: 100%;
        height: 50vh;
      }

      .hidden {
        height: 0;
        overflow: hidden;
        width: 0;
      }
    `,
  ],
})
export class DocViewerComponent {
  src: any;
  title: any;
  info: any;
  viewer: any;
  docViewerLoaded = false;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    const { title, src, info, viewer } = data;
    this.title = title;
    this.info = info;
    this.src = src;
    this.viewer = viewer;
    console.log('data: ', data);
  }

  onDocumentLoaded(event) {
    console.log('event: ', event);
    this.docViewerLoaded = true;
  }
}
