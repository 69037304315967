import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContentHandlerService {
  howToBurnADiscComponent(key) {
    switch (key) {
      case 'getStepsData':
        return [
          { id: 1, title: 'GENERATE A DISC', completed: true },
          { id: 2, title: 'DOWNLOAD TO DESKTOP', completed: true },
          { id: 3, title: 'GO TO DOWNLOAD LOCATION', component: 'app-go-to-download-location', completed: false },
          { id: 4, title: 'BURN THE DISC', component: 'app-how-to-burn-adisc-steps', completed: false },
        ];
      default:
        break;
    }
  }

  homePageComponent(key) {
    switch (key) {
      case 'homePageParagraph':
        return `A unique application that allows you to upload files (e.g. PDF, Word, videos, JPEG, Medical images and more) from
    your desktop into folders where they are stored. You can then seamlessly share selected files with others. You can
    also choose to collaborate while simultaneously viewing the same files while in a video conference session.
    <br />
    <br />
    <a href="http://www.nuagedx.com">www.nuagedx.com</a>`;
      case 'homePageTitle':
        return 'Demo Component';
      case 'homePageMessage':
        return `This is a demo component loaded dynamically from a module.`;
      default:
        break;
    }
  }

  clientProfileComponent(key) {
    switch (key) {
      case 'clientProfile':
        return [
          {
            id: 1,
            name: 'John Doe',
          },
        ];
      default:
        break;
    }
  }

  constructor() {}
}
