<div class="panel-wrapper fusion-column column col-lg-3 col-md-3 col-sm-3 fusion-pricingtable-column">
  <div class="panel-container">
    <div class="fusion-panel">
      <div class="panel-heading">
        <h3
          class="title-row"
          style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
          data-fontsize="18"
          data-lineheight="27px">
          Enterprise Platinum and Enterprise Platinum Plus (+)
        </h3>
      </div>
      <div class="panel-body pricing-row">
        <div class="price price-with-decimal">
          <span class="price-unit"
            ><span class="currency">$</span><span class="integer-part">44</span><sup class="decimal-part">95</sup
            ><span class="time">mo</span></span
          ><span class="price-unit"
            ><span class="currency">$</span><span class="integer-part">39</span><sup class="decimal-part">95</sup
            ><span class="time">mo</span></span
          >
        </div>
      </div>
      <ul class="list-group">
        <li class="list-group-item normal-row">
          Each subscriber can simultaneously share same documents with one (1) administrator and one (1) associate of
          choice
        </li>
        <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
        <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
        <li class="list-group-item normal-row">DICOM image disc upload and Cloud storage (medical imaging studies)</li>
        <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
        <li class="list-group-item normal-row">Document and video (MP4, MOV, AVI) upload and Cloud storage</li>
        <li class="list-group-item normal-row">HIPAA Compliant Storage</li>
        <li class="list-group-item normal-row">
          Built-in Zoom video conference feature with unlimited Video conference time and 300 participants/session
        </li>
        <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
        <li class="list-group-item normal-row">Video conference recording and storage</li>
        <li class="list-group-item normal-row">5TB /month storage comes with plan** Unlimited storage as needed</li>
        <li class="list-group-item normal-row">Remote Client upload â€“ discs and documents</li>
        <li class="list-group-item normal-row">Video conference transcription (*coming soon) â€“ no extra charge</li>
        <li class="list-group-item normal-row">Voice recognition (*coming soon) â€“ no extra charge</li>
      </ul>
    </div>
  </div>
</div>
