<div *ngIf="notes?.length">
  <mat-card *ngFor="let note of notes">
    <mat-card-header>
      <mat-card-subtitle>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center">
          <div
            fxFlex="70"
            class="account-label"
            [ngClass]="{ 'blue-text': note.hasNotifications }">
            <mat-icon>account_circle</mat-icon> <b>{{ note.authorName || note.createdBy }}</b>
          </div>
          <div
            fxFlex="30"
            class="date-label"
            *ngIf="note.datetime">
            {{ note.datetime | date : 'MM-dd-yyyy HH:mm:ss' || '' }}
          </div>
        </div>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="indented-content">
      <p *ngIf="note.note">{{ note.note }}</p>
      <div *ngIf="note.attachments?.length > 0">
        <div *ngFor="let attachment of note.attachments">
          <a
            href="javascript:void(0)"
            (click)="handleOpenAttachment(attachment.filePath, $event)"
            target="_blank"
            ><mat-icon>attach_file</mat-icon> {{ attachment.description }}</a
          >
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
