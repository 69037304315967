import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.css']
})
export class SessionTimeoutComponent implements OnInit {
  timer: number;

  constructor (public dialogRef: MatDialogRef<SessionTimeoutComponent>) { }

  ngOnInit() {
    this.timer = 10;
    setInterval(() => {
      this.timer--;
      if (this.timer === 0) { this.sessionEnd(); }
    }, 1000);
  }

  continue() {
    this.dialogRef.close('session_continue');
  }

  sessionEnd() {
    this.dialogRef.close('session_end');
  }

  finished() {
    this.sessionEnd();
  }

}
