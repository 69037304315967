<mat-accordion multi="false">
  <mat-list role="list">
    <ng-container *ngFor="let item of howToBurnADiscSteps">
      <mat-list-item
        class="auto-height"
        role="listitem"
        *ngIf="isString(item)">
        {{ item }}
      </mat-list-item>
      <mat-list-item
        class="auto-height"
        role="listitem"
        *ngIf="isObject(item)">
        <mat-expansion-panel
          class="custom-panel"
          [class.disabled-panel]="!item.component"
          [hideToggle]="!item.component"
          [disabled]="!item.component">
          <mat-expansion-panel-header class="header">
            <mat-panel-title
              class="flex-align"
              [ngClass]="{
                completed: item.completed,
                running: item.running,
                ready: item.ready
              }">
              <mat-icon class="red-icon">{{ item.completed ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
              {{ item.title }}
            </mat-panel-title>
            <mat-spinner
              *ngIf="item.running"
              class="spinner"
              [diameter]="30"
              [strokeWidth]="4"
              [color]="'accent'"></mat-spinner>
            <!-- Create an OK button to close the panel and open the next one. -->
            <button
              *ngIf="item.ready && !item.running && !item.completed"
              mat-raised-button
              color="accent"
              (click)="openNextPanel(item)">
              OK
            </button>
          </mat-expansion-panel-header>
          <ng-container
            *ngIf="isObject(item)"
            [ngSwitch]="item.component">
            <app-how-to-burn-adisc-steps *ngSwitchCase="'app-how-to-burn-adisc-steps'"></app-how-to-burn-adisc-steps>
            <app-go-to-download-location *ngSwitchCase="'app-go-to-download-location'"></app-go-to-download-location>
          </ng-container>
        </mat-expansion-panel>
      </mat-list-item>
    </ng-container>
  </mat-list>
</mat-accordion>
