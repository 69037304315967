<div
  class="paragraph"
  *ngIf="error">
  {{ errorMessage }}
  <a
    href="#"
    (click)="reloadThisPage($event)"
    >Reload</a
  >
  |
  <a
    href="#"
    (click)="rememberLastSession($event)"
    >Back to Client/Matter Profile</a
  >
</div>
