<app-window-title
  [title]="'Create folder'"
  [closable]="true"></app-window-title>

<form [formGroup]="createFolderForm">
  <mat-form-field appearance="fill">
    <mat-label>Enter folder name:</mat-label>
    <input
      type="text"
      [formConrol]="foldernameFormControl"
      [errorStateMatcher]="matcher"
      matInput
      #folderName
      name="foldername"
      id="foldername" />
    <mat-error
      class="mat-small"
      *ngIf="foldernameFormControl.hasError('required')">
      Folder name is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <br />
  <button
    [disabled]="folderName.value === ''"
    mat-flat-button
    (click)="createFolder(folderName.value)"
    color="warn">
    CREATE FOLDER
  </button>
</form>
