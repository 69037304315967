import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowsManagerService {
  searchComponentRef: any;
  profileDialogRef: any;
  clientProfileOpened: boolean;

  constructor() { }
}
