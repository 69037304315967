import { Component, Inject, Input } from '@angular/core';

@Component({
  template: ` <video
      class="video-viewer"
      *ngIf="viewer === 'video'"
      controls>
      <source
        src="{{ src }}"
        type="video/mp4" />
    </video>
    <ngx-doc-viewer
      *ngIf="viewer !== 'video'"
      [url]="src"
      [viewer]="viewer"
      class="viewer"></ngx-doc-viewer>`,
  styles: [
    `
      .video-viewer {
        width: 100%;
        height: 100%;
      }

      :host {
        background: #fff;
        height: 100%;
      }

      .viewer {
        height: 100%;
        width: 100%;
      }
    `,
  ],
  selector: 'app-doc-viewer-no-data',
})
export class DocViewerNoDataComponent {
  @Input() src: any;
  @Input() title: any;
  @Input() info: any;
  @Input() viewer: 'google' | 'office' | 'mammoth' | 'video' | 'pdf' | 'url' = 'google';

  constructor() {
    this.title = this.title;
    this.info = this.info;
    this.src = this.src;
    this.viewer = this.viewer || 'google';
  }
}
