import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../ui/optionChooser/optionChooser.component';
import { ErrorStateMatcher } from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-new-folder',
  templateUrl: './new-folder.component.html',
  styleUrls: ['./new-folder.component.scss']
})

export class NewFolderComponent implements OnInit {
  foldernameFormControl = new FormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();
  createFolderForm: FormGroup;

  constructor (public dialogRef: MatDialogRef<NewFolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.createFolderForm = new FormGroup({
      foldernameFormControl: new FormControl('', [Validators.required])
    });
  }

  createFolder(folderName) {
    this.dialogRef.close(folderName);
  }

}
