import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import firebase from 'firebase';

@Component({
  selector: 'app-view-note',
  template: `
    <app-window-title
      title="View Note"
      [closable]="true"></app-window-title>

    <b>Title:</b> {{ data.title }}<br />
    <b>Created By:</b> {{ data.createdBy }}<br />
    <b>Note text:</b> {{ data.note }}<br />
    <b>Patient Name:</b> {{ data.patientName }}<br />

    <div
      class="mat-body"
      *ngIf="data.attachments && data.attachments.length">
      <h3>Attachments:</h3>
      <div style="flex-wrap: wrap; display: flex">
        <div *ngFor="let attachment of data.attachments">
          <div fxLayout="row">
            <div fxLayout="column">
              <b>{{ substringFileName(attachment.filePath) }}</b>
              <div>{{ attachment.description }}</div>
            </div>
            <div fxLayout="row">
              <button
                (click)="browseFile(attachment.filePath)"
                mat-icon-button>
                <mat-icon>visibility</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      body {
        background-color: #f5f5f5;
        margin: 0;
        padding: 0;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: #333;
      }
    `,
  ],
})
export class ViewNoteComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  substringFileName(path: string) {
    if (typeof path !== 'string') return '';
    if (!path || path === '') return '';
    const splitted = path.split('/');
    if (splitted.length === 1) return splitted[0];
    return splitted[3];
  }

  browseFile(path: string) {
    firebase
      .storage()
      .ref()
      .child(path)
      .getDownloadURL()
      .then(url => window.open(url, '_blank'));
  }
}
