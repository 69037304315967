import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FirebaseUtilitiesService } from '../services/firebase-utilities.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-active-consultant',
  templateUrl: './active-consultant.component.html',
  styleUrls: ['./active-consultant.component.scss'],
})
export class ActiveConsultantComponent implements OnInit {
  @Input() consultant: any;
  @Input() consultants: any;
  @Input() patientDocId: string;
  @Input() caseName: string;
  @Input() selectedFiles: any = [];
  @Input() sharedFilesCount: number = 0;
  @Input() selected: boolean;
  @Input() restrictedProfiles: any = [];
  @Output() needConsultantsReloadEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToFolderEvent: EventEmitter<any> = new EventEmitter();
  @Output() shareEvent: EventEmitter<any> = new EventEmitter();
  @Output() unshareEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    public snackBar: MatSnackBar,
    private firebase_utilities_$: FirebaseUtilitiesService,
    private Utils$: UtilsService,
  ) {}

  ngOnInit(): void {
    console.log('-');
  }

  isRestrictedProfile(consultant): boolean {
    if (!this.restrictedProfiles) return false;
    if (this.restrictedProfiles.length === 0) return false;
    return this.restrictedProfiles.includes(consultant.uid);
  }

  share(consultant) {
    this.shareEvent.emit({ email: consultant.email, uid: consultant.uid });
  }

  goToIndex(event) {
    event.stopPropagation();
    event.preventDefault();
    this.goToFolderEvent.emit({ folderId: 'all', consultant: this.consultants._value[0] });
  }

  unShare(consultant) {
    this.unshareEvent.emit({ consultant: consultant.email, type: 'single' });
  }

  checkSelectedShared(consultant, selectedFiles) {
    let counder = 0;
    if (selectedFiles.length < 2) return false;
    selectedFiles.forEach(file => {
      if (this.fileIsShared(consultant, file)) counder++;
    });
    return counder === selectedFiles.length ? true : false;
  }

  fileIsShared(consultant, file) {
    return this.Utils$.fileIsShared(consultant, file);
  }

  makeProfilePublic(event, consultant) {
    event.stopPropagation();
    event.preventDefault();
    this.firebase_utilities_$
      .makeProfilePublic(this.patientDocId, consultant.uid)
      .then(r => this.afterProfileUpdated())
      .catch(e => console.log(e));
  }

  private afterProfileUpdated() {
    this.snackBar.open('Profile visibility updated', 'OK', { duration: 2000 });
    this.needConsultantsReloadEvent.emit();
  }

  checkSelected(consultant) {
    if (this.selectedFiles.length > 1) return this.checkSelectedShared(consultant, this.selectedFiles) ? true : false;
  }

  makeProfileRestricted(event, consultant) {
    event.stopPropagation();
    event.preventDefault();
    this.firebase_utilities_$
      .makeProfileRestricted(this.patientDocId, consultant.uid)
      .then(r => this.afterProfileUpdated())
      .catch(e => console.log(e));
  }
}
