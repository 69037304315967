<div style="display: flex; flex-direction: row" mat-dialog-title>
  <h3>Create Admin</h3>
</div>
<div mat-dialog-content>
  <div fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="mdc-text-field mdc-text-field--no-label name-signup">
        <input
          type="text"
          class="mdc-text-field__input"
          placeholder="Name"
          #nameInput
          [value]="name"
          (input)="name = nameInput.value"
          required
        />
      </div>
      <div class="mdc-text-field mdc-text-field--no-label name-signup">
        <input
          type="text"
          class="mdc-text-field__input"
          placeholder="Specialty"
          #specialty
          [value]="name"
          (input)="name = specialty.value"
        />
      </div>

      <div class="mdc-text-field-helper-line">
        <div
          class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg"
        >
          Admin must have a first name
        </div>
      </div>

      <!--Email Text Field -->
      <div class="mdc-text-field mdc-text-field--no-label email-signup">
        <input
          autocomplete="false"
          type="email"
          class="mdc-text-field__input"
          placeholder="Email"
          #emailInput
          [value]="email"
          (input)="email = emailInput.value"
          required
        />
      </div>
      <div class="mdc-text-field-helper-line">
        <div
          class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg"
        >
          Email invalid
        </div>
      </div>

      <!-- Password Text Field -->
      <div class="mdc-text-field mdc-text-field--no-label password-signup">
        <input
          type="password"
          id="input_password"
          class="mdc-text-field__input"
          placeholder="Password"
          #passwordInput
          [value]=""
          (input)="password = passwordInput.value"
          required
          minlength="8"
          autocomplete="new-password"
        />
      </div>
      <div class="mdc-text-field-helper-line">
        <div
          class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg"
        >
          Password must have a min length of 8
        </div>
      </div>
      <p class="fgpass-text">A password reset link will be sent to the Admin</p>
      <!-- Checkbox -->
      <div fxLayoutAlign="column">
        <div class="mdc-form-field">
          <div class="mdc-radio admin-radio">
            <input
              class="mdc-radio__native-control"
              type="radio"
              id="radio-admin"
              name="radios"
              (click)="onCheckedAccount('an Admin')"
            />
            <div class="mdc-radio__background"></div>
          </div>
        </div>
        <div class="mdc-form-field">
          <div
            class="mdc-radio cons-radio"
            (click)="onCheckedAccount('a Consultant')"
          >
            <input
              class="mdc-radio__native-control"
              type="radio"
              id="radio-consultant"
              name="radios"
            />
            <div class="mdc-radio__background"></div>
          </div>
        </div>
      </div>
      <h5 style="color: red; font-family: Arial">{{ errorMessage }}</h5>
      <!-- Forgot your password Text -->
      <p class="fgpass-text">A password reset link will be sent to the Admin</p>
    </div>
  </div>
</div>
<mat-dialog-actions align="center">
  <button mat-flat-button mat-dialog-close color="accent">Cancel</button>
  <button mat-flat-button (click)="createUser()" color="primary">
    Create Admin
  </button>
</mat-dialog-actions>
