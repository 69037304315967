import { MatDialogRef } from '@angular/material/dialog';
import { Component, NgZone } from '@angular/core';

@Component({
  templateUrl: './two-factor-auth-request.dialog.html',
})
export class TwoFactorAuthRequestDialogComponent {
  constructor(private ngZone: NgZone, private dialogRef: MatDialogRef<TwoFactorAuthRequestDialogComponent>) {}

  handleClose(answer?) {
    this.ngZone.run(() => this.dialogRef.close({ answer: answer }));
  }
}
