<div
    class="homepage-container"
    *ngIf="!userEnabled"
>
    <a
        class="logo-link"
        href="/"
    >
        <img
            alt="NuageDX Logo"
            src="../../assets/Nuage-Logo.png"
            class="logo-image"
        >
    </a>
    <p
        class="homepage-paragraph"
        [innerHTML]="homepageParagraph"
    ></p>
</div>
