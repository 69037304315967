import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-window-title',
  template: `
    <div fxLayout="row">
      <div fxFlex>
        <h3
          class="mat-headline"
          [ngClass]="{ 'title-no-bottom-space': subtitle }"
          style="display: block"
          *ngIf="title">
          {{ title }}
        </h3>
        <i
          class="mat-body"
          style="display: block"
          *ngIf="subtitle">
          {{ subtitle }}
        </i>
      </div>
      <div
        fxFlex="none"
        fxFlexOffset="1">
        <button
          [ngClass]="{ tiny: closableStyle }"
          mat-flat-button
          mat-dialog-close
          *ngIf="closable"
          color="warn">
          X
        </button>
      </div>
    </div>
    <hr
      *ngIf="subtitle"
      class="subtitle-hr" />
  `,
  styles: [
    `
      .subtitle-hr {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        border: 0;
        border-top: 1px solid #eee;
      }
      .title-no-bottom-space {
        margin-bottom: 0;
      }
      .tiny {
        border-radius: 50%;
        width: 30px !important;
        min-width: 0px !important;
        height: 30px !important;
        background-color: transparent !important;
        padding: 0;
        color: red;
      }
    `,
  ],
})
export class WindowTitleComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() closable: boolean;
  @Input() closableStyle: boolean = false;
  @Input() bottomBorder: boolean = false;
}
