<div
  class="panel-wrapper fusion-column column col-lg-3 col-md-3 col-sm-3 fusion-pricingtable-column"
  *ngFor="let plan of plans">
  <div class="panel-container">
    <div class="fusion-panel">
      <div class="panel-heading">
        <h3
          class="title-row"
          style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
          data-fontsize="18"
          data-lineheight="27px">
          {{ plan.label }}
        </h3>
      </div>
      <div class="panel-body pricing-row">
        <div
          class="price price-with-decimal"
          [innerHTML]="plan.price"></div>
      </div>
      <ul
        class="list-group"
        [innerHTML]="plan.content"></ul>
    </div>
  </div>
</div>
