import 'firebase/functions';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmsService {
  fc = firebase.functions();
  constructor (private http: HttpClient
  ) { }

  sendSMS(message: string, phoneNumber: string) {
    const url = `${environment.constants.cloudfunctionsURL}sms-sendSMS`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'secret-key'
      })
    };
    return this.http.post(url, { message, phoneNumber }, httpOptions);
  }
}
