import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent {

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: { email: string },
    private dialogRef: MatDialogRef<AddContactComponent>) { }

  handleSubmit(name, jobDescription) {
    if (name && jobDescription) {
      this.dialogRef.close({ name, jobDescription });
    } else {
      return false;
    }
  }

}
