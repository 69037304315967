import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { UserRoles } from 'src/app/dictionaries/UserRoles';

import { DialogService } from 'src/app/dialog.service';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { UIMessagingService } from '../../services/uimessaging.service';
import Validation from '../providers/CustomValidators';
import { SimpleMessageWindowComponent } from './../ui/simple-message-window/simple-message-window.component';
import { UpgradePlanComponent } from './../upgrade-plan/upgrade-plan.component';

@Component({
  selector: 'app-create-cua-user',
  templateUrl: './create-cua-user.component.html',
  styleUrls: ['./create-cua-user.component.scss'],
})
export class CreateCuaUserComponent implements OnInit {
  currentUser: firebase.User;
  curentUserRole: any;
  UserRoles: {
    owner: string;
    admin: string;
    client: string;
    associate: string;
    consultant: string;
    superuser: string;
  };
  clientForm: FormGroup;
  radioOptions: { value: string; label: string; prefix: string }[];
  submitApproval = false;
  submitted = false;
  passwordStrength: number;
  @ViewChild('passwordComponent') passwordComponent: any;
  generalError: any;
  whitespace = new RegExp(/^[^\\s]*$/);

  constructor(
    public auths: AuthService,
    public router: Router,
    public ng: NgZone,
    public dialog: MatDialog,
    private dialog_$: DialogService,
    public dialogRef: MatDialogRef<CreateCuaUserComponent>,
    private auth_$: AuthService,
    private uiMessaging_$: UIMessagingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
      client: UserRoles.client,
    };
  }

  async createUser(type?: string) {
    let user;
    const ownerID =
      (await this.auths.getUserRole(this.currentUser.uid)) === UserRoles.owner
        ? this.currentUser.uid
        : await this.auths.getOwnerID(this.currentUser.uid);

    if (!ownerID) {
      this.uiMessaging_$.toastMessage('ownerID is missing', 'FIX');
      return new Error('ownerID is missing');
    }

    const { email, firstname, lastname, password, phone } = this.clientForm.value;
    user = new User(
      UserRoles.client,
      email.toLowerCase(),
      `${firstname} ${lastname}`,
      ownerID,
      ownerID,
      [ownerID],
      firstname,
      lastname,
      this.data.patient.caseName,
      phone,
    );
    // FIXME: Have to do this with a Cloud Function instead.
    return this.auths.createUserAndSendPasswordResetEmail(password, user, this.currentUser.uid);
  }

  getErrorMessage(formControl, controlname?: string, num?) {
    if (formControl.invalid) {
      if (formControl.errors.required) {
        return controlname === 'usertype' ? `You need to select a user type` : `You need to provide ${controlname}.`;
      }
      if (formControl.errors.minlength) {
        return `The field ${controlname} requires at least ${formControl.errors.minlength.requiredLength} characters length.`;
      }
      if (formControl.errors.email) {
        return `A valid email address is required.`;
      }
      if (formControl.errors.whitespace) {
        return `Please avoid blank spaces.`;
      }
      if (formControl.errors.mustMatch) {
        return `Password and confirm password doesn't match.`;
      }
      if (formControl.errors.passwordStrength) {
        return `Password is not strong enough.`;
      }
    } else {
      return;
    }
  }

  handleUpgradeAnswer(val) {
    if (val === 'thanks') {
      this.dialog_$.open(SimpleMessageWindowComponent, {
        width: '350px',
        data: { title: 'Thanks!!', message: '<p>Your account has been upgraded.</p>' },
      });
      return;
    }

    if (val !== 'upgrade') return;

    const dialogConfig = {
      width: '950px',
      data: {
        title: 'Upgrade Plan',
        currentPlan: this.auths.userData.getValue()['plan']['id'],
        message: '<p>Go and upgrade your current plan.</p>',
      },
    };

    const dialogRef = this.dialog_$.open(UpgradePlanComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: value => this.handleUpgradeAnswer(value),
      error: err => console.warn(err),
      complete: () => console.log('Completed'),
    });
  }

  async ngOnInit() {
    this.passwordStrength = 0;

    this.radioOptions = [
      { value: 'associate', label: 'Associate', prefix: 'an' },
      { value: 'admin', label: 'Admin', prefix: 'an' },
      { value: 'consultant', label: 'Consultant', prefix: 'a' },
    ];

    this.curentUserRole = await this.auths.getUserRole(this.auths.uid);
    if (this.curentUserRole !== UserRoles.owner) {
      this.radioOptions = [{ value: 'consultant', label: 'Consultant', prefix: 'a' }];
    }

    this.clientForm = new FormGroup(
      {
        firstname: new FormControl(this.data.patient.FirstName, [Validators.required]),
        lastname: new FormControl(this.data.patient.LastName, [Validators.required]),
        email: new FormControl('', [Validators.email, Validators.required]),
        passwordconfirm: new FormControl('', [Validators.required]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(4),
          () => Validation.passwordStrength(this.passwordStrength),
          Validators.pattern(this.whitespace),
        ]),
        phone: new FormControl('', [Validators.required]),
      },
      Validation.mustMatch('password', 'passwordconfirm'),
    );
    this.clientForm.controls.password.valueChanges.subscribe({
      next: value => {
        if (value && value.indexOf(' ') >= 0)
          this.clientForm.controls.password.setErrors({ whitespace: true }, { emitEvent: false });
        else return;
      },
    });

    this.auths.user.subscribe(async user => {
      if (user !== null && user !== undefined) this.currentUser = user;
      else if (user === undefined && user === null) {
        document.getElementById('nuageMenu').style.display = 'none';
        this.router.navigateByUrl('/');
      }
    });
  }

  onStrengthChanged(event) {
    this.passwordStrength = event;
    this.clientForm.controls['password'].updateValueAndValidity();
    this.submitApproval =
      event === 100 && this.clientForm.controls.passwordconfirm.value === this.clientForm.controls.password.value
        ? true
        : false;
  }

  async onSubmit() {
    this.submitted = true;

    // If the plan is 'free' or 'fp' show the upgrade window.

    if (this.clientForm.invalid) {
      return;
    }

    /************************/
    if (document.querySelector('#options-group')) {
      document.querySelector('#options-group').classList.remove('hidden');
    }

    if (this.clientForm.invalid) {
      return;
    }

    if (
      ['free', 'fp', 'silver', 'silveryearly', 'gold', 'goldyearly'].includes(
        this.auths.userData.getValue()['plan']['id'],
      )
    ) {
      this.showRequireUpgradeWindow(
        "<p>Your current plan doesn't allow you to create <b>Client/Matter Profile Users</b>.</p>",
      );
      return;
    }

    this.showLoader();
    const results = await this.createUser('cua');

    this.hideLoader();

    if (results && results.code) {
      this.auths.errorAuth.next(results.message);
      this.generalError = results.message;
      return;
    } else {
      this.dialogRef.close({ success: true, result: { email: this.clientForm.value.email } });
    }
  }

  showLoader() {
    this.auth_$.showLoader();
  }

  hideLoader() {
    this.auth_$.hideLoader();
  }

  showRequireUpgradeWindow(message?: string) {
    const dialogConfig = {
      width: '350px',
      data: {
        title: 'Upgrade Plan',
        message:
          message ||
          '<p>You have reached your limit of users with your current plan.  You can add users by upgrading your plan.</p>',
        buttons: [{ text: 'PLAN UPGRADE', value: 'upgrade' }],
      },
    };

    this.dialog_$
      .open(SimpleMessageWindowComponent, dialogConfig)
      .afterClosed()
      .subscribe({
        next: val => this.handleUpgradeAnswer(val),
        error: err => console.warn(err),
        complete: () => console.log('Completed'),
      });
  }
}
