<div
  class="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column">
  <div
    class="fusion-separator fusion-full-width-sep"
    style="
      align-self: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0px;
      margin-bottom: 10px;
      width: 100%;
    ">
    <div
      class="fusion-separator-border sep-single sep-dashed"
      style="--awb-height: 20px; --awb-amount: 20px; border-color: #e0e0e0; border-top-width: 5px"></div>
  </div>
  <div
    class="fusion-title title fusion-title-7 fusion-title-center fusion-title-text fusion-title-size-two"
    style="--awb-margin-top: 20px; --awb-margin-bottom: 30px"
    id="compare_plans">
    <div class="title-sep-container title-sep-container-left">
      <div
        class="title-sep sep- sep-solid"
        style="border-color: #e0dede"></div>
    </div>
    <span class="awb-title-spacer"></span>
    <h2
      class="title-heading-center"
      style="margin: 0; text-transform: var(--awb-typography3-text-transform); --fontSize: 33; line-height: 1.5">
      Compare plans in detail
    </h2>
    <span class="awb-title-spacer"></span>
    <div class="title-sep-container title-sep-container-right">
      <div
        class="title-sep sep- sep-solid"
        style="border-color: #e0dede"></div>
    </div>
  </div>
  <div
    class="table-1 fusion-no-small-visibility pricing-table"
    id="pricing-table">
    <table width="100%">
      <thead>
        <tr>
          <th
            style="text-align: center"
            align="left"></th>
          <th
            style="text-align: center"
            align="left">
            <h3
              data-fontsize="24"
              style="--fontSize: 24; line-height: 1.13"
              data-lineheight="27.12px"
              class="fusion-responsive-typography-calculated">
              Free
            </h3>
          </th>
          <th
            style="text-align: center"
            align="left">
            <h3
              data-fontsize="24"
              style="--fontSize: 24; line-height: 1.13"
              data-lineheight="27.12px"
              class="fusion-responsive-typography-calculated">
              Silver
            </h3>
          </th>
          <th
            style="text-align: center"
            align="left">
            <h3
              data-fontsize="24"
              style="--fontSize: 24; line-height: 1.13"
              data-lineheight="27.12px"
              class="fusion-responsive-typography-calculated">
              Gold
            </h3>
          </th>
          <th
            class="most-popular"
            align="center">
            <strong class="popular-plan">Most popular</strong>
            <p></p>
            <h3
              data-fontsize="24"
              style="--fontSize: 24; line-height: 1.13"
              data-lineheight="27.12px"
              class="fusion-responsive-typography-calculated">
              Platinum
            </h3>
          </th>
          <th
            class="most-popular"
            align="center">
            <strong class="popular-plan">Most popular</strong>
            <p></p>
            <h3
              data-fontsize="24"
              style="--fontSize: 24; line-height: 1.13"
              data-lineheight="27.12px"
              class="fusion-responsive-typography-calculated">
              Clio Platinum
            </h3>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="pricing-row">
          <td align="center"></td>
          <td align="center">
            <h3
              style="text-align: center; --fontSize: 20; line-height: 1.5"
              data-fontsize="20"
              data-lineheight="30px"
              class="fusion-responsive-typography-calculated"
              [innerHTML]="prices.free"></h3>
          </td>
          <td
            style="text-align: center"
            align="center">
            <h3
              data-fontsize="20"
              style="--fontSize: 20; line-height: 1.5"
              data-lineheight="30px"
              class="fusion-responsive-typography-calculated"
              [innerHTML]="prices.silver"></h3>
          </td>
          <td
            style="text-align: center"
            align="left">
            <h3
              data-fontsize="20"
              style="--fontSize: 20; line-height: 1.5"
              data-lineheight="30px"
              class="fusion-responsive-typography-calculated"
              [innerHTML]="prices.gold"></h3>
          </td>
          <td
            style="text-align: center"
            align="left">
            <h3
              data-fontsize="20"
              style="--fontSize: 20; line-height: 1.5"
              data-lineheight="30px"
              class="fusion-responsive-typography-calculated"
              [innerHTML]="prices.platinum"></h3>
          </td>
          <td align="center">
            <h3
              style="text-align: center; --fontSize: 20; line-height: 1.5"
              data-fontsize="20"
              data-lineheight="30px"
              class="fusion-responsive-typography-calculated"
              [innerHTML]="prices.lpmp"></h3>
          </td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[0].text"></td>
          <td align="center"><app-check-image [value]="features[0].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[0].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[0].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[0].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[0].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[1].text"></td>
        <td align="center"><app-check-image [value]="features[1].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[1].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[1].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[1].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[1].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[2].text"></td>
          <td align="center"><app-check-image [value]="features[2].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[2].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[2].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[2].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[2].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[3].text"></td>
          <td align="center"><app-check-image [value]="features[3].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[3].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[3].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[3].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[3].plans.lpmp"></app-check-image></td>
        </tr>

        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[4].text"></td>
          <td align="center"><app-check-image [value]="features[4].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[4].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[4].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[4].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[4].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[5].text"></td>
          <td align="center"><app-check-image [value]="features[5].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[5].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[5].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[5].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[5].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[6].text"></td>
         <td align="center"><app-check-image [value]="features[6].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[6].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[6].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[6].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[6].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[7].text"></td>
          <td align="center"><app-check-image [value]="features[7].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[7].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[7].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[7].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[7].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[8].text"></td>
          <td align="center"><app-check-image [value]="features[8].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[8].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[8].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[8].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[8].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[9].text"></td>
          <td align="center"><app-check-image [value]="features[9].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[9].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[9].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[9].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[9].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[10].text"></td>
          <td align="center"><app-check-image [value]="features[10].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[10].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[10].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[10].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[10].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[11].text"></td>
          <td align="center"><app-check-image [value]="features[11].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[11].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[11].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[11].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[11].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[12].text"></td>
          <td align="center"><app-check-image [value]="features[12].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[12].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[12].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[12].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[12].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[13].text"></td>
          <td align="center"><app-check-image [value]="features[13].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[13].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[13].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[13].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[13].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[14].text"></td>
          <td align="center"><app-check-image [value]="features[14].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[14].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[14].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[14].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[14].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[15].text"></td>
          <td align="center"><app-check-image [value]="features[15].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[15].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[15].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[15].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[15].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[16].text"></td>
          <td align="center"><app-check-image [value]="features[16].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[16].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[16].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[16].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[16].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[17].text"></td>
          <td align="center"><app-check-image [value]="features[17].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[17].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[17].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[17].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[17].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[18].text"></td>
          <td align="center"><app-check-image [value]="features[18].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[18].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[18].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[18].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[18].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[19].text"></td>
          <td align="center"><app-check-image [value]="features[19].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[19].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[19].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[19].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[19].plans.lpmp"></app-check-image></td>
        </tr>
        <tr>
          <td
            align="left"
            class="text"
            [innerHTML]="features[20].text"></td>
          <td align="center"><app-check-image [value]="features[20].plans.free"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[20].plans.silver"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[20].plans.gold"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[20].plans.platinum"></app-check-image></td>
          <td align="center"><app-check-image [value]="features[20].plans.lpmp"></app-check-image></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
