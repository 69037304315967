import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-file-dialog',
  template: `
    <app-window-title
      [closable]="true"
      [title]="data.title"
      [subtitle]="data.label + ': ' + data.name"></app-window-title>
    <div mat-dialog-content>
      <mat-form-field appearance="fill">
        <mat-label>File content</mat-label>
        <input
          matInput
          required
          [(ngModel)]="data.desc"
          placeholder="{{ data.label }}" />
        <mat-error *ngIf="errorDescription"> {{ data.label }} is required. </mat-error>
      </mat-form-field>

      <br />

      <mat-form-field
        appearance="fill"
        *ngIf="data.date">
        <mat-label>Date of file</mat-label>
        <input
          matInput
          (dateChange)="doSomething()"
          [matDatepicker]="picker"
          [(ngModel)]="data.date.value" />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-error
        class="mat-body error-dateoffile"
        *ngIf="errorDate">
        Date of file is required.
      </mat-error>

      <div
        *ngIf="errorMessage"
        fxLayoutAlign="center center"
        fxLayout="column">
        <h5 style="color: red; font-family: Arial">{{ errorMessage }}</h5>
      </div>
    </div>

    <div mat-dialog-actions>
      <button
        mat-flat-button
        color="warn"
        (click)="saveChanges($event, data)"
        cdkFocusInitial>
        SAVE CHANGES
      </button>
    </div>
  `,
  styles: [
    `
      .mat-form-field {
        width: 100%;
      }

      .mat-dialog-content {
        margin-right: 0;
        margin-left: 0;
      }

      .error-dateoffile {
        position: relative;
        top: -22px;
        left: 0;
        border-top: 1px solid #f00;
        padding-top: 10px;
        padding-left: 10px;
      }
    `,
  ],
})
export class EditFileDialogComponent {
  data: any;
  nameFormControl = false;
  fileContentFormControl = false;
  errorMessage = '';
  errorDescription = false;
  errorDate = false;
  constructor(
    private dialogRef: MatDialogRef<EditFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) { title, type, message, name, desc, date, label },
  ) {
    this.data = {
      title,
      type,
      label,
      message,
      name,
      desc,
      date: date ? new FormControl(new Date(date)) : null,
    };
  }

  doSomething() {
    this.errorDate = this.data.value === null ? true : false;
  }

  saveChanges(event, data) {
    event.preventDefault();
    const { desc, date } = data;
    if (desc) {
      if (!desc || desc === '') {
        this.errorDescription = true;
        return;
      }
    }
    if (date) {
      if (!date.value || date.value === null) {
        this.errorDate = true;
        return;
      }
    }

    if (data.type === 'folder') {
      if (!desc || desc === '') {
        this.errorDescription = true;
        return;
      } else {
        data.name = desc;
      }
    }
    this.dialogRef.close(data);
  }

  close() {
    this.dialogRef.close(this.data);
  }
}
