import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-to-burn-adisc-steps',
  templateUrl: './how-to-burn-adisc-steps.component.html',
  styleUrls: ['./how-to-burn-adisc-steps.component.scss'],
})
export class HowToBurnADiscStepsComponent {
  constructor() {}

  stepsToBurnADisc = [
    {
      title: 'Step 1: Insert a blank disc',
      description:
        "Insert a blank disc into your computer's disc drive. Make sure the disc is compatible with your drive.",
    },
    {
      title: 'Step 2: Open burning software',
      description:
        "Open your preferred disc burning software. If you don't have one, there are many free options available online.",
    },
    {
      title: 'Step 3: Select files',
      description:
        "Select the files you want to burn to the disc. You can usually do this by clicking on 'Add files' or 'Browse' in the software.",
    },
    {
      title: 'Step 4: Start burning',
      description:
        "Click on 'Burn' or 'Start' in the software to start burning the files to the disc. Wait for the process to complete.",
    },
    {
      title: 'More information',
      description:
        'In order to get more information on how to burn a disc, you can visit the following link: <a href="https://www.wikihow.com/Burn-a-CD" target="_blank">https://www.wikihow.com/Burn-a-CD</a>',
    },
    // Add more steps as needed
  ];
}
