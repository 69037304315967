import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
  constructor() {}
  @Output() clickAction = new EventEmitter();
  @Input() notifications: any[] = [];
  runClick(action: string, id: string) {
    this.clickAction.emit({ action, id });
  }
  onHandleClick(code) {
    this.clickAction.emit(code);
  }
}
