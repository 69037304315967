import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ZendeskService } from 'src/app/services/zendesk.service';
import { UserRoles } from 'src/app/dictionaries/UserRoles';

@Component({
  selector: 'app-client-profile-top-buttons',
  templateUrl: './client-profile-top-buttons.component.html',
  styleUrls: ['./client-profile-top-buttons.component.scss'],
})
export class ClientProfileTopButtonsComponent {
  discsFolderName = 'Discs';
  UserRoles = UserRoles;
  // Declare an EventEmmiter to emit an event when the user clicks on the "Import from Apps" button
  @Output() importFromAppsEvent = new EventEmitter<any>();

  // Declare en EventEmmiter to emit an event when the user clicks on the "Back to Clio" button
  @Output() backToClioEvent = new EventEmitter<any>();

  // Declare an EventEmmiter to emit an event when the user clicks on open dialog button
  @Output() openDialogEvent = new EventEmitter<any>();

  @Output() addConsultantEvent = new EventEmitter<any>();

  // Declare Inputs to receive data from the parent component (imrpotFromAppsBtn, clioMatterId, realRole, permissions_uploadfiles).
  @Input() importFromAppsBtn: boolean;
  @Input() clioMatterId: string;
  @Input() realRole: string;
  @Input() permissions_uploadfiles: boolean;
  @Input() currentFolderName: string;

  constructor(private zendesk_$: ZendeskService) {}

  openDialog($event: MouseEvent) {
    this.openDialogEvent.emit($event);
  }

  addConsultant() {
    this.addConsultantEvent.emit();
  }

  showImportFromApps() {
    const ticketBody = `Ticket body goes here`;
    this.importFromAppsEvent.emit();
    return this.zendesk_$.createTicket('Import from Apps', ticketBody).then(res => {
      console.log('Ticket created', res);
    });
  }

  backToClio(clioMatterId) {
    this.backToClioEvent.emit(clioMatterId);
  }
}
