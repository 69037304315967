<p
  *ngIf="
    currentFolder !== '' && filesTableDataSource.data.length && permissions_uploadfiles && UserRoles.client !== realRole
  "
  [ngClass]="'mat-body'">
  1. You must select one or more of the
  <b *ngIf="currentFolderName !== discsFolderName; else elseLabel"> files you have uploaded </b>
  <ng-template #elseLabel><b> files you have generated </b></ng-template>
  to have the file actions enabled.
  <br />
  <span *ngIf="currentFolderName !== discsFolderName">
    2. Click
    <mat-icon
      [ngClass]="'file-icon'"
      class="people-icon"
      aria-hidden="false"
      aria-label="This file is shared."
      matTooltip="This file is shared.">
      people
    </mat-icon>
    to see who has this file.
  </span>
</p>
