import { Component, Input, OnInit } from '@angular/core';
import { FilesService } from 'src/app/services/files.service';
const dicomdirFileName = 'DICOMDIR';
@Component({
  selector: 'app-medical-studies-list',
  template: `
    <mat-list role="list">
      <div class="mat-list-items-container">
        <table
          border="1"
          class="simple-list-table">
          <thead>
            <tr>
              <th>Existing NuageDx Medical Images (studies)</th>
              <th>Study date</th>
            </tr>
          </thead>
          <tbody class="table-data">
            <tr *ngFor="let study of medicalStudies">
              <td>{{ study.fileDesc }}</td>
              <td>{{ study.fdate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <mat-list-item
        class="item-exists"
        *ngIf="medicalStudies?.length === 0"
        role="listitem"
        >No Medical Images imported yet.</mat-list-item
      >
    </mat-list>
  `,
  styles: [
    `
      .item-exists {
        color: #228b22 !important;
      }

      .mat-list-option,
      .mat-list-item {
        min-height: 25px;
        height: auto !important;
      }

      .medical-studies-list {
        width: 100%;
      }

      .mat-list-container .mat-list-item-content {
        font-size: 14px !important;
        direction: rtl !important;
      }

      .mat-list-container .mat-list-item-content .mat-list-text {
        text-align: left !important;
        padding-left: 15px !important;
      }

      .simple-list-table th {
        text-align: left;
        font-weight: 400;
        padding: 10px;
        font-size: small;
        border-right: 0;
        border-left: 0;
      }

      .table-data td {
        color: green;
        padding: 10px;
        border-right: 0;
        border-left: 0;
      }

      .simple-list-table {
        width: 100%;
        border-collapse: collapse;
        border-color: #ccc;
        border-right: 0;
        border-left: 0;
      }
    `,
  ],
})
export class MedicalStudiesListComponent implements OnInit {
  medicalStudies = [];
  constructor(private files_$: FilesService) {}

  displayedColumns: string[] = ['fileDesc', 'uploadedDate'];
  ngOnInit(): void {
    this.files_$.filesArray.subscribe((files: any) => {
      this.medicalStudies = files.filter((file: any) => file.name === dicomdirFileName);
    });
  }
}
