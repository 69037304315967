import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-loader',
  template: `
    <div class="mat-small loader-container">
      <p>
        {{ message || 'Please wait...' }}
        <mat-progress-bar
          color="warn"
          mode="indeterminate"></mat-progress-bar>
      </p>
    </div>
  `,
})
export class ProgressLoaderComponent implements OnInit {
  @Input() message: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (this.data?.message) {
      this.message = this.data.message;
    }
  }
}
