import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-client-matter-info',
  styles: [``],
  template: `
    <ul>
      <li *ngFor="let field of matterData">{{ field.label }}: {{ field.value }}</li>
    </ul>
  `,
})
export class ClientMatterInfoComponent {
  @Input() matterData: any[];
  constructor() {}
}
