<div
  fxLayout="row"
  fxLayoutAlign=" center">
  <mat-icon
    fxFlex="5"
    fxFlex="none"
    >account_circle</mat-icon
  >

  <div
    fxLayout="column"
    fxFlex="90"
    fxFlexOffset="2">
    <span
      [ngStyle]="{ margin: '0' }"
      class="mat-subheading-1">
      {{ client.FirstName && client.LastName ? client.FirstName + ' ' + client.LastName : client.name }}
      <mat-icon [ngClass]="hasNotifications ? 'blinking-star' : 'hidden'">notifications</mat-icon>
    </span>
    <span
      class="mat-body"
      fxFlex>
      Matter name: {{ client.caseName }}
      <span *ngIf="client.DateOfBirth">| DOB - {{ client.DateOfBirth }}</span>
    </span>
  </div>
  <button
    fxFlex="5"
    *ngIf="!permissions.dialogdata && permissions.ableToEditClient"
    (click)="handleEditClient($event, client)"
    mat-icon-button
    class="edit-client-button"
    aria-label="Click here to edit this client."
    matTooltip="Click here to edit this client.">
    <mat-icon>create</mat-icon>
  </button>
</div>
